import { web3 } from "@project-serum/anchor";
import { ADDRESS_LOOKUP_MAP } from "../../constants";
import { ApplicationError } from "./error";

import CITIZEN_TOKEN_ID_MAP from "../../constants/collections/Solsteads_Citizens";

const SOLSTEADS_ID_CITIZEN_TOKEN_MAP = Object.entries(
  CITIZEN_TOKEN_ID_MAP
).reduce((acc, entry) => {
  const [key, value] = entry;
  acc[value] = key;
  return acc;
}, {});

export function getSolsteadsTokenAddressById(
  solsteadsId: string
): web3.PublicKey | undefined {
  let tokenAddress = null;
  if (/^[1-9][0]*-\d{1,4}$/.test(solsteadsId)) {
    tokenAddress = SOLSTEADS_ID_CITIZEN_TOKEN_MAP[solsteadsId];
  } else {
    tokenAddress = ADDRESS_LOOKUP_MAP[solsteadsId]?.tokenAddress;
  }

  if (!tokenAddress) {
    throw new ApplicationError("Not Found", 404);
  }

  return new web3.PublicKey(tokenAddress);
}
