export class ApplicationError extends Error {
  appMessage: string;
  appStatusCode: number;

  constructor(message, statusCode) {
    super(message);
    this.appMessage = message;
    this.appStatusCode = statusCode;
  }
}
