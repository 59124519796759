import { Address } from "../types";
import { Solstead } from "../../solsteads-api";
import { getSolsteadsTokenAddressById } from "../backend/application/getSolsteadTokenAddress";

const CDN_URL = process.env.NEXT_PUBLIC_SOLSTEADS_CDN;

export function getSolsteadConstants(solsteadsId: string): Partial<Solstead> {
  if (/^[1-9][0]*-\d{1,4}$/.test(solsteadsId)) {
    return {
      id: solsteadsId,
      name: `Citizen ${solsteadsId.split("-").slice(1).join("#")}`,
      image: `${CDN_URL}/citizens/${solsteadsId}.png`,
      bannerImage: null,
      biome: null,
      defaultFrame: {
        id: "none",
      },
      guests: null,
      street: null,
      token: {
        blockchain: "sol",
        address: getSolsteadsTokenAddressById(solsteadsId).toString(),
      },
    };
  } else {
    const baseAddress = ADDRESS_LOOKUP_MAP[solsteadsId];
    if (!baseAddress) {
      return;
    }
    return {
      id: solsteadsId,
      name: baseAddress.name,
      image: `${CDN_URL}/addresses/${solsteadsId}.png`,
      bannerImage: `${CDN_URL}/addresses/${solsteadsId}_banner.png`,
      guests: null,
      biome: {
        id: baseAddress.biome,
      },
      defaultFrame: {
        id: baseAddress.frameType,
      },
      street: {
        id: baseAddress.streetId,
      },
      token: {
        blockchain: "sol",
        address: baseAddress.tokenAddress,
      },
    };
  }
}

export const ADDRESS_LOOKUP_MAP: {
  [key: string]: Omit<Address, "id" | "imageURL" | "bannerImageURL">;
} = {
  "69-ape-lane": {
    name: "Degen Ape Academy",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "ape-lane",
    tokenAddress: "HDxkerZc3HpVtzoxt4qFjUa3BhKM3SHrTg2sXzSBShhG",
  },
  "100-ape-lane": {
    name: "100 Ape Lane",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "ape-lane",
    tokenAddress: "4LYu1KFjWWHLqohSq149h4f3s8bSAwwemYJunb66fWe6",
  },
  "100-rosa-street": {
    name: "100 Rosa Street",
    biome: "field",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "3PV5ouTtDBjPRF5Z22vK7MuFxW3ja4hAuDMZYVNYxAgY",
  },
  "100-stewart-avenue": {
    name: "100 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "Cr3yaF4Wfd1UzMedMyppT6A2CiZm7rWuKNAuGVbkwLCz",
  },
  "100-supple-circle": {
    name: "100 Supple Circle",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "supple-circle",
    tokenAddress: "D7TweGagGSF5CpZMNAev4PhuZuuaRXBVXM8biRQ4YpUu",
  },
  "100-teeps-lane": {
    name: "100 Teeps Lane",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "6tnx9ze5h97vkScfqF15MJbM2UH6nPf1Gqo5eYcn39UW",
  },
  "100-wall-street": {
    name: "100 Wall Street",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "H8Ny8YY9HdKcmLtXyquGaHXEXC3BvhGfmE4fUPCdX4T9",
  },
  "101-ape-lane": {
    name: "101 Ape Lane",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "7gxWN9UQM9zuwfQY4Q3fxLQQgszEpU2c2fEt2RGNAeCH",
  },
  "101-rosa-street": {
    name: "101 Rosa Street",
    biome: "beach",
    frameType: "rounded-lavender",
    streetId: "rosa-street",
    tokenAddress: "Gr7BwqSoq345roKxyQeG9DzubQqsA78xyso984gC7Qbw",
  },
  "101-stewart-avenue": {
    name: "101 Stewart Avenue",
    biome: "mountain",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "44kE9nq7s3yBSHUwhohiNqxfjSANLA2GqCpHVfWtmwvy",
  },
  "101-supple-circle": {
    name: "101 Supple Circle",
    biome: "arctic",
    frameType: "gold",
    streetId: "supple-circle",
    tokenAddress: "8cgfVaeatEuDEaQ17A8Xj58eh8dPcER4y6Kzvh2MJVVK",
  },
  "101-teeps-lane": {
    name: "101 Teeps Lane",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "MZr4EwC83vMXLr8PjABiq41L7aspojABUM6Q8ZmEnJG",
  },
  "101-wall-street": {
    name: "101 Wall Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "wall-street",
    tokenAddress: "98GLTBrwcHdj5T4sgM67RJPcMM6Z9Gq9uvVcVSm18EVF",
  },
  "102-ape-lane": {
    name: "102 Ape Lane",
    biome: "arctic",
    frameType: "shuttered-blue",
    streetId: "ape-lane",
    tokenAddress: "HBW7EnPCm4c4uAEhKwGgcpJJNYvFZre6ASBCQhTHWxg5",
  },
  "102-rosa-street": {
    name: "102 Rosa Street",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "9nSA71nagZ7WXCTQKuFVgd1itCXzNL86RPbmZP2qrpbX",
  },
  "102-stewart-avenue": {
    name: "102 Stewart Avenue",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "8hovXczJ2Defp7ZGBCB7JtpHvZuDqjMiiKoWgASt6GG8",
  },
  "102-supple-circle": {
    name: "102 Supple Circle",
    biome: "city",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "DxpUXaus5mobpA7NX7Z9FyqwmrK7qCo9wReT96R2iiL9",
  },
  "102-teeps-lane": {
    name: "102 Teeps Lane",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "teeps-lane",
    tokenAddress: "AXbvUxYFBk1gmjm4V9n4m8yyiN5uHe7RjJrkbPQ878zL",
  },
  "103-rosa-street": {
    name: "103 Rosa Street",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "rosa-street",
    tokenAddress: "3cK8Vaw1p5Kz6vj6J8d9uwZASTjidHBebCwSrVaWaVYK",
  },
  "103-stewart-avenue": {
    name: "103 Stewart Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "HqtqXXN4QvhZBKR2fPpHgMAyPbbkF5bdJuDFx46mSnuX",
  },
  "103-supple-circle": {
    name: "103 Supple Circle",
    biome: "underwater",
    frameType: "rounded-pale-green",
    streetId: "supple-circle",
    tokenAddress: "2z1YVeiMUN873GLgpBTMrTnLhbBm7AbFtn41mJt7W9kc",
  },
  "104-rosa-street": {
    name: "104 Rosa Street",
    biome: "city",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "6hHFSCdayMUhr82CENChcqjQEjFckJz5hp4g8xF8NmjV",
  },
  "104-stewart-avenue": {
    name: "104 Stewart Avenue",
    biome: "arctic",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "Fk8XJ2GWRonekXWxBPuehtYp3XUPjM3v13xPMigcEdec",
  },
  "104-supple-circle": {
    name: "104 Supple Circle",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "supple-circle",
    tokenAddress: "8o5WfHoWn1H9oR7Bd8sSr832t7ovUdTQ8nMEm3hGSgYL",
  },
  "104-teeps-lane": {
    name: "104 Teeps Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "BP82oh47uBRUbTJY5H7rAfePTgdQE7U1y5V4ZYhFVus",
  },
  "105-rosa-street": {
    name: "105 Rosa Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "8K12DeKwFv83frMVKLHSWYbk7PrXiCmHHZFT4kcWkJH2",
  },
  "106-ape-lane": {
    name: "106 Ape Lane",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "Fyr4ehLGT7BLigPgpBSLLjVKerZ4k53qCD3gsvCyejXu",
  },
  "106-rosa-street": {
    name: "106 Rosa Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "6pVkAzH2K4Ggm61dBK4895Dhsz6zCX8AJopHzHW2og9r",
  },
  "106-supple-circle": {
    name: "106 Supple Circle",
    biome: "beach",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "4Hkgrhdfyr7xMfFuFU3otykaVUAS2RtvwgyzmztbjaWF",
  },
  "107-ape-lane": {
    name: "107 Ape Lane",
    biome: "city",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "7yjrkgULnnE6tB4kShdWdDXFZ6uPMaQ2GTVag24L29zq",
  },
  "107-rosa-street": {
    name: "107 Rosa Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "rosa-street",
    tokenAddress: "DNe8EmTqfsT8Q1P13KRaWWdXUD5H7uSYh4bbag5YFyor",
  },
  "107-stewart-avenue": {
    name: "107 Stewart Avenue",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "HWq6xvkqfFytQSedjzU7jrCvaKHNYQsY3ksYmmMAzxZ4",
  },
  "107-supple-circle": {
    name: "107 Supple Circle",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "CE43uyr8V9McdcdPusEhXiuuxxAfLNdyXtMwoV4Mitt8",
  },
  "107-teeps-lane": {
    name: "107 Teeps Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "teeps-lane",
    tokenAddress: "6ftCaZwMpgxQswtA62KS9gkgDCe3DCZwciaHecEcv6Ra",
  },
  "107-wall-street": {
    name: "107 Wall Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "wall-street",
    tokenAddress: "849KGErNpgjdnTow4MiZ4kSbsgif7NyAPiiQf11UtvVX",
  },
  "108-ape-lane": {
    name: "108 Ape Lane",
    biome: "field",
    frameType: "rounded-green",
    streetId: "ape-lane",
    tokenAddress: "GWytMfDxgJyC88HMfuyqv33rwdFaPWdfZ2stNxFZgC7s",
  },
  "108-rosa-street": {
    name: "108 Rosa Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "rosa-street",
    tokenAddress: "2nwXHdGTx2FMHz29csKeSQneKKyVRxfcS5ACbrgPJLaa",
  },
  "108-stewart-avenue": {
    name: "108 Stewart Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "EmvLRrmcpAyf8eTaLd8rd2VnVoKM87xaS9xf18kuSWCW",
  },
  "108-supple-circle": {
    name: "108 Supple Circle",
    biome: "beach",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "EwU7h131VveqLw9UxamnUUJMFUUYDDffUXNrY4vijSwV",
  },
  "108-teeps-lane": {
    name: "108 Teeps Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "AR1gxyfhWiDAeqxT1TQ5uRwXV15tJkgGgBhBBWR77fE7",
  },
  "108-wall-street": {
    name: "108 Wall Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "wall-street",
    tokenAddress: "GdFdmLfg5k5mJ7UTe4AtyxDcGFpix8UU3gLamHP5AztC",
  },
  "109-ape-lane": {
    name: "109 Ape Lane",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "94GDFygpwUcRMYZhfjQSKXyxhe6N3gfzSVXg8gXXwMCf",
  },
  "109-rosa-street": {
    name: "109 Rosa Street",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "rosa-street",
    tokenAddress: "3nYvnYbPVKmAnbfrYzG9Vuxogd7JTZBLWHofXr4BhX4G",
  },
  "109-stewart-avenue": {
    name: "109 Stewart Avenue",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "9foSE3yABY8EhFchXgVFC8bQMBUm8jr7dTbXeZCnCa7B",
  },
  "109-supple-circle": {
    name: "109 Supple Circle",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "supple-circle",
    tokenAddress: "9kDKHeBgHMoAC2TE3Dwu3JQVok8GKjU1bFVNyyHGQdzX",
  },
  "109-teeps-lane": {
    name: "109 Teeps Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "E8LLH9NaYquF3n9gUkLny4LfkxkEEwNPcZW8H1ARRJJN",
  },
  "109-wall-street": {
    name: "109 Wall Street",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "wall-street",
    tokenAddress: "A6FuBfv7PVghsu78KokmRBvwA6PVijqSe2mN5Q6PL9bh",
  },
  "110-ape-lane": {
    name: "110 Ape Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "ape-lane",
    tokenAddress: "DkrZ4vW9VVdR9XP63F7nXyuejRWtjpv8fFhDpH1magUp",
  },
  "110-supple-circle": {
    name: "110 Supple Circle",
    biome: "beach",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "CVvQdS5NsAbx3Fq92q7k5r9V7ntEJqFTF8AQnsSinPGr",
  },
  "110-teeps-lane": {
    name: "110 Teeps Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "7v653U6DH21oJ5YuniQWEnat92uY7xT1ywYuYtWyxf6i",
  },
  "110-wall-street": {
    name: "110 Wall Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "wall-street",
    tokenAddress: "CZ5HnPT4Lo9Eh7zFEnkbojik2gpXieoSNWS9S2iAoJjP",
  },
  "111-ape-lane": {
    name: "111 Ape Lane",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "ape-lane",
    tokenAddress: "8Twsc9LEDzCeJdNZvYHAeq8M5PZMC7sLqqyfMGbCmRHV",
  },
  "111-supple-circle": {
    name: "111 Supple Circle",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "Hyb7h6EHY6cwgPBrTt4tMLbb2KRroGXeLUeVPmsVQk4n",
  },
  "111-wall-street": {
    name: "111 Wall Street",
    biome: "volcanic",
    frameType: "occult",
    streetId: "wall-street",
    tokenAddress: "4FUxMByg9wneq8sRXCAiWV5QvSDVU75sd2iKXoxnhp7h",
  },
  "111-wisteria-lane": {
    name: "111 Wisteria Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "wisteria-lane",
    tokenAddress: "FjxGCoJHkU43wbv4M5oNwYyD7G7XeMFyk7XK4HkvpGFc",
  },
  "112-ape-lane": {
    name: "112 Ape Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "2H17FrS2f43zHz2tRdZoXPobBxEgUtK66a9pSK9QHfcD",
  },
  "112-supple-circle": {
    name: "112 Supple Circle",
    biome: "field",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "t9gZ4RZoo7HEn7Aa2mLy9dbSn9BiWzNPz9ZJhnxmXE1",
  },
  "112-wall-street": {
    name: "112 Wall Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "wall-street",
    tokenAddress: "DCea6Prq31WrehqCpW1Tda42r4nwVJhE8VEFSFvpcAtE",
  },
  "113-stewart-avenue": {
    name: "113 Stewart Avenue",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "9FuXaUfZdjzgSQvqriUCRqE5aBWG8QojNaSBHZzmNSVb",
  },
  "113-wall-street": {
    name: "113 Wall Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "wall-street",
    tokenAddress: "6PNmd1Af8JuGHsG1U7dXnWqgoBT2n2u3muxP3aeRixQF",
  },
  "114-ape-lane": {
    name: "114 Ape Lane",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "ape-lane",
    tokenAddress: "2UyA1MJqgosi2KCKDUD4KMdE9ki14SrgoaBF7Etq613d",
  },
  "114-stewart-avenue": {
    name: "114 Stewart Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "EdTo1RyzbYSKi8PMHbUegCEf2TXjKRYrjZTpH7naP7eN",
  },
  "114-supple-circle": {
    name: "114 Supple Circle",
    biome: "field",
    frameType: "rounded-red",
    streetId: "supple-circle",
    tokenAddress: "FRcseFaifKzpr7Nt1YNs58trdP8A9EoARMRg8cPXZFfH",
  },
  "114-wisteria-lane": {
    name: "114 Wisteria Lane",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "wisteria-lane",
    tokenAddress: "8jhfqQ6Vak1NV2ajmswboZ8pgvEzNjnsYLuxgcb4hvEa",
  },
  "115-ape-lane": {
    name: "115 Ape Lane",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "ape-lane",
    tokenAddress: "HAm3jzWMyy2YTj3NUZzCTdg36CdP2Ke35fw8zdBkY9Lj",
  },
  "115-stewart-avenue": {
    name: "115 Stewart Avenue",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "EKninCXSuBSBMTCS1VKwZYXHQkSAG9BVoNXUurjXTDXp",
  },
  "115-supple-circle": {
    name: "115 Supple Circle",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "5weq8vQsCz9zsbsxiJMTrzwDerj5VN45w8L39aC15YXQ",
  },
  "115-wall-street": {
    name: "115 Wall Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "8oPZ4CU62RptfMcoiRAQMXF7uRGtmpS8zg2zUR3uVN9W",
  },
  "116-ape-lane": {
    name: "116 Ape Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "EYxqh8oFu2SigwkANNY46LCpQS37zxRE3Ly1LVEw4UzD",
  },
  "116-supple-circle": {
    name: "116 Supple Circle",
    biome: "city",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "2CJdMG6PaMT4AWtcecbjFfWFFRxePM6EknuU4MMcyeJv",
  },
  "116-wall-street": {
    name: "116 Wall Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "EF4ATmMwe9bhzfpvKxdFUvHFP1K77PAdBrGBg3pbiQKy",
  },
  "116-wisteria-lane": {
    name: "116 Wisteria Lane",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "wisteria-lane",
    tokenAddress: "14eBKCmTrCQSdF2vgt7oJPkFq7vgQqc3WPTKW8ubztCi",
  },
  "117-stewart-avenue": {
    name: "117 Stewart Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "E7EmyTULVe8seg1Za1vneg67BLeUMYzSB2ww3JQX3p4K",
  },
  "117-supple-circle": {
    name: "117 Supple Circle",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "H3WaMNwHYGWsM8pozRmVo8ZDi9nSzaQEUUURcY9CBcpU",
  },
  "118-ape-lane": {
    name: "118 Ape Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "Gk3uCKTwDraMSWxoLfNXmb8yc6kjmXGXEEFDhSxsZ1aZ",
  },
  "118-stewart-avenue": {
    name: "118 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "DSsjmLaucivccjhcFHWPiJcsDFxps3P8MMg3jJsQEPrP",
  },
  "118-supple-circle": {
    name: "118 Supple Circle",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "supple-circle",
    tokenAddress: "DLHhj2m76rtxVXxxEiLXJ5QC3HwmQLE2KFoWFW58pV4P",
  },
  "119-ape-lane": {
    name: "119 Ape Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "A6piQq29bHv62iaHL2T9Nw8u9qnFXfGNrdrJXXWeCAG5",
  },
  "119-canal-street": {
    name: "119 Canal Street",
    biome: "field",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "4A9K3D7vq5A5gMrHAYLVMpNciNFajsvU12JRnfq5533N",
  },
  "119-supple-circle": {
    name: "119 Supple Circle",
    biome: "city",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "HZDpEERaYt9Kx3QeZ6tJPaYpbrU8DfPUkWnwjdKdb9cX",
  },
  "119-wall-street": {
    name: "119 Wall Street",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "wall-street",
    tokenAddress: "Bu8n9xmoiDRVoBDhp6YQDVeT8qh5gC7LKP8Z9Epqe8or",
  },
  "119-wisteria-lane": {
    name: "119 Wisteria Lane",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "wisteria-lane",
    tokenAddress: "5LQf5Pd9ithotWgTXQ2qSqjnNbr26S3kF3FrBdfyZSxD",
  },
  "12-arcadian-alley": {
    name: "12 Arcadian Alley",
    biome: "woods",
    frameType: "slime",
    streetId: "arcadian-alley",
    tokenAddress: "CxjtfBQqej9JsSuFTUTzY5w28qaocAVRRDbieiu7RgsC",
  },
  "120-ape-lane": {
    name: "120 Ape Lane",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "ape-lane",
    tokenAddress: "FH59eyqmaMEqAJkXML6HYFL8iNzhx9icsjKVq4EqW5pH",
  },
  "120-canal-street": {
    name: "120 Canal Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "GZqdwx4ULjbksx2BUUthETsndBMiVzYPgrQEDmsVHtEr",
  },
  "120-stewart-avenue": {
    name: "120 Stewart Avenue",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "6JjFnzeujpRidpDqp37Q2EMRPT5Qxs9SoUBxRf6ed9oF",
  },
  "120-supple-circle": {
    name: "120 Supple Circle",
    biome: "woods",
    frameType: "occult",
    streetId: "supple-circle",
    tokenAddress: "C3vZDUVSnbkWRHLeR8XuknhBDHdKTi12MhAJ4piHXArW",
  },
  "120-wall-street": {
    name: "120 Wall Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "wall-street",
    tokenAddress: "BhFp2NQi6aM2ug3Cb18x18trKqqEj5nrFQnbQV8QpSYd",
  },
  "120-wisteria-lane": {
    name: "120 Wisteria Lane",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "wisteria-lane",
    tokenAddress: "DDv3eDVne4x2n61p32R6R8wBaSSuhcjX1sacRaaaUQA9",
  },
  "122-ape-lane": {
    name: "122 Ape Lane",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "ape-lane",
    tokenAddress: "4Jw2bk86zxPnUzVa3WaYdbmRFQa5Zei3DRmp1VzQR55C",
  },
  "122-canal-street": {
    name: "122 Canal Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "canal-street",
    tokenAddress: "5PtbnGQ9ddDEXtePGr1vc2tA2A9anijSZhMqWWi2HrfF",
  },
  "122-wall-street": {
    name: "122 Wall Street",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "wall-street",
    tokenAddress: "9BUkYJz5RuwMApVf8hERNy24AFeXQ1wBYgXdZCPnkGyA",
  },
  "123-canal-street": {
    name: "123 Canal Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "AxyZAMeMXgiGpgRdYh6BKSDAXQ3VwAnngzYKKfM2t1Cm",
  },
  "123-wall-street": {
    name: "123 Wall Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "7MaAemsRtiM9x97JPowtYhzqztRxt2iiH5dwSTahFB1b",
  },
  "123-wisteria-lane": {
    name: "123 Wisteria Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "2scv8M1Zm3j13WXJeLEKuFrGfRYfgsUQwKfvirABdG6K",
  },
  "124-canal-street": {
    name: "124 Canal Street",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "canal-street",
    tokenAddress: "DGcDnSQtP4PcprYAPaztg7cWk5Ci4H82ELGUskWQEHs8",
  },
  "124-stewart-avenue": {
    name: "124 Stewart Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "6v9UQAhBLTGYevCQFgVpBPHWbr2LQCL8w5XJN4cm3z6T",
  },
  "125-canal-street": {
    name: "125 Canal Street",
    biome: "arctic",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "49KAMxoRa9wF9iBUNPL7GzwGm3xbU3qgsJdHLzsWzFYS",
  },
  "125-stewart-avenue": {
    name: "125 Stewart Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "3xsvhnz5E9CRkXtY2vYzgne3oeM8FVAzGNTCXaC6enL3",
  },
  "126-canal-street": {
    name: "126 Canal Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "canal-street",
    tokenAddress: "3USFPeFV5PR9Wv5ApJ7DbP8nzM2c8AZtqKiQuGnmEKbY",
  },
  "126-stewart-avenue": {
    name: "126 Stewart Avenue",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "4CnbsKJART1evffuTWDo9hTG7fkqLGw3dBVodt9G11pq",
  },
  "126-wall-street": {
    name: "126 Wall Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "wall-street",
    tokenAddress: "CUEvjc4cohfe87yRPD6hRTUbfPniTHLVGZj7okbbgd35",
  },
  "127-canal-street": {
    name: "127 Canal Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "canal-street",
    tokenAddress: "4cP8cYugqJuKsSSSkddur5Rjquxb6hgqYzQBQ6w1zN1E",
  },
  "127-stewart-avenue": {
    name: "127 Stewart Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "93ZFNpRS4m5xfHJcxCRqEJajoGtsWL5aNtx5pijWnjpL",
  },
  "128-canal-street": {
    name: "128 Canal Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "canal-street",
    tokenAddress: "5oYGgWmLtufHMTAzh3eGsEyoQpsUAmRvoV6gbFbRuaNH",
  },
  "128-stewart-avenue": {
    name: "128 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "stewart-avenue",
    tokenAddress: "F6pxGWs21TZgBhgrNp9HoWhU7QJ2tcLAE2zSr9TfYXYh",
  },
  "128-wall-street": {
    name: "128 Wall Street",
    biome: "desert",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "bj1ESPvZooLhbbrKx4iRb3HVFjPRafCvGLBNuoJLV64",
  },
  "129-canal-street": {
    name: "129 Canal Street",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "canal-street",
    tokenAddress: "HgWy5yVsq9b1rpEyAupyWjzyGpszyst1yxV53uGLWR98",
  },
  "130-wall-street": {
    name: "130 Wall Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "wall-street",
    tokenAddress: "4yUcFtMdygyMkTXWcYQ7UwDTdMQYXJLUUkngWsUC73ak",
  },
  "130-wisteria-lane": {
    name: "130 Wisteria Lane",
    biome: "treehouse",
    frameType: "shuttered-pale-green",
    streetId: "wisteria-lane",
    tokenAddress: "Ho5GuvYFfEV14s7XKE4iGPc5wkYe2LfepjFSeYmWfgjF",
  },
  "131-stewart-avenue": {
    name: "131 Stewart Avenue",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "stewart-avenue",
    tokenAddress: "AhB85fRRXTcySoYNK9Yfu2pjeD3ZG9fPMHXNFK5U31xc",
  },
  "131-wall-street": {
    name: "131 Wall Street",
    biome: "arctic",
    frameType: "shuttered-dark-green",
    streetId: "wall-street",
    tokenAddress: "2ZMgyt1MYQDRmDhruNCB9evY6iD5E4BtCdBysZ3GZ5jo",
  },
  "131-wisteria-lane": {
    name: "131 Wisteria Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "6SxEv9hvA3WLWDKgpG6V3Quyv2GNeFxyaEuBuHYabdns",
  },
  "132-stewart-avenue": {
    name: "132 Stewart Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "9R9ft714hSedxd95htKRQ2pU2nj3fxbAq2wXQVrjygMX",
  },
  "133-stewart-avenue": {
    name: "133 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "CDY7PhC5wWp7ZoQWvesh4699ytSSJvyuo9fTW2wGkfup",
  },
  "133-wall-street": {
    name: "133 Wall Street",
    biome: "beach",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "4iAm6hfik7SapsX2D9RF6WcpgeKhKrqZfcQDmUuQ2V8D",
  },
  "134-stewart-avenue": {
    name: "134 Stewart Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "J27HmThQMhR3WQnTkdDzjRQrtmpyKnk68e3w9MnvSgDz",
  },
  "134-wall-street": {
    name: "134 Wall Street",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "HXyBNJeWGvK3HPjgQbAfXiDYcTkntCvEpmigMJ8cAVZM",
  },
  "136-canal-street": {
    name: "136 Canal Street",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "EAduMUiDrWm8QcKFH5J3P1BRVahLasWAeRgC2KMEYqch",
  },
  "136-stewart-avenue": {
    name: "136 Stewart Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "6wL9zVk78WsYRztEWYtpcxKXqi69GGD1qEWXp24zfoP",
  },
  "137-canal-street": {
    name: "137 Canal Street",
    biome: "arctic",
    frameType: "rounded-red",
    streetId: "canal-street",
    tokenAddress: "68DjrRiGXuDmX77CL8jRLbPBoMATCm5Uo9maGBsBiFgQ",
  },
  "137-stewart-avenue": {
    name: "137 Stewart Avenue",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "WTX7wfun6ShiP49B2gw3cjt4WVfqwKUn24szRG3Hkgz",
  },
  "137-wisteria-lane": {
    name: "137 Wisteria Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "wisteria-lane",
    tokenAddress: "ABRhZcEBhUqyCbLKb7qwwVpykUek5cMjSSpJZtSpdUK",
  },
  "138-canal-street": {
    name: "138 Canal Street",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "canal-street",
    tokenAddress: "8RJcPYAUJR3ZS82vZhFcpACMy5iRHmLFBdaXJQBp1X2s",
  },
  "138-stewart-avenue": {
    name: "138 Stewart Avenue",
    biome: "field",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "BDmksxEXRcxss7J7tqmq42uCboB7D3coDNTJPLMiKcnH",
  },
  "139-canal-street": {
    name: "139 Canal Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "canal-street",
    tokenAddress: "HnbHnLMiVAwairVDjmZbpqsEriKtLHRYoWuyMMwbZMxi",
  },
  "139-stewart-avenue": {
    name: "139 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "4R86jy8jdHBZtoX9TnUVLfNyQF4T3jtzRLVbqCDULdCd",
  },
  "139-wisteria-lane": {
    name: "139 Wisteria Lane",
    biome: "mountain",
    frameType: "rounded-lavender",
    streetId: "wisteria-lane",
    tokenAddress: "CADfWxd2AM88B34FBrvTyJgYwBg6CUqBX2UWzACutv5p",
  },
  "14-arcadian-alley": {
    name: "14 Arcadian Alley",
    biome: "mountain",
    frameType: "glass",
    streetId: "arcadian-alley",
    tokenAddress: "3ua6CzCdDgqJBb2amZro3PosTVxima4GYLzkgsKWuoNC",
  },
  "14-teeps-lane": {
    name: "14 Teeps Lane",
    biome: "city",
    frameType: "rounded-dark-green",
    streetId: "teeps-lane",
    tokenAddress: "5UcincE3hA267tyMgkn4ZpyXuXke4RUv6Xp5ngqCbbia",
  },
  "142-canal-street": {
    name: "142 Canal Street",
    biome: "treehouse",
    frameType: "shuttered-green",
    streetId: "canal-street",
    tokenAddress: "8XtMPBJtrXkuKg8htSHins5TefHLe4E25GmeowyXEcC7",
  },
  "142-stewart-avenue": {
    name: "142 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "GXGxxvkY7V2n7GtnSrqUPUNjrE9sPuZkY4mZgBciVpaG",
  },
  "143-canal-street": {
    name: "143 Canal Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "canal-street",
    tokenAddress: "FkHifGqBmKmcAZJbYX4JEu3dsE9VABJuQ9eKJFGwzUzy",
  },
  "143-stewart-avenue": {
    name: "143 Stewart Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "fUUKNaGNm1wsFYqV4udrLU2u7An4reod2kCQwdPexcC",
  },
  "143-wisteria-lane": {
    name: "143 Wisteria Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "wisteria-lane",
    tokenAddress: "A1jQtjm8CpLBjBweaJZZdZ3oboTmJrzx1AQYscNHV4GK",
  },
  "144-stewart-avenue": {
    name: "144 Stewart Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "A19UV6eBpdhZXq4TjrfdtMVpEGwRCGYxos9nVtrZCrbN",
  },
  "145-canal-street": {
    name: "145 Canal Street",
    biome: "arctic",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "6vUgP5ucBLMaymqA8Pfde6qVNjyMiCqJfxgq1CMD9DG2",
  },
  "146-canal-street": {
    name: "146 Canal Street",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "69X1CsBFCgBNJsgZdYkeiPbXCqhyAXTGGaUFH9QNgYxP",
  },
  "146-wisteria-lane": {
    name: "146 Wisteria Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "wisteria-lane",
    tokenAddress: "56jSHiKrUxM2v6A8LLfRRZUNwTz2JuT16puVYU1TRvgF",
  },
  "147-stewart-avenue": {
    name: "147 Stewart Avenue",
    biome: "volcanic",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "ASKQx5JmBnNWnTEbDSNVeZjKpgy7cApMaCjKXqKXn4JB",
  },
  "148-canal-street": {
    name: "148 Canal Street",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "canal-street",
    tokenAddress: "e35fTEHuDKXRZaX5kwzj9Qr7umW1xYMKpq6gxBWptYm",
  },
  "149-stewart-avenue": {
    name: "149 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "3FTA2zPcTC4dvhht6p9UiPN9vtJ8N1MFD9GujmbHU9XF",
  },
  "149-wisteria-lane": {
    name: "149 Wisteria Lane",
    biome: "mountain",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "AhuLW62ceAU5thqeqncDjtEhVNJtzLi7kbFpycvSawVy",
  },
  "15-jump-street": {
    name: "15 Jump Street",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "jump-street",
    tokenAddress: "CGoRnKo3yvngCfSLYtiSzvfWd6LyuCN3p1RytvR6M1jf",
  },
  "15-teeps-lane": {
    name: "15 Teeps Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "BWW2Mjhk376199mZCBkzJM69NZheQr6jCkxYxT4euHxr",
  },
  "150-canal-street": {
    name: "150 Canal Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "AKKLGEXiVmPMbddqxKmPwqcxozc7xMWgXEfy7fzrame7",
  },
  "150-stewart-avenue": {
    name: "150 Stewart Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "stewart-avenue",
    tokenAddress: "EustZuhwsnypPxrbzLRA4k9gMDE6jbh7buK4Umt9Bxfw",
  },
  "150-wisteria-lane": {
    name: "150 Wisteria Lane",
    biome: "mountain",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "3q2uXekpL5qMSpGTrYpiiLL7ute92SXY5MqTo2Znrdpj",
  },
  "151-canal-street": {
    name: "151 Canal Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "canal-street",
    tokenAddress: "By1GZTgE28eaxcshw32pb89kqmus2L7HjR72xnRa8C1W",
  },
  "152-stewart-avenue": {
    name: "152 Stewart Avenue",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "stewart-avenue",
    tokenAddress: "4tcvWzZz2N7hhzdyQ5tBajFbiFnkhvpzyZJ1yBM6ZVnY",
  },
  "153-canal-street": {
    name: "153 Canal Street",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "canal-street",
    tokenAddress: "6SyorUfi2uJgzWs5M4PwewHMAG6ZjBu34viS6oGH6nqS",
  },
  "153-stewart-avenue": {
    name: "153 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "stewart-avenue",
    tokenAddress: "E4cwfQkDxxGixTirqrcJ2dSm1o8DVzz58sqpTNDUPBfT",
  },
  "154-canal-street": {
    name: "154 Canal Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "4Af5dzCj7773AboRj9CC8sk5NfpQtMxvuXMBvDDoP5uA",
  },
  "154-wisteria-lane": {
    name: "154 Wisteria Lane",
    biome: "woods",
    frameType: "occult",
    streetId: "wisteria-lane",
    tokenAddress: "8qLykeSPt9kvMZZ6AmtzBtZNvVdHhuZLS1xe7aPmRyke",
  },
  "156-canal-street": {
    name: "156 Canal Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "canal-street",
    tokenAddress: "H8WoBb6hgBGXUV2YoUWPaU29eTSd52Q5JAKbtDv1ohKU",
  },
  "156-stewart-avenue": {
    name: "156 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "3vnzAv916oCK3tbDBSfEFRqKfib1qKjHqptqMhkTMQLm",
  },
  "157-stewart-avenue": {
    name: "157 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "J4uV3AvtYdr57H28fBfKAqLuMYdBGxsY8dEfCqEJak11",
  },
  "157-wisteria-lane": {
    name: "157 Wisteria Lane",
    biome: "field",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "hQ7tLC6mhqNq6nf66BfkrZ7dfxtak9mzL3Ltmd9NTm3",
  },
  "158-stewart-avenue": {
    name: "158 Stewart Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "8Su559QGPooEXS6cuseXKtJCg3R8kLSznZSpJV8ACJk3",
  },
  "159-stewart-avenue": {
    name: "159 Stewart Avenue",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "8zgmPg8FhQ6eBefJCAqkBSTrsVkNbAyWnw2JJyLxP5Nu",
  },
  "159-wisteria-lane": {
    name: "159 Wisteria Lane",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "wisteria-lane",
    tokenAddress: "82Sfs25YugZj6K4WTAaZWhpm1vzJtNUXUigc4zTektLZ",
  },
  "16-brain-lane": {
    name: "16 Brain Lane",
    biome: "treehouse",
    frameType: "glass",
    streetId: "brain-lane",
    tokenAddress: "22ptQs3jqAfUPLP2T6Tj9BrgBq3EUz6uNga76DTW1M44",
  },
  "16-jump-street": {
    name: "16 Jump Street",
    biome: "city",
    frameType: "glass",
    streetId: "jump-street",
    tokenAddress: "6W8H9BxaDmeTLPxpk1WNirUPDSBmU5mPegm77DLkqNCS",
  },
  "16-teeps-lane": {
    name: "16 Teeps Lane",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "6yzcyoazZfz2PQ1WyENHR1pT9CbDPcY1GtTj3RdpAwXz",
  },
  "160-stewart-avenue": {
    name: "160 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "7xrXGh7S6TQMxywqHHdwwycyLDvsJW5X7S4jkX1gq7v3",
  },
  "161-stewart-avenue": {
    name: "161 Stewart Avenue",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "E8iRukFv8X9NbkN4rXJn65VTY4s4jifpBj7ctGpGEiaW",
  },
  "161-wisteria-lane": {
    name: "161 Wisteria Lane",
    biome: "city",
    frameType: "crystal",
    streetId: "wisteria-lane",
    tokenAddress: "HNqsbxfcDcKr8N5paTvhJRmzXuSEmPrdF3eZZaMzkCjH",
  },
  "162-canal-street": {
    name: "162 Canal Street",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "canal-street",
    tokenAddress: "13KmfV75BXsTnWw5C8TusuKv7PmLKUNkiMb5ZfnufMe1",
  },
  "162-stewart-avenue": {
    name: "162 Stewart Avenue",
    biome: "treehouse",
    frameType: "rounded-green",
    streetId: "stewart-avenue",
    tokenAddress: "6fJAVc2jjwR9Z2K6viiw1Vi3isy27cSUaQP3WCvGwjG5",
  },
  "163-stewart-avenue": {
    name: "163 Stewart Avenue",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "wMHVZPM2F5RxbKBVhpmhNyddWV7ddUftuTyrBT3jxTi",
  },
  "163-wisteria-lane": {
    name: "163 Wisteria Lane",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "wisteria-lane",
    tokenAddress: "95ffcTTKZRDqg2CTXrn1SQCmJzzegiXZrMxWuN11XY59",
  },
  "164-canal-street": {
    name: "164 Canal Street",
    biome: "field",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "DyVqaNKA8RyF2eDmFAUndriqtQxMrSTe3ms7sZwW2EYe",
  },
  "164-stewart-avenue": {
    name: "164 Stewart Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "4EZA9TgC3XvdvAne8L7m5X3KeaFJ52pmaWxVhc4iRQ2W",
  },
  "166-canal-street": {
    name: "166 Canal Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "DmmBE8sWgWRkv7C4erJW8WdXXX4cTK2u4djeXgTCCbEP",
  },
  "166-stewart-avenue": {
    name: "166 Stewart Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "stewart-avenue",
    tokenAddress: "FQaEe7PHoEAuR1jizRaRjC66PWw59stTGwucqaycpxWh",
  },
  "166-wisteria-lane": {
    name: "166 Wisteria Lane",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "wisteria-lane",
    tokenAddress: "xCLhVE6mKn6cBhaXJSnTLmSVqtM1Tv32HvUrtMiWmRs",
  },
  "167-canal-street": {
    name: "167 Canal Street",
    biome: "city",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "HGhRxey3cAHfgsNWVMFgdM5gsLg6ky1Ade4hGbnqxUwY",
  },
  "168-canal-street": {
    name: "168 Canal Street",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "canal-street",
    tokenAddress: "8RV7xtpZPDXdmPjZwq9J3Q5c3edffWtLcG3PW3t9c7gN",
  },
  "168-stewart-avenue": {
    name: "168 Stewart Avenue",
    biome: "arctic",
    frameType: "rounded-lavender",
    streetId: "stewart-avenue",
    tokenAddress: "7euyo5Kj7KsfS2iszjGgbToKA5rnmdS32wJ7rdTXSyTr",
  },
  "168-wisteria-lane": {
    name: "168 Wisteria Lane",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "wisteria-lane",
    tokenAddress: "9PN9HGDyDW8QD4VXMxuq7GTW5yrNpnwGZueJfgyPPDaN",
  },
  "169-canal-street": {
    name: "169 Canal Street",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "Fu3jp4kTaAtsx8GdShVgP5xLs1wyfiBiiCQKpy9muUsP",
  },
  "17-jump-street": {
    name: "17 Jump Street",
    biome: "woods",
    frameType: "gold",
    streetId: "jump-street",
    tokenAddress: "Hqm8Lz6kFQ8bBUnmYQwts6jJkA4anwgyJta8XMpedDeE",
  },
  "170-canal-street": {
    name: "170 Canal Street",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "canal-street",
    tokenAddress: "61ndjoVi6qHf7VL9KpMSzVHVcivPnVVtw8rpxuNewmjX",
  },
  "170-wisteria-lane": {
    name: "170 Wisteria Lane",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "wisteria-lane",
    tokenAddress: "AD7m4G3e3LWVxhbgFhtyZtjM6RsYEGpKQzTqy1srFKG6",
  },
  "171-stewart-avenue": {
    name: "171 Stewart Avenue",
    biome: "volcanic",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "DJmkR4ZfT3tFXXvJ5FXkUs5Cxx1XAcHDk8HtgbUUGWob",
  },
  "172-canal-street": {
    name: "172 Canal Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "5gb5fEma4cfQq2pPPqgpBqmvzZWRu9NMTKgNK8tJshfZ",
  },
  "172-stewart-avenue": {
    name: "172 Stewart Avenue",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "4HQbazskz8MAa7eHeRnJSGrrvPfiixz8BhsV8rHvhHh6",
  },
  "173-wisteria-lane": {
    name: "173 Wisteria Lane",
    biome: "underwater",
    frameType: "occult",
    streetId: "wisteria-lane",
    tokenAddress: "4ovakE73Drg4syhU3FNLGbQt9jr79q6HuUjGXRkszwkQ",
  },
  "174-wisteria-lane": {
    name: "174 Wisteria Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "32QozhuYe8wzdhmTghPqhGjhMTecS4oFoscKmz3RsvJL",
  },
  "176-canal-street": {
    name: "176 Canal Street",
    biome: "desert",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "3mZGjcgTdQ1ZoPziqPNDDQAizi1hUf3mM9Kjj2C1DKQs",
  },
  "178-canal-street": {
    name: "178 Canal Street",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "canal-street",
    tokenAddress: "8QzVVx6mfRZNF4mZ9diHyu97jaqKmUizZZu54dxQ6di5",
  },
  "179-wisteria-lane": {
    name: "179 Wisteria Lane",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "wisteria-lane",
    tokenAddress: "ijyntyYYM4RXNnDpyxn7iy4orkMXtFfgdWJ9FcSwDT9",
  },
  "18-jump-street": {
    name: "18 Jump Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "jump-street",
    tokenAddress: "8zUgPV4HNMCfV6TsakEYxoW3JiA1bRSFohc12C16XVsK",
  },
  "18-teeps-lane": {
    name: "18 Teeps Lane",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "Hgp9EeQxzzYtmhFhgwNoSDJXqmasZij4QnzUe9q5oZ9y",
  },
  "181-canal-street": {
    name: "181 Canal Street",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "canal-street",
    tokenAddress: "CrohN4LPALJDtXS6YWv6A8jCaEr3kjp5qj8HANwoqhCn",
  },
  "181-wisteria-lane": {
    name: "181 Wisteria Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "wisteria-lane",
    tokenAddress: "JBwrddJ9FNQjzPFmtmCjWy3Rr9gwF8e7TJqV8xZX46RM",
  },
  "182-canal-street": {
    name: "182 Canal Street",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "canal-street",
    tokenAddress: "27N1qxwefXdgeYUkHA8W6pPv87mkGo9cUuaBDjeWtJgX",
  },
  "183-canal-street": {
    name: "183 Canal Street",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "canal-street",
    tokenAddress: "AFWNm1bVqLg2R4qsx4Vd1FzyhWmx2D7uqWLGKHGkdn5i",
  },
  "184-canal-street": {
    name: "184 Canal Street",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "GycDiGghaJG7ZqfX7wWK3DR8ZPSXSdcF6UjNHEE6X4uR",
  },
  "184-wisteria-lane": {
    name: "184 Wisteria Lane",
    biome: "treehouse",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "6R1ciwNzTjmeBLx5wHNzXixrZZMhtwWCz1a16g5pYF7U",
  },
  "185-canal-street": {
    name: "185 Canal Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "29hb4pm4CRPQVsRQyeqvS1zHpFDegTx7DtXhZWAyF9D9",
  },
  "186-canal-street": {
    name: "186 Canal Street",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "canal-street",
    tokenAddress: "DLssrqPCPvm4R2AUUv2z6BMY48U673xqFKNJYMoeUxfN",
  },
  "187-canal-street": {
    name: "187 Canal Street",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "canal-street",
    tokenAddress: "4HbvxjUCihH89ahYSksGMfvvAwy4Eu2kdHBzT9bg2cXd",
  },
  "188-canal-street": {
    name: "188 Canal Street",
    biome: "volcanic",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "4CTvBNwNKKCNRNJKivkE9opqXYKznrGq6nj2Pt4JXSF3",
  },
  "189-canal-street": {
    name: "189 Canal Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "canal-street",
    tokenAddress: "shSy7zzL3Gy2sL9Z6kBpgVBDT3KSYr8f5tVQvHmuAG7",
  },
  "19-jump-street": {
    name: "19 Jump Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "jump-street",
    tokenAddress: "4AywK6YUN4sxyX1bWth7JeJ4c6CTxME26rcU71n2cyUu",
  },
  "190-baker-street": {
    name: "190 Baker Street",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "baker-street",
    tokenAddress: "DD4GjxNcTxh6NevGJS1NMrpi7f73QqfpGF37ocGDZBQk",
  },
  "190-wisteria-lane": {
    name: "190 Wisteria Lane",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "wisteria-lane",
    tokenAddress: "9evMkS71yLdRJ51GFLNrAF8Gjbf5MpkgLDD6Svtrzqts",
  },
  "191-wisteria-lane": {
    name: "191 Wisteria Lane",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "wisteria-lane",
    tokenAddress: "CMiQHKXqd6mKLbmKLMsspTaJQdJiLAKmXZkXhbUTooG6",
  },
  "192-baker-street": {
    name: "192 Baker Street",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "baker-street",
    tokenAddress: "DYLoFXyq7JrERB7JY3bKUR9Z1gwtYfWKNYtwSqRg6Lg6",
  },
  "193-baker-street": {
    name: "193 Baker Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "79aYESZ8o8Y996vSbBx5hAo75zd2S4cQWhF2g9ELytya",
  },
  "193-canal-street": {
    name: "193 Canal Street",
    biome: "desert",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "C4B5zFwdCg5AMG8JxUBnJvLYBoFE5jXbkvZ6F72PsSLp",
  },
  "193-wisteria-lane": {
    name: "193 Wisteria Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "wisteria-lane",
    tokenAddress: "F3Yyrh2VuWk5Roqa8sdJqyumSi3yBqLJBVnyNVZJbGJV",
  },
  "194-baker-street": {
    name: "194 Baker Street",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "baker-street",
    tokenAddress: "4dmg2D4kfG92rUVQ58unEMRA8ZXCToo68spKKzfwu9XN",
  },
  "194-canal-street": {
    name: "194 Canal Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "canal-street",
    tokenAddress: "5WEF6E5L29QLch51D2syyiihQRja7hVGMxemtMuAJnCz",
  },
  "194-wisteria-lane": {
    name: "194 Wisteria Lane",
    biome: "arctic",
    frameType: "glass",
    streetId: "wisteria-lane",
    tokenAddress: "8SwWG4C2pntm3LejXnxDLoV9QC25cY7wobLXRLbkdVCP",
  },
  "195-baker-street": {
    name: "195 Baker Street",
    biome: "field",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "Cicc1bHtDnm7gRSEdfyDKUBbFFdwbzCxYomfB3ZHVjSh",
  },
  "195-canal-street": {
    name: "195 Canal Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "canal-street",
    tokenAddress: "DYVyykmgGJs7wC1Gb3XFZvRv4jN5AUzhrJQjkr4Po3qd",
  },
  "196-baker-street": {
    name: "196 Baker Street",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "9NPUxLZaoxC8U3NdMY8gro7YYkreVtyu72vvLzhZ4pWR",
  },
  "196-canal-street": {
    name: "196 Canal Street",
    biome: "city",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "GeGpWcooHmu1EpuKR31vjqQG7eJBHANH2kphfQoNc47f",
  },
  "196-wisteria-lane": {
    name: "196 Wisteria Lane",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "wisteria-lane",
    tokenAddress: "DoHFRDRjz5rgcSKP2F363c6BsPCM4jVwrFcP1cAahJJj",
  },
  "197-baker-street": {
    name: "197 Baker Street",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "AKWJxLX7hkfzoTvAUFAxCB21tDcXd6J3dMEeWL98u3ht",
  },
  "197-canal-street": {
    name: "197 Canal Street",
    biome: "beach",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "2pP7dQ87FUcAYorGqAx7KZ7524cd2UszooY9n5SPR411",
  },
  "197-wisteria-lane": {
    name: "197 Wisteria Lane",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "wisteria-lane",
    tokenAddress: "Aq8u2nRhHhD9vowaYcKnNjjGrEesKCoGMJdT7L28CBH8",
  },
  "198-baker-street": {
    name: "198 Baker Street",
    biome: "desert",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "32cB2Fdy3M5G37tU2YpQUZK8qNiqQdTHiixWDwR83fNV",
  },
  "198-canal-street": {
    name: "198 Canal Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "9nYgqcFFKha3unuGHRMSE8V1pDuPsHj8figkDsgKjEsK",
  },
  "20-jump-street": {
    name: "20 Jump Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "jump-street",
    tokenAddress: "32bXsZL5qzkfKC2AFNz69xuK9fzFrf6GbQtYPuAVujqb",
  },
  "20-teeps-lane": {
    name: "20 Teeps Lane",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "teeps-lane",
    tokenAddress: "9QgyY5attbKu4CRW7HNq3sb7sr9v8GnJyJTi59GhXbEq",
  },
  "200-baker-street": {
    name: "200 Baker Street",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "8WyPdjy2j8HL7VmR8Ue27hjVXWAXju3g5kPtKoMQjsHj",
  },
  "200-canal-street": {
    name: "200 Canal Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "Dm4Ta3bY2HTB1rJarr9MAxwMA2FFik5AoSUTf5QyE3wx",
  },
  "201-baker-street": {
    name: "201 Baker Street",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "8qUF2CqmiZeqgboG6wf911PpYzgT4FcJbR5zFg6hzJ8",
  },
  "201-bit-boulevard": {
    name: "201 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "AhRXEMhEZypN7PjbvyhDrhgbhVsNhtfWQoQqyRhQJHiW",
  },
  "201-canal-street": {
    name: "201 Canal Street",
    biome: "city",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "35KKZkfXEMAnPsvGNGeqXKm5vRwmHeu13a8u9gajCoHy",
  },
  "202-bit-boulevard": {
    name: "202 Bit Boulevard",
    biome: "field",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "B2ipEZSWdL4wSpB8ubTBbic53NPeyk5STqa49xUsGvw2",
  },
  "202-canal-street": {
    name: "202 Canal Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "canal-street",
    tokenAddress: "AxBqU1PFe6b9onzhH9etpJwV2oFzNTNPyyPKFrZtmg6r",
  },
  "203-baker-street": {
    name: "203 Baker Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "baker-street",
    tokenAddress: "BF25tCnztepZtYndbTSv3Yca7vcsxBYVF3PadjgTNxpt",
  },
  "203-bit-boulevard": {
    name: "203 Bit Boulevard",
    biome: "beach",
    frameType: "rounded-dark-green",
    streetId: "bit-boulevard",
    tokenAddress: "GJShqcbSL4kys3SPFqZcS6P7x5EphszdjGWh3S6AuLid",
  },
  "203-canal-street": {
    name: "203 Canal Street",
    biome: "mountain",
    frameType: "gold",
    streetId: "canal-street",
    tokenAddress: "BoobdTML4tniR7i72QQVRoKdvmuq3CbNTYheASyVGR4M",
  },
  "204-baker-street": {
    name: "204 Baker Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "EZe8zhmtq7wEPqc92Ggo2HJ2rZZR8KB7Ry676gzsEP2s",
  },
  "204-bit-boulevard": {
    name: "204 Bit Boulevard",
    biome: "beach",
    frameType: "gold",
    streetId: "bit-boulevard",
    tokenAddress: "673RMeYvVh2ByBPuHTKkRWcVeckrJ7z7RHFHuVYgwW9g",
  },
  "204-canal-street": {
    name: "204 Canal Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "canal-street",
    tokenAddress: "FrtQr3nfapYEj2uBGT8iC3syWcwTZcX2yjzzhaNEHauN",
  },
  "205-baker-street": {
    name: "205 Baker Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "4QyZbJbWZbrDS9LUsAexHqJ3Nqd5bmRHd62aSHWYy5kR",
  },
  "205-canal-street": {
    name: "205 Canal Street",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "canal-street",
    tokenAddress: "4gokN6VpYsNAYvQ4nyDf25BpzVnjwBtfWeLXYYKjsSgN",
  },
  "206-bit-boulevard": {
    name: "206 Bit Boulevard",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "F6u3qsxp991KUvM73ryVzad9k1PC1aqNPbsx2RYeE3Nb",
  },
  "206-canal-street": {
    name: "206 Canal Street",
    biome: "woods",
    frameType: "glass",
    streetId: "canal-street",
    tokenAddress: "33LTbtZqiTT9rpFs3dYHX3Heer474VgeePCHDbD9yLaC",
  },
  "207-baker-street": {
    name: "207 Baker Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "4Uj3LrAJfVd41pDSzLpSnR21emguRS2yTTsttD4Gy9j9",
  },
  "207-bit-boulevard": {
    name: "207 Bit Boulevard",
    biome: "field",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "7Mek18EKv6womv1qLH65WXukFiNA2JNW6eWzJoMNRBqw",
  },
  "207-canal-street": {
    name: "207 Canal Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "canal-street",
    tokenAddress: "2iKjEFsGs96V6W6E73Qhvi52fAZg9o5pE6TBwW3oKVqL",
  },
  "207-delray-circle": {
    name: "207 Delray Circle",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "7ygdJFVdESf4NrCikuW27vtMm7pQziAw2B2KkqheQgnp",
  },
  "208-baker-street": {
    name: "208 Baker Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "9Nuptc7XTvDzqLfBzk8BALiXnua3R8cTeoXfNuQEQULs",
  },
  "208-bit-boulevard": {
    name: "208 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "6F6VVfXwGASiCQayVDvTpcpLUc2doDu5QcfH9qJUyV18",
  },
  "208-canal-street": {
    name: "208 Canal Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "canal-street",
    tokenAddress: "828edX21gZWRD3geRJUB7eHRzFVVKji54FjmutJWagcb",
  },
  "208-delray-circle": {
    name: "208 Delray Circle",
    biome: "beach",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "AxEN6PHbsZUj4GqLpoSWcPR8s3Bz6G2H8HrapTTEgcQZ",
  },
  "209-baker-street": {
    name: "209 Baker Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "Fs9kCZ5BmJyEzmoK58iNM3TtbexioJctTRiHcL7nwDuq",
  },
  "209-canal-street": {
    name: "209 Canal Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "canal-street",
    tokenAddress: "DhKQQX8FotUYy2ad7NQ55YcPUm7afnwCt6HtwwDhFxbr",
  },
  "209-delray-circle": {
    name: "209 Delray Circle",
    biome: "underwater",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "77jA3vHRKrhA8DQtdBS2bR5wvCpsY4oretyRGFkCYhDN",
  },
  "21-jump-street": {
    name: "21 Jump Street",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "jump-street",
    tokenAddress: "2TpavKosSCnDheMqPzxYWF2us7pEsJKt2p8D8afA5Y9X",
  },
  "210-baker-street": {
    name: "210 Baker Street",
    biome: "arctic",
    frameType: "rounded-pale-green",
    streetId: "baker-street",
    tokenAddress: "FznwmnzVSosQBG1jsLTC5LvKjvqRMmtiXW1q3sTJ8Ati",
  },
  "210-bit-boulevard": {
    name: "210 Bit Boulevard",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "6Yiq9vKa5CUg81yjQ4wsfNuQxTxVgpAKBvyZyn7pK7hD",
  },
  "210-canal-street": {
    name: "210 Canal Street",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "canal-street",
    tokenAddress: "BW7kAVJDqHs7DYKGVdJZF647u7mwoQPr4NTjWe67zmUr",
  },
  "210-delray-circle": {
    name: "210 Delray Circle",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "5o4CB4qUdNXed6W1hEcP2b7kXkAFpBvJD6NGw72n7xpB",
  },
  "211-baker-street": {
    name: "211 Baker Street",
    biome: "field",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "Bfcjx8tT7YzsXo946USgDUHHRyw43ZERezbvobrgG93k",
  },
  "212-baker-street": {
    name: "212 Baker Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "Dtr2cQiUvLfTwkJvHZUtS9NxsFPCw8ZomxdqQLXNMq4i",
  },
  "212-bit-boulevard": {
    name: "212 Bit Boulevard",
    biome: "desert",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "7D4SjXV5UuohDZpxot4BvHLrX6F1Yk4tjhTALJehJESC",
  },
  "212-delray-circle": {
    name: "212 Delray Circle",
    biome: "mountain",
    frameType: "gold",
    streetId: "delray-circle",
    tokenAddress: "BwLHaAgP1rnUcesTL2RnGT6PsgKsgdPPqwRUSXSmhPXP",
  },
  "213-bit-boulevard": {
    name: "213 Bit Boulevard",
    biome: "underwater",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "7EFDhZkyoMJDVaHJXwYGaaHeQQxS7YveFxKC2HZ3wLfH",
  },
  "213-delray-circle": {
    name: "213 Delray Circle",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "5mQkrvhEpeVd6uHdi8xdRAaidVE3Yn2oAF2XX5aSfZ4u",
  },
  "214-baker-street": {
    name: "214 Baker Street",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "baker-street",
    tokenAddress: "H3aGdZ7yemoZevEosFTo8A5qYybxvi9yGzSjVx8jLwXT",
  },
  "214-bit-boulevard": {
    name: "214 Bit Boulevard",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "bit-boulevard",
    tokenAddress: "6yx1Wgsqc6bPQBnAp5gyNFchM7DhCDFEWL1bFU65kZvz",
  },
  "214-delray-circle": {
    name: "214 Delray Circle",
    biome: "mountain",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "483zij6pePABXpJWWhW4frcoqY8Q6KRXwzk97PwVwmyz",
  },
  "215-baker-street": {
    name: "215 Baker Street",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "4kmMwHsGwETmNvuqBhJVDjdy65eFgaUykhLdrd5Au7bV",
  },
  "215-bit-boulevard": {
    name: "215 Bit Boulevard",
    biome: "field",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "BnqAqfYXh4W9NcCTGmzmXZo2pCAfSPwbWyF7caXFjxNq",
  },
  "215-delray-circle": {
    name: "215 Delray Circle",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "83A9hr6BBkcUEHAhNfovEqLoPPV4XUmN52QPkyRfYfiG",
  },
  "216-baker-street": {
    name: "216 Baker Street",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "Bet4EbF54pKg3WJVnq4SMDS1wTrRLyPRyNRzKq1TrbpJ",
  },
  "216-bit-boulevard": {
    name: "216 Bit Boulevard",
    biome: "mountain",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "AkYaPNQ7i2Qvf2ATmj2uT3i97Ym7GyizccdfbnEGVDo9",
  },
  "216-delray-circle": {
    name: "216 Delray Circle",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "Hs7CBVionB5RCMDH3V2377J9GzR7hTvg9WsQnyMoYzmN",
  },
  "217-baker-street": {
    name: "217 Baker Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "54V37iBkdMDJBr5ybDTWbe53C1Wm6ox5BUQTnnhig4tz",
  },
  "217-bit-boulevard": {
    name: "217 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "2RPyeTdCrQsWAfPJFtdwnkVwadX97rriacWMXApwSwuJ",
  },
  "218-baker-street": {
    name: "218 Baker Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "f2ALqVTUgaTpUo7Q1rz8G9N2CQgxu8Cy87QCfUBoqhy",
  },
  "218-bit-boulevard": {
    name: "218 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "PN6tcVUj37UZ4ThGy4iiBDHDYH5KugevLx4j6GVy3uG",
  },
  "218-delray-circle": {
    name: "218 Delray Circle",
    biome: "treehouse",
    frameType: "rounded-lavender",
    streetId: "delray-circle",
    tokenAddress: "DkZncKFu9VnmFg7XBeT1tiVn3rFFSQe7Cs7MQWjaM2cW",
  },
  "219-bit-boulevard": {
    name: "219 Bit Boulevard",
    biome: "treehouse",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "DM4RHDkZDxmopc4PC57VMt8JDZS73ASSBZbqvgYqtd67",
  },
  "219-delray-circle": {
    name: "219 Delray Circle",
    biome: "underwater",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "3RWrdXCzUDhp87wizavQ3G5BujKnFDWxEKsqWatysPLX",
  },
  "22-jump-street": {
    name: "22 Jump Street",
    biome: "city",
    frameType: "glass",
    streetId: "jump-street",
    tokenAddress: "JeWWEnRzghAdQw2QirKhYv2JcmkMtRDbzj8VDPPU6p5",
  },
  "220-delray-circle": {
    name: "220 Delray Circle",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "delray-circle",
    tokenAddress: "8ykJRw9DVup9RCkXeb73DTZV4UMrba3ZZWXxW1sver2G",
  },
  "221-bit-boulevard": {
    name: "221 Bit Boulevard",
    biome: "city",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "HRRr5ZjrBzsQqPtKMmDx4JBWLYiiv3esDof6MehAFu1U",
  },
  "222-baker-street": {
    name: "222 Baker Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "baker-street",
    tokenAddress: "VKUgcrTV98CXYQ9SVx9cMEdmxjr4vKSt6JYNxg5yMcw",
  },
  "222-bit-boulevard": {
    name: "222 Bit Boulevard",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "bit-boulevard",
    tokenAddress: "98wxz1tkTjzx5ir97qH91NhcqePXA7X3WWhWfjCf88vV",
  },
  "223-bit-boulevard": {
    name: "223 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "44rTggj8Ew7rwEzHTAQ2yB2mFoDdtzLqFhqUoj5YiLti",
  },
  "223-delray-circle": {
    name: "223 Delray Circle",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "delray-circle",
    tokenAddress: "GqdLnvmuExA4miuKiagAC6MssqxhFtCwrQdpSen331rr",
  },
  "224-bit-boulevard": {
    name: "224 Bit Boulevard",
    biome: "mountain",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "9xmfLg5Vf7XGVqE3YDMZrh37TKUEimUZuSNKjh4rbuPe",
  },
  "225-baker-street": {
    name: "225 Baker Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "CBtnrVbzhnBcBhMVEBgaTYMqwV7ZuztqmjupNhjx4hyz",
  },
  "225-bit-boulevard": {
    name: "225 Bit Boulevard",
    biome: "beach",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "8Rgkywk66tQqieCVVXW3n7sBjNpKfVwC3hyDbdmEwA1y",
  },
  "226-baker-street": {
    name: "226 Baker Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "Ckr1T6w9FvwyUh2cWHsZrzQJuvqAbchAZPZMB13viYog",
  },
  "226-bit-boulevard": {
    name: "226 Bit Boulevard",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "GPXmJ5ERYamDbVkffBrD5PooFgDE3LB5j4tX9r2yLJAQ",
  },
  "226-delray-circle": {
    name: "226 Delray Circle",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "9YFLzjTf97mpiHW2ixMCW98rkJTmPTGsgy3thBrXGvAb",
  },
  "227-baker-street": {
    name: "227 Baker Street",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "baker-street",
    tokenAddress: "AffuCBbA97XoodP1UsKNV8EWnc247wXx3bCbL7ospRnp",
  },
  "227-bit-boulevard": {
    name: "227 Bit Boulevard",
    biome: "city",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "8jTD8faHDT6eXaQgTnvYozmmCEH3UnPo4bbUUQ9JSP9x",
  },
  "227-delray-circle": {
    name: "227 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "AwYKLfr6VZwDJk8GhLRL6XZjaVtANk1PGu5khBoPspdN",
  },
  "228-baker-street": {
    name: "228 Baker Street",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "32Wwhc4ZRAZ2JiyoFcTFFRzaYBxxtwZJk1tZAnvZYgGg",
  },
  "228-bit-boulevard": {
    name: "228 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "7kKJhQnN24uuTxxJrE2QkzVKigKx2fMxHk6TH49WaVyT",
  },
  "229-baker-street": {
    name: "229 Baker Street",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "8Vs7SxgqqdEzoznQXWm9P4VFTAQ3TSskfPec7JYMXTQX",
  },
  "229-bit-boulevard": {
    name: "229 Bit Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "mFQrRwwGiaLy1c8Vrq522syJNBDJ9Vws1Jaw6hcpDuo",
  },
  "229-delray-circle": {
    name: "229 Delray Circle",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "nb59cEjeQqQbNQniDa5z4955GCikgfsVbMmjWba1tvC",
  },
  "23-jump-street": {
    name: "23 Jump Street",
    biome: "mountain",
    frameType: "occult",
    streetId: "jump-street",
    tokenAddress: "FxyPCEmqaNXjtKfLzhrXFqiCgLpJCNPNCQtq3jDLFwF5",
  },
  "23-teeps-lane": {
    name: "23 Teeps Lane",
    biome: "volcanic",
    frameType: "rounded-dark-green",
    streetId: "teeps-lane",
    tokenAddress: "Ghrcg87xS7vt5XqUFCPPSWAKYCCENv7od1U2KwkhR6X",
  },
  "230-baker-street": {
    name: "230 Baker Street",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "baker-street",
    tokenAddress: "NJcTAkCRjeTfVZXdDumLAiYbKRwpiNxqXa6i7BxG12a",
  },
  "230-bit-boulevard": {
    name: "230 Bit Boulevard",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "BFxwFbtPMt2WNTy7UwdWo6Wrw9LJWKHeVfJmR44SnD2y",
  },
  "231-baker-street": {
    name: "231 Baker Street",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "baker-street",
    tokenAddress: "HVhct71LJECeq4K6W26eMvxHGVpsEMntnaUMErASKQn7",
  },
  "232-baker-street": {
    name: "232 Baker Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "59ECqqP4iG7MFQNkvYes1HikpbKAZYdvWa1Ggd8FNhdP",
  },
  "232-bit-boulevard": {
    name: "232 Bit Boulevard",
    biome: "field",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "Cen497y5SUNP2e2YJpxWovrP4jQAU2kPAfEmDggDSvWh",
  },
  "232-delray-circle": {
    name: "232 Delray Circle",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "delray-circle",
    tokenAddress: "BmbLchtx2JtQae2dPLVtnngAbZRyVVkGiVwVUhxbVBTb",
  },
  "232-privet-drive": {
    name: "232 Privet Drive",
    biome: "mountain",
    frameType: "crystal",
    streetId: "privet-drive",
    tokenAddress: "CjF1xaXDMbdNSfqozpRKmPQuSaj756Bi9dKkwMpqDv5a",
  },
  "233-bit-boulevard": {
    name: "233 Bit Boulevard",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "4cGtLqsbjywm8NZwyzDijEVU4QyfLz1S9x3s2rZkQYe5",
  },
  "233-delray-circle": {
    name: "233 Delray Circle",
    biome: "field",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "Fh5JbJKHt2PCHkYaP5MRdbhKbQgYubqnosiG8tMMtM6w",
  },
  "233-privet-drive": {
    name: "233 Privet Drive",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "privet-drive",
    tokenAddress: "FgfmbXZbbx1t4NiXSzpJY1FMjojDXbAirAMqqvkbjf9X",
  },
  "234-baker-street": {
    name: "234 Baker Street",
    biome: "desert",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "C4Te8vH4CKpQvz3yng1mpsi4A8jQuX4uGvuJ4F7i9tYp",
  },
  "234-bit-boulevard": {
    name: "234 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "Di2otapZgiQnz43dkBtNq8aQSp8vANhMbv6RBZT2pxBA",
  },
  "235-baker-street": {
    name: "235 Baker Street",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "baker-street",
    tokenAddress: "5zNGrADud1dkoyfN3qat3ofouxBvhwzNc99qygonwKFy",
  },
  "235-bit-boulevard": {
    name: "235 Bit Boulevard",
    biome: "mountain",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "ASo7gPNXZwmvxAAoLxRJQu3AHuab1SF4bz3QtBohE1qr",
  },
  "235-delray-circle": {
    name: "235 Delray Circle",
    biome: "beach",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "5u8Zu2vQzokaWgN1rJki2yJKKnSqgSCX61MorKdxF6L8",
  },
  "235-privet-drive": {
    name: "235 Privet Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "privet-drive",
    tokenAddress: "BfPjZDjuw2eCmw7UXrGHuDxWv8gktz91T3GVzyUjfFND",
  },
  "236-privet-drive": {
    name: "236 Privet Drive",
    biome: "beach",
    frameType: "crystal",
    streetId: "privet-drive",
    tokenAddress: "BzDaJPVTLMon93btZmEbuEpSAPayXdXTsaUEyDgouEuE",
  },
  "237-baker-street": {
    name: "237 Baker Street",
    biome: "mountain",
    frameType: "rounded-pale-green",
    streetId: "baker-street",
    tokenAddress: "2U9yfLdNHSzZK2mAx5D9GbdzRRLBuupXenN6pmTT6X6q",
  },
  "237-bit-boulevard": {
    name: "237 Bit Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "5W5Vc4YnHB14TGFQs719A8aJxQ3rT4mRhCEuH2b7TJuS",
  },
  "237-privet-drive": {
    name: "237 Privet Drive",
    biome: "mountain",
    frameType: "rounded-lavender",
    streetId: "privet-drive",
    tokenAddress: "CF5wdjHaLsuQX9P4ctsiw3GjfPRtpNpJyMwZABhSQDnG",
  },
  "238-baker-street": {
    name: "238 Baker Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "baker-street",
    tokenAddress: "HkKAcWpWs9u9vUiECd4X2c2xbjaCw3nuWhBSu3VCLb46",
  },
  "238-bit-boulevard": {
    name: "238 Bit Boulevard",
    biome: "field",
    frameType: "rounded-green",
    streetId: "bit-boulevard",
    tokenAddress: "8gB8zxhxxLNanXPmiLDeokSiNXhg8iMx7JvAHu6VREka",
  },
  "238-delray-circle": {
    name: "238 Delray Circle",
    biome: "woods",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "3bksKos4QNfX2W62s7732AW6NyU9AU9DCxebk9vM4SHm",
  },
  "239-baker-street": {
    name: "239 Baker Street",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "baker-street",
    tokenAddress: "J2Na3ynDGsPM68hxBno8nsDcnHxLaFJpGpENDkv13isX",
  },
  "239-bit-boulevard": {
    name: "239 Bit Boulevard",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "GRqbn7EEJe1CtuZQBeK3tMxVap9UKDLPSNLPYR2V1VFu",
  },
  "239-privet-drive": {
    name: "239 Privet Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "privet-drive",
    tokenAddress: "43oCtU1JK3eRsU2UgejJVejHSWVJL7txVCUawF59fnVJ",
  },
  "24-teeps-lane": {
    name: "24 Teeps Lane",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "teeps-lane",
    tokenAddress: "86M83GwLMNZSw8Gw9Z2KUtnx4yFJ6hBBzYXFzNA9tF6w",
  },
  "240-baker-street": {
    name: "240 Baker Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "4BbZJt823XukzAgR7uprkPyfYvbDq4aDek9ntrxg76Vg",
  },
  "240-bit-boulevard": {
    name: "240 Bit Boulevard",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "DNcFRpLtSLN4BhEG4gek8yAohqCGBF2LJaKrafdi9v5D",
  },
  "240-privet-drive": {
    name: "240 Privet Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "privet-drive",
    tokenAddress: "BWMLxBT4FUy6DMff8yE7e4edtNChXbisoawNNETzeFJh",
  },
  "241-baker-street": {
    name: "241 Baker Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "8RGoc2uAHia3KKTpJyM9iskja4qVqZcno1yqmFRs8aPb",
  },
  "241-bit-boulevard": {
    name: "241 Bit Boulevard",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "ADsJjJy8gcbKKvwbZWHLS8vVyd982NJ2MtmTnWtqw9Xx",
  },
  "241-delray-circle": {
    name: "241 Delray Circle",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "delray-circle",
    tokenAddress: "AmMtJiRGr7DddiukFUDUwo72Vtd5WpPcjccmZ5XezoeR",
  },
  "241-privet-drive": {
    name: "241 Privet Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "privet-drive",
    tokenAddress: "CZKbRQ48EQpgwu6VWnef8RQw8VyddZ4rkH6caZ77pHR7",
  },
  "242-baker-street": {
    name: "242 Baker Street",
    biome: "underwater",
    frameType: "occult",
    streetId: "baker-street",
    tokenAddress: "BErJc4jkg49iySissX42hSKtL5Jc8mKDmTLGBoC9yLM6",
  },
  "242-bit-boulevard": {
    name: "242 Bit Boulevard",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "9RQokMVAGsHkGCrggVdkRoP3mChybJ6voo7iH5bLwQX8",
  },
  "242-delray-circle": {
    name: "242 Delray Circle",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "B18yjxcBwLKuinpvoUMpLPc7RYqEMFyvgkhDorhmzsJY",
  },
  "243-bit-boulevard": {
    name: "243 Bit Boulevard",
    biome: "desert",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "GkA32zfv2LFQmVQie1ouMhBXaANdTRhYQqrbUy3ptFD3",
  },
  "243-delray-circle": {
    name: "243 Delray Circle",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "2rDC5UrwcjRHbxjdiwT7wHykgmQhYVxZGKFUkizNMPyv",
  },
  "243-privet-drive": {
    name: "243 Privet Drive",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "privet-drive",
    tokenAddress: "4NXG6Y4vcpJphfZSgGJhLzM1Rkm4BrmwCWrU3uBqmG6A",
  },
  "244-bit-boulevard": {
    name: "244 Bit Boulevard",
    biome: "beach",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "B2onMguskbAe2zdcegKR7GRx4uivX17ozPtparKC8qdi",
  },
  "244-privet-drive": {
    name: "244 Privet Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "privet-drive",
    tokenAddress: "HfxqVfnFZ6FTrBkDufBUBiKVnPFn7RGWUw1Zxh1gyVuA",
  },
  "245-baker-street": {
    name: "245 Baker Street",
    biome: "city",
    frameType: "rounded-green",
    streetId: "baker-street",
    tokenAddress: "BMkPQsYKC1ZSyi9CUweHQa5J89AQiNi63747rp729BJJ",
  },
  "245-bit-boulevard": {
    name: "245 Bit Boulevard",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "Ao5s25ehJcbCPLCqtJLeFBLSYrocHGw1pqs2VX4UNA2M",
  },
  "245-delray-circle": {
    name: "245 Delray Circle",
    biome: "city",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "Ep3iWaMrCSMQ1oh21XnHmb49eHLmETdt8BsdGfDJ6bsi",
  },
  "245-privet-drive": {
    name: "245 Privet Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "privet-drive",
    tokenAddress: "9FTFkPe7WKoGRpRWLqh6r3wipEScWFiJgDNbNro4PAF4",
  },
  "246-baker-street": {
    name: "246 Baker Street",
    biome: "beach",
    frameType: "shuttered-pale-green",
    streetId: "baker-street",
    tokenAddress: "6Uff22BhQh3gDMZ8nf1hP9hWPrFYUDPUTzC6Q4F5qfG1",
  },
  "246-bit-boulevard": {
    name: "246 Bit Boulevard",
    biome: "city",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "HnJcfVRBXHRi7fzTbZrUfVnviwrT41QuyjSFRqR6bkwV",
  },
  "246-delray-circle": {
    name: "246 Delray Circle",
    biome: "city",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "8FWHDQE1vvzadMEH1YvAkmKdSd1WFvZQ89Hc6RAVWCYd",
  },
  "247-baker-street": {
    name: "247 Baker Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "baker-street",
    tokenAddress: "DetngUuaJhXY7SgV8CfL3pz71VMyGFNBoK7hesa9T4wT",
  },
  "247-bit-boulevard": {
    name: "247 Bit Boulevard",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "8uHMatuyZo6qM6VN1bWgnaurkyDH7yd8ZDDLDtzmQUwa",
  },
  "247-pico-street": {
    name: "247 Pico Street",
    biome: "field",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "FyqxfJGAAoeer4aSvtNNH9Uj6EA7iPA6pjCoaJHLMb19",
  },
  "247-privet-drive": {
    name: "247 Privet Drive",
    biome: "underwater",
    frameType: "occult",
    streetId: "privet-drive",
    tokenAddress: "9SQ2hXAsxV1UUYHKPq7pQj2DVsGcFsC94yYWdCn2JZDJ",
  },
  "248-baker-street": {
    name: "248 Baker Street",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "baker-street",
    tokenAddress: "Sz8t27w4pJG8x7biVWxs5x17sGeem4Fss8DMXUQS5Ak",
  },
  "248-bit-boulevard": {
    name: "248 Bit Boulevard",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "GUQierP5Kg1AQcTTV4WrYPnAktv2bt55ynPB945JmVPC",
  },
  "248-pico-street": {
    name: "248 Pico Street",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "pico-street",
    tokenAddress: "JDEqjLgej2182VunAAXwBq42gCLxH1DncH35MgyZ6GJB",
  },
  "248-privet-drive": {
    name: "248 Privet Drive",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "privet-drive",
    tokenAddress: "6RdwpQdZwFHoLd5gCpJQqBFCoDSf5rbx9pLiZNWmusLF",
  },
  "249-baker-street": {
    name: "249 Baker Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "EtWhDympiZAQv5uFkP61WxHDFhamZqSrEE6cYanfWPLC",
  },
  "249-bit-boulevard": {
    name: "249 Bit Boulevard",
    biome: "underwater",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "CZHo69N6XP6jdq9iuwJUFNXu2DUmSm6EjECAXwQYJJSo",
  },
  "249-delray-circle": {
    name: "249 Delray Circle",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "ER83NbTyivnXDXQgwYRVNtA9MD6Cikmi4DNqFRWCRZ6y",
  },
  "249-pico-street": {
    name: "249 Pico Street",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "7onyxAo8VaTwH2e9KsjamsWnRjkSE9qGCb7Doepg3eTZ",
  },
  "25-arcadian-alley": {
    name: "25 Arcadian Alley",
    biome: "field",
    frameType: "glass",
    streetId: "arcadian-alley",
    tokenAddress: "3ckchkFQazxF7FEtzdUyr4jL6W6RQ2w3uHdHFyjjLbdg",
  },
  "25-jump-street": {
    name: "25 Jump Street",
    biome: "city",
    frameType: "crystal",
    streetId: "jump-street",
    tokenAddress: "EJSAnKSMQMSm62f8316SPqgH9NoaavUF3NDpXD6dUxNP",
  },
  "25-teeps-lane": {
    name: "25 Teeps Lane",
    biome: "field",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "26pWcBrhp3jhZaKG4UziejRGrDNRtB8ue32cPiPu8kue",
  },
  "250-baker-street": {
    name: "250 Baker Street",
    biome: "city",
    frameType: "glass",
    streetId: "baker-street",
    tokenAddress: "4mV1sdzWMWospB18CSxYAd2qrUECCKrS54fUZsm1z5aP",
  },
  "250-bit-boulevard": {
    name: "250 Bit Boulevard",
    biome: "volcanic",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "5CJR3XJKazrCt4XrudaQ3Ro1VY4HMonZAngodb1HvfQU",
  },
  "250-delray-circle": {
    name: "250 Delray Circle",
    biome: "arctic",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "5zpXdpxXRvxU4rcUx5UCLUVQks6PGBewGYSJKqi8yTWT",
  },
  "250-privet-drive": {
    name: "250 Privet Drive",
    biome: "mountain",
    frameType: "rounded-dark-green",
    streetId: "privet-drive",
    tokenAddress: "GFfjPuz9abhsYYbsXP8opEBXgUfzPAdzKDQAXa6ZNjF3",
  },
  "251-baker-street": {
    name: "251 Baker Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "AZHagTrJsvLE89WtNc8cQdrYTUH98CEr7iE2DN9tXtEa",
  },
  "251-bit-boulevard": {
    name: "251 Bit Boulevard",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "6KXP4fbpuLR5cTre2EMNd9MFsPPTtUwinnErjDv7Xacs",
  },
  "251-delray-circle": {
    name: "251 Delray Circle",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "delray-circle",
    tokenAddress: "HxbwWtXYZXutrJCGLcbGnbpyL45wKwqYq7DXicZDJ2En",
  },
  "251-privet-drive": {
    name: "251 Privet Drive",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "privet-drive",
    tokenAddress: "CrjiFSxjBTAhBEzMTL4AaUKJR4cVfxBrx9GhDnHDfvWz",
  },
  "252-baker-street": {
    name: "252 Baker Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "AK58KcsQSBBbyDLdQLuxpxW3XS5Zd8zn71hyZLrWMPA1",
  },
  "252-bit-boulevard": {
    name: "252 Bit Boulevard",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "BpPc49m7mfPyrUHNrZYxkx8J9vRTgTm34oJb4HfpWHN",
  },
  "252-pico-street": {
    name: "252 Pico Street",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "pico-street",
    tokenAddress: "CKZUpPjXB7HGafxq84fcokz3JVQ1Rq85UyGHPCfPgsBG",
  },
  "252-privet-drive": {
    name: "252 Privet Drive",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "privet-drive",
    tokenAddress: "7AqGLDhLoXZNa2iYvYULQi5rhbdCt4No1rrs3Zfktfz7",
  },
  "253-baker-street": {
    name: "253 Baker Street",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "baker-street",
    tokenAddress: "9k3TbkXGyPf2ZPwJ6z4A5pJCou64kkY2ftNVDQGSR5SX",
  },
  "253-bit-boulevard": {
    name: "253 Bit Boulevard",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "83UmTbAdnQKChXVDgMVjFkvUAg76ZnFRo3io1CQgQkGL",
  },
  "253-delray-circle": {
    name: "253 Delray Circle",
    biome: "desert",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "8PPxDwWxL8mSvXFEtoDNwzRUj1yw5RH8TXN9W6GBRbxW",
  },
  "253-pico-street": {
    name: "253 Pico Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "pico-street",
    tokenAddress: "AqQD9ZALLK2E6Gw8gWY2bnTV2M6hniqNWcRyqDPGhCsn",
  },
  "254-baker-street": {
    name: "254 Baker Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "3vvJz4EotsW6ctezfsDE9fhocRUZdPaExmLu496bkcC2",
  },
  "254-bit-boulevard": {
    name: "254 Bit Boulevard",
    biome: "arctic",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "7MNcbtTzcs7dXDJBjS83C9tRZB5yAyVYVGC82kNL6296",
  },
  "255-bit-boulevard": {
    name: "255 Bit Boulevard",
    biome: "city",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "6JaBmWC1EiTCwwnSU2s9qwe2CXMeFR2McCfSMUAmh5eh",
  },
  "256-baker-street": {
    name: "256 Baker Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "72WZ24x4pMFuG2tekzhqNysQRuWYtkqks2KryKBbsoNa",
  },
  "256-bit-boulevard": {
    name: "256 Bit Boulevard",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "2BPGjZ9deWgJaKKfdpsHUVFNajo8xhiHz78S7ZTLHUvn",
  },
  "256-delray-circle": {
    name: "256 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "CcEc7Wv4LgKrQD7Q6RXoL3RoamS9aPTyf2YehkCinp9z",
  },
  "256-privet-drive": {
    name: "256 Privet Drive",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "privet-drive",
    tokenAddress: "9VQocfxEbouBNeYqoG1jaxV5yFfwnyeg4ngSxbXe9pmH",
  },
  "257-bit-boulevard": {
    name: "257 Bit Boulevard",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "2pVrYSfLPGpbQUsq2oxEbKqA2wiwj4QWPcPqSNc6PkX2",
  },
  "257-privet-drive": {
    name: "257 Privet Drive",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "privet-drive",
    tokenAddress: "3PMekNodci1zroycYE3uzdBXtKitgADs59qmkQFk4ikt",
  },
  "258-baker-street": {
    name: "258 Baker Street",
    biome: "treehouse",
    frameType: "shuttered-green",
    streetId: "baker-street",
    tokenAddress: "3YqAWjaAdUqG9x7zLyTtCJoJpnHX8N7i3FFcvNjGMAyW",
  },
  "258-bit-boulevard": {
    name: "258 Bit Boulevard",
    biome: "city",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "DUDsAaK8HE6ngRcj11VQorQv8PU7hwCPzrT7UkV2hSwX",
  },
  "259-delray-circle": {
    name: "259 Delray Circle",
    biome: "beach",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "AxHfVTmPTNhqojeA2B1CrEaKWv3iZEJe3GtsgcCLHkgH",
  },
  "259-pico-street": {
    name: "259 Pico Street",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "pico-street",
    tokenAddress: "J97AKVjezDyU8nkTBL1o4kNUfwmNwBvv89aHkUxzH2fT",
  },
  "26-jump-street": {
    name: "26 Jump Street",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "jump-street",
    tokenAddress: "A4ZuGdgo2x5MDiRJwzJxxzYByQ7TDCUiiXsJgN3oGomA",
  },
  "26-teeps-lane": {
    name: "26 Teeps Lane",
    biome: "treehouse",
    frameType: "occult",
    streetId: "teeps-lane",
    tokenAddress: "BYU2nPnLzWjRihRRxLykdk4zweACtQLXFp7JLd28nrwd",
  },
  "260-pico-street": {
    name: "260 Pico Street",
    biome: "desert",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "98F3kkbTXYuodEiAQJQ2gunQBm9iQ7jhAoXa6a6u6MCZ",
  },
  "260-privet-drive": {
    name: "260 Privet Drive",
    biome: "underwater",
    frameType: "crystal",
    streetId: "privet-drive",
    tokenAddress: "9P72y4dggNoEqz6krgrxoWa88LfMEmRUuMCf4yMRnAvz",
  },
  "261-baker-street": {
    name: "261 Baker Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "GVpCbPyrehCAmTbhbkBkEUYovjRpEDF8keedrJX49kH3",
  },
  "261-bit-boulevard": {
    name: "261 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "31pYqgujuEfzEPZfakiSVTx2jXMNorCEDKZyraT1tjVR",
  },
  "261-frontier-street": {
    name: "261 Frontier Street",
    biome: "field",
    frameType: "occult",
    streetId: "frontier-street",
    tokenAddress: "BPGT3LLDYRNRPYRTdE3Twhvm4RU26XBFfL5rpjTrhkCM",
  },
  "261-pico-street": {
    name: "261 Pico Street",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "ARnVBtCAHxkjUTTwNk7xQJQpwNajFEfCEm6Jqn2H7pDM",
  },
  "261-privet-drive": {
    name: "261 Privet Drive",
    biome: "underwater",
    frameType: "glass",
    streetId: "privet-drive",
    tokenAddress: "GWyBveH778vjrYYYLJWM5gDzen3ECP6CnSKjz9SVtEut",
  },
  "262-baker-street": {
    name: "262 Baker Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "2cTYtnxwzF3TjubFj8E3xiVzMtB37Whaech1oAe43Wr6",
  },
  "262-bit-boulevard": {
    name: "262 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "9CxYyGWDGizouKmvdfKRtSzzqwKarVxw7bf3vC3UrvxX",
  },
  "262-pico-street": {
    name: "262 Pico Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "HE9A8AbRYqmFhcGqSBX8HiSvAtFAxarTYZdw6umd3qB7",
  },
  "263-baker-street": {
    name: "263 Baker Street",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "baker-street",
    tokenAddress: "Cuo84aR6WXxvwDN5WXM5Tj524X8kyPKMQriifQWycvxw",
  },
  "263-bit-boulevard": {
    name: "263 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "Biom9evd6PJqqdexydqdovyWNKJNPkMnWdKWTi8JxNjN",
  },
  "263-delray-circle": {
    name: "263 Delray Circle",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "delray-circle",
    tokenAddress: "7kJErhoEqVs5Hm8AUADTnE9RUCPoTXULnEp3r3qNZvC2",
  },
  "263-frontier-street": {
    name: "263 Frontier Street",
    biome: "volcanic",
    frameType: "glass",
    streetId: "frontier-street",
    tokenAddress: "4w6dzGeDvC4KE6q6aF76r3zFkbN1LeZzLpgDkhHpi3Th",
  },
  "263-privet-drive": {
    name: "263 Privet Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "privet-drive",
    tokenAddress: "7wo2Ywu3w89apt39dg7ng5RUueAuDm7be1HS9M1viW6o",
  },
  "264-baker-street": {
    name: "264 Baker Street",
    biome: "city",
    frameType: "crystal",
    streetId: "baker-street",
    tokenAddress: "D8XCEcBoo3QFDJL1dCBpJ29NSYMd7afNHRxQvw1UPXDJ",
  },
  "264-bit-boulevard": {
    name: "264 Bit Boulevard",
    biome: "volcanic",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "4SLJCRY4doiYZGNBiAvbB95P46WWdeBhEGmRhqF5Y664",
  },
  "264-pico-street": {
    name: "264 Pico Street",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "pico-street",
    tokenAddress: "7BT6nKvAbYzoPkvY2JUzHF7n1Vbii71HuAJRmYZpRxhk",
  },
  "264-privet-drive": {
    name: "264 Privet Drive",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "privet-drive",
    tokenAddress: "C4Pp1e4MaVXinZi5xn5jnmraZH9pP322BPVm76NzFCH2",
  },
  "265-delray-circle": {
    name: "265 Delray Circle",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "854oCUNo6FX2gu3aZCTubmJZA2JzE6zX5fzt4xJwXZhp",
  },
  "265-privet-drive": {
    name: "265 Privet Drive",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "privet-drive",
    tokenAddress: "CxgqWyRdLLEM3neNHKRdsA3RyKnGyNewY6yiyGMJuovs",
  },
  "266-bit-boulevard": {
    name: "266 Bit Boulevard",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "bit-boulevard",
    tokenAddress: "JDqwnQTHidyc4RjcMqUt7R75GkWEGi8RuxRvqe2gNbE1",
  },
  "266-privet-drive": {
    name: "266 Privet Drive",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "privet-drive",
    tokenAddress: "CKf9dBku6rm5KT7o1dq4zLKRAyLuHeHxAEvwaDwWCpo8",
  },
  "267-baker-street": {
    name: "267 Baker Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "baker-street",
    tokenAddress: "9EuwTL5iqzTcSHBWVbBCQG69BZFHZ8XrbMSEFA4xKfk",
  },
  "267-bit-boulevard": {
    name: "267 Bit Boulevard",
    biome: "beach",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "FnumEvHfKTUDnACmxn8Mm2sqoKgysWAvLKb4iNfzjUk6",
  },
  "267-delray-circle": {
    name: "267 Delray Circle",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "EWqMcLcjx2fQ7NxfCyUaaxweCBffYL9xxbGf5FRbxs6F",
  },
  "267-pico-street": {
    name: "267 Pico Street",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "B5JExsHD3FcVtsVcwD9XfFMSF5qijhvN5SK9RPVE7iGp",
  },
  "267-privet-drive": {
    name: "267 Privet Drive",
    biome: "city",
    frameType: "glass",
    streetId: "privet-drive",
    tokenAddress: "8NB2ACDW6UfNQqVTzZSTJdacVKcWFWBeLRyvVaZ3NxGo",
  },
  "268-baker-street": {
    name: "268 Baker Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "H5N4E73JKR6ApSPWAzN5YuhCEom6MyJQet7LsSJT1coN",
  },
  "268-bit-boulevard": {
    name: "268 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "bit-boulevard",
    tokenAddress: "8GCBeYL5hxACpaqDQMv7ZYTUFjnfjm8xaudiGM4yp5Tz",
  },
  "268-delray-circle": {
    name: "268 Delray Circle",
    biome: "underwater",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "BDwo8uJhAPCc7KBdpR1yQvNf7ViuTXFCuaNRbUsWzrGq",
  },
  "268-pico-street": {
    name: "268 Pico Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "61wZDba51rKA1nTxcXbBBBBkTpy9nTKE5VRHJsJGXycJ",
  },
  "269-baker-street": {
    name: "269 Baker Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "baker-street",
    tokenAddress: "AkRKNoSa9AjwDib6Gsb7nRjuwTJsEP74gK48W2v8Q5zB",
  },
  "269-bit-boulevard": {
    name: "269 Bit Boulevard",
    biome: "volcanic",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "Zmnm7rZg776m4QtRFQ9iXFubsha4hJeCXRo4vD4Wjpt",
  },
  "269-delray-circle": {
    name: "269 Delray Circle",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "delray-circle",
    tokenAddress: "9cooQJZvZ94UUbXrQVL785fccCVASBYyPj6VeduPC1R",
  },
  "269-frontier-street": {
    name: "269 Frontier Street",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "frontier-street",
    tokenAddress: "FgoBZQ5BZGW4dT7V7QdsqFVfK3dsmMBJtbGXgpwEzex2",
  },
  "27-ape-lane": {
    name: "27 Ape Lane",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "ape-lane",
    tokenAddress: "G4Ubn9PrXSvNm2du6zAvfUFVpD32c3wrL8hCcTyoZyzT",
  },
  "27-arcadian-alley": {
    name: "27 Arcadian Alley",
    biome: "woods",
    frameType: "shuttered-dark-green",
    streetId: "arcadian-alley",
    tokenAddress: "EaoHB28H87w6ubLstmMiqAixt6wFTj9z7vj2CEimMN9g",
  },
  "27-jump-street": {
    name: "27 Jump Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "jump-street",
    tokenAddress: "9b7gcfJmGNYNKMmezkfTYnhxbzyajRiDcjR1ffPPyW2e",
  },
  "27-stewart-avenue": {
    name: "27 Stewart Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "stewart-avenue",
    tokenAddress: "BFFRLup689pshtUc3GRcemaibHr7mei61xsu7PbN5rDE",
  },
  "27-teeps-lane": {
    name: "27 Teeps Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "teeps-lane",
    tokenAddress: "54FJm9FtoFUHEJN3o6omGyF44SqVK1nUEbhq4xYXhSAG",
  },
  "270-bit-boulevard": {
    name: "270 Bit Boulevard",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "3s9nhRQpere3CTZeQ1Nn6ersjKTZ4n29N2xGmQC3cf6B",
  },
  "270-delray-circle": {
    name: "270 Delray Circle",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "FfhyY63GmHgHZV54rEjA9wjDUrM4aWuejqPGgnKzE68B",
  },
  "270-pico-street": {
    name: "270 Pico Street",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "pico-street",
    tokenAddress: "4dLvxyjbxpunDV3joDHoG3rSpfthPJ4kDMFuK7JjgTqh",
  },
  "271-baker-street": {
    name: "271 Baker Street",
    biome: "arctic",
    frameType: "shuttered-yellow",
    streetId: "baker-street",
    tokenAddress: "2uHNkK89FxjrPBzj4dYniSxaNTM4EnV3VfKN7BLaVkvB",
  },
  "271-bit-boulevard": {
    name: "271 Bit Boulevard",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "5tiKH1DTQfSy9wtqRBGyKTjRWswEZDxSfCpAKvmJMxhJ",
  },
  "271-delray-circle": {
    name: "271 Delray Circle",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "DWkAp4GpoRTtCLmPecoDrcBn4qKNRshwsXvRFccEwGij",
  },
  "272-baker-street": {
    name: "272 Baker Street",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "baker-street",
    tokenAddress: "Avtwg6cVd2NN4M6y127hrs1iaJpa2uU6C41X9Yzkb3Kp",
  },
  "273-bit-boulevard": {
    name: "273 Bit Boulevard",
    biome: "city",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "DpZPGcX9wGU99uq94fyYBosKM5JVNGKb4HmYmixnB3XS",
  },
  "274-bit-boulevard": {
    name: "274 Bit Boulevard",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "2MwZC6kPM9VGzzaRBMJzvKUAoa1Gn2BMZgdKxmdiubZz",
  },
  "274-delray-circle": {
    name: "274 Delray Circle",
    biome: "volcanic",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "H1MtuFeyfHaJJi1TkXCLHizF5zfeNtn9DC8grNfakBZC",
  },
  "274-frontier-street": {
    name: "274 Frontier Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "frontier-street",
    tokenAddress: "3CwQetY1N3fGMQMw5QMf4y9L2swxcY1AcitXLRWrdWPo",
  },
  "274-pico-street": {
    name: "274 Pico Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "pico-street",
    tokenAddress: "9eQx3tYKGZrVj49HJSL4orzBZ6DVeaR2oCKXzjZcxuwN",
  },
  "275-bit-boulevard": {
    name: "275 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "8YvY5QFC4Snt2cVxZNJ1seksJZ2b32aGTkCkzttsbRTP",
  },
  "275-delray-circle": {
    name: "275 Delray Circle",
    biome: "beach",
    frameType: "occult",
    streetId: "delray-circle",
    tokenAddress: "Gz1MCZa4ytZbFzzkgAMkAvTQX1aLrMUMfhCf76LjWZHo",
  },
  "275-frontier-street": {
    name: "275 Frontier Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "frontier-street",
    tokenAddress: "FE5x2N4sZzqBMPcz6ja4eiR6vqm9B6ss9bguCRm4s6oK",
  },
  "275-pico-street": {
    name: "275 Pico Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "ALxjNx6oBJYLtEDtu96eEvJfjg1bP5WieSwBk7NGdwd",
  },
  "276-baker-street": {
    name: "276 Baker Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "baker-street",
    tokenAddress: "9RQdVNJviUshdAt48R32F2utAyamqtTKUsegw53iwTsN",
  },
  "276-bit-boulevard": {
    name: "276 Bit Boulevard",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "GL7Zk81ZeRYyohoJ7aaSFxiAkrev27CDgpB1p1Z9VbDT",
  },
  "276-frontier-street": {
    name: "276 Frontier Street",
    biome: "arctic",
    frameType: "rounded-pale-green",
    streetId: "frontier-street",
    tokenAddress: "9WCEHAxDguc9JixBCKpUSAT1tERp5VAmAZsybGP43tpH",
  },
  "276-pico-street": {
    name: "276 Pico Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "pico-street",
    tokenAddress: "B61FWoJjBWsK3wddyW9gdLWbo3aCnEFmXcF9DnGyhtnM",
  },
  "277-bit-boulevard": {
    name: "277 Bit Boulevard",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "NX1CYRBGobsR9nx9mbcUBMJahXHPR9E1YcSBgDiPMg1",
  },
  "277-delray-circle": {
    name: "277 Delray Circle",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "delray-circle",
    tokenAddress: "V5LuuvARMAxHkZbVWDtVg4MTSDvF2Yy2xNYGJir9F7R",
  },
  "277-pico-street": {
    name: "277 Pico Street",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "DXFSdKBoqXvPf5Y57hUqRKLTNB8bWuYu7VWdbbwZLqFG",
  },
  "278-bit-boulevard": {
    name: "278 Bit Boulevard",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "vopSp6AqTVzYuDJXd1Wu7rVz6wkzB1uFytrTZkUqgq8",
  },
  "278-delray-circle": {
    name: "278 Delray Circle",
    biome: "field",
    frameType: "rounded-green",
    streetId: "delray-circle",
    tokenAddress: "7j5fgfqUBk9wjWgH1kbSPT2zDEU2ypbWi7kzhW5SkfPB",
  },
  "278-pico-street": {
    name: "278 Pico Street",
    biome: "city",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "3wAyiMoonhW8jskAAuvJMojqix89HFazFygdVfDjVPKZ",
  },
  "279-bit-boulevard": {
    name: "279 Bit Boulevard",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "79A74dtHSC2nUjGpw839ZXpM51eoLpTzhWiHv99kPFe1",
  },
  "28-ape-lane": {
    name: "28 Ape Lane",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "8Div5eK9AwBNVaz2UEpjNBTR2EmBYvg9ghawxvWbsiuS",
  },
  "28-jump-street": {
    name: "28 Jump Street",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "jump-street",
    tokenAddress: "9XeDMVvruUAU1rEQ9BsvUxS5ZLsfpjUjAFuYgTm7ZVA9",
  },
  "28-stewart-avenue": {
    name: "28 Stewart Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "5GuaGcXhR8fV9odV6DLQr9MhympQJCBAz4bo23XYBjVC",
  },
  "28-teeps-lane": {
    name: "28 Teeps Lane",
    biome: "mountain",
    frameType: "crystal",
    streetId: "teeps-lane",
    tokenAddress: "tqbr3X7EMHthZ3evW73w7RgDqmbgmNg8ZUKXBuij64D",
  },
  "280-bit-boulevard": {
    name: "280 Bit Boulevard",
    biome: "volcanic",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "9NH5JoZQKPkf1EFQVxXzX1XhtgFvYgwFsBV2aa9MTxvi",
  },
  "281-bit-boulevard": {
    name: "281 Bit Boulevard",
    biome: "underwater",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "2tvD4eHLTy5m3exs1BX3cdy7bxHdWFqE8ZCFKhsCDdiU",
  },
  "281-pico-street": {
    name: "281 Pico Street",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "pico-street",
    tokenAddress: "AAdQXhx3vaezHxNFbfTmkLztVjZfPDKgV1hfN68hdcYT",
  },
  "282-bit-boulevard": {
    name: "282 Bit Boulevard",
    biome: "arctic",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "J9ek1ZhR2eVmSd9f7XLATEEMtX74ifqyzzydATsf1Dcv",
  },
  "282-delray-circle": {
    name: "282 Delray Circle",
    biome: "field",
    frameType: "rounded-green",
    streetId: "delray-circle",
    tokenAddress: "F6rswNxd16EEmb3dWoF98QMP8yxkVyCwEisHRF4akcsh",
  },
  "282-pico-street": {
    name: "282 Pico Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "EhiytiRP7anpAtGrfoRmEasVxaNn3Qz3Nsjxx6VgUB1G",
  },
  "283-bit-boulevard": {
    name: "283 Bit Boulevard",
    biome: "city",
    frameType: "rounded-red",
    streetId: "bit-boulevard",
    tokenAddress: "SKjjxJj23DuSPSvuAN4UxZpFxnLnYVYT23zqQegkFfH",
  },
  "283-pico-street": {
    name: "283 Pico Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "3DmktnHwKdeJoDH1oFavXqQ8RcYfVjD6XxVN3j9KoqUF",
  },
  "284-bit-boulevard": {
    name: "284 Bit Boulevard",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "AXppk6xJA5mfYQoBGuzxypvRdFWnpeULE3DirpYaY2bs",
  },
  "284-delray-circle": {
    name: "284 Delray Circle",
    biome: "field",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "6HfE5emn3gic8FQHVJ1DuLUBXKMXde8SYYCLQhEDkZiW",
  },
  "284-pico-street": {
    name: "284 Pico Street",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "pico-street",
    tokenAddress: "8pkipL1Tqy5iWghggBcPgrXVqDpxeiJv8Vyy86AtsLnn",
  },
  "285-bit-boulevard": {
    name: "285 Bit Boulevard",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "J8JLULYXfvuHpDr8C8Yb88mPjT7wh5XPWCWSaGSEi2Uy",
  },
  "285-delray-circle": {
    name: "285 Delray Circle",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "4jrHSDo2waThQJ9pg4d8SLXMcG5EfWfZjmtMxNBLNgb9",
  },
  "285-pico-street": {
    name: "285 Pico Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "hYqB9VNpCFfVfYQyWsrtT4qg5ufb1RXYj2R2N91mpQe",
  },
  "286-bit-boulevard": {
    name: "286 Bit Boulevard",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "BkjmdmtKqoraf1Qh4zt8TgCfFarMLXVWumhKVEaPfR6C",
  },
  "286-delray-circle": {
    name: "286 Delray Circle",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "AAPtyjds1e8vCM97kN6LBuEqiXnitcNciARTrQVJcMdR",
  },
  "286-pico-street": {
    name: "286 Pico Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "9Sj4185dLKSE9pEvwopuCSgMuGBAZBN9NFG1iEAGHeMc",
  },
  "287-bit-boulevard": {
    name: "287 Bit Boulevard",
    biome: "woods",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "CpBoaWFj4EaYQ4yMVwfP3e65yJ7D75wnTU3BktUNVUw4",
  },
  "287-delray-circle": {
    name: "287 Delray Circle",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "2izA8GBrC7VAomoVTM88wqsnCM4xR2WXuEQFFJRSweQ5",
  },
  "288-bit-boulevard": {
    name: "288 Bit Boulevard",
    biome: "underwater",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "GWnV1wLRC5RCtG2zd6iXzVx7Zja9QZeXZtWzEJVoEsyV",
  },
  "289-bit-boulevard": {
    name: "289 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "9dNvySAoEJtXKMV6jm4rQznBVPsiVgqENsTYz8Ui3CCm",
  },
  "289-delray-circle": {
    name: "289 Delray Circle",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "J8koPGTkihwsEtXAzjkKCWKzC6QJb8m3TTaG9tDVsbYq",
  },
  "289-pico-street": {
    name: "289 Pico Street",
    biome: "woods",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "6rNvbK8draVatEqyWTm2JLrfZ7SXRs4FBVtsnqwoZSz6",
  },
  "29-ape-lane": {
    name: "29 Ape Lane",
    biome: "field",
    frameType: "occult",
    streetId: "ape-lane",
    tokenAddress: "Ad8MCYa4tgahQWbjdkJmp8whirqmW8vbb3qN9C7Qm1C6",
  },
  "29-jump-street": {
    name: "29 Jump Street",
    biome: "beach",
    frameType: "glass",
    streetId: "jump-street",
    tokenAddress: "BSRXdKBoK8Wrr2ZFzvMoQUGS4MqGdZyt7nGjRfNBiQ5P",
  },
  "29-teeps-lane": {
    name: "29 Teeps Lane",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "teeps-lane",
    tokenAddress: "VwiFCyMgbj4MWQimNNGN96sN3bd4wXkWf9iWAN8ZKB8",
  },
  "290-bit-boulevard": {
    name: "290 Bit Boulevard",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "D656vxfxmTGN9Jen25K4q61CLZ95rhCLB163s8p4Y8Ez",
  },
  "291-bit-boulevard": {
    name: "291 Bit Boulevard",
    biome: "beach",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "4MWRfk9DPzftdCDpzFHixSN7F2BJVw36ywsLKtisgBws",
  },
  "292-bit-boulevard": {
    name: "292 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "D4qBferqFDRyK3nnFmW8NU6EjE61Bh7BmzzvEGsRL1Er",
  },
  "292-pico-street": {
    name: "292 Pico Street",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "pico-street",
    tokenAddress: "CTGmQdoYUS1iH6dExGFayi15sieevcNyu65H8MFmqD15",
  },
  "293-bit-boulevard": {
    name: "293 Bit Boulevard",
    biome: "desert",
    frameType: "glass",
    streetId: "bit-boulevard",
    tokenAddress: "D5tjLJW9EmN6rMpvsTbrJcgPuhuv2CQGucMspkA52mDM",
  },
  "293-pico-street": {
    name: "293 Pico Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "4hdJNgwbVdLAZaYCWE2Rip6rtsujVcku6WWJim2Y78qi",
  },
  "294-bit-boulevard": {
    name: "294 Bit Boulevard",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "6K9d6DVrW8yxVyjrUKtznrsWw8wHyDeywwDiWAPiAwAf",
  },
  "294-pico-street": {
    name: "294 Pico Street",
    biome: "treehouse",
    frameType: "rounded-lavender",
    streetId: "pico-street",
    tokenAddress: "DtsMRpcmVAF1u9trhoWReD1DRxff6XaRddjMrZujzYWK",
  },
  "295-bit-boulevard": {
    name: "295 Bit Boulevard",
    biome: "mountain",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "4c649aefoTKDqXFjoUzMJKUEZqgnFCxcfdBbPSvMQaHT",
  },
  "295-delray-circle": {
    name: "295 Delray Circle",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "3LBQFgWF6ZZortQMBsBhHXLSxY15LhSevk5k8N6d4ttN",
  },
  "296-bit-boulevard": {
    name: "296 Bit Boulevard",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "A4aS2BsEG4mXhL8jK5ntoKbWF9SkNzVnV5SGz51vKNom",
  },
  "296-delray-circle": {
    name: "296 Delray Circle",
    biome: "beach",
    frameType: "occult",
    streetId: "delray-circle",
    tokenAddress: "9iPSBfmsWgy6guthQAEAQB3dtV3w5WZjKxVN3pUtnd3Q",
  },
  "296-pico-street": {
    name: "296 Pico Street",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "BJsXoLudQqTE7KXWa6gSqNbq3KQQvyNpJ6FMiSUTDF9c",
  },
  "297-bit-boulevard": {
    name: "297 Bit Boulevard",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "FScEZoVc96AHqfae3DQ2osLYkLbYMft1ts3wXKrWcSYN",
  },
  "297-delray-circle": {
    name: "297 Delray Circle",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "AqHFk5ES25WonKk1S2a3nbQqtTo6wZ5Z3b8bF92VTY5k",
  },
  "298-bit-boulevard": {
    name: "298 Bit Boulevard",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "bit-boulevard",
    tokenAddress: "2TG3APWAR45AJHj7hQ8eJN4mxDBbnk4qEyocMxzrxa2K",
  },
  "298-delray-circle": {
    name: "298 Delray Circle",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "GC6vkVmKTf9H4WmcYqJYTeSTCDvivfcQR84LgUx7uTrR",
  },
  "298-pico-street": {
    name: "298 Pico Street",
    biome: "field",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "DYDqa6hdn1HY7wUNx2M83RU77BhQmVF7SxekRjDRiV27",
  },
  "299-bit-boulevard": {
    name: "299 Bit Boulevard",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "Esr3mgM5A2oxKUfR5hnzzX7pAWJh1enK8QsDxB17BRSW",
  },
  "299-delray-circle": {
    name: "299 Delray Circle",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "6WDCnN73kmxexKxxJvTAdpgVesuVt5RHPTmHBz3xWsh8",
  },
  "299-pico-street": {
    name: "299 Pico Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "pico-street",
    tokenAddress: "E1Kz96vF627WpFJbwjQhJNoFMq7PdEJbEBhzaAakN7qV",
  },
  "30-ape-lane": {
    name: "30 Ape Lane",
    biome: "mountain",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "4Nt3gKBroVSzsHjy1p7j2oZwdtHywbdgiXXtXaWz5i3z",
  },
  "30-jump-street": {
    name: "30 Jump Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "jump-street",
    tokenAddress: "w4FaPb7F5y7iEv1QqtE28MUyQFjdhrcgeApcPoRfzoD",
  },
  "30-rosa-street": {
    name: "30 Rosa Street",
    biome: "city",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "9i2PvYje2t61PsjXpwR7ddDRLHvQLTgg7qnd7qN3h247",
  },
  "30-stewart-avenue": {
    name: "30 Stewart Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "FsfGikHUNGSiFD7dBWAqUiwjTci3ettdWFPUPMVJ5L47",
  },
  "30-supple-circle": {
    name: "30 Supple Circle",
    biome: "field",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "2Jus14cz9u1FgYQtRtX7RXmf5FnAxkiQfPrif8g4uUPf",
  },
  "300-bit-boulevard": {
    name: "300 Bit Boulevard",
    biome: "beach",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "HNLVwBojTEZb6tY2QdUuXWv7tLToiLVW8uuX55fUYciC",
  },
  "300-delray-circle": {
    name: "300 Delray Circle",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "9azaVzgEKsgPKYKDLLQXRcnGWd8KevqBdbW5E2Yppe7x",
  },
  "300-pico-street": {
    name: "300 Pico Street",
    biome: "woods",
    frameType: "occult",
    streetId: "pico-street",
    tokenAddress: "4ChvbTAdCRkTmHg1hKuDAyaMgJmxDhyaksY234LcKz3j",
  },
  "301-bit-boulevard": {
    name: "301 Bit Boulevard",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "BLNHzqasPvLCTgu2PXz7RC2UqwptUFnFYG1dM52CE1Jv",
  },
  "302-bit-boulevard": {
    name: "302 Bit Boulevard",
    biome: "desert",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "ChP4p83m2jA8J3jEYoNGeGqZv4CynfkBhxwvV9VBHJ7Y",
  },
  "302-delray-circle": {
    name: "302 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "HCswT88fT1fXNTbiQcK5aCpMfRbVwXYdF7TGu6fYHBhZ",
  },
  "302-pico-street": {
    name: "302 Pico Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "3KosercWAZMogEma6vP98YyHLC9gS9nGUQqrmpqREmC1",
  },
  "303-bit-boulevard": {
    name: "303 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "bit-boulevard",
    tokenAddress: "7H66ZCeT4s4eMiEsgHuXTEkmRb3G5KKqJ4MWsfjkvcDi",
  },
  "303-delray-circle": {
    name: "303 Delray Circle",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "delray-circle",
    tokenAddress: "4MdbfXbX2PfKK1F9zVCC6AgqPvLhYRg447soYCgz7ZP4",
  },
  "303-pico-street": {
    name: "303 Pico Street",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "2Y8Zazas4HDPtvXRWGTyYj7iTjyZSfSZBYNY819Lgfyw",
  },
  "304-bit-boulevard": {
    name: "304 Bit Boulevard",
    biome: "field",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "6mhspWAabvaYBsngVn74574NnwB9MtkuEfV9rwxdCXsn",
  },
  "304-delray-circle": {
    name: "304 Delray Circle",
    biome: "volcanic",
    frameType: "shuttered-green",
    streetId: "delray-circle",
    tokenAddress: "Ej6T3k2forkJwicTTLUo1kWyXmejpFNGqZMVm74PvL1d",
  },
  "305-bit-boulevard": {
    name: "305 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "6BRvVC49tfoaPsNYGavZDJQ9w33wSmjNyUvPMeEFSqsL",
  },
  "305-delray-circle": {
    name: "305 Delray Circle",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "2SdMgnknNnDXea11aYnsrrCAVmdC72GN6SCFgDw84DPL",
  },
  "305-pico-street": {
    name: "305 Pico Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "pico-street",
    tokenAddress: "EkeJ2GsQdjhaBoFoCAFmsYcTjsZwpYaXXmKdscDnp2EA",
  },
  "306-pico-street": {
    name: "306 Pico Street",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "pico-street",
    tokenAddress: "GMqBHR6ULNARELN8J3cHMhF64RpwNRjgtgx611DEN8VX",
  },
  "307-bit-boulevard": {
    name: "307 Bit Boulevard",
    biome: "desert",
    frameType: "occult",
    streetId: "bit-boulevard",
    tokenAddress: "8HhB2zKqBft4nT87zw7Mw79mbhfM8V2w6jLaaJRU5gtC",
  },
  "307-first-avenue": {
    name: "307 First Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "JArTcxdTGoU5YSpuxZtmpYPmKatSpyzU12pa3qqKHxDJ",
  },
  "308-bit-boulevard": {
    name: "308 Bit Boulevard",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "3FKbgDKiNyABmbcjnZVCpM734H5LCHX1BeRjKraSJePJ",
  },
  "308-delray-circle": {
    name: "308 Delray Circle",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "EHYMZCf5e78ed3ado84tHE6dTrQakLTvaXpimZdiXzUG",
  },
  "308-first-avenue": {
    name: "308 First Avenue",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "first-avenue",
    tokenAddress: "CzxkiYKEx91V459vGMhuuAzvU6gzZrv4S7b1kLw7yuP9",
  },
  "309-delray-circle": {
    name: "309 Delray Circle",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "8GZCuLLxZHMZayUU9gUytf2TKfgPGtkgH6jPvdg4Dc9N",
  },
  "309-first-avenue": {
    name: "309 First Avenue",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "first-avenue",
    tokenAddress: "87BSeiHHdJzyzv5i2ykGB8UBxPWafKqnDA6mkmd7opEM",
  },
  "309-pico-street": {
    name: "309 Pico Street",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "pico-street",
    tokenAddress: "Dz1uNV15hyvh7nXniSBe7pR8i2s6fEqzVke4NNqg1ASL",
  },
  "31-ape-lane": {
    name: "31 Ape Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "Fjx2tSew458U1bNi4osytocH1a3ZJqFPijFHsB74nNGf",
  },
  "31-rosa-street": {
    name: "31 Rosa Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "DDguPNbgdLH9p6J9mGg6adazb4XtSJCxGzxiXSAje474",
  },
  "31-stewart-avenue": {
    name: "31 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "AfrKtbMi2zbgMfgiDYo8SHBWwiC7kJgskRQFou4d7YN6",
  },
  "31-supple-circle": {
    name: "31 Supple Circle",
    biome: "desert",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "2THxhMcDbB1WA2X4aJfDwLKzSiRsB5HvYWYUj2UJnEfB",
  },
  "31-teeps-lane": {
    name: "31 Teeps Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "teeps-lane",
    tokenAddress: "4UBrfNLAyYPwYRYvy9PJStqa8ztkFDjZsAuaL2rUtkXJ",
  },
  "310-bit-boulevard": {
    name: "310 Bit Boulevard",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "98NrxVbHPd3sqnidhc3dGEhyHX4vA6DhgsYUhTNWRJue",
  },
  "310-delray-circle": {
    name: "310 Delray Circle",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "FopHmfqSovde9c6WmiF8jpDB3cBQDKsKX85NiaeS5WZz",
  },
  "310-pico-street": {
    name: "310 Pico Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "pico-street",
    tokenAddress: "8Sf8avwyLdXSMw12cwSBHfAwLhb1Ekvb962HYckpUV7g",
  },
  "310-sierra-avenue": {
    name: "310 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "UZRPBT8moomBhr3urLXqFVkTwbHV9bRZLVRPkQ8d6qn",
  },
  "311-first-avenue": {
    name: "311 First Avenue",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "first-avenue",
    tokenAddress: "67Kf7bCFt6kXmqnBp2nFk85cucNcNjPQhxZZiEJLMcSX",
  },
  "311-pico-street": {
    name: "311 Pico Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "8YosuwjP2PayiEr7KFpGkvuq23rgVsc8GEsy8yhLWhuj",
  },
  "311-sierra-avenue": {
    name: "311 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "EY4dyVh9g2VYv8zuaEzLqgsYxU4Wowj8A47jXq1TtqNW",
  },
  "312-bit-boulevard": {
    name: "312 Bit Boulevard",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "bit-boulevard",
    tokenAddress: "CeSjcRjuufz7nptQ6J4sV436ys3s6N1cNqoqFeDrckf3",
  },
  "312-delray-circle": {
    name: "312 Delray Circle",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "HF5BG7t4zfMgC2jXiuKHFbF4XX6iYJUnZFWjGUj8ahV1",
  },
  "312-first-avenue": {
    name: "312 First Avenue",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "first-avenue",
    tokenAddress: "2XCmLJLLHxEUNp3GTsUvsvs9DxRz17v3BFVeiLvP6Kd4",
  },
  "312-sierra-avenue": {
    name: "312 Sierra Avenue",
    biome: "woods",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "5m1UN1p1Y81kHvguA4DpuQ48kHgrBzgw3qqEUgereGZq",
  },
  "313-bit-boulevard": {
    name: "313 Bit Boulevard",
    biome: "volcanic",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "Hqaw5Y1pK8F8DxZBAPGLmPcvxZyunFFxnrjBTogHNFCu",
  },
  "313-delray-circle": {
    name: "313 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "delray-circle",
    tokenAddress: "6Bmm1zWoWbXwdXR7NrEBSX4VsMXYt2nLeYrBW5riJedL",
  },
  "313-first-avenue": {
    name: "313 First Avenue",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "CQyN2yHoViGiXmFbmHqMtuXLZjAmBnTDdMBzSwFgj4dZ",
  },
  "313-pico-street": {
    name: "313 Pico Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "3vCvtTCv9zxG7n7q8h8kspr2n1uFUfi8qjJzUogZssw6",
  },
  "313-sierra-avenue": {
    name: "313 Sierra Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "HorRWwPBobmW2Xi5HBLB11RnCghLyZugEHqq9TuFKMjD",
  },
  "314-bit-boulevard": {
    name: "314 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "2oUTgjK81BRTLQkU2PoZDTbK3R8AzHJjiLPBKvhnnVmG",
  },
  "314-delray-circle": {
    name: "314 Delray Circle",
    biome: "city",
    frameType: "rounded-dark-green",
    streetId: "delray-circle",
    tokenAddress: "3r8YK8CkN44m8zQa88VP85zkGBxEntnHbmPN2BX3q6SJ",
  },
  "314-first-avenue": {
    name: "314 First Avenue",
    biome: "field",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "5bgQP4wFn3SB2KxYHCXtzBfmn81oaPi55LokZ2htC1dG",
  },
  "314-pico-street": {
    name: "314 Pico Street",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "Gz3K7DWpmDGPJ6eoM5ixMZCKBq9LMWQZnpRHbBoeczNF",
  },
  "314-sierra-avenue": {
    name: "314 Sierra Avenue",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "DQbzfKteY8LyzqvseK1ZHwiNF5s9BvX4KPhE74EfqbCL",
  },
  "315-bit-boulevard": {
    name: "315 Bit Boulevard",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "GHAWe88VajxWmG2h4dcTx9ZmchbC1CxTveFuJgQV4o2k",
  },
  "315-delray-circle": {
    name: "315 Delray Circle",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "3uBxxhAhpcgxQuHW5Mqz3FViFwGxEntLdruFJnv7PMA6",
  },
  "315-pico-street": {
    name: "315 Pico Street",
    biome: "volcanic",
    frameType: "shuttered-pale-green",
    streetId: "pico-street",
    tokenAddress: "FKqkvYcHuscdndLzuzsgvdARmfUvffHSZ4pY4opEKtco",
  },
  "315-sierra-avenue": {
    name: "315 Sierra Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "7h9Z6tJ7ihtfuenCFaxMh1sMWq65yQLhHgibAC1qSfoP",
  },
  "316-bit-boulevard": {
    name: "316 Bit Boulevard",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "E2h62VN5i4oEzq33eUqVpYHLHYuAjSe1FfgHqP2ncdDc",
  },
  "316-first-avenue": {
    name: "316 First Avenue",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "first-avenue",
    tokenAddress: "ukpCtjz9xgBYW3pibA5YHFJh5bvFudXkQonbFkPEZcn",
  },
  "316-pico-street": {
    name: "316 Pico Street",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "Bon2BkUrwBwmCZN7rgobFu2q9RLTVjwyNzB6Mscfvhen",
  },
  "316-sierra-avenue": {
    name: "316 Sierra Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "95eLxNUqJ5pvgEYQhSJMT3SrTeq9fRAvYzResT1rQrdq",
  },
  "317-bit-boulevard": {
    name: "317 Bit Boulevard",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "bit-boulevard",
    tokenAddress: "6YVUgmXHsvgAFZavCnPrrzdeN3AAoNTs2YKD6f9VvpUL",
  },
  "317-delray-circle": {
    name: "317 Delray Circle",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "delray-circle",
    tokenAddress: "J3S8rKHybkpMpBeDDsEf2vW53324UeMhULC2vsdku8km",
  },
  "317-first-avenue": {
    name: "317 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "5hRhQthMwnLxndGTdG7R5qQALXRKuU6FDSta8duy5KrW",
  },
  "318-bit-boulevard": {
    name: "318 Bit Boulevard",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "H6FX7LZ4jmcrF63dCm5XcFzQB3xLUFYvvekgBRsX3kzP",
  },
  "318-delray-circle": {
    name: "318 Delray Circle",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "delray-circle",
    tokenAddress: "AnSpZgiABNaQ29QVgcdezbFG4cc8TwkCyyvRGUX7X6Qt",
  },
  "318-first-avenue": {
    name: "318 First Avenue",
    biome: "mountain",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "EhrUi3E2HxofWzPbB9PERdmWzu9bxRwsbkbzYJYvJnsF",
  },
  "318-pico-street": {
    name: "318 Pico Street",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "c5mvW2f134r9MAKgFCBGrMr5gRxd6HDgSNZXYXnaPQB",
  },
  "318-sierra-avenue": {
    name: "318 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "ExKWz6SXmkSwzW4UZAHPJBhBPRPqYnYBvzvgoCE5hiPh",
  },
  "319-bit-boulevard": {
    name: "319 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "bit-boulevard",
    tokenAddress: "EctCkfZBSsCzufbfSgh7TpPAmUj6wkMd2iHE5NmVcEbS",
  },
  "319-sierra-avenue": {
    name: "319 Sierra Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "EgK26q8XWuJ7dayeq1C5nuEryi9jvfMDHmvjHRvFdMzF",
  },
  "32-supple-circle": {
    name: "32 Supple Circle",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "supple-circle",
    tokenAddress: "4mbH8KP43wmWXJt2s3KwLv4K84KYcqf5ZFa9ctU1VMs4",
  },
  "32-teeps-lane": {
    name: "32 Teeps Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "G6jiWSbfWiViA1eVqY5msqyKZENVi7rnXk6MvYwR1d4S",
  },
  "320-bit-boulevard": {
    name: "320 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "bit-boulevard",
    tokenAddress: "6asMaWNkBpwUZNjfTu1zF64NMEncbEb8Nkd7WFvahdPS",
  },
  "320-delray-circle": {
    name: "320 Delray Circle",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "APEC6QKwmy7GeHURDiRTAzq9JizTd5f92oRCqwm6oxgM",
  },
  "320-first-avenue": {
    name: "320 First Avenue",
    biome: "beach",
    frameType: "rounded-lavender",
    streetId: "first-avenue",
    tokenAddress: "AioWnsBvm6MkfeQLEwfTYYAuMY6JmBJsKzTxKERkZWW5",
  },
  "320-grime-lane": {
    name: "320 Grime Lane",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "4k67iWZDEVfDyi5fDxjGTMjEkcGGt4uzG9ZKzz5qx4i5",
  },
  "320-lynwood-avenue": {
    name: "320 Lynwood Avenue",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "4eFeMeH9CmMrgr965fQJaWPCE1c6ismR1e6Tw6gVvdTn",
  },
  "320-sierra-avenue": {
    name: "320 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "sierra-avenue",
    tokenAddress: "754M6KPaqWw9soRbYf1UgXK4ddGUw25DtGt5byZn6YRf",
  },
  "321-delray-circle": {
    name: "321 Delray Circle",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "delray-circle",
    tokenAddress: "58k1Aq1uTAU6ZLwSCkURoNmQFcxfAq3bECmueu4Le88x",
  },
  "321-first-avenue": {
    name: "321 First Avenue",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "first-avenue",
    tokenAddress: "77cTdKa5rBvQwRafDiUSbjxFJm3PBVympDMasoMjPUMe",
  },
  "321-grime-lane": {
    name: "321 Grime Lane",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "5pSy1P9M2ag4amZZqxaZX3nE2QeyaeJmRtiRwUf6e6j1",
  },
  "321-pico-street": {
    name: "321 Pico Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "FU7MLyb5R96fpm3cnY99Z6WcQWAm7JvJKkKs5xf53rfk",
  },
  "321-sierra-avenue": {
    name: "321 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "GbAafSPqqwQ8yE7GXbXTDvcdsZpa1VzQSPssd2it1Uhf",
  },
  "321-solana-vista-drive": {
    name: "321 Solana Vista Drive",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "solana-vista-drive",
    tokenAddress: "26cefiUyQ9ANhgGwEd69cS4WCPXeHm3DsXVzuXhjnCJ1",
  },
  "322-first-avenue": {
    name: "322 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "3HR2p6azdxYGr79NhH7N2dWJ3NQzohzzfoqYRPCcCwc7",
  },
  "322-sierra-avenue": {
    name: "322 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "47owmmDVTcmpMUoNg3EvqVMpzQeKF7t9kUvrbW46KdTz",
  },
  "322-solana-vista-drive": {
    name: "322 Solana Vista Drive",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "solana-vista-drive",
    tokenAddress: "FNnMbo9LCk8R2dVQkzo3faEPFT4y6zHKtERd1JbNL6M2",
  },
  "323-bit-boulevard": {
    name: "323 Bit Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "bit-boulevard",
    tokenAddress: "CeugaALrxTQzSJ3UePVDNhbhxESfE6nXLeA8gmKr5GUj",
  },
  "323-delray-circle": {
    name: "323 Delray Circle",
    biome: "woods",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "TisU8bj641emwSzk33FxVKmpYkQ7bMrEibWTfTgXcD7",
  },
  "323-first-avenue": {
    name: "323 First Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "BTjRJHXAgxwStVvV95DRZG7ChDKGGK9dUgCSQGAEYeet",
  },
  "323-grime-lane": {
    name: "323 Grime Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "8o9sg8FD8KDz78RDY347wjBcPGsLLAXSiwpFsG7hx1e1",
  },
  "323-lynwood-avenue": {
    name: "323 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "EQQVJbZv7hBeZdKGVzzvTe1Q7VJHhN52DwJhntrezrEm",
  },
  "323-pico-street": {
    name: "323 Pico Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "7ykegh9VyWddTFVYx2QjXp1jFJtTfyt4a4tpSSiTKQER",
  },
  "323-sierra-avenue": {
    name: "323 Sierra Avenue",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "ALqhp2AuQWyjDvptj6gWEqB3eek8hukK97NdmYcTLW9A",
  },
  "324-delray-circle": {
    name: "324 Delray Circle",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "5LXu7VJfyzbnzK1qXJfQ7kZZuZxJeMzxY8xtA2ejBr2h",
  },
  "324-first-avenue": {
    name: "324 First Avenue",
    biome: "mountain",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "6A3baPy1MhtgrvVfS9VM3VKPW7kyRVCGRoixWgQYfQAE",
  },
  "324-pico-street": {
    name: "324 Pico Street",
    biome: "city",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "Bv8n7randBetiz7PduRPqjwrzvokAtbTQVmXDqgFja4H",
  },
  "325-first-avenue": {
    name: "325 First Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "EjogvM9Xo6fYFmBjE8XfENCQoPPiSWg3aVm1FTph4peQ",
  },
  "325-grime-lane": {
    name: "325 Grime Lane",
    biome: "woods",
    frameType: "occult",
    streetId: "grime-lane",
    tokenAddress: "8P4FydBR94YG9QWQQKMxxwc1TuppWeCP4J5aYEFLrSKD",
  },
  "325-lynwood-avenue": {
    name: "325 Lynwood Avenue",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "EFKKncsqmobMSpkegeGEDgCnRMTYkXtyZ8vfre2KY2KY",
  },
  "325-sierra-avenue": {
    name: "325 Sierra Avenue",
    biome: "arctic",
    frameType: "rounded-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "7TC9rT5G2DCCopUWmqeL86mBES2sqA6aEtBpFzMjbos2",
  },
  "325-solana-vista-drive": {
    name: "325 Solana Vista Drive",
    biome: "city",
    frameType: "glass",
    streetId: "solana-vista-drive",
    tokenAddress: "3Y8i6PAZBAqQBczSLrMMzRk9G8CXVownyqhe4zotmaec",
  },
  "326-first-avenue": {
    name: "326 First Avenue",
    biome: "field",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "FioDnu6aTsAi75ytL3AJN9KyBTPZmY44bBDjYFQh65db",
  },
  "326-grime-lane": {
    name: "326 Grime Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "427xX5MrkFjRFyfzKuY2rx1T7LrNDRccvLB7rVPdpanV",
  },
  "326-lynwood-avenue": {
    name: "326 Lynwood Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "D9uzJB1yL9Eb76nekvQCXAD9ArAULYh5QLNza7dJJP7Z",
  },
  "326-pico-street": {
    name: "326 Pico Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "CPn4VbjDRiZXKYta8xnJsDzHrhM4rb21LdrnJfnzErww",
  },
  "326-sierra-avenue": {
    name: "326 Sierra Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "BE5YE5gkwutgUCPDdFcsQgwZjb2cd9qefHCSfNQ4QPPa",
  },
  "326-solana-vista-drive": {
    name: "326 Solana Vista Drive",
    biome: "city",
    frameType: "glass",
    streetId: "solana-vista-drive",
    tokenAddress: "EEPePjrjA5xAnPwkfguHES8BunUeiCWjruAYoKFNR3kN",
  },
  "327-bit-boulevard": {
    name: "327 Bit Boulevard",
    biome: "woods",
    frameType: "crystal",
    streetId: "bit-boulevard",
    tokenAddress: "Gmy5q6fxwpDmq73hDNPNzB8TtpfPb2BjwhBAyY87Qhq",
  },
  "327-grime-lane": {
    name: "327 Grime Lane",
    biome: "mountain",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "Aw6jT51oJHj2Uf3deTzEZwGhaycoWYvfvUimrjjpYHLZ",
  },
  "327-lynwood-avenue": {
    name: "327 Lynwood Avenue",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "C2onQV5s69pQHZM5JkHZrtxHrLjLzjNyobnEFasHbkPV",
  },
  "327-pico-street": {
    name: "327 Pico Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "pico-street",
    tokenAddress: "BxXNy1gBR4reUZtVLpcuV8F2Feufb1aHxCuohwFEow1L",
  },
  "327-sierra-avenue": {
    name: "327 Sierra Avenue",
    biome: "city",
    frameType: "occult",
    streetId: "sierra-avenue",
    tokenAddress: "8bhseVfQu4AnTWaim9iYEipq5pybfeqitNeqvoYjvayP",
  },
  "327-solana-vista-drive": {
    name: "327 Solana Vista Drive",
    biome: "treehouse",
    frameType: "gold",
    streetId: "solana-vista-drive",
    tokenAddress: "5qqzNXsUsUW9F4dNZaD52XHzBZq5BDSXHGR1EjXWQWyH",
  },
  "328-first-avenue": {
    name: "328 First Avenue",
    biome: "arctic",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "DJQbdQgkFradbKQ7GgapjQpZG1WocthDXFDjaGLmZLux",
  },
  "328-grime-lane": {
    name: "328 Grime Lane",
    biome: "mountain",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "2f82dx7rWCyVETGtec6J2LKWT98GdQW5DUKHyFYKTkQ4",
  },
  "328-pico-street": {
    name: "328 Pico Street",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "pico-street",
    tokenAddress: "3eacT4EDK5aGJnMuygnwkctRB27UTaXrAXVPQbqeQoHX",
  },
  "328-sierra-avenue": {
    name: "328 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "DUrHdVqfxEhF36r9uRoGmqPdSXmxpTD5cxHB2jX8RwQW",
  },
  "329-bit-boulevard": {
    name: "329 Bit Boulevard",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "bit-boulevard",
    tokenAddress: "H89ki33vn5TTfXK4yqnMVowuwAMTKaZZYgDPnYmtmYeB",
  },
  "329-delray-circle": {
    name: "329 Delray Circle",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "GpKSj8dktV12ZEr82Ttsq1nXAvsaEJPMPrUP3Vg2CGaw",
  },
  "329-lynwood-avenue": {
    name: "329 Lynwood Avenue",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "EuJch1RiVYbQX5pph1K2HXD1dAusXYnm3iKUBE5jTYUc",
  },
  "329-sierra-avenue": {
    name: "329 Sierra Avenue",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "26R9ccMfb8sztBHU3tTxj25iKyQUBTisqEY5UtcZ792c",
  },
  "329-solana-vista-drive": {
    name: "329 Solana Vista Drive",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "solana-vista-drive",
    tokenAddress: "Cu2bNk3iJ5mppowKWu22LZArwuEzRMLWFdRZw62kfTYX",
  },
  "33-rosa-street": {
    name: "33 Rosa Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "AQBjezzWgnYVYCceBgERRD37dvD5vFZNxzSuXDssHUhj",
  },
  "33-supple-circle": {
    name: "33 Supple Circle",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "supple-circle",
    tokenAddress: "DsLrshMuFyPjJE1AZerRFD28KdQK3i77FnAtG4VQ3tAs",
  },
  "33-teeps-lane": {
    name: "33 Teeps Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "BgiRGBV59pdyqpwdnJj58CzPhnC3hofrrTfdHd1gYJEs",
  },
  "330-first-avenue": {
    name: "330 First Avenue",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "first-avenue",
    tokenAddress: "FSKXiJ3XQRQwVWYVYD2WJEQYjmzn8h2wXNG5YCEz7EkZ",
  },
  "330-grime-lane": {
    name: "330 Grime Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "Fb4uXctcvceawc2ScXYD2SpsKVw2a3StqG4JwfWKk1gR",
  },
  "330-pico-street": {
    name: "330 Pico Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "EvPLRJVPkRKc2sLDUapdrTRY3zxrJBeR5nNbMbtWXWpC",
  },
  "330-solana-vista-drive": {
    name: "330 Solana Vista Drive",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "solana-vista-drive",
    tokenAddress: "8smMXNPb744BGsTZGKMZdceUkGzFKvv6sTTVTmNNU7pZ",
  },
  "331-delray-circle": {
    name: "331 Delray Circle",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "delray-circle",
    tokenAddress: "HjngGtaE3AxXTnPyyFxmyxt9fRtJybctA8o7cgTH9Ei1",
  },
  "331-grime-lane": {
    name: "331 Grime Lane",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "32DerWcoMHoshaEM4jP1nA57sXhJLtZ25V4FNV5YbQzu",
  },
  "331-lynwood-avenue": {
    name: "331 Lynwood Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "2udmpkWsESVcc7GWVohRsr26DLfKwNog3ziMKT2Yg2Xe",
  },
  "331-sierra-avenue": {
    name: "331 Sierra Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "sierra-avenue",
    tokenAddress: "27vHefNBqoTuTsXSBLmZ2xKYgfdWXnmbPZv9gxBBvWNw",
  },
  "331-solana-vista-drive": {
    name: "331 Solana Vista Drive",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "solana-vista-drive",
    tokenAddress: "3E7Au5ebaVJLHmjwndEdPNYw4Vnx1EnriZQDt31cWHBF",
  },
  "332-lynwood-avenue": {
    name: "332 Lynwood Avenue",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "BTLGxEPPr6ha93rp92WEGxn6wkFfUsJcAagGCAY4SkgS",
  },
  "332-pico-street": {
    name: "332 Pico Street",
    biome: "woods",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "3GdyYmiGzcVma5z2LHKkD6vmsbisFmBh13iyyymzZyGK",
  },
  "332-solana-vista-drive": {
    name: "332 Solana Vista Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "solana-vista-drive",
    tokenAddress: "3sH6n9hXzZ4cRyBBSFNj1ReWqoAZ34QS2pnkU4bQn5iL",
  },
  "333-delray-circle": {
    name: "333 Delray Circle",
    biome: "field",
    frameType: "occult",
    streetId: "delray-circle",
    tokenAddress: "DfxpDe8z6t6fhz7J7n2PfiZP3vomgZvhgKECQCHkPtsQ",
  },
  "333-lynwood-avenue": {
    name: "333 Lynwood Avenue",
    biome: "field",
    frameType: "occult",
    streetId: "lynwood-avenue",
    tokenAddress: "7YXytmom4F4j7a5nW9QsE6akL6a4vtnxudYJPFGdkJDy",
  },
  "333-sierra-avenue": {
    name: "333 Sierra Avenue",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "6Bw1Z9hwKPVTaNBo6MeFNxgh6mPPbvtpGMRuC6KZRXLm",
  },
  "333-solana-vista-drive": {
    name: "333 Solana Vista Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "solana-vista-drive",
    tokenAddress: "DvKwdpafurCtkpdsFezQdcCQyMh4WwvHgDKkog1PFKLh",
  },
  "334-delray-circle": {
    name: "334 Delray Circle",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "9TkiVH4abhRVYFNoVrVhWXZxZBZhDoc6zxM4qDcV3AFw",
  },
  "334-lynwood-avenue": {
    name: "334 Lynwood Avenue",
    biome: "field",
    frameType: "rounded-green",
    streetId: "lynwood-avenue",
    tokenAddress: "CcEwfFrtXtN9ix3c9bKrqg9fSWhtrzc9fHoLanArs344",
  },
  "334-solana-vista-drive": {
    name: "334 Solana Vista Drive",
    biome: "field",
    frameType: "glass",
    streetId: "solana-vista-drive",
    tokenAddress: "JDFxKSYZiFBP8GrApE8DwbAebs9LasEckEz9CKotBnnk",
  },
  "335-delray-circle": {
    name: "335 Delray Circle",
    biome: "woods",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "9bTRz3ciEbJJCWLLPTFNLX5xPfkE76iSqtUgvySxqcTy",
  },
  "335-first-avenue": {
    name: "335 First Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "4HQFWQGUYKEpPnwTywUUto45pK4TWh41J6oewc1CRmfz",
  },
  "335-lynwood-avenue": {
    name: "335 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "2ZUVGb4M8oqJV3A6qJ8vZh74akgcCgyEvc8K7AGwy1TD",
  },
  "336-first-avenue": {
    name: "336 First Avenue",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "first-avenue",
    tokenAddress: "HK7HyBgEBS8bdVkuVPB1cj3Zgze924z1Tt1suFZdAy8k",
  },
  "336-lynwood-avenue": {
    name: "336 Lynwood Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "51EU6RfDtrydZqfEe68QiRGKdks1TZxrDbZieLys923",
  },
  "336-sierra-avenue": {
    name: "336 Sierra Avenue",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "5vAizqHFsvG1MfCrRNHMSN6GJtiw99EMLiS7oq3kW5Sc",
  },
  "337-first-avenue": {
    name: "337 First Avenue",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "D77ecojKkVB25ab2mggQQuLmWQnkhzXCBqYTboWVmWhj",
  },
  "337-grime-lane": {
    name: "337 Grime Lane",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "grime-lane",
    tokenAddress: "H6GvSDW6vpVv9sCaj9z4tCPc6P82npxL45xiiycS5Rwz",
  },
  "337-lynwood-avenue": {
    name: "337 Lynwood Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "DagA1ybvRRpNtSJxPGwxrmoWJPRAXWPj5tpkYQxe6Q4m",
  },
  "337-sierra-avenue": {
    name: "337 Sierra Avenue",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "sierra-avenue",
    tokenAddress: "GLadPb7fbwjEuJpYW76jfRSGjb2rRsgbpE127YNFhHD4",
  },
  "338-delray-circle": {
    name: "338 Delray Circle",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "143TZos6FmrBBb6w42eSepjsS6CAfy1WMajVicboyqvE",
  },
  "338-first-avenue": {
    name: "338 First Avenue",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "18wfmWWBbV6ZaxxaK4WjqHHQGEbVDgUFCWMEqP4X6Sk",
  },
  "338-lynwood-avenue": {
    name: "338 Lynwood Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "DfUYMWCm9HbiaxYkoSGuifxvh4GDcuESCp18RR5cW6s",
  },
  "338-pico-street": {
    name: "338 Pico Street",
    biome: "desert",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "25HD9Lgj9k5ELQrh6ivwyy3axkDALdkHPUnLGA8oAjcd",
  },
  "338-sierra-avenue": {
    name: "338 Sierra Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "5VeuVuutrRztTMowfvrjoki6go2SQ91ME5gXUmSCLqrD",
  },
  "339-delray-circle": {
    name: "339 Delray Circle",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "3GcGaCMb3sWEb5f812Vooe5fwsnoTkrGMt7qEkCaoY7G",
  },
  "339-grime-lane": {
    name: "339 Grime Lane",
    biome: "city",
    frameType: "rounded-red",
    streetId: "grime-lane",
    tokenAddress: "65pNsDKRD3i4ju16xFtadc4m4j1tGzQb4ab2qF1ZTi4V",
  },
  "339-pico-street": {
    name: "339 Pico Street",
    biome: "field",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "5joWtqXsBXADpUmNb7KpAmC4sGqbvcUq11mR2wsPj5vF",
  },
  "339-sierra-avenue": {
    name: "339 Sierra Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "GL8RHxzWBw7naiviHuVo6jaFHwtNEeArzYEww2isqFFE",
  },
  "34-rosa-street": {
    name: "34 Rosa Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "EzLNBWTWm9GqbesZXu9A6Js7w8r3HpfyKPinBsamLM7q",
  },
  "34-supple-circle": {
    name: "34 Supple Circle",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "supple-circle",
    tokenAddress: "8TGRDrJtzy5sb88kBEQcYk44aGT6WsgoKJPPhUssQ4ZV",
  },
  "34-teeps-lane": {
    name: "34 Teeps Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "Fwb5z3dnwwdzH78SuekvAEg8QacDhY3GZhRj8mSDxZWg",
  },
  "340-delray-circle": {
    name: "340 Delray Circle",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "delray-circle",
    tokenAddress: "5SHpBMYK5BYizNnfWZuoscqotPaot7JVxyyvCa2CLAkN",
  },
  "340-first-avenue": {
    name: "340 First Avenue",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "Fg6vL42iP6jtqjsbx1Na2rgU7s6Lvcx8PfeoHjV8ooMr",
  },
  "340-grime-lane": {
    name: "340 Grime Lane",
    biome: "field",
    frameType: "rounded-green",
    streetId: "grime-lane",
    tokenAddress: "2RfMqv3EmpLf8kpHZSZzYbomC8YZRYf5za2GeUZAdHj6",
  },
  "340-lynwood-avenue": {
    name: "340 Lynwood Avenue",
    biome: "woods",
    frameType: "occult",
    streetId: "lynwood-avenue",
    tokenAddress: "8pz6AnsvpDdFyYbrKbiEL6Z4vCpZRDQkvjLxn2gigyZr",
  },
  "340-pico-street": {
    name: "340 Pico Street",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "1j1H26Hc4ipwrpN7mq8FRBecZ2XAKXoztX5MCLiNZQT",
  },
  "340-sierra-avenue": {
    name: "340 Sierra Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "sierra-avenue",
    tokenAddress: "5vVCBZiot9bvViHNW1faBGe11V2Q9EY4WMm2vdEdAsgY",
  },
  "341-delray-circle": {
    name: "341 Delray Circle",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "FXxvA9ouK9Kxb9zVNVNimFBfXmBuUP9JLmD4trNiqaSE",
  },
  "341-first-avenue": {
    name: "341 First Avenue",
    biome: "desert",
    frameType: "occult",
    streetId: "first-avenue",
    tokenAddress: "FbNUvZbfbXVAwuw3mvKkpyRSutCmdoiE1T3pSec3DBbR",
  },
  "341-lynwood-avenue": {
    name: "341 Lynwood Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "4LJ2irx29oLfNvMxrzsEG33AJfR88aubndsUFpW9BQ7E",
  },
  "341-sierra-avenue": {
    name: "341 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "5sadYXphULYaYfWAGr2TbNuir4qCtiaJeQA2NEQ2Wwxy",
  },
  "342-lynwood-avenue": {
    name: "342 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "24ipB8MhnxuQpLMtAUJerMrq1Kj9oWtGJCWPE7KB4zGx",
  },
  "342-pico-street": {
    name: "342 Pico Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "DQ2eom5swgE23VrewDPJtzme7dSJcF2MephEEbkMuxaW",
  },
  "342-sierra-avenue": {
    name: "342 Sierra Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "HqfMmZdDjfgtGv62o4A5xecakfsyQHEu1XwhYfxGoE2q",
  },
  "343-delray-circle": {
    name: "343 Delray Circle",
    biome: "arctic",
    frameType: "rounded-lavender",
    streetId: "delray-circle",
    tokenAddress: "HoNjFqdziEUBdLnNZgQdGo5FJfuLARJP3Qw3T59BQgeh",
  },
  "343-first-avenue": {
    name: "343 First Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "APSDT7wT8jDf93XKCEivKFATZMTKHkc6SsSECAqZYH2t",
  },
  "343-pico-street": {
    name: "343 Pico Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "9xTNY5pZTRf4seyFMsMNtusLkNUVzSsY7TyjE5Z27weZ",
  },
  "344-delray-circle": {
    name: "344 Delray Circle",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "delray-circle",
    tokenAddress: "AhGzN8tYgi2hdBUivMKPEys3mNfa6MGMsHAasug9V7xP",
  },
  "344-grime-lane": {
    name: "344 Grime Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "B7z3NEcuyQ3NEqocJ4po1Gee9ZB4BQtgyCZEFBJbxMik",
  },
  "344-lynwood-avenue": {
    name: "344 Lynwood Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "lynwood-avenue",
    tokenAddress: "6WqEy8JSEAybtBGL4TpvHUSn6MDqy8Cu8dRsyo21fTqA",
  },
  "344-pico-street": {
    name: "344 Pico Street",
    biome: "arctic",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "EHxQbyA6gF6dVboay9VZKAaVovdrB8jYnPTcW9AJYcuM",
  },
  "345-delray-circle": {
    name: "345 Delray Circle",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "3vJGDYF7C2gXPT37KKe9qsv7DAuqYycoyTEAHf4CfNnp",
  },
  "345-first-avenue": {
    name: "345 First Avenue",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "6tbMeKJSzWdMC2E45WDE86pJDaWsWCX1k6zqdrMFyZZP",
  },
  "345-grime-lane": {
    name: "345 Grime Lane",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "grime-lane",
    tokenAddress: "6AfDSjnpSGmounBycVZL2MwobwfaLB2agxwo3zg13GTJ",
  },
  "345-lynwood-avenue": {
    name: "345 Lynwood Avenue",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "D3hzm6snjsKGuTcDm8sbstzMDLwgKDo5Vx8FzYHNPxeU",
  },
  "345-sierra-avenue": {
    name: "345 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "Do4GiV5efGj5TWcAT4nCy5oZBA9aASa98wAeyZksunrB",
  },
  "346-delray-circle": {
    name: "346 Delray Circle",
    biome: "arctic",
    frameType: "glass",
    streetId: "delray-circle",
    tokenAddress: "Acguvx6oT3gjyJsrLNMBB6BGDDszC7KRT4ykVTcTaTXd",
  },
  "346-grime-lane": {
    name: "346 Grime Lane",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "ewu9pEHG7vwRvCvrt7F8eN46SB73Etehy1REd8FaiL5",
  },
  "346-pico-street": {
    name: "346 Pico Street",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "pico-street",
    tokenAddress: "44E2gdR11u4ugGopn18EztubfYgeRFpMYn4351ymKja4",
  },
  "346-sierra-avenue": {
    name: "346 Sierra Avenue",
    biome: "underwater",
    frameType: "rounded-dark-green",
    streetId: "sierra-avenue",
    tokenAddress: "6iQ7tp6YembbuDCMQNvsujVRkrqYLq26p39JNH1HtGLF",
  },
  "347-delray-circle": {
    name: "347 Delray Circle",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "delray-circle",
    tokenAddress: "4xdv8p4EgmWK9hGwRw7nxYf8nw96UzBjoCMNVj9A5oU8",
  },
  "347-lynwood-avenue": {
    name: "347 Lynwood Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "lynwood-avenue",
    tokenAddress: "9N7DVs2xFpUjaiZaoEYABXfG4UyQXsSbeUdoeMEqmCM3",
  },
  "348-delray-circle": {
    name: "348 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "delray-circle",
    tokenAddress: "FqJ9w8ft6zgQLuhbCJCuZeMigamrctNmnDgL5RZM16TT",
  },
  "348-first-avenue": {
    name: "348 First Avenue",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "ELzcYfkMqWN9qWD5cvbX9hUZPu29QyyDiTxag91sVGwm",
  },
  "348-lynwood-avenue": {
    name: "348 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "lynwood-avenue",
    tokenAddress: "8ZYAq2pj6RyogVJZsSNTdGcAicVxsu7rD25j3aP4ASzz",
  },
  "348-pico-street": {
    name: "348 Pico Street",
    biome: "beach",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "8b7aABUm3tzR8Rm9NAbfvpVYAhVdzxGBkhgySbpKZaf7",
  },
  "349-first-avenue": {
    name: "349 First Avenue",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "AGZ42yFuVqKTsj8pzyrLqirq5iqqj2mkc1FLMHUaxwpX",
  },
  "349-grime-lane": {
    name: "349 Grime Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "JB48P2cf7mkLNFnScNTP9fj9SJz7Pvv8CBkuBn8mTaYk",
  },
  "35-ape-lane": {
    name: "35 Ape Lane",
    biome: "field",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "FCNrekmLA2o7TsoiF1F9YFKpxApXK4Eh7JKfC3ehrJLy",
  },
  "35-rosa-street": {
    name: "35 Rosa Street",
    biome: "volcanic",
    frameType: "shuttered-red",
    streetId: "rosa-street",
    tokenAddress: "8A1ULJcJE7dv1FFLqd5LMcnUWyaqXLehdXZHsQCi1k39",
  },
  "35-stewart-avenue": {
    name: "35 Stewart Avenue",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "stewart-avenue",
    tokenAddress: "G54n7wonE9MfuTmfRTwCiiezYmv3dmdCMJJA5JhAaQqt",
  },
  "35-supple-circle": {
    name: "35 Supple Circle",
    biome: "city",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "5pxSLwHninoVXQGYpq47mSkP6cowNHKvpN7PqfeqtCGZ",
  },
  "350-delray-circle": {
    name: "350 Delray Circle",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "delray-circle",
    tokenAddress: "FAq5FeFXT9zXch2VKerDH743yt2JseEeXbor3PDMBmwx",
  },
  "350-first-avenue": {
    name: "350 First Avenue",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "first-avenue",
    tokenAddress: "Xm5ZVy7x6kv5s75zmKjTtq7zMauyEqHggUGSNX33u7P",
  },
  "350-grime-lane": {
    name: "350 Grime Lane",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "grime-lane",
    tokenAddress: "2eo7Kdq6APEoTubkHwB8aVitopQP2AJKyvthE53UFGZ3",
  },
  "350-lynwood-avenue": {
    name: "350 Lynwood Avenue",
    biome: "underwater",
    frameType: "occult",
    streetId: "lynwood-avenue",
    tokenAddress: "8TSL81MR4c6zVzYyEWXrrnCDu3jKLZcFfE5kAbzPhkzy",
  },
  "350-pico-street": {
    name: "350 Pico Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "pico-street",
    tokenAddress: "HqCFDxs8A4g3xasD92omuKxAg81WpoUPXAu38dqteXeA",
  },
  "350-sierra-avenue": {
    name: "350 Sierra Avenue",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "9pim623nsf1gcYM2C3D3v9jvkMCGQKuAYFBtLEfTcjfo",
  },
  "351-delray-circle": {
    name: "351 Delray Circle",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "2WZiCXLzYEU7B13KxtN6TR6aa5AzeU2iMCMmAuHVpmyv",
  },
  "351-grime-lane": {
    name: "351 Grime Lane",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "Hvb3KghJc3JdSAuy9xKDzZAtZ5AJQkEhBeypCb5HfTp2",
  },
  "351-lynwood-avenue": {
    name: "351 Lynwood Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "lynwood-avenue",
    tokenAddress: "7RPbWjPNXrch47hWDGBboHSg4T7knKEWRFd3Z26ay1QC",
  },
  "351-sierra-avenue": {
    name: "351 Sierra Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "DAyUCAQ2hogzRp9DSTpN8TaKhSBP33WaqsJTbzMuxNYf",
  },
  "352-first-avenue": {
    name: "352 First Avenue",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "4Qtfx21Q2BaiBhJ6j8UMTgmWsV7LDPySRkAELtC9E6Wf",
  },
  "352-sierra-avenue": {
    name: "352 Sierra Avenue",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "sierra-avenue",
    tokenAddress: "6uacLZ5boAZVBB41hipKvzpDBFn5Vb7i6twKZoP1zNe8",
  },
  "353-delray-circle": {
    name: "353 Delray Circle",
    biome: "underwater",
    frameType: "crystal",
    streetId: "delray-circle",
    tokenAddress: "8L1CFv8FGHZEejNcz4xU41uKzEzvLF3MEbfwtThEBbky",
  },
  "353-first-avenue": {
    name: "353 First Avenue",
    biome: "woods",
    frameType: "gold",
    streetId: "first-avenue",
    tokenAddress: "A9h3zwEYWjJ9GYxMu1TjrmCZ4beGCzCrS21U3psk5xwU",
  },
  "353-grime-lane": {
    name: "353 Grime Lane",
    biome: "volcanic",
    frameType: "rounded-lavender",
    streetId: "grime-lane",
    tokenAddress: "3w6Zq1WW5UZwsEZ6cbwntdwVzghMxTtgWYaFZr6uftid",
  },
  "354-delray-circle": {
    name: "354 Delray Circle",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "delray-circle",
    tokenAddress: "9f7rUzYHtUGVivywDF8e4YSuww56YQAkwfpaYLU2xTbD",
  },
  "354-grime-lane": {
    name: "354 Grime Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "4bRixLaMWYsa61yebDQxUdWTdR73ocsgand8Yh78aYxL",
  },
  "354-lynwood-avenue": {
    name: "354 Lynwood Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "lynwood-avenue",
    tokenAddress: "HSJBwkNpf6Ubo3EAuyd7V2d77oqpo8up2auMcTr1HyWg",
  },
  "354-pico-street": {
    name: "354 Pico Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "69BgJHaavQL1YpsH13sNCxUJZa7uPCWEU1q47VxwdNox",
  },
  "355-first-avenue": {
    name: "355 First Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "2MroaH3gcmTHj375vqSePPyzYhBWUoNKtGdHWQ2GiXPh",
  },
  "355-lynwood-avenue": {
    name: "355 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "CDaNw2EDPXYo1JzkyB749R8BMGmbEWqUFe5TF2YZvHRM",
  },
  "355-pico-street": {
    name: "355 Pico Street",
    biome: "field",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "2a8QTfNhpyhcrAKRn2jALgrXcXXtux3MrYtE4UTDmgiD",
  },
  "356-grime-lane": {
    name: "356 Grime Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "3Kh98pWKo2BkZAmFj9aCzi6WjY6TCkrLyxZcB62AwNwM",
  },
  "356-lynwood-avenue": {
    name: "356 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "9KiyVr1bPP3EWzJnqbHNatCGLAwea88jhsfEDDkbLe4t",
  },
  "356-pico-street": {
    name: "356 Pico Street",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "G2zBMydsrsxqyv3ZV5RYmggQ1tQFnxc4y2pZSsj96BM1",
  },
  "357-delray-circle": {
    name: "357 Delray Circle",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "delray-circle",
    tokenAddress: "5mbXWTaWv8WxxCuvEZ9wYxzzTBSXVMx2wJ5gms3aCtGW",
  },
  "357-grime-lane": {
    name: "357 Grime Lane",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "grime-lane",
    tokenAddress: "CXUnCDbkhQJUXoCqVCq8Hqfk8n52xrJsB4oKabziS19z",
  },
  "357-pico-street": {
    name: "357 Pico Street",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "pico-street",
    tokenAddress: "En683nAbfmiDEQ3jyNvcd5gq74SzRy5wJVMizzVPDQUx",
  },
  "357-sierra-avenue": {
    name: "357 Sierra Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "CP9Z8cMAW4UEeBhvQuG41JyaBcjTUeCeauPTiPkCYKPb",
  },
  "358-first-avenue": {
    name: "358 First Avenue",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "2trir5XzeEE28zAbXYZtTDpig3LGpLiwUeRE7QpXxpsC",
  },
  "358-pico-street": {
    name: "358 Pico Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "EgZKpe6eQtSyzhY94ytwcegAcWtY8EMFYnkSgRqR7fb7",
  },
  "358-sierra-avenue": {
    name: "358 Sierra Avenue",
    biome: "treehouse",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "HJHpSetMWHdSz6tBcfwTdykHBWQXZSLwdpDbVFKTYvUD",
  },
  "359-delray-circle": {
    name: "359 Delray Circle",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "delray-circle",
    tokenAddress: "H9ZvV8mEWLQLBCMD5QhhXQSveemKd2FhNrLaq71AHZyh",
  },
  "359-lynwood-avenue": {
    name: "359 Lynwood Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "3E2ixacpWhAHTU3bq75L7hkWrXcxCx8jiXgKkB8BMAA1",
  },
  "36-ape-lane": {
    name: "36 Ape Lane",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "ape-lane",
    tokenAddress: "BzYhhjdApsrTEQLMEYt9fJv8uX8p9KxcVRyy1cqWAiJs",
  },
  "36-stewart-avenue": {
    name: "36 Stewart Avenue",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "9mXnP2uRF2mRzVfHzEGKV9MrvmmCDkuQEEuzswmmsL7Y",
  },
  "36-supple-circle": {
    name: "36 Supple Circle",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "supple-circle",
    tokenAddress: "ErhPaTffwshAVqc1M38wcjh2cxwtdJCHVKWFWjG84Jnj",
  },
  "36-teeps-lane": {
    name: "36 Teeps Lane",
    biome: "city",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "4eAKmg2r3BEx2X9Fpf14AB2L86Cm8qVNwYiiQLkNW6Nr",
  },
  "360-bamboo-boulevard": {
    name: "360 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "8UmgbeexsKAX7vaDGSMwGGSXtN3c3jwXrBUkiMurQDoW",
  },
  "360-first-avenue": {
    name: "360 First Avenue",
    biome: "field",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "9sFovvHvWZfM9KK7w9y1MQVyHcuBvwUxSpmPoWzCbKzC",
  },
  "360-grime-lane": {
    name: "360 Grime Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "grime-lane",
    tokenAddress: "742FBaWS1k2HLFrwVsFb5wZP1Kn3qeW3BhJbpMnsew1b",
  },
  "360-sierra-avenue": {
    name: "360 Sierra Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "3uoYiwsYBxAPngT7J51RXC1N8zkehDyqzhkMf4Z52Mmu",
  },
  "361-bamboo-boulevard": {
    name: "361 Bamboo Boulevard",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "6Z2y9CRz9kmRxPKRNMxtETFtf4nKnaNe6PwYSteJLCw7",
  },
  "361-first-avenue": {
    name: "361 First Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "FJdwqD9ebL2DxbkqkoCRHhuXPfGkwpDFajbCLaagBqZU",
  },
  "361-grime-lane": {
    name: "361 Grime Lane",
    biome: "city",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "8wrH7KSXYaxpDPokP3p91LHG5tmWa2RizGHGcPjaDEHR",
  },
  "361-lynwood-avenue": {
    name: "361 Lynwood Avenue",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "3sitbSGDqDdqxJHTgKTV8pbksAgGwR8yGkcNc9AR4got",
  },
  "361-sierra-avenue": {
    name: "361 Sierra Avenue",
    biome: "mountain",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "By4keBJbdTTuVfYvVYKe5ikVQBcNiarVEqEK2mWXsnWC",
  },
  "362-bamboo-boulevard": {
    name: "362 Bamboo Boulevard",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "BRRM5Rvv9sMQihbqos4z8ymH7zuEaS8XZf1qNSm4inZY",
  },
  "362-first-avenue": {
    name: "362 First Avenue",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "7bXf2NoNqcbEpRwMLoXLNQ5pBWMR4UM8y3uwWQvWkx5G",
  },
  "362-grime-lane": {
    name: "362 Grime Lane",
    biome: "field",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "21vQ7zBqt2h1iu5uLpa3rHJfKefCvKcS4jp4CEVMGt6v",
  },
  "362-lynwood-avenue": {
    name: "362 Lynwood Avenue",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "lynwood-avenue",
    tokenAddress: "6X8LK95u56xz15atNe9ws6AARU1enupBqN2neT7rcCfF",
  },
  "363-bamboo-boulevard": {
    name: "363 Bamboo Boulevard",
    biome: "desert",
    frameType: "crystal",
    streetId: "bamboo-boulevard",
    tokenAddress: "3CBFQ7AnQmyrjH6oryVxGwCCc8ErC1qC9Qcaxu3rXyKu",
  },
  "363-grime-lane": {
    name: "363 Grime Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "7XB4w6uJqP4pYPP9LDVVysDWPsWZzUHc4wnJm5Lu6ryT",
  },
  "363-lynwood-avenue": {
    name: "363 Lynwood Avenue",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "lynwood-avenue",
    tokenAddress: "3PYWZEa6KbVV6t7EMVvp3i4Z26QXwzRQLbfhocw7XeHh",
  },
  "363-pico-street": {
    name: "363 Pico Street",
    biome: "woods",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "J8EzbaC1kivLTZZ8jBqUGMRTuRGHUEMZm8HsPWZrEeCB",
  },
  "363-sierra-avenue": {
    name: "363 Sierra Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "5nXBBHbRw8XGrqvEvkwDJqgg5Ax4fjAoxMPfi5vsY24U",
  },
  "364-bamboo-boulevard": {
    name: "364 Bamboo Boulevard",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "C6szaawE3RBs5gzxhRUazZLvseJEkr7XFZ37Sfxgd85Q",
  },
  "364-first-avenue": {
    name: "364 First Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "HXB81m43HXErQngZ1th6q9VjUcqgKZQe94mfvdo7hDfn",
  },
  "364-grime-lane": {
    name: "364 Grime Lane",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "GTe2dx9cTDoXEL3j7xHprE1tNYME1oHMvc3DRKufTJxB",
  },
  "364-lynwood-avenue": {
    name: "364 Lynwood Avenue",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "CJYytRRmZvr9gV4PyCZPbWJHHdxah3cNB14eapikRvvs",
  },
  "364-pico-street": {
    name: "364 Pico Street",
    biome: "field",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "Bs899uQ7oCgtHY2K3ZYxt53pnrRznFaffLrkzC3H7LLE",
  },
  "365-first-avenue": {
    name: "365 First Avenue",
    biome: "mountain",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "6R9Fzgu4tkyHuF1kVropQ9FS2tyKnNEW1ziQtxxNK9V1",
  },
  "365-grime-lane": {
    name: "365 Grime Lane",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "2C2AevYoUQT3WE868bYodsVbhgW5SyGiCfyUwhg6Rqvo",
  },
  "365-lynwood-avenue": {
    name: "365 Lynwood Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "EsNXpyDxPDNq7C3EVMhkbudUrfC6pHeYTgaKbKxvp2dE",
  },
  "366-bamboo-boulevard": {
    name: "366 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "8x52FB4649A4jJ95sRwQL9pbCuKDT52SzbYggwCkx5R9",
  },
  "366-lynwood-avenue": {
    name: "366 Lynwood Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "Fjfr5FnattX7LwBfGNppjtBHfWvE14YMJfNgjsQcn44Q",
  },
  "366-sierra-avenue": {
    name: "366 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "sierra-avenue",
    tokenAddress: "CkWZXAGRmczFPb44Csn22YcWHm78tFqgHohqxUXXKZGm",
  },
  "367-bamboo-boulevard": {
    name: "367 Bamboo Boulevard",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "w3XpddTzWXb16irZGTa5i2TvfZVqoXjLZdUofGW9zQ7",
  },
  "367-grime-lane": {
    name: "367 Grime Lane",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "GTHpRRVF6n2mfQ5KvZxtBEECyXnzeXSuPkXdAQ7cqnCQ",
  },
  "367-lynwood-avenue": {
    name: "367 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "CtvWvKB6bqrmUsEHHEjB3UFev1HFCURwvPY73NKFZ8sZ",
  },
  "367-pico-street": {
    name: "367 Pico Street",
    biome: "treehouse",
    frameType: "occult",
    streetId: "pico-street",
    tokenAddress: "HisLQD9PsPNWALmmDYt9N5juKWt3QDoChvCTv3rX93E6",
  },
  "368-bamboo-boulevard": {
    name: "368 Bamboo Boulevard",
    biome: "woods",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "7BT2AimF7SxQ6hQKiWPKJm8unMr2LPW7dRdgN8hg24PX",
  },
  "368-first-avenue": {
    name: "368 First Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "8ck3UkRMwCLGYDTqpHkdDzZ5XNx8nN7MaK76Hm1DC3T1",
  },
  "368-grime-lane": {
    name: "368 Grime Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "7SkzZRmL96tT5Jd3GfDmgToUFQMsCwXnYh2nwVzN4f5W",
  },
  "368-lynwood-avenue": {
    name: "368 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "C3GJyG8VaaqZgUJFEMJdxL9rUc11UX44uR1rhQUyHL5g",
  },
  "369-first-avenue": {
    name: "369 First Avenue",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "5jHqwLMoGFDQ3YNLwixy9iHiaYNAdo28PHg7uRU6G7xX",
  },
  "369-pico-street": {
    name: "369 Pico Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "8ihN7wqh6Kk7Z8nHnj9SsNciUE5SSVLK3qD5329FBdVG",
  },
  "37-ape-lane": {
    name: "37 Ape Lane",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "ape-lane",
    tokenAddress: "LoXEsgvgTP1GA6vKbNxm3127CE841oUPMAgmZjhEhis",
  },
  "37-rosa-street": {
    name: "37 Rosa Street",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "rosa-street",
    tokenAddress: "9QAuzr5cgLnEbNhUzMDKW5DCGaGodsi6q4wxUsN4ohu2",
  },
  "37-supple-circle": {
    name: "37 Supple Circle",
    biome: "volcanic",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "9AJ9SuqikgCuTdggVHYvRgVdA1NxomW3wsNLbWH9rSgB",
  },
  "37-teeps-lane": {
    name: "37 Teeps Lane",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "teeps-lane",
    tokenAddress: "AJugNdg4FYJrSyHgJQQWaVfH8mXm4eWcJUp2PB7BR7D4",
  },
  "370-bamboo-boulevard": {
    name: "370 Bamboo Boulevard",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "9c26PuZYmfbjGSbHwCxoAUW4eUdUVtc1wfsEwbsdd4hU",
  },
  "370-lynwood-avenue": {
    name: "370 Lynwood Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "Ajy56UNowjH6eQcGSs26FBewF7GcdbzY5HbKbqKYZxRF",
  },
  "370-pico-street": {
    name: "370 Pico Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "2WiZRkgq11xq5b7DsZ3DVicXWXFk21Bg5PtYToLRfJ4Z",
  },
  "371-bamboo-boulevard": {
    name: "371 Bamboo Boulevard",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "9zyh1rtR5ZB31Cj1Uv78M8WUshjNsBjtq4RVAzjnAZqX",
  },
  "371-grime-lane": {
    name: "371 Grime Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "FTn1CrawgDeB6j18zZv5HMPVRE8K58e8LF5rVa4huCN6",
  },
  "371-pico-street": {
    name: "371 Pico Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "BwHJdpdGcvmYEdWXcj4Fnju8KAHTnTL8KiwzTFyBUyp4",
  },
  "371-sierra-avenue": {
    name: "371 Sierra Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "CLNLezBexkgTAiXiEuEQ6GsoAzd3HEgNAHKhUFQZDa6a",
  },
  "372-first-avenue": {
    name: "372 First Avenue",
    biome: "field",
    frameType: "rounded-green",
    streetId: "first-avenue",
    tokenAddress: "GBgBhQ6JWYCiePix3Sv8y3yZ5FpQT9dAUFLDmP2d2Hyj",
  },
  "372-grime-lane": {
    name: "372 Grime Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "E1M46v29HYnkVggBTrpDJWgNCJ6NEtGG4JdGjpVMLsB4",
  },
  "372-lynwood-avenue": {
    name: "372 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "lynwood-avenue",
    tokenAddress: "CKtXA7zFqYrdui65jpxMBmuTVVjNFUTSHh9sz7epLfNg",
  },
  "372-sierra-avenue": {
    name: "372 Sierra Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "CG91sDRvULUopT8SDWAzqyX55Jqk9mDxidpQpp63UpyE",
  },
  "373-first-avenue": {
    name: "373 First Avenue",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "5oDgjDELm7bTHj2UpF7t8MmzqHWcwCjngavKjE2jvJbr",
  },
  "373-grime-lane": {
    name: "373 Grime Lane",
    biome: "mountain",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "5vkaSBtBtUqnMZTZhYCpojjG6foVNphAgaqc68j7wbYP",
  },
  "373-lynwood-avenue": {
    name: "373 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "87YrmEHHHxrRqaJmxzFNcZphNnnDEbVn5bWMDcGCFeyr",
  },
  "373-pico-street": {
    name: "373 Pico Street",
    biome: "treehouse",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "656Ud1BirPTJWfV8j7mvHo8e15UMB4fAQcJJAmBe3ycN",
  },
  "373-sierra-avenue": {
    name: "373 Sierra Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "sierra-avenue",
    tokenAddress: "8Y5HKAhi75LBNDrVfiKaaCsBZiD7kN6XuUWFZZMpoAbz",
  },
  "374-bamboo-boulevard": {
    name: "374 Bamboo Boulevard",
    biome: "desert",
    frameType: "gold",
    streetId: "bamboo-boulevard",
    tokenAddress: "7uAXvEduPeYrfJWXzXQrmHHEEVVgwJKu83t8uSk29RsW",
  },
  "374-first-avenue": {
    name: "374 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "EwHrbZf9MogJhSiZ1QmsGtqZr2or6noNjexaTL2qigcR",
  },
  "374-grime-lane": {
    name: "374 Grime Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "aZcB4ZFwkSqfEjvehUuVzW3JndW9CrqDGqj3Q39JAPu",
  },
  "374-lynwood-avenue": {
    name: "374 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "lynwood-avenue",
    tokenAddress: "3rSgSUoFBFRMTy3ryeYo4VCJgyEe36YQsjkQqWAGvqD9",
  },
  "374-pico-street": {
    name: "374 Pico Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "pico-street",
    tokenAddress: "GyBTddPAkFiUhLunMLzWXwrDoYceW27H5YtGpcmJ6V5K",
  },
  "374-sierra-avenue": {
    name: "374 Sierra Avenue",
    biome: "underwater",
    frameType: "rounded-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "FFotVrjDVT2hCbTmh5ZbQvaVGk7Gy13Ws3EGipyvpPys",
  },
  "375-bamboo-boulevard": {
    name: "375 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "3ey9XSm9ZufwNn4Qyvcgvo6ywvRPYqj8tMje3PzjrPeg",
  },
  "375-first-avenue": {
    name: "375 First Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "F5Yb6DWYQ2CQtkcaDGecZ8ESemCA1i8HZSdgvJ2waeHp",
  },
  "375-grime-lane": {
    name: "375 Grime Lane",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "4qBgHnL9riYjRwqRD8CH3o4WMYhMR4jahp2mi7a4qFrR",
  },
  "375-lynwood-avenue": {
    name: "375 Lynwood Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "6H8zrb6ZGijMcg1xsxvGmzv1pWwGn88FZuNQpQRt1qCi",
  },
  "376-bamboo-boulevard": {
    name: "376 Bamboo Boulevard",
    biome: "desert",
    frameType: "crystal",
    streetId: "bamboo-boulevard",
    tokenAddress: "H3FKzWM4ASGmYoghw4sdRgPcZKSxrZUZwFk32qaBE1jE",
  },
  "376-first-avenue": {
    name: "376 First Avenue",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "96gRnM5ubW6atv2Yn6mHKiRF8kVCHRpZCYXAPsueYmy",
  },
  "376-grime-lane": {
    name: "376 Grime Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "HmA4XYy4DfEwzdVuYuYA6299z3Cse8nKhwdHnDZehyib",
  },
  "376-lynwood-avenue": {
    name: "376 Lynwood Avenue",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "BWBJRXYWdgv6q6aj1uX3EdKGo9CtxSkFt2nENufeNkG1",
  },
  "377-lynwood-avenue": {
    name: "377 Lynwood Avenue",
    biome: "volcanic",
    frameType: "rounded-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "4AFR9MX4MkSRSTyoe7FviLC34ZtfTHMY4k8JZxa3eSgq",
  },
  "377-pico-street": {
    name: "377 Pico Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "8kM1UwsCsyd6ZTywyjyhn9yd4X6eRLkwaoKqLsnqoY5v",
  },
  "377-sierra-avenue": {
    name: "377 Sierra Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "31mRUu8DqofKcfhp5NB6yH4hUMRXowLmsBLttRUdvsK5",
  },
  "378-bamboo-boulevard": {
    name: "378 Bamboo Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "A9N3kQzDvC4o4e9nSjXyzo4h4t683fg26gYPgorx8raZ",
  },
  "378-first-avenue": {
    name: "378 First Avenue",
    biome: "field",
    frameType: "occult",
    streetId: "first-avenue",
    tokenAddress: "FXek4B5sjKBxYq2Y5zFRR4x37NuHS541so4X8a1ydNeH",
  },
  "378-grime-lane": {
    name: "378 Grime Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "5dExKMPjGBF644hoQP8k92YQoUSURWScCug93HinawkC",
  },
  "378-lynwood-avenue": {
    name: "378 Lynwood Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "45ZLL63vgY8VKrMCCFT5JaXfuAEZ1hb3PLSFgHejkNBc",
  },
  "378-pico-street": {
    name: "378 Pico Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "CGRAa1RwBMaUGfGXuivzMsNeGvFWbWfmMMcesvHQPfuv",
  },
  "379-first-avenue": {
    name: "379 First Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "5qbsiZqB6NQYrZzUzf6tP2DEn1qHTGh4kvtWWfBRLz6k",
  },
  "379-grime-lane": {
    name: "379 Grime Lane",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "479hojaw3tqARhmjAofz1XAprLvKSuwAz9K6yi8i7tmG",
  },
  "379-lynwood-avenue": {
    name: "379 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "9cvJ3uMwyShMYvyAkjLUALYXzsmtHbHuGZc9CEqBsHPh",
  },
  "379-sierra-avenue": {
    name: "379 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "68i66GaiTEhTLR9D5JHsP48TP4ceCxhgnbT84ZEoVBc7",
  },
  "38-ape-lane": {
    name: "38 Ape Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "ape-lane",
    tokenAddress: "J6UfT7qqEG7W5bheyGoCXMcg9Hcw6jhw23yFE6kJwbER",
  },
  "38-rosa-street": {
    name: "38 Rosa Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "rosa-street",
    tokenAddress: "AKJpt6P5gHnjihatq19iaWPvmEHkyWetv3LwkqVzjwe2",
  },
  "38-stewart-avenue": {
    name: "38 Stewart Avenue",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "H8ohicf5DbeBKk1SkMB1iWiU98T1QAFan9xR1yrGy6YG",
  },
  "38-supple-circle": {
    name: "38 Supple Circle",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "42YYzr6gf6bzkHLkz4wRFTKmyxZuLhXTT8P8F5XwKE3d",
  },
  "38-teeps-lane": {
    name: "38 Teeps Lane",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "teeps-lane",
    tokenAddress: "GyJPp8hH5bJCDxAgMr7zfDQBdGtW8shDaMUPskqxNh3T",
  },
  "380-bamboo-boulevard": {
    name: "380 Bamboo Boulevard",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "HDn87MdDfrEn5fNKbnEDhvCB1rA9JwGnHb4ou8iumkLN",
  },
  "380-grime-lane": {
    name: "380 Grime Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "6hymKnuuuehG7HEqMRWhSFosMAazuQ4h7eo47Bp61fFJ",
  },
  "380-lamport-lane": {
    name: "380 Lamport Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "Fb2GSrmLc4A6mNGh2t4XqE4QWphQ9ieMkeH6ZMT1Grjd",
  },
  "380-lynwood-avenue": {
    name: "380 Lynwood Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "FJfcz8PWS5ni3DM52UQRVtmHrjvmt6AgUMPkLKW3AjBC",
  },
  "380-pico-street": {
    name: "380 Pico Street",
    biome: "desert",
    frameType: "occult",
    streetId: "pico-street",
    tokenAddress: "7buPTahKE6ma6KdCeimdKns7xpzq1GHtLXMbiws96C9W",
  },
  "381-bamboo-boulevard": {
    name: "381 Bamboo Boulevard",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "DpXoGrEYf2jirioYC92VNbzk3XNBqUscfC7qhMsB4XkT",
  },
  "381-first-avenue": {
    name: "381 First Avenue",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "7PpE91Rg1RJ75gH5rQMagFXS64iZfp6De29J3wXofx1S",
  },
  "381-grime-lane": {
    name: "381 Grime Lane",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "CXKfMad7CGn1fJXHwmSvUyeDPcCK47CLcHk6xspk7ViM",
  },
  "381-lynwood-avenue": {
    name: "381 Lynwood Avenue",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "lynwood-avenue",
    tokenAddress: "68a5d3HoYenn4axwrhbndwwrv3nSopAniAUQniT4jZ6A",
  },
  "381-pico-street": {
    name: "381 Pico Street",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "pico-street",
    tokenAddress: "6Uv26DjL4odRozNAu428FctXJBnxgQPTKjurd5i59GFB",
  },
  "382-bamboo-boulevard": {
    name: "382 Bamboo Boulevard",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "6aJD5mBZHdQVHEcxDjNdZfd1ppvGci1FKtJqByGpWd7U",
  },
  "382-first-avenue": {
    name: "382 First Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "first-avenue",
    tokenAddress: "GUPchYeGNNhoMV7ugqCdy5cEEW8rXvRZ8C52gAqh8KF7",
  },
  "382-grime-lane": {
    name: "382 Grime Lane",
    biome: "field",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "3ekPk9SJ7RAMJ1tJRyzh1a6mJsKqDhFBspPg69dfxDxG",
  },
  "382-lamport-lane": {
    name: "382 Lamport Lane",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "8sFPbNXrFSsLoYzvz2etUkCGGnbiwLargxzVzSirzqRM",
  },
  "382-lynwood-avenue": {
    name: "382 Lynwood Avenue",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "2er3icUFJfomDbYPrrSErKMg59UHWWtS6nGuniC1Dy9o",
  },
  "382-pico-street": {
    name: "382 Pico Street",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "pico-street",
    tokenAddress: "5sundNkbz5qGDRwarZnGG3GdyBNGK39ED9XzS6TBpdkG",
  },
  "383-grime-lane": {
    name: "383 Grime Lane",
    biome: "city",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "4T17ojG42JbYbeHfJVqkyy2zLjsZFDFsNbgUuryhfM2A",
  },
  "383-lynwood-avenue": {
    name: "383 Lynwood Avenue",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "RnYMEoh5jdpBbvQzpUqR49k89jMV8nrupiqmL8xctjq",
  },
  "383-pico-street": {
    name: "383 Pico Street",
    biome: "beach",
    frameType: "rounded-pale-green",
    streetId: "pico-street",
    tokenAddress: "2SjAHsAigAej37xtpTwxphehpHSuJEYJL9Jt7o1R8c2C",
  },
  "384-bamboo-boulevard": {
    name: "384 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "7wRCAF6rgUaA4d2aAoccVppHRv1nrSkUj9kfn3jKv8Ly",
  },
  "384-first-avenue": {
    name: "384 First Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "first-avenue",
    tokenAddress: "7cR9sU9vbzFEL9H9CSaGWeBQ6pLnFvpK993cfTS5Kbho",
  },
  "384-grime-lane": {
    name: "384 Grime Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "Kn5N3NWjnqjnQCs1srt2YWTvNXcDjmt5j4VbsCt1Y7g",
  },
  "384-lynwood-avenue": {
    name: "384 Lynwood Avenue",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "BbWSag9TaDV26J9SxA5nkHd1mQJvQ8foz3W4G67DKPNe",
  },
  "384-pico-street": {
    name: "384 Pico Street",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "pico-street",
    tokenAddress: "FyyFBsvuu2kqKd9vEsDNR6bioKpB9JNdMBmJGtugzDHr",
  },
  "384-sierra-avenue": {
    name: "384 Sierra Avenue",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "F1bmVrwEkpWeTXqGn61zddXhkqbfqRchQYRvv9RvBCpL",
  },
  "385-bamboo-boulevard": {
    name: "385 Bamboo Boulevard",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "B4h27itj2CRB2w7E3cLbN3cVfBsQy7wmJidndq9kkNuK",
  },
  "385-first-avenue": {
    name: "385 First Avenue",
    biome: "field",
    frameType: "occult",
    streetId: "first-avenue",
    tokenAddress: "FpUoPEFryvzRcjHcHUD4oefMAvju9CxshsM146nUSaD1",
  },
  "385-grime-lane": {
    name: "385 Grime Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "Atn2Js9rmUHurhveUaX76TUPUogryYYrSBw5ykK8WSRF",
  },
  "385-sierra-avenue": {
    name: "385 Sierra Avenue",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "Ahj44PHHsWAnCBWindyteSVYbaz54WtCYWSAKyg2yuNb",
  },
  "386-lynwood-avenue": {
    name: "386 Lynwood Avenue",
    biome: "mountain",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "HUZNcgiuevVchcdQa5mtg1NJrPxfUAm1pT96PjDnqR9b",
  },
  "387-bamboo-boulevard": {
    name: "387 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "bamboo-boulevard",
    tokenAddress: "6cLa938jznhYdJ4i8XubPwgsHD8rKS4df2ZGGLcNgwb6",
  },
  "387-first-avenue": {
    name: "387 First Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "BtQRjUmPDQwgR6q7qc6k3jYwF4q2mgaUmKtofjXYPsJZ",
  },
  "387-grime-lane": {
    name: "387 Grime Lane",
    biome: "city",
    frameType: "rounded-green",
    streetId: "grime-lane",
    tokenAddress: "DgbqKrn9Ke8CA1s5gfJpP871qvjGsF3owyYQ8vHHJESL",
  },
  "387-lamport-lane": {
    name: "387 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "HRqv9M7NJuKkz8K8AwRAQrVxxSR1DH5paigXWx7eJ9BV",
  },
  "387-lynwood-avenue": {
    name: "387 Lynwood Avenue",
    biome: "arctic",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "3U9XQVTYTq6juQjP953ujgJDqyDyU1LEap8hLxvZH7AK",
  },
  "387-sierra-avenue": {
    name: "387 Sierra Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "7A4iLWaykhbZ6xpTZRpuBk1dWhVkqE7nzsgbad8a3nR5",
  },
  "388-first-avenue": {
    name: "388 First Avenue",
    biome: "volcanic",
    frameType: "rounded-green",
    streetId: "first-avenue",
    tokenAddress: "6hoK5GUKmBSCCUe6b3Fxtjy9zjZfHEa783RxD88Lpmf5",
  },
  "388-lamport-lane": {
    name: "388 Lamport Lane",
    biome: "underwater",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "8KsadJfQeqWHrP3bjbatfVyD3reGcKUinXMeVZdmVDU6",
  },
  "388-lynwood-avenue": {
    name: "388 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "lynwood-avenue",
    tokenAddress: "6wbL5ahW448QtQkFeuUW3K759ZzrA25smkrBTo3nZeLx",
  },
  "388-pico-street": {
    name: "388 Pico Street",
    biome: "field",
    frameType: "crystal",
    streetId: "pico-street",
    tokenAddress: "JEFoqd3obyhYwJropd9FMzaCtJ9fzgRpB7BBcdL87eHU",
  },
  "388-sierra-avenue": {
    name: "388 Sierra Avenue",
    biome: "beach",
    frameType: "rounded-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "CtnyQQx9xCX1DHqv1cqm73Xm7ZuMVUUndTQPotus1zrw",
  },
  "389-grime-lane": {
    name: "389 Grime Lane",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "67kU3Q6uxpHnEWk5mvq85Yu7UQ4h3m4BwYQGdWdG2snA",
  },
  "389-lynwood-avenue": {
    name: "389 Lynwood Avenue",
    biome: "arctic",
    frameType: "rounded-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "XNtrnegT9cUTG79qscmfLYZed9zxFzEFutqSEWuwtB9",
  },
  "389-pico-street": {
    name: "389 Pico Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "pico-street",
    tokenAddress: "3QEQpaZ97YdE9fx3788vyZ43drzrf8wfXf83bTikBkcv",
  },
  "39-ape-lane": {
    name: "39 Ape Lane",
    biome: "field",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "79qex3xxj1zkncLi8EBu9XKAfEi6isBPrk2cGDKv3eF1",
  },
  "39-rosa-street": {
    name: "39 Rosa Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "Hrr22QouD2FzVs4xvTm7na2whA14Zv3oPen71bdAsNLh",
  },
  "39-supple-circle": {
    name: "39 Supple Circle",
    biome: "volcanic",
    frameType: "rounded-green",
    streetId: "supple-circle",
    tokenAddress: "CXruJoA4PXV6LdwsXywhVVRHdFfUXmMmM5Wyov5vnWQY",
  },
  "39-teeps-lane": {
    name: "39 Teeps Lane",
    biome: "field",
    frameType: "occult",
    streetId: "teeps-lane",
    tokenAddress: "5boTuJDvLbt8ZyQrNoKAsUPVSmxjwRyBkmaovE3Xzra6",
  },
  "390-bamboo-boulevard": {
    name: "390 Bamboo Boulevard",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "Bkq6ayMh6aVEP8jjqjvve1a2wDW7ZGi1q6sy7vCqt4Ss",
  },
  "390-first-avenue": {
    name: "390 First Avenue",
    biome: "underwater",
    frameType: "occult",
    streetId: "first-avenue",
    tokenAddress: "9kRjLm7UCf4WvET6udjmxwEhaXCpj9LLVEDmbRVLoGJw",
  },
  "390-grime-lane": {
    name: "390 Grime Lane",
    biome: "field",
    frameType: "occult",
    streetId: "grime-lane",
    tokenAddress: "3yLsE154GiHcsyj2kH5zSuV1s5arTVi1Md1b4NVE98Dm",
  },
  "390-lamport-lane": {
    name: "390 Lamport Lane",
    biome: "treehouse",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "EeBHfbMJoVcXK96fvFS7pDfoTPmLAXm5ZhFrtuyYNW8Z",
  },
  "390-mint-mile": {
    name: "390 Mint Mile",
    biome: "city",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "AzJdga4rQhhUKc9BLJ7Hx5P7yV4Tchnwrtm1ZjUMEz1Z",
  },
  "390-pico-street": {
    name: "390 Pico Street",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "pico-street",
    tokenAddress: "7rKY9XjSosmx9awXwrXAjhRySzec2kjyoNNgTfELBA1x",
  },
  "391-bamboo-boulevard": {
    name: "391 Bamboo Boulevard",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "FhPR5jpjUa8uJxrJrrsJ8r2aRrmPVnsvwNn4DAu8U2Kk",
  },
  "391-first-avenue": {
    name: "391 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "Gbk5Fu7nGVG3ymCXxuzpocMPnt2ZqHwFqcyYVBNmSztZ",
  },
  "391-grime-lane": {
    name: "391 Grime Lane",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "HC2rr6SHcx8igu3Ec4TgyKBLF5yCa3Kn6DBsE8MJUxWA",
  },
  "391-mint-mile": {
    name: "391 Mint Mile",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "mint-mile",
    tokenAddress: "DLUeKAiowg7FSuTQYJCMf3WFiDhMDe9QrmHg4bh1dSRb",
  },
  "391-sierra-avenue": {
    name: "391 Sierra Avenue",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "HQHF9sGKbe1V74TrWV1VpQrbCTZMCAHxnqWaFvLzjdLd",
  },
  "392-first-avenue": {
    name: "392 First Avenue",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "Gcvkpd7h1A7bPzeYpGd3fxAk8yYkNWeKQRBmUbJr5qfA",
  },
  "392-lamport-lane": {
    name: "392 Lamport Lane",
    biome: "desert",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "66fufXXTMQFYeGvSATfYWJW3TaQC4FBMsRsuedmAntbx",
  },
  "392-lynwood-avenue": {
    name: "392 Lynwood Avenue",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "21XTktaoK5QvJpgLzdbcmvXsiQYbrvEhamHpuJqTT8wF",
  },
  "392-pico-street": {
    name: "392 Pico Street",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "pico-street",
    tokenAddress: "BoYwwMWo8yzChhvpTgkDaeUHijUNbHgPiwpAn6TTfsUe",
  },
  "393-bamboo-boulevard": {
    name: "393 Bamboo Boulevard",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "8N4STKoEJTyRSW9H75mpYC2g4zMXBWzy2kByHKVppTQ9",
  },
  "393-first-avenue": {
    name: "393 First Avenue",
    biome: "woods",
    frameType: "occult",
    streetId: "first-avenue",
    tokenAddress: "13gqnezSYbqJiKYZyTqkYJ9XpGPyUQruX69DEDiM8g4m",
  },
  "393-lamport-lane": {
    name: "393 Lamport Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "H46zyn9CsvqmNMerBuhdQRCkdFSRh8h8TFGvtdMHL6wQ",
  },
  "393-lynwood-avenue": {
    name: "393 Lynwood Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "EsGQcWjNCDSaDi6SXMacKgfZnZ9rm8iDin8PkKdENvpx",
  },
  "393-mint-mile": {
    name: "393 Mint Mile",
    biome: "woods",
    frameType: "crystal",
    streetId: "mint-mile",
    tokenAddress: "2x9NK3kJPrYZVoBNtHDT23zZZePaUewHWotYGQ5iGV4W",
  },
  "393-sierra-avenue": {
    name: "393 Sierra Avenue",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "sierra-avenue",
    tokenAddress: "55jD8Wtows7CZ3tVJrRKL5Aqe2sJ22wa51mGikaHctF4",
  },
  "394-first-avenue": {
    name: "394 First Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "first-avenue",
    tokenAddress: "BWusSA3kVNrX1Zso6AdSBNJoNmp5aLT9qZCg2msmPRcv",
  },
  "394-lamport-lane": {
    name: "394 Lamport Lane",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "9AeTJapE25wDQ1pGUJXDbnEVRNvwUiEwst5MJ58Bivjg",
  },
  "394-lynwood-avenue": {
    name: "394 Lynwood Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "lynwood-avenue",
    tokenAddress: "G1xE7AC2MdjmJKGNyUzMfNr3TCTLq9sHgMspybpJLjLV",
  },
  "394-mint-mile": {
    name: "394 Mint Mile",
    biome: "arctic",
    frameType: "shuttered-yellow",
    streetId: "mint-mile",
    tokenAddress: "8niyBCtTmwXhPhon1FvJfP46WFsivEobmqyQQyPAp2YU",
  },
  "395-first-avenue": {
    name: "395 First Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "J3VgVu3Pgu6F6Js5fKFJUKuNCND9Leo6Sb7o7tzNEhUM",
  },
  "395-lamport-lane": {
    name: "395 Lamport Lane",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "GaJNSdN5oAXkc1AYc7yhwZyXFduA4Jtjq8i4RpWP1JAk",
  },
  "395-lynwood-avenue": {
    name: "395 Lynwood Avenue",
    biome: "treehouse",
    frameType: "rounded-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "F6zR5CVP4XJPjKbXxRRq1YusnH9srZQeU1kL6T7wx4r",
  },
  "396-bamboo-boulevard": {
    name: "396 Bamboo Boulevard",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "C2YCFAwthr1Z6z7JMRNuUPhVkS1oHZ6veTZLJMEdEQuM",
  },
  "396-grime-lane": {
    name: "396 Grime Lane",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "Ay7Zja4wB4S4rjB2tkpPpcwTyVJYAGoZdj6VyfVrMvph",
  },
  "396-lamport-lane": {
    name: "396 Lamport Lane",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "lamport-lane",
    tokenAddress: "8naGu1iF8JyE7WT595QwjonXae8CAWzJrKXqT43fkNMP",
  },
  "396-lynwood-avenue": {
    name: "396 Lynwood Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "lynwood-avenue",
    tokenAddress: "BhVyBHCGkH8avAiuSyS269GeUqqSz37HHVF2DhaTb8UL",
  },
  "396-sierra-avenue": {
    name: "396 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "sierra-avenue",
    tokenAddress: "FdVkDufLj6MwvePk7mJoPstGtogoNanNKGeMA9BKBRCN",
  },
  "397-bamboo-boulevard": {
    name: "397 Bamboo Boulevard",
    biome: "woods",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "EhPp8qvrv1Fi52SSHWeyRcjSFYRcS3phaHYPxTY85c8n",
  },
  "397-first-avenue": {
    name: "397 First Avenue",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "F9ZYxcp1MKfqhq61F4i6zaDf18gaJX31METYdsdCL1vW",
  },
  "397-lamport-lane": {
    name: "397 Lamport Lane",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "lamport-lane",
    tokenAddress: "dmrnFymGqS7BwZxz371MTCeEDT8LKgTfmXc9rXCCWDW",
  },
  "397-pico-street": {
    name: "397 Pico Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "Fa5tM16YrnjRqt3VYC9FVWCkHQdjYU9CGQEhHVJxhoVF",
  },
  "397-sierra-avenue": {
    name: "397 Sierra Avenue",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "EZZc2Ukt1XMh6da6NmCBbmTV7PcTjm2hciTtFuNXJFm5",
  },
  "398-bamboo-boulevard": {
    name: "398 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "3SodkC6qSLRXZjD3wpE8A2nNiqCd1b1a62tdAibPakfF",
  },
  "398-first-avenue": {
    name: "398 First Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "EEqLAbqC6aF8XMpN4smgwruAHukUV6sne6WrKDBuQseR",
  },
  "398-grime-lane": {
    name: "398 Grime Lane",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "grime-lane",
    tokenAddress: "876kesd4zx7yJTWAsCLeXjmriXU7raBvaCWMSrxLbLBZ",
  },
  "398-mint-mile": {
    name: "398 Mint Mile",
    biome: "mountain",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "4ZrviaatRGAKPN25epVscnNG4vt8351v7QMBuFrqdBCu",
  },
  "399-bamboo-boulevard": {
    name: "399 Bamboo Boulevard",
    biome: "arctic",
    frameType: "rounded-lavender",
    streetId: "bamboo-boulevard",
    tokenAddress: "5r2GRYJ9wQGXnNJNSsAJhrgax5PSU5JGTnmrL3Rin68w",
  },
  "399-first-avenue": {
    name: "399 First Avenue",
    biome: "mountain",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "DVf8Cr6s7RsiLmPC3xLEAprgomjPKNoEJCgzGavmPgZL",
  },
  "399-lamport-lane": {
    name: "399 Lamport Lane",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "lamport-lane",
    tokenAddress: "6nYDtCqdxwUp61Ew4PXowqSysnMm3wRgH6wsn1P7uZTj",
  },
  "399-lynwood-avenue": {
    name: "399 Lynwood Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "lynwood-avenue",
    tokenAddress: "A9UDSko5RWgHfvqwLCZ5a6tPu5ZiXPCdADRhXfifBH7N",
  },
  "399-mint-mile": {
    name: "399 Mint Mile",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "mint-mile",
    tokenAddress: "A2X94Mxcet87Te3qAmmcA7RhmFyCSzbrSrDCk6Ebn88M",
  },
  "399-pico-street": {
    name: "399 Pico Street",
    biome: "mountain",
    frameType: "rounded-dark-green",
    streetId: "pico-street",
    tokenAddress: "DWFzSjHDwhiZgSAijB8TjeHU92vpjiwS1NvZ2eLW7uu4",
  },
  "399-sierra-avenue": {
    name: "399 Sierra Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "7j8KCokFSXk3a9w8v8dhDV1QikG4JDZ3JGLM24gTEwE2",
  },
  "40-ape-lane": {
    name: "40 Ape Lane",
    biome: "city",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "6bFDrnvaLa7LdoedAmG7KAoN4PjwZ7byKBHmyrQfjCc2",
  },
  "40-rosa-street": {
    name: "40 Rosa Street",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "rosa-street",
    tokenAddress: "A6SnkKP8crZy8wY4sejej2ZtrRhN9xC8qTNFMeQyFtXW",
  },
  "40-supple-circle": {
    name: "40 Supple Circle",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "supple-circle",
    tokenAddress: "FX92CNhQWkXNN3e3KTEB2fAkB8MBKZ6XjL4KyzALzDXu",
  },
  "40-teeps-lane": {
    name: "40 Teeps Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "5Ygermg3AuRmJHkh4XjoVBc6B9XUYXan8oRgBWap6TiS",
  },
  "400-bamboo-boulevard": {
    name: "400 Bamboo Boulevard",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "5JS7jBeDqhrh6D6hDoNHV7YdmEhvPFUwW9Snv8bxheza",
  },
  "400-first-avenue": {
    name: "400 First Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "CFaXiG1opd9pok4Drvb4qEyWF7DLtTcZZ2cHmApH1SzS",
  },
  "400-grime-lane": {
    name: "400 Grime Lane",
    biome: "woods",
    frameType: "occult",
    streetId: "grime-lane",
    tokenAddress: "7fM1bKXNF3kwVnAchfDDHt3Kyy51nnMNgR9dYyPnFuqs",
  },
  "400-mint-mile": {
    name: "400 Mint Mile",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "mint-mile",
    tokenAddress: "EbXXHEdaVASmQMh1De34L6Vnb5Ko8GV6EDQRR4pH543X",
  },
  "400-sierra-avenue": {
    name: "400 Sierra Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "sierra-avenue",
    tokenAddress: "CjYtXhhSrF63uaJNQyPEGhQZTinW3AdXdDVBcYnMmmvM",
  },
  "401-bamboo-boulevard": {
    name: "401 Bamboo Boulevard",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "2ewWuyv7YpJCb3jCjaWcsbVniymChrmX7v3GUSsU4FYp",
  },
  "401-first-avenue": {
    name: "401 First Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "first-avenue",
    tokenAddress: "GdBLCrj2YT2qZfUvutGvURV9oFbzgaQ8t6ybgRQyTNUL",
  },
  "401-grime-lane": {
    name: "401 Grime Lane",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "grime-lane",
    tokenAddress: "9fqFUhQo14X73ZiiMJ2PtTyJPdSoa5UzxzzRT6Zae6ZZ",
  },
  "401-lynwood-avenue": {
    name: "401 Lynwood Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "BN8wXxXdnLsBSjWxRQagvthyW5m8aU4sT9uDifhtBEcS",
  },
  "401-mint-mile": {
    name: "401 Mint Mile",
    biome: "desert",
    frameType: "crystal",
    streetId: "mint-mile",
    tokenAddress: "FH8oNkyhXtxXtVZjXKoPzeWopt6UG6ixkkrDCu869qHD",
  },
  "401-pico-street": {
    name: "401 Pico Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "pico-street",
    tokenAddress: "2Psr9uRo2zYaG1m8toxpKiawrsemzc9HLfhKmWJndQJu",
  },
  "401-sierra-avenue": {
    name: "401 Sierra Avenue",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "sierra-avenue",
    tokenAddress: "A8yecDa2SpKR4EGQtn1AqaXx1TeEYVHnjUhxYsGMccqR",
  },
  "402-bamboo-boulevard": {
    name: "402 Bamboo Boulevard",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "4zDxZJ6x7J6EhGYp8FkC75YhgvM4E5mt9LvMkzhMYwez",
  },
  "402-blockchain-drive": {
    name: "402 Blockchain Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "blockchain-drive",
    tokenAddress: "6AYHozzkHtgDmBGDaQ4CmgCEUKbr9xbXDZxKf8xa77Bj",
  },
  "402-first-avenue": {
    name: "402 First Avenue",
    biome: "beach",
    frameType: "gold",
    streetId: "first-avenue",
    tokenAddress: "3gM7z8MqKQU4fUNqawFWxj6QtqdpdjJWoAQtFn42P3QM",
  },
  "402-lamport-lane": {
    name: "402 Lamport Lane",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "CAejViDmopEMc83JTkneVzY1mrnvXALYw7sFRapgUkat",
  },
  "402-lynwood-avenue": {
    name: "402 Lynwood Avenue",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "lynwood-avenue",
    tokenAddress: "5iLdZt2Gg1SQUjyJow9XGctR5D8eQa4jt2PgaR1cPgvZ",
  },
  "402-mint-mile": {
    name: "402 Mint Mile",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "mint-mile",
    tokenAddress: "G2ZuSCKELqkun815pvBcnxBQGZBNDfWpYg8hU5Evaz1a",
  },
  "403-bamboo-boulevard": {
    name: "403 Bamboo Boulevard",
    biome: "woods",
    frameType: "occult",
    streetId: "bamboo-boulevard",
    tokenAddress: "4hoto4yhBeZvYsD5cax4uvAUNFp3EEcqcDumRULf25jQ",
  },
  "403-evergreen-terrace": {
    name: "403 Evergreen Terrace",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "evergreen-terrace",
    tokenAddress: "4xBK3aQ2cmnAGexh9bzurUkq7AxUSboYF9qpLKhFAdaM",
  },
  "403-first-avenue": {
    name: "403 First Avenue",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "first-avenue",
    tokenAddress: "6XhsjZ4YA195BxnyNhnCugQamRVwyWhWVEffqiKvWG2e",
  },
  "403-grime-lane": {
    name: "403 Grime Lane",
    biome: "arctic",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "4cuB3s4jQipk5wZ1nFKNHTdtQ5i9mqdzLksQZCctD5Xv",
  },
  "403-lynwood-avenue": {
    name: "403 Lynwood Avenue",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "lynwood-avenue",
    tokenAddress: "3b1t5qgc76TiWnmWWWYJXHQPhvVugRMvNnJ2PkdYfZRh",
  },
  "403-sierra-avenue": {
    name: "403 Sierra Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "J1cWodqXfMZKmw8JDK9p7bCx5EdfBdXAhQn5v88tEBfy",
  },
  "404-bamboo-boulevard": {
    name: "404 Bamboo Boulevard",
    biome: "arctic",
    frameType: "rounded-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "vNvcuA7tA3BFTLCrdYY9JWdPJfJntvHEFMLNNdCvFqk",
  },
  "404-blockchain-drive": {
    name: "404 Blockchain Drive",
    biome: "field",
    frameType: "glass",
    streetId: "blockchain-drive",
    tokenAddress: "At8gtH4qhFgAM6C2b7PNmAmyapLVF8ZSDvAUQQfBMQxL",
  },
  "404-first-avenue": {
    name: "404 First Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "3F2bgi9PNHsKd3gzgjjZveLMst2u9ziWZFuDCobMKXM7",
  },
  "404-grime-lane": {
    name: "404 Grime Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "G3Nog5BQFfG2MKyLvEMF1HrP366yJpLDdpXTb8ank5Qm",
  },
  "404-lamport-lane": {
    name: "404 Lamport Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "DjaWfntfL5iUCa795h2CPmUwHmLv5433qKz8SYMq6Pz3",
  },
  "404-lynwood-avenue": {
    name: "404 Lynwood Avenue",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "2BKAdTUXfXWTpc4qEK9Dgcxxe34pHWcB1ZxUv573stGF",
  },
  "404-sierra-avenue": {
    name: "404 Sierra Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "3iGNVnmbAvoTrrQ5HW1QjpxqnjvHweZEQ9tLCWEKof3v",
  },
  "405-blaze-boulevard": {
    name: "405 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "blaze-boulevard",
    tokenAddress: "4woHyEs4jmSdytQyBW8jjMHMH8QmNRbxaD7KHn2JGpvS",
  },
  "405-first-avenue": {
    name: "405 First Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "first-avenue",
    tokenAddress: "Gdb7Ksi625URsGJEB6ceQ2P1pCKqcqiMGHpyivEzbCRE",
  },
  "405-grime-lane": {
    name: "405 Grime Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "6SeoNuqav1G7i9pDsZTb7q8WvCVu29E6SoTY7SceHjp9",
  },
  "405-mint-mile": {
    name: "405 Mint Mile",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "mint-mile",
    tokenAddress: "B76qS9MorerECtNMaavCDpBbGmBfghY1LjFKCbsjyvnX",
  },
  "405-sierra-avenue": {
    name: "405 Sierra Avenue",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "sierra-avenue",
    tokenAddress: "FpGwFBug6A9jGZPv269jPsYKk9RTFQyRjLLSHG9RGSPi",
  },
  "406-bamboo-boulevard": {
    name: "406 Bamboo Boulevard",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "Ab7G31P3GqqA95s6eWH14oHrzNcVyc7xiu7VrNs7yCE8",
  },
  "406-blaze-boulevard": {
    name: "406 Blaze Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "blaze-boulevard",
    tokenAddress: "6qegfWqrSLMZrbEbSmdQrTg3WodydRJ2C7Kwtcg6quco",
  },
  "406-blockchain-drive": {
    name: "406 Blockchain Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "blockchain-drive",
    tokenAddress: "GX953FFbUaMcWg7ibz64AhZECcK4GuyGqd569o1XuAar",
  },
  "406-lynwood-avenue": {
    name: "406 Lynwood Avenue",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "GcupyS5Z5cyEutMDjGYox1WfFuVD8oCWKcc6CgfFtp5V",
  },
  "406-sierra-avenue": {
    name: "406 Sierra Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "4G7xSCX59qjzyBoJG9xYgt3hKu31N67iqzgQDERiEcG3",
  },
  "407-bamboo-boulevard": {
    name: "407 Bamboo Boulevard",
    biome: "field",
    frameType: "gold",
    streetId: "bamboo-boulevard",
    tokenAddress: "EUdTFgGup5T6XtWWNHySYpmQyy1ARYX332x5FFGvhBuH",
  },
  "407-blaze-boulevard": {
    name: "407 Blaze Boulevard",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "5SzPdu1fFRBASwr7w6bTZqzffzx1qwDCnHgDL9KQvL2u",
  },
  "407-blockchain-drive": {
    name: "407 Blockchain Drive",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "blockchain-drive",
    tokenAddress: "2K7d7PuWWJMG9YT4wfFTUtNzKvp766VnhJ7qRxUnvhmD",
  },
  "407-evergreen-terrace": {
    name: "407 Evergreen Terrace",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "evergreen-terrace",
    tokenAddress: "AU5yZHmVE8DSYEkoBS3p7phowypqsKZy6u2mo8TB1T4Y",
  },
  "407-grand-avenue": {
    name: "407 Grand Avenue",
    biome: "treehouse",
    frameType: "rounded-pale-green",
    streetId: "grand-avenue",
    tokenAddress: "GpoKePFF6xUvqohB9NiMwLW8QeeF3foEnK4W4QGWeUp5",
  },
  "407-lynwood-avenue": {
    name: "407 Lynwood Avenue",
    biome: "city",
    frameType: "rounded-green",
    streetId: "lynwood-avenue",
    tokenAddress: "3HzHWHio8uUHZs2Th4CH2gYygCS3B4LL1CVhqLtjLu1L",
  },
  "407-mint-mile": {
    name: "407 Mint Mile",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "mint-mile",
    tokenAddress: "6w3SLpLUKsHjSS3JPrysP3QM9Jh9sijrcEp3pZND7H2g",
  },
  "407-sierra-avenue": {
    name: "407 Sierra Avenue",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "Evy613KpgMKzWQCbvyYpuaVBDwWJsdzfe47EyniaD6cu",
  },
  "408-evergreen-terrace": {
    name: "408 Evergreen Terrace",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "evergreen-terrace",
    tokenAddress: "8NUL4LSZewrMB4wsALWP7X4uffP4TrrhLNcLgspiaEnk",
  },
  "408-grand-avenue": {
    name: "408 Grand Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "grand-avenue",
    tokenAddress: "7NnR9LXpuNKLkhmHivsKYpgSApDq3mktLynt1QXtpEra",
  },
  "408-lamport-lane": {
    name: "408 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "86iG9m2hizvSFG646RpGfvqGK3CSxXKFmiK9321Sm253",
  },
  "409-bamboo-boulevard": {
    name: "409 Bamboo Boulevard",
    biome: "desert",
    frameType: "crystal",
    streetId: "bamboo-boulevard",
    tokenAddress: "FZ5pJFUHU5C7TNdiez1LbqmaebUJbtnzXdHFAQDQruQb",
  },
  "409-blaze-boulevard": {
    name: "409 Blaze Boulevard",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "blaze-boulevard",
    tokenAddress: "BNceRRX6eXBAt1MWMmCzZNvuaQNTu16KPVg12YarMs3J",
  },
  "409-block-hills-drive": {
    name: "409 Block Hills Drive",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "block-hills-drive",
    tokenAddress: "D8XgiZGVp1g9NYe1NYe86M3rQaknBRQJELnpQ7H3vavk",
  },
  "409-first-avenue": {
    name: "409 First Avenue",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "BHrja5WMmxqgmg32PekEz97ZRZyezozc3xgVEPUrdTht",
  },
  "409-grand-avenue": {
    name: "409 Grand Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "grand-avenue",
    tokenAddress: "GixrmsyWX6bZQ8ztkVg4thEyvdoiK17w1nJbVeoDfZJY",
  },
  "409-grime-lane": {
    name: "409 Grime Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "AQvFKBxyXAUfdHYRnKXWfdreAL77LZHksyAwFJUq95vr",
  },
  "409-lynwood-avenue": {
    name: "409 Lynwood Avenue",
    biome: "city",
    frameType: "occult",
    streetId: "lynwood-avenue",
    tokenAddress: "AHbvm1FoeHxq6mTfM43vrvf723BZjZmRWhwegmsxZpu7",
  },
  "41-ape-lane": {
    name: "41 Ape Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "25UYz5Ga5UyCRBjGTbb4ehJfrhTbr2wbb2x4YMDZ9Zxp",
  },
  "41-rosa-street": {
    name: "41 Rosa Street",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "rosa-street",
    tokenAddress: "5BeGHM5JsDxo9GwpqE6ZJZ1QaniyCA7AHqabdLHkCyW6",
  },
  "41-supple-circle": {
    name: "41 Supple Circle",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "supple-circle",
    tokenAddress: "DzvWhrbQQbCynkndLniSYxEG42qqB8696bdp5dMDVTvH",
  },
  "41-teeps-lane": {
    name: "41 Teeps Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "Cw18NmzYBYNNSagdEHVe1NmYwKVzz36boRn9Q3V1wqxG",
  },
  "410-blaze-boulevard": {
    name: "410 Blaze Boulevard",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "blaze-boulevard",
    tokenAddress: "3k3hH5vsepagV8hrr1UGuXTbJt35uKZFc4oYvkvsshDk",
  },
  "410-block-hills-drive": {
    name: "410 Block Hills Drive",
    biome: "field",
    frameType: "rounded-red",
    streetId: "block-hills-drive",
    tokenAddress: "EtA66Uoj3AWngiiRwznrG17BQSUyr2rkzLtpst8uaHqR",
  },
  "410-dahlia-drive": {
    name: "410 Dahlia Drive",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "s1AFBxrTvaHY4Z34sfLbPNjbnMd9VV5i1NDhSNW1nB4",
  },
  "410-mint-mile": {
    name: "410 Mint Mile",
    biome: "arctic",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "AnG3LBViPXEPpjWReQSMEiqfnknHR3ht98ynSsG4PWaP",
  },
  "410-sierra-avenue": {
    name: "410 Sierra Avenue",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "sierra-avenue",
    tokenAddress: "5tdr6CfxPUjFnYgC4JXCby1T2rdYxZ2bvDKW3gURq1zn",
  },
  "411-blaze-boulevard": {
    name: "411 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "blaze-boulevard",
    tokenAddress: "HctiCTYBP7M9GB9adcAKCzJ1oHE5DZ42rvt4mVQ7WRJt",
  },
  "411-block-hills-drive": {
    name: "411 Block Hills Drive",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "block-hills-drive",
    tokenAddress: "5Bf9pWPCSxjPZqE97yrrVJjQYCbW82TpX4Az1AGiQ4JF",
  },
  "411-dahlia-drive": {
    name: "411 Dahlia Drive",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "DTeH7zykx1PKQis4YKAvFR2Qy1dz4HZEXYpRB2BHuwv",
  },
  "411-evergreen-terrace": {
    name: "411 Evergreen Terrace",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "evergreen-terrace",
    tokenAddress: "7YaMnnYkHS23jSneFprLeadsxUoqoWFwZy6jy6HwvmXN",
  },
  "411-first-avenue": {
    name: "411 First Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "FNDC5MdpRAm8Tyva5HmTg749kJsLEdQRUFzHS4EZDaJB",
  },
  "411-lynwood-avenue": {
    name: "411 Lynwood Avenue",
    biome: "underwater",
    frameType: "occult",
    streetId: "lynwood-avenue",
    tokenAddress: "EN5H2djYPLtxohKHzdryMhfcWLVVu1bNaiz7prU46fAp",
  },
  "411-mint-mile": {
    name: "411 Mint Mile",
    biome: "city",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "F8W2VjtmWZfYZEfZgZ9o7H4Som7c9op5gNV5RDZiuR1k",
  },
  "412-bamboo-boulevard": {
    name: "412 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "3LjA73e5oKfsgqoyPkjaLbwHvePLBksykYEJ1jX49PTX",
  },
  "412-blaze-boulevard": {
    name: "412 Blaze Boulevard",
    biome: "mountain",
    frameType: "glass",
    streetId: "blaze-boulevard",
    tokenAddress: "C5bdrAvgrwMCpkwBdEUQP5s46zwniSuBGVcb79bwXFbA",
  },
  "412-block-hills-drive": {
    name: "412 Block Hills Drive",
    biome: "woods",
    frameType: "shuttered-dark-green",
    streetId: "block-hills-drive",
    tokenAddress: "JA4Dy1gKMJntKoC8syg613mfxn36p3TBn4LMcD5tgzAS",
  },
  "412-blockchain-drive": {
    name: "412 Blockchain Drive",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "blockchain-drive",
    tokenAddress: "CbJ1AgEBx8VFEuUymt3gmY7xK5y6vQEKNheJGhZd3RGb",
  },
  "412-dahlia-drive": {
    name: "412 Dahlia Drive",
    biome: "beach",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "Diw73kScREK2VCce7AywPGqoJCCCYsyH7ZgQQAjWYnmY",
  },
  "412-first-avenue": {
    name: "412 First Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "HtXC3PW2TLsDH8CngiWBuCyWgbZMTtU7DqaeR3pfPpCw",
  },
  "412-grime-lane": {
    name: "412 Grime Lane",
    biome: "field",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "G4uTh72Mn6qqKe1trxmdcQYrMh71MeaMxGFFMwBUjWKr",
  },
  "412-mint-mile": {
    name: "412 Mint Mile",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "mint-mile",
    tokenAddress: "B4BVhsSCVFWaMLm5o92iEtTNe8Ls7DQ25XDk4qb6Vrip",
  },
  "413-bamboo-boulevard": {
    name: "413 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "Ebcm4zCKfVpL1WwNayMgcxf8xmVkAo2PZkCrixmX3PQs",
  },
  "413-blaze-boulevard": {
    name: "413 Blaze Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "blaze-boulevard",
    tokenAddress: "CP9xQKA7jMymgddQziXqRuhHxfYk7Di5X4XoaH6NExcb",
  },
  "413-block-hills-drive": {
    name: "413 Block Hills Drive",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "block-hills-drive",
    tokenAddress: "6JrGpst3ZnESWBsbEgaRQaVD3p7sGbkFKeQr4b1bq6Az",
  },
  "413-blockchain-drive": {
    name: "413 Blockchain Drive",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "blockchain-drive",
    tokenAddress: "HNP9LgEz5zq5Lj1GiGL5pCNwo3yaKdPvX1bkVCa6knzD",
  },
  "413-first-avenue": {
    name: "413 First Avenue",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "first-avenue",
    tokenAddress: "2Hc8P62aHgiHRKtejSaLhZGb2aiew4xJD8SLyBocZ47c",
  },
  "413-grand-avenue": {
    name: "413 Grand Avenue",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "grand-avenue",
    tokenAddress: "JAx8VX9h1mJ9WRq4uNykcBYkKgrRoP5maBiBbyNbe25v",
  },
  "413-lamport-lane": {
    name: "413 Lamport Lane",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "lamport-lane",
    tokenAddress: "DQsjFxtRARSpT2LzRJQdjpBF5x33fXsgPCZiX1d1eMrP",
  },
  "413-sierra-avenue": {
    name: "413 Sierra Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "sierra-avenue",
    tokenAddress: "6HdyfuAVYtHJDxovLBVqKkvuy1uoXWkrQWyigRM8CMqj",
  },
  "414-bamboo-boulevard": {
    name: "414 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "AZtVqg7xENUkkis59MdgQywCbnRdyZXuLTwPfpK3h2Lz",
  },
  "414-blaze-boulevard": {
    name: "414 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "FmHtP4PCJp4ourR1Gud4YZhqeTUwJa1bCjUVgiUUcbKV",
  },
  "414-block-hills-drive": {
    name: "414 Block Hills Drive",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "block-hills-drive",
    tokenAddress: "EKmwoZfF7F19n2i6hC6PUP1UY6onn4Y9ja2RPar97NB",
  },
  "414-grime-lane": {
    name: "414 Grime Lane",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "grime-lane",
    tokenAddress: "H8amLU229LBLuSPsXdHUpfb3DkAfuYzM2H5PYqFCAMoe",
  },
  "414-lamport-lane": {
    name: "414 Lamport Lane",
    biome: "field",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "8AWowTDmcDoYG2v7mtFRNHYbtTfKkJjHvkTRDb9rL5Kb",
  },
  "415-blaze-boulevard": {
    name: "415 Blaze Boulevard",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "blaze-boulevard",
    tokenAddress: "GQwMp7KW5e5ixThfcqa5jL7TmjysYwqASyaM5Nt9RADH",
  },
  "415-block-hills-drive": {
    name: "415 Block Hills Drive",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "block-hills-drive",
    tokenAddress: "8BrBG6wfYgdQWKTY1ALWjvmcNaLKXmQYmgFSsLDSkxA7",
  },
  "415-blockchain-drive": {
    name: "415 Blockchain Drive",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "blockchain-drive",
    tokenAddress: "59Fo6j59xTDZp4PQGvprorkLWfJxv2t3Ebm8qiWU5DEV",
  },
  "415-grand-avenue": {
    name: "415 Grand Avenue",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "grand-avenue",
    tokenAddress: "3o2tCRRm5K3mk7QKwPSe9JgA3oVeFL96xshZWq5LoD5W",
  },
  "415-lynwood-avenue": {
    name: "415 Lynwood Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "lynwood-avenue",
    tokenAddress: "3a7fd2GDigVkCyekk14zTHzkMbvNTe22HNB2xF4s8iQC",
  },
  "416-blaze-boulevard": {
    name: "416 Blaze Boulevard",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "blaze-boulevard",
    tokenAddress: "EK4w7DT1AcDVv5wTDbKVLYZrj28TwiEVV8Fn4XbuS18",
  },
  "416-blockchain-drive": {
    name: "416 Blockchain Drive",
    biome: "field",
    frameType: "glass",
    streetId: "blockchain-drive",
    tokenAddress: "G55XpVSnxFhRrSaZYy9bgpqNCv7JeipyagZXyWN7Fqoz",
  },
  "416-dahlia-drive": {
    name: "416 Dahlia Drive",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "DXSRK9gtcsCuYmdG1kouZVHX5DUguT7XmwfR9ZgjwGrg",
  },
  "416-first-avenue": {
    name: "416 First Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "first-avenue",
    tokenAddress: "AJT1VB6x8yLiiEA7FMBVtjiXJVXg3rHHFFe4kRgrtdf",
  },
  "416-grime-lane": {
    name: "416 Grime Lane",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "7AF7oQowcRyY9wRr6eLHbt6GCcZmYjZTEi6tL3DVZ91H",
  },
  "416-lamport-lane": {
    name: "416 Lamport Lane",
    biome: "treehouse",
    frameType: "rounded-green",
    streetId: "lamport-lane",
    tokenAddress: "HcQuT8vpU38idhKQGLKPuo7oKF8kGQMfSXvDfKtKuFbD",
  },
  "416-mint-mile": {
    name: "416 Mint Mile",
    biome: "city",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "BgqVcAajxBmqyqrFb16oPTnWS6ff96fEtWxfmjSTyuco",
  },
  "417-blaze-boulevard": {
    name: "417 Blaze Boulevard",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "blaze-boulevard",
    tokenAddress: "7a4MtRKCFqym8zp5S2abzxWVrFUtZz1vmNx4Scn2opXr",
  },
  "417-block-hills-drive": {
    name: "417 Block Hills Drive",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "block-hills-drive",
    tokenAddress: "7MNyeuDfMRm2XTJq1ZDaTHTy8iJqYVExVLo17aXUGTuL",
  },
  "417-blockchain-drive": {
    name: "417 Blockchain Drive",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "blockchain-drive",
    tokenAddress: "DYCVfgydmd81FAEgeTcxUKtJiAKruLTzaEhVSrJ1dW2w",
  },
  "417-dahlia-drive": {
    name: "417 Dahlia Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "bGsynZ51pxFBAyhwjq26mTa2WC9wKZGQyhUi4hGZJdX",
  },
  "417-first-avenue": {
    name: "417 First Avenue",
    biome: "volcanic",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "EiQ3Hft7VTTQzJTgyVXk4iEikXPsqoahsp58MMQvuHaU",
  },
  "417-grand-avenue": {
    name: "417 Grand Avenue",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "grand-avenue",
    tokenAddress: "8o7rgvY5gL3cfhkwoReK8pmRJK3FadSR9jMZTUHqju5p",
  },
  "417-grime-lane": {
    name: "417 Grime Lane",
    biome: "city",
    frameType: "occult",
    streetId: "grime-lane",
    tokenAddress: "EuoGPGAZtYfGXtLUWWLmbt7q9yCoM4DDhGPuPmfTbL6p",
  },
  "417-lamport-lane": {
    name: "417 Lamport Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "6sEPxdqGEKFZcde1g8SqeSB5brPAGtFEdicV1TkSsYLp",
  },
  "417-mint-mile": {
    name: "417 Mint Mile",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "mint-mile",
    tokenAddress: "7e9peZeK3iqp983Yh7xgEzwKQZhKHy2pseozq3t6TqPm",
  },
  "417-sierra-avenue": {
    name: "417 Sierra Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "sierra-avenue",
    tokenAddress: "8gqUkJynMw5DFw7NijYzcv4dMf7tXovQTN5bcAiyJnR2",
  },
  "418-blaze-boulevard": {
    name: "418 Blaze Boulevard",
    biome: "woods",
    frameType: "shuttered-dark-green",
    streetId: "blaze-boulevard",
    tokenAddress: "A1gyHXuGgErm6LNA3LcV2bbTEhrSbRRjYrZSRpvi4mdv",
  },
  "418-first-avenue": {
    name: "418 First Avenue",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "first-avenue",
    tokenAddress: "E8JCPcT9vEgxyLZ2aSGWhHNd6RXjR2xVjGTkHogJRcS4",
  },
  "418-grand-avenue": {
    name: "418 Grand Avenue",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "grand-avenue",
    tokenAddress: "CBTBpGHU9FBrgNFLt9qhBFEnCGoFNYjPMoBznuEZdN67",
  },
  "418-lamport-lane": {
    name: "418 Lamport Lane",
    biome: "city",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "8cai85UBBdhEV7xYuthMLfQGxdeLN4KEqw41AdLe9xZR",
  },
  "418-lynwood-avenue": {
    name: "418 Lynwood Avenue",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "lynwood-avenue",
    tokenAddress: "6yJvBm2GEBzZucUMHSABnzNVwVYWshSaNyEx5fCPvKZj",
  },
  "418-sierra-avenue": {
    name: "418 Sierra Avenue",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "9QTZuw5DBA1N9pKRFx3Crnr47Vq1JLfgPK6tCHBV66dZ",
  },
  "419-bamboo-boulevard": {
    name: "419 Bamboo Boulevard",
    biome: "volcanic",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "FgXUAnSugQv4LbTTBd77c6MYkVXpUNP6c8n6kktACFMk",
  },
  "419-blaze-boulevard": {
    name: "419 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "blaze-boulevard",
    tokenAddress: "9CPf34QSxCL9zVkyZJcHfWXH8fFPLUJDaMYdVWi6zyX8",
  },
  "419-block-hills-drive": {
    name: "419 Block Hills Drive",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "block-hills-drive",
    tokenAddress: "ErCFs8T89Q2uepJoU1y85e4tXTv3VNYRVvyHYfcRE8qF",
  },
  "419-blockchain-drive": {
    name: "419 Blockchain Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "blockchain-drive",
    tokenAddress: "7GB1JrcSY9CxPKtADDMg5UBMbdFkiLXs3XmuNeUgwK5m",
  },
  "419-lamport-lane": {
    name: "419 Lamport Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "FJDe6Sz2BS6K1htinRrGCBiEZ3Wf9D5L6i5yN945PVem",
  },
  "419-sierra-avenue": {
    name: "419 Sierra Avenue",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "J6CBSbzJ36D7QQbpLGxEiL9YVut4M2tP9mh4Z1eBzZSc",
  },
  "42-ape-lane": {
    name: "42 Ape Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "2owGhw3arxdsrG5URwa3aCjfA7WVc7yvWp4uwDuHNRfA",
  },
  "42-rosa-street": {
    name: "42 Rosa Street",
    biome: "volcanic",
    frameType: "rounded-pale-green",
    streetId: "rosa-street",
    tokenAddress: "9i84EAEj6rE5H7D8S3uPiD4k2J1gZn539pvdAe6iRRuh",
  },
  "42-stewart-avenue": {
    name: "42 Stewart Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "AXM7DN7iwHnhtRannc6HtcX28Wprqz7z2WLunhxYYR3j",
  },
  "42-supple-circle": {
    name: "42 Supple Circle",
    biome: "woods",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "B6HZrZsARPtW8KC3XE8NU1wLoasDve49KLesMBftz4mU",
  },
  "42-teeps-lane": {
    name: "42 Teeps Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "DyXTd7ZVLAaboeB8oLKVAjJD6fF96zKmi9epXzQZ6vzR",
  },
  "420-blaze-boulevard": {
    name: "420 Blaze Boulevard",
    biome: "mountain",
    frameType: "crystal",
    streetId: "blaze-boulevard",
    tokenAddress: "EaddwKJ4fQAYstNuYd5tpd7PVNyYK9vf11fSEanwuKgV",
  },
  "420-blockchain-drive": {
    name: "420 Blockchain Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "blockchain-drive",
    tokenAddress: "DUy9Ank1NV5hSAX8YoEEJmVfCzY8iHWN5QdgqCHEqJAc",
  },
  "420-dahlia-drive": {
    name: "420 Dahlia Drive",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "5jt7Xya3pgHJ79ri3dsyrZr5MgskfTVDyt5iE5KNJbzV",
  },
  "420-first-avenue": {
    name: "420 First Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "FBoWbF5uZigGPiD9htXXCoWZ8qRhJ41pt3yYbu6Z61MC",
  },
  "420-grime-lane": {
    name: "420 Grime Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "grime-lane",
    tokenAddress: "HEhAPAJSHpCGQZR5Zbx3Nnd7U4Yi5JHpksCcmnUwy1pc",
  },
  "420-lamport-lane": {
    name: "420 Lamport Lane",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "4Nw4pbubN9kDCwrRkUZaNPzF9wqRUavuTjX6BjzPEVVL",
  },
  "420-ligma-lane": {
    name: "420 Ligma Lane",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "ligma-lane",
    tokenAddress: "HAToaMT92veYfxDxqquJaGcmgZA3zyUctdypxKKtAcTg",
  },
  "421-bamboo-boulevard": {
    name: "421 Bamboo Boulevard",
    biome: "mountain",
    frameType: "crystal",
    streetId: "bamboo-boulevard",
    tokenAddress: "4iGVYAG15gekezGa8WYAFPkH43C9VfG1e5vyxLxt1bCC",
  },
  "421-blaze-boulevard": {
    name: "421 Blaze Boulevard",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "AX6r4GPPFRSXdAsxdwLjTstxoTdJakRw9v6WdbKwx4DR",
  },
  "421-blockchain-drive": {
    name: "421 Blockchain Drive",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "blockchain-drive",
    tokenAddress: "EWEAq8hJ1RHV6oF83vYpzfndmoViaYpuWoXDAb4ygHN5",
  },
  "421-first-avenue": {
    name: "421 First Avenue",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "first-avenue",
    tokenAddress: "AxXWxjxYrJH1W8KZ5WLyNA1MXuUnGu9RQejtQkSzF1ce",
  },
  "421-grand-avenue": {
    name: "421 Grand Avenue",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "grand-avenue",
    tokenAddress: "FHctKKzvQKjhHjVJXwP9XULhZvsVqx4fopXaSc5Ud8Tr",
  },
  "421-lamport-lane": {
    name: "421 Lamport Lane",
    biome: "volcanic",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "9kfEznif73eQTt4bdrPZoDbB2nBjTeVF9i1uUMhKyn2M",
  },
  "421-sierra-avenue": {
    name: "421 Sierra Avenue",
    biome: "mountain",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "9M1uCiBSRxygwHEa1GKDL7qBLgTRkV2UW29RYSku9KT5",
  },
  "422-blaze-boulevard": {
    name: "422 Blaze Boulevard",
    biome: "desert",
    frameType: "crystal",
    streetId: "blaze-boulevard",
    tokenAddress: "3VpUaR7EktkpXw4eRo1q9McufnAY7j81C1JoWBTFctWS",
  },
  "422-block-hills-drive": {
    name: "422 Block Hills Drive",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "block-hills-drive",
    tokenAddress: "3ke3C5SF9wKoLLQEjDPt73miTfcfJrRjhHyszDYq7Bvh",
  },
  "422-evergreen-terrace": {
    name: "422 Evergreen Terrace",
    biome: "woods",
    frameType: "glass",
    streetId: "evergreen-terrace",
    tokenAddress: "9fD1KbaehCCP64xZ5kixSrXrPYiZ38bpRbRQnNkB6kvV",
  },
  "422-grime-lane": {
    name: "422 Grime Lane",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "grime-lane",
    tokenAddress: "2p8PGE3L5kSzLHZikjdh7D6oQbuYTjvhpfasKUfXskUV",
  },
  "422-mint-mile": {
    name: "422 Mint Mile",
    biome: "field",
    frameType: "crystal",
    streetId: "mint-mile",
    tokenAddress: "2SdqzhmCxF6LAeQNoSpakHV5tWc6bFpJsQzfx6UZHvjS",
  },
  "422-sierra-avenue": {
    name: "422 Sierra Avenue",
    biome: "underwater",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "4xvGdwQNy3XSkbF4KGHvztx5QWTwFjVRodVYje1NaUwP",
  },
  "423-blaze-boulevard": {
    name: "423 Blaze Boulevard",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "2Am6vbxwM9a5WgG6qmc6uhuUjzCkio32VTwbK9oZ6mQX",
  },
  "423-block-hills-drive": {
    name: "423 Block Hills Drive",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "block-hills-drive",
    tokenAddress: "4GMJhqd2h57wBkZ7DbpvE74eGufeTFuYMfjRusN2gzh2",
  },
  "423-dahlia-drive": {
    name: "423 Dahlia Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "AeAjuBmzzBMFE5qXahUs4fP6eZrpDZ9Fs7jAKHWmyjxo",
  },
  "423-first-avenue": {
    name: "423 First Avenue",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "first-avenue",
    tokenAddress: "F9ZrUaEqNcViLLrpf6kMZ7nky12wzbQzs98ENH7ThzUM",
  },
  "423-grand-avenue": {
    name: "423 Grand Avenue",
    biome: "city",
    frameType: "rounded-dark-green",
    streetId: "grand-avenue",
    tokenAddress: "uWN6LuRXsqxnV2HFrycqnSskZfyYpKQJuAEEpiqzoxd",
  },
  "423-grime-lane": {
    name: "423 Grime Lane",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "3sckHLtNA5CwnB87QnMevwpoexdqTH4PBmsNkquXjyP4",
  },
  "424-blaze-boulevard": {
    name: "424 Blaze Boulevard",
    biome: "desert",
    frameType: "crystal",
    streetId: "blaze-boulevard",
    tokenAddress: "A3zCgr1ZVo9ZHxGgkMr6Ki49K6dnW8u21iTEvuQDXL5H",
  },
  "424-block-hills-drive": {
    name: "424 Block Hills Drive",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "block-hills-drive",
    tokenAddress: "8bS3ksA7zzr33Ffmxhxu94ty2Fx6WdDkjBD1cbJpeq7g",
  },
  "424-first-avenue": {
    name: "424 First Avenue",
    biome: "underwater",
    frameType: "gold",
    streetId: "first-avenue",
    tokenAddress: "BhK7X7m3PBTbWext9vxqubr8LU3KnPmPnCS9b7tv1iqn",
  },
  "424-grand-avenue": {
    name: "424 Grand Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "grand-avenue",
    tokenAddress: "FMvY2eM8GjyXqBT5ZiVfbxJCHmdmaTCFv4bBFxb1RJxv",
  },
  "424-grime-lane": {
    name: "424 Grime Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "2vJ1DvcGZGGP2Q2tS4YcnYr61pFkVVwzMoVE5fheWj2o",
  },
  "424-sierra-avenue": {
    name: "424 Sierra Avenue",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "9YMhwpRCypTCwJzssPdaEkiXQNjEz1dNTPti2Lsk7xqx",
  },
  "425-bamboo-boulevard": {
    name: "425 Bamboo Boulevard",
    biome: "city",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "AHjwWdty7gGs7S8jTAmVsMRgBbpkHAB6GMGfkkq2vqnK",
  },
  "425-blaze-boulevard": {
    name: "425 Blaze Boulevard",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "9ETFJEsMZRR11Gro9k3kzxpx7dj4fi3HSKYjuhzHAcgL",
  },
  "425-block-hills-drive": {
    name: "425 Block Hills Drive",
    biome: "arctic",
    frameType: "crystal",
    streetId: "block-hills-drive",
    tokenAddress: "KEKTaBzZYAexCZZH7pWUHDcToBc5pSdesGaeXYTxgaC",
  },
  "425-dahlia-drive": {
    name: "425 Dahlia Drive",
    biome: "desert",
    frameType: "occult",
    streetId: "dahlia-drive",
    tokenAddress: "6fWb1EkfseMnTjkDKfMhYFvyMwUAC9NwsdkMaUb2K8Du",
  },
  "425-grand-avenue": {
    name: "425 Grand Avenue",
    biome: "field",
    frameType: "rounded-green",
    streetId: "grand-avenue",
    tokenAddress: "J5F9btbD4YLQhbVrThfMCZvwxVjj19gP1xJggmgbGZRD",
  },
  "425-grime-lane": {
    name: "425 Grime Lane",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "grime-lane",
    tokenAddress: "CkZYr4vt4chasPwyLR8VcwJSdeKL5GDRqkwnQoVWLnUW",
  },
  "425-lamport-lane": {
    name: "425 Lamport Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "HJmJdB1PnWbpx9AtTXtGCLkSRvtJ81AuNJMABuBNmLh5",
  },
  "425-mint-mile": {
    name: "425 Mint Mile",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "mint-mile",
    tokenAddress: "8Vk7Kw9XGnVHwRftS3tBj2N93BdLzFck5gERwNT5s1DH",
  },
  "425-sierra-avenue": {
    name: "425 Sierra Avenue",
    biome: "volcanic",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "5DzbuHtkWE74wsgXmiF44puDSKMhQiW3x6dDUfYjTfcU",
  },
  "426-blaze-boulevard": {
    name: "426 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "blaze-boulevard",
    tokenAddress: "3922i2BUgph4N8cXgcK9w87GdFkLc6KnUVVMoegdmC9F",
  },
  "426-block-hills-drive": {
    name: "426 Block Hills Drive",
    biome: "city",
    frameType: "occult",
    streetId: "block-hills-drive",
    tokenAddress: "AZcu7n6rG2ubT5VXvLspWLt2jtpH94mbaVANT7okhXSK",
  },
  "426-first-avenue": {
    name: "426 First Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "62SRvKA7nXR5LtiUBXVoHQSEdRQUiP87nykNTsZr3gD5",
  },
  "426-grand-avenue": {
    name: "426 Grand Avenue",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "grand-avenue",
    tokenAddress: "EPetjoUXfQenexBUWB1CXYC3G3hpVtpm3rwfc2u1sUPB",
  },
  "426-grime-lane": {
    name: "426 Grime Lane",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "GXM9sKfcorszHCzBTdhngsPbZHiWtfBaLxVniTELVkmL",
  },
  "426-lamport-lane": {
    name: "426 Lamport Lane",
    biome: "field",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "7wnSwjnfuvi3pKvsPf2gG5GC1PeXG1aD1UsMGpVxwVHT",
  },
  "426-mint-mile": {
    name: "426 Mint Mile",
    biome: "woods",
    frameType: "occult",
    streetId: "mint-mile",
    tokenAddress: "HGG9qEsfcA8jyKJwHierUoRruC7GoxMGTQRgnPiVGeae",
  },
  "427-bamboo-boulevard": {
    name: "427 Bamboo Boulevard",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "EXGaJpgoufHrSwDKeK3G943Ui3fHznh1A6HXRp1zunZQ",
  },
  "427-block-hills-drive": {
    name: "427 Block Hills Drive",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "block-hills-drive",
    tokenAddress: "AVr9GNrMhUjufbuuNwEMhcscbfsm3AWtmgmj7cBXtsmp",
  },
  "427-grand-avenue": {
    name: "427 Grand Avenue",
    biome: "desert",
    frameType: "occult",
    streetId: "grand-avenue",
    tokenAddress: "AweAAFpwyyd4m6zFuerrkSPaykXEjaLbTjFE8ZznS1oo",
  },
  "427-lamport-lane": {
    name: "427 Lamport Lane",
    biome: "city",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "9WXyFjyy8F7geTHTrrpxgkgoSfkrSy519aRxJaDNsfmc",
  },
  "427-sierra-avenue": {
    name: "427 Sierra Avenue",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "ygUJXyV6FmAt8gE8QGm3WzWUnfCUZj9JZkCJ68LR6aj",
  },
  "428-bamboo-boulevard": {
    name: "428 Bamboo Boulevard",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "bamboo-boulevard",
    tokenAddress: "tTdbgmdR3uUe51kGPZnatHjPVCYZqgexFn5AsDd9n87",
  },
  "428-blaze-boulevard": {
    name: "428 Blaze Boulevard",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "blaze-boulevard",
    tokenAddress: "GLAuPQuVNJM6Xa7rgKGN2YxNhGdjczNW9H7FVBwrirr9",
  },
  "428-block-hills-drive": {
    name: "428 Block Hills Drive",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "block-hills-drive",
    tokenAddress: "25Et4rk98TEBmpgeGg1a9KmMR4g2G6S6JdESkdUaQ7Bo",
  },
  "428-evergreen-terrace": {
    name: "428 Evergreen Terrace",
    biome: "beach",
    frameType: "crystal",
    streetId: "evergreen-terrace",
    tokenAddress: "CaUuUMZjq8YunKLuQbwUV7boZpvDaYqNpKo6KHPmjCKo",
  },
  "428-first-avenue": {
    name: "428 First Avenue",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "first-avenue",
    tokenAddress: "HsaWu2C67xA46J3yr1CE7GWZwqpbY2XqiQV4tetDZgSF",
  },
  "428-grand-avenue": {
    name: "428 Grand Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "grand-avenue",
    tokenAddress: "4g6UxMEQhASJRGKsRnVyAxETiMUarhFtzqYUuqbiWKrr",
  },
  "428-lamport-lane": {
    name: "428 Lamport Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "lamport-lane",
    tokenAddress: "J8NdjqAbFU6cW2kM7fbQe2aA8NsVvcG77m7w5up9tyF1",
  },
  "428-mint-mile": {
    name: "428 Mint Mile",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "mint-mile",
    tokenAddress: "6qSgw2rPmSoMSvsN3qU8ydxUbcDwN5XntPy5f5tgQKuR",
  },
  "428-sierra-avenue": {
    name: "428 Sierra Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "D6HmrirytUzRoxWQ1JKBXh9HAQzsCZj9eN1FcWJJY2iq",
  },
  "429-bamboo-boulevard": {
    name: "429 Bamboo Boulevard",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "DD1j9YVQs4bgibHKqUMwcSGqqYW4dC3yvUFUj5eX9ijE",
  },
  "429-blaze-boulevard": {
    name: "429 Blaze Boulevard",
    biome: "woods",
    frameType: "crystal",
    streetId: "blaze-boulevard",
    tokenAddress: "EPEHCTYMhThBhEN7jqxoDxdW3FXvRPk7SrfFn96DCA9o",
  },
  "429-block-hills-drive": {
    name: "429 Block Hills Drive",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "block-hills-drive",
    tokenAddress: "3R1h57k7sa4q48wVho8UjWEHFX7ZcCehE9cSoLvM7pDJ",
  },
  "429-blockchain-drive": {
    name: "429 Blockchain Drive",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "blockchain-drive",
    tokenAddress: "ETjJsnq93dJX3do1HuTMCgV88mEndh5kFHFjgU8vYK8B",
  },
  "429-first-avenue": {
    name: "429 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "H7arEbDDT4PJLA5fKTxN8Y24rwsyZG85YsJrpjprB7mJ",
  },
  "429-grime-lane": {
    name: "429 Grime Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "B3yyaozpWfsFr8bPycT3P5mZ26TjhUypqVidqjKRf97q",
  },
  "429-lamport-lane": {
    name: "429 Lamport Lane",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "yBE2r4jGSbKrazzARKBRJgKuZeSRyrdfJc9i371G8v7",
  },
  "43-ape-lane": {
    name: "43 Ape Lane",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "ape-lane",
    tokenAddress: "3gp8rNpbsFFSR42hvoasNs4zwD4yUhdD2iqbJrG6JULK",
  },
  "43-rosa-street": {
    name: "43 Rosa Street",
    biome: "field",
    frameType: "gold",
    streetId: "rosa-street",
    tokenAddress: "9vBLXr7aQbjzp194xf7Y6DsaipbG5f4rrbdxeQfUUztr",
  },
  "43-stewart-avenue": {
    name: "43 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "stewart-avenue",
    tokenAddress: "A1LvbPnuTKGxFzhQoJ7srxnabjGcLWHe9dfxwWoPC1vL",
  },
  "43-supple-circle": {
    name: "43 Supple Circle",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "supple-circle",
    tokenAddress: "Euo6HvJ4VC7NBxLekGUbyL41Y7U7FEBfQay9f1BQNh1u",
  },
  "43-teeps-lane": {
    name: "43 Teeps Lane",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "teeps-lane",
    tokenAddress: "5XMsMCxqChV46BkkKC6GEAPV45E8zn74qZdYYdH68b8R",
  },
  "430-blaze-boulevard": {
    name: "430 Blaze Boulevard",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "blaze-boulevard",
    tokenAddress: "EcbmBWsUSLofRtLiCEUicrjqn2iWWctyWgGY4e4X7Hzd",
  },
  "430-block-hills-drive": {
    name: "430 Block Hills Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "block-hills-drive",
    tokenAddress: "6uasM1dBiysdCNhiKsM9aQGXceLH27rRQTurTuVvUu1N",
  },
  "430-evergreen-terrace": {
    name: "430 Evergreen Terrace",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "evergreen-terrace",
    tokenAddress: "6mQ3Nhmye2rKy8pAoTmttDCEdNpq7W6c4BRGUyrFuFLw",
  },
  "430-grand-avenue": {
    name: "430 Grand Avenue",
    biome: "field",
    frameType: "rounded-green",
    streetId: "grand-avenue",
    tokenAddress: "WaQ5Sj7xzwbPysT9DdzVkuAS6raqufriw1sqNhU9nh9",
  },
  "430-grime-lane": {
    name: "430 Grime Lane",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "7EejhwVbKoEJB2R8M1x1rd8NBg4Soh4ebJYVTVjRuaa7",
  },
  "430-lamport-lane": {
    name: "430 Lamport Lane",
    biome: "arctic",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "G5mVihYsKJfwHvjuDbf8rz8E8oZsDLcR9yk328P9NYrS",
  },
  "431-bamboo-boulevard": {
    name: "431 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "5tHuAuRHfLgGawGwsPr3HGwZUprprsNjW4ZcpHLppBHH",
  },
  "431-blaze-boulevard": {
    name: "431 Blaze Boulevard",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "blaze-boulevard",
    tokenAddress: "HrW8qAYjoZBMbfPvsSfxZDMmCwbKH7HZAMQ2fp5xnWrq",
  },
  "431-block-hills-drive": {
    name: "431 Block Hills Drive",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "block-hills-drive",
    tokenAddress: "8wRGQ5wkGgH7dwGN3jXzYYCKmrnqkYQCvmv8Te43a1ec",
  },
  "431-blockchain-drive": {
    name: "431 Blockchain Drive",
    biome: "treehouse",
    frameType: "rounded-green",
    streetId: "blockchain-drive",
    tokenAddress: "78bpwWqCiQtGohkwEkzFbiS5X8jg56YBMk487hf4ZKA7",
  },
  "431-dahlia-drive": {
    name: "431 Dahlia Drive",
    biome: "field",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "9ppWRnH82aQBUT62GP15xoW5ckbaC4ubrcM2zWoo3ar3",
  },
  "431-first-avenue": {
    name: "431 First Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "An47ve42N7EWfoCvbdM5KfjVWKVT8jWgFVgm6MVGZ2ym",
  },
  "431-grand-avenue": {
    name: "431 Grand Avenue",
    biome: "woods",
    frameType: "occult",
    streetId: "grand-avenue",
    tokenAddress: "DyaFspQ9sSdhXUqtCUn1h9XdWyocimdRXZfKQBdZzfU6",
  },
  "431-grime-lane": {
    name: "431 Grime Lane",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "CxVfTQnd9Psdn5VFwgBiqcsoNamr3VKoy9RrAcAkkvpL",
  },
  "432-bamboo-boulevard": {
    name: "432 Bamboo Boulevard",
    biome: "field",
    frameType: "occult",
    streetId: "bamboo-boulevard",
    tokenAddress: "8E2CDMdeaEG2NsdkAs7k4JCftddMjBfMnDpNQ5dQjuuZ",
  },
  "432-blaze-boulevard": {
    name: "432 Blaze Boulevard",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "blaze-boulevard",
    tokenAddress: "3Q8dMFe5LXcno9viNkdk3Bwp5N9Lq8uwUgjqtB7N7cLJ",
  },
  "432-first-avenue": {
    name: "432 First Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "4cgaEKw4Re8XqciQRjuekJTbiCJT7A2JnFBDLvwt9xkH",
  },
  "432-grime-lane": {
    name: "432 Grime Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "496nrYDzmetayJ4BdPtAkT8T89gikgr6HLTUqYhyxfKp",
  },
  "432-lamport-lane": {
    name: "432 Lamport Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "lamport-lane",
    tokenAddress: "6SKBFAk8fh6tdPQ8KzL5wtq7sPk6FKP7tFx4cCMGVCxt",
  },
  "432-mint-mile": {
    name: "432 Mint Mile",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "mint-mile",
    tokenAddress: "2ax9T1CfjYSpHQaf4RbeS3SNhZeA1FqZW9ZwMUiM7yAG",
  },
  "432-sierra-avenue": {
    name: "432 Sierra Avenue",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "sierra-avenue",
    tokenAddress: "EEH4LE7dDrGvQxHyaGbzxw9WaymubrTNA8bQNZNiJseJ",
  },
  "433-blaze-boulevard": {
    name: "433 Blaze Boulevard",
    biome: "beach",
    frameType: "glass",
    streetId: "blaze-boulevard",
    tokenAddress: "3VXaBV8pFzBrcChubLTuRUs3JAGHqLBAg1g8gRNuGu4E",
  },
  "433-first-avenue": {
    name: "433 First Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "AjMUJjqNcg27oVau5PPkro7etuNpmwheug4xfMpXmhVP",
  },
  "433-grand-avenue": {
    name: "433 Grand Avenue",
    biome: "woods",
    frameType: "crystal",
    streetId: "grand-avenue",
    tokenAddress: "7LQK9kpkiCdXDPpKYJNhVEvg1UF1KT4i69ATuThcPJ5C",
  },
  "433-grime-lane": {
    name: "433 Grime Lane",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "GQjcHr39HYXuNeo18p6xaRKCtSoA2gArWEM3TD7oYfiB",
  },
  "433-lamport-lane": {
    name: "433 Lamport Lane",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "G79ZVpcws1VkSh3wWX99MEbhBcmsn8jhgZ6W2xL3Npc8",
  },
  "434-bamboo-boulevard": {
    name: "434 Bamboo Boulevard",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "3XNgNjnhngdRgUMoUzpTYpihxFJvXf1AG8fBvrJMYiLn",
  },
  "434-block-hills-drive": {
    name: "434 Block Hills Drive",
    biome: "treehouse",
    frameType: "glass",
    streetId: "block-hills-drive",
    tokenAddress: "2kqHsWsG4CTPtQxUzndqtA36Adan7qJmcdQaNvZ3cPq5",
  },
  "434-dahlia-drive": {
    name: "434 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "HkfSxwoeSqNxqmhTtoDYPjaqYrJpQbH4gyQ2Xe3oQABS",
  },
  "434-grand-avenue": {
    name: "434 Grand Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "grand-avenue",
    tokenAddress: "CeBHHreCcnY5EEH743HBpK5k4Ya45ofBGiLD5Sge1KzR",
  },
  "434-grime-lane": {
    name: "434 Grime Lane",
    biome: "treehouse",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "E9xmiaTP6Ho55o3qRgTRpJbypWXvh1mrqTFnyqC1iviu",
  },
  "434-lamport-lane": {
    name: "434 Lamport Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "CqkZVXLGGdzYR6S5KnpEtuBVnHiUxh22zgv5m69Qmzrv",
  },
  "434-sierra-avenue": {
    name: "434 Sierra Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "sierra-avenue",
    tokenAddress: "3e5kQo5gP5T9yAnmFsJmxQQCKGkChejwxoyFmWRdXSBy",
  },
  "435-bamboo-boulevard": {
    name: "435 Bamboo Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "56axNHHHKCNk3svjUSMXKmnwkRoqeAcJoiGDMwrCid72",
  },
  "435-blaze-boulevard": {
    name: "435 Blaze Boulevard",
    biome: "field",
    frameType: "crystal",
    streetId: "blaze-boulevard",
    tokenAddress: "EYJCuw57nBHgA36Vt5BGsW8fLrTJ7s3pgeNpL3AsQEUk",
  },
  "435-block-hills-drive": {
    name: "435 Block Hills Drive",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "block-hills-drive",
    tokenAddress: "5hqJGQSuwQ5A17FNHNSeDNJSxYRJoB8SdKFrT1rBpcpn",
  },
  "435-first-avenue": {
    name: "435 First Avenue",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "first-avenue",
    tokenAddress: "3J6bgNKdmaUrWs5iyGG36EtjwdC2iThk1gBy1EUpfRfo",
  },
  "435-lamport-lane": {
    name: "435 Lamport Lane",
    biome: "city",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "GGXuo5mVupvNXXY8PyYxZmwaGb3sksebXAPZdEe1MXbH",
  },
  "435-sierra-avenue": {
    name: "435 Sierra Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "sierra-avenue",
    tokenAddress: "86rKZzWG9AM3WPYat295efHK34SNjeJfbjxDZVFPJCGe",
  },
  "436-bamboo-boulevard": {
    name: "436 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "FmwmYfkYLfCvhKksqYxVhk6edQNxKw91C4L8vJpEBSP3",
  },
  "436-blaze-boulevard": {
    name: "436 Blaze Boulevard",
    biome: "beach",
    frameType: "occult",
    streetId: "blaze-boulevard",
    tokenAddress: "7CcmJcKc4NNt1BjUCNKuqEikbeXQuvYJnw7JqgJSTGxf",
  },
  "436-grand-avenue": {
    name: "436 Grand Avenue",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "grand-avenue",
    tokenAddress: "FfAbZXmcAZgVi2V39WMZmybd6ygD3vajVP7dWSd1urEZ",
  },
  "436-lamport-lane": {
    name: "436 Lamport Lane",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "99B1cYZCDoxqGKVNPyZSzH6i5Mf1vL3SjuP4qY88KB4A",
  },
  "437-blaze-boulevard": {
    name: "437 Blaze Boulevard",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "blaze-boulevard",
    tokenAddress: "HAV2gTofrLeaYyc1rh9N2J23YsRAoHqD8PFBTdMwcb8L",
  },
  "437-block-hills-drive": {
    name: "437 Block Hills Drive",
    biome: "city",
    frameType: "rounded-red",
    streetId: "block-hills-drive",
    tokenAddress: "6hqu2YkRDagpKJBurBRagFYWtBAqpSLYa7GNWv7NioTz",
  },
  "437-dahlia-drive": {
    name: "437 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "HcVCsUcyjTrV5ynMVEZBrtDDX9BRmHCtnyPBQ9qgoqk6",
  },
  "437-lamport-lane": {
    name: "437 Lamport Lane",
    biome: "arctic",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "2YoMBu1Mv1eppsoNXnAcutGqyTkfrFpmWiU7iPDE8exw",
  },
  "437-mint-mile": {
    name: "437 Mint Mile",
    biome: "field",
    frameType: "glass",
    streetId: "mint-mile",
    tokenAddress: "APRS2F8F9SHB8U7WxgDeJG1pqjUszypPyjpEQm1L8tJC",
  },
  "438-bamboo-boulevard": {
    name: "438 Bamboo Boulevard",
    biome: "city",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "vwTr58GzyGYo3bZbc9xrKy4yySXPsHzxDhWGviKJ3sX",
  },
  "438-block-hills-drive": {
    name: "438 Block Hills Drive",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "block-hills-drive",
    tokenAddress: "9aNvjW3xqAVVzWT2PwStBTQu7pgBDptATaW5kFUiwy5n",
  },
  "438-first-avenue": {
    name: "438 First Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "first-avenue",
    tokenAddress: "8wCKPsnGpkD3TrnRPYN79PJevEq4qqqKDBnws3zhRMn",
  },
  "438-grand-avenue": {
    name: "438 Grand Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "grand-avenue",
    tokenAddress: "2JNcDfZYg6hjP8zsUqfp42tBBs3jwyM2b43BVj1VVM3w",
  },
  "438-lamport-lane": {
    name: "438 Lamport Lane",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "lamport-lane",
    tokenAddress: "FSx9H9pQpA3xLKeivkTvQDo1fzdgnv5mXAyo2Lo9rh35",
  },
  "438-sierra-avenue": {
    name: "438 Sierra Avenue",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "sierra-avenue",
    tokenAddress: "2JHNS5XQn9QQ88Sy9Ccu81PnnrHzFtjwFZDhPmU5nPXF",
  },
  "439-blaze-boulevard": {
    name: "439 Blaze Boulevard",
    biome: "field",
    frameType: "rounded-green",
    streetId: "blaze-boulevard",
    tokenAddress: "CinSkeCVEDCr6yAjLshW1JsS5994awXsXjn8GqT1pJqv",
  },
  "439-block-hills-drive": {
    name: "439 Block Hills Drive",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "block-hills-drive",
    tokenAddress: "6dMNTqyT7wMeLfRhsSMJmPgoMmKzc14eESJw6V3F7iqh",
  },
  "439-first-avenue": {
    name: "439 First Avenue",
    biome: "arctic",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "H1Zw2nHFDpQAq3RgWv6LZrwBjXUCmy6nSM3S4GzdFbkz",
  },
  "439-mint-mile": {
    name: "439 Mint Mile",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "mint-mile",
    tokenAddress: "B3SziisruMdMmp4Vn3WUbUcjhszqhY7LZ71wkbUhELdz",
  },
  "44-ape-lane": {
    name: "44 Ape Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "7BrBoaoVbBYab1TrNTt9CMW1DoUFCwikrFdTEX96CyFW",
  },
  "44-rosa-street": {
    name: "44 Rosa Street",
    biome: "desert",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "F6dHqBqJjzfngcUQ1uqLrXax6qZnxykZHfsgJwE1XmoE",
  },
  "44-supple-circle": {
    name: "44 Supple Circle",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "supple-circle",
    tokenAddress: "4dXQdACQZ4uFYEA6f5bwgdSrYN8JntkKDbZcUzBti7s6",
  },
  "44-teeps-lane": {
    name: "44 Teeps Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "42Gf9zn5MV5hb2wYcGgDdm2T7yHfcSqqQdh41NZ6RZw4",
  },
  "440-bamboo-boulevard": {
    name: "440 Bamboo Boulevard",
    biome: "beach",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "B19orSGy4NDf3CSKQeZR9s5mAZwa1fyCKznk5f5vqP71",
  },
  "440-block-hills-drive": {
    name: "440 Block Hills Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "block-hills-drive",
    tokenAddress: "DrWi5zPKSGeZVSb1Jyzejp9Jw853KLGg6KezVTayvuxX",
  },
  "440-first-avenue": {
    name: "440 First Avenue",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "first-avenue",
    tokenAddress: "5kvpwqNJRPDfnm2VhKnYd5fBdCt5gSBawfgmiUmvfd2D",
  },
  "440-grand-avenue": {
    name: "440 Grand Avenue",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "grand-avenue",
    tokenAddress: "AQ6JSCskASBCmM6D24JmpHaNk7Y3dT6Wum1gJJ3KQeAn",
  },
  "440-grime-lane": {
    name: "440 Grime Lane",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "63bfbQTws9pL7GFnovrvzq6JWUU8fKXWWheciYVEW8K3",
  },
  "440-lamport-lane": {
    name: "440 Lamport Lane",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "lamport-lane",
    tokenAddress: "53q18gVZNSC3FoLLdKAZwggMkVMyL8X9BNnrXMJibGBA",
  },
  "440-sierra-avenue": {
    name: "440 Sierra Avenue",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "sierra-avenue",
    tokenAddress: "HH7do6USKfRogmYyyRuWxGMQgJ6mjk5q1zrq3ZjmvVDj",
  },
  "441-grand-avenue": {
    name: "441 Grand Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "grand-avenue",
    tokenAddress: "HrXrdJiuFNYaYxHNhN3aoz38TAwVqzCveVGGtKybpeX7",
  },
  "441-grime-lane": {
    name: "441 Grime Lane",
    biome: "city",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "hffkGXog71BsjGMaNw5ikCSFaz5j4yMV3Srr8ZeKGHM",
  },
  "442-bamboo-boulevard": {
    name: "442 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "64UQBJsR49hCrc99doG4XEkFJTywTy7rAffMAzts1Yyi",
  },
  "442-dahlia-drive": {
    name: "442 Dahlia Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "Aqyv5rPfvFbVtQHCvehM75rph2AfX5nExoU1rWFLqbwT",
  },
  "442-grand-avenue": {
    name: "442 Grand Avenue",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "grand-avenue",
    tokenAddress: "7QYMVNfxA6yH8zUYo3MH8bG1Gg4o8YVRGVNx55Gm4y6u",
  },
  "442-grime-lane": {
    name: "442 Grime Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "grime-lane",
    tokenAddress: "GJWVMAwe61hySjgxsk1SfAepucNngKR6nWfz68XiXQyn",
  },
  "442-lamport-lane": {
    name: "442 Lamport Lane",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "BgHC61H4aRmbRmVUQVMk5ehQvvojH3tJxbmb1mDAKcgS",
  },
  "442-sierra-avenue": {
    name: "442 Sierra Avenue",
    biome: "volcanic",
    frameType: "rounded-green",
    streetId: "sierra-avenue",
    tokenAddress: "AFLvm28xZbScZhWJJw9Kf93rgqwNTFVsm5xKnAPQwKXp",
  },
  "443-bamboo-boulevard": {
    name: "443 Bamboo Boulevard",
    biome: "city",
    frameType: "occult",
    streetId: "bamboo-boulevard",
    tokenAddress: "DZdcxkAtna1BN334di9jjvF76KmkudjqFGvVqAYrNWBY",
  },
  "443-block-hills-drive": {
    name: "443 Block Hills Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "block-hills-drive",
    tokenAddress: "GZn3ajzDBu5WPN13uNyUFyV6KEErBb3aaAXFQ8TTv7JB",
  },
  "443-dahlia-drive": {
    name: "443 Dahlia Drive",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "5JLoxU2zXq1q5vFT2pmM2HQWqW4bNvELpqx3w2zk9QgK",
  },
  "443-first-avenue": {
    name: "443 First Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "7JFwbAUs6CLcUMrVCfV9xTzVBpvNM971upAvYFHiLEFZ",
  },
  "443-grand-avenue": {
    name: "443 Grand Avenue",
    biome: "arctic",
    frameType: "crystal",
    streetId: "grand-avenue",
    tokenAddress: "A8jbqtzw7YkPnRNkAuR9LWKWiV6qGPZ9naykgX5MfjkR",
  },
  "443-grime-lane": {
    name: "443 Grime Lane",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "JvtkHKyjp9DwMUVeR4yh1kMisGgxribVq1NcvoS7sUB",
  },
  "443-lamport-lane": {
    name: "443 Lamport Lane",
    biome: "desert",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "DUUpkKQjJa9QMH2ZngX1BofAPaCVbNxLgG8XiEZUmJ9p",
  },
  "443-sierra-avenue": {
    name: "443 Sierra Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "sierra-avenue",
    tokenAddress: "9AjdJoUisvqMYKrnJiaxpWUWBrrGQ6Qw2qxazS1XCKao",
  },
  "444-block-hills-drive": {
    name: "444 Block Hills Drive",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "block-hills-drive",
    tokenAddress: "BhNAAFk8MbMw29BdueRHbyUuyPGoSRQh9nXqZGhnjGMG",
  },
  "444-dahlia-drive": {
    name: "444 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "dahlia-drive",
    tokenAddress: "29AYrRsx1sDVtaiJvimkXmXpx2RGgC41BYbJtXrfXKJi",
  },
  "444-first-avenue": {
    name: "444 First Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "AXtw4uYmuhtBWxBzQejAQYnq9KHHWQPq1zLgK8Psknt1",
  },
  "444-grand-avenue": {
    name: "444 Grand Avenue",
    biome: "treehouse",
    frameType: "glass",
    streetId: "grand-avenue",
    tokenAddress: "jaj4KT9oNrZZDagx3ufbbhAJxypw6cEq72s9APpS7Ct",
  },
  "444-grime-lane": {
    name: "444 Grime Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "CrMSngaPcqC9ST1sZNftomJDFuXiBvYQ7N7nLTd6x3jD",
  },
  "444-lamport-lane": {
    name: "444 Lamport Lane",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "ANXEYQwweSK5cjXEaJXjJL15q6rBd2GD8kZf5aS5Hvcp",
  },
  "444-sierra-avenue": {
    name: "444 Sierra Avenue",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "DbqeAQwexV7o8aDu3Gkx4XBBjz9P9ghFS8G4nyefigM8",
  },
  "445-bamboo-boulevard": {
    name: "445 Bamboo Boulevard",
    biome: "field",
    frameType: "glass",
    streetId: "bamboo-boulevard",
    tokenAddress: "DNm12M68GewJshecXJ1DdwkR4YDi9nBUBfzm4Kz4QsTg",
  },
  "445-block-hills-drive": {
    name: "445 Block Hills Drive",
    biome: "mountain",
    frameType: "glass",
    streetId: "block-hills-drive",
    tokenAddress: "GmahoBSm8eGqarmNtSJRcinAgNhgVy1439R5mMwu4V3K",
  },
  "445-dahlia-drive": {
    name: "445 Dahlia Drive",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "7EVDzKXibB8eFFp3pWeCiGEJejYV8wa23d6izHBFBTPj",
  },
  "445-grand-avenue": {
    name: "445 Grand Avenue",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "grand-avenue",
    tokenAddress: "DyUTkhsde7N3s97mkyY2EV93EhCF35khv5JznAfcty1f",
  },
  "445-grime-lane": {
    name: "445 Grime Lane",
    biome: "woods",
    frameType: "rounded-lavender",
    streetId: "grime-lane",
    tokenAddress: "DES9nfw2EjENqdg6bgZBMVT5KYVqnfrSW9yHgdSVTimW",
  },
  "445-lamport-lane": {
    name: "445 Lamport Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "4JbguxpKx6BRxhJNoxYtuqzJWUSW7apesA72NtQvgEkz",
  },
  "445-sierra-avenue": {
    name: "445 Sierra Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "sierra-avenue",
    tokenAddress: "3DxfXRUmev6yWxhnGRfKJjPbAfh7ScK2D58bvYNVY7yg",
  },
  "446-block-hills-drive": {
    name: "446 Block Hills Drive",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "block-hills-drive",
    tokenAddress: "C3GawLp3jFznn2f3BV3RVktgWvKtBiBB6Lr485LUazvq",
  },
  "446-first-avenue": {
    name: "446 First Avenue",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "first-avenue",
    tokenAddress: "3cbAWKbxDbsdNcFQfZQTyg44ghyn5rXSei2y6a31q1Us",
  },
  "446-grand-avenue": {
    name: "446 Grand Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "grand-avenue",
    tokenAddress: "Fgxy3gTUAoipvNEs1HwBGsyyvrjExLqCG8XnGRN7LwDC",
  },
  "446-lamport-lane": {
    name: "446 Lamport Lane",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "lamport-lane",
    tokenAddress: "BjA51RuYTa1nmqXCbpP3nAuKvxcXc4AtbMEtD8Nyrebz",
  },
  "446-sierra-avenue": {
    name: "446 Sierra Avenue",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "sierra-avenue",
    tokenAddress: "FiSRD29QmeRVNE3fyNMYddQFnqippEAp51iBPfYw8o38",
  },
  "447-block-hills-drive": {
    name: "447 Block Hills Drive",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "block-hills-drive",
    tokenAddress: "2cdwAHtxSkbKeM5xtBk5cDYkjgWwdYYKCMsVuHXEFXm5",
  },
  "447-dahlia-drive": {
    name: "447 Dahlia Drive",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "J9T8G7Y8iCXbtbncyNXbtCjHm3MqDCbFVZEuoNbC6Z7M",
  },
  "447-grand-avenue": {
    name: "447 Grand Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "grand-avenue",
    tokenAddress: "5RW6nySyrUuXHL6mCh5TkNhUT5cwmPpBEN4P3VpE4fxE",
  },
  "448-bamboo-boulevard": {
    name: "448 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "51TRRoU6AdivqszFChWLzPnzTAY2C3vrVgi3sBAVMKqy",
  },
  "448-block-hills-drive": {
    name: "448 Block Hills Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "block-hills-drive",
    tokenAddress: "JnfMNZrKsQv16uhPkuNxS4ThxeD9M8wsXPo9tVsa4m4",
  },
  "448-grand-avenue": {
    name: "448 Grand Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "grand-avenue",
    tokenAddress: "44SLiWYScsCYQFEemu3j3RhCz6kR1VAVS3PUSAQ8Enqp",
  },
  "448-grime-lane": {
    name: "448 Grime Lane",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "grime-lane",
    tokenAddress: "FJqkESBs7PRcuuUsKpW1hyvHKgq7GgtNZEzd2ZVaN2LA",
  },
  "448-lamport-lane": {
    name: "448 Lamport Lane",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "AqMRn4qo8d1DH7qnerwz6kbEjQ5LHX8hSWPoXYHxQeFy",
  },
  "449-bamboo-boulevard": {
    name: "449 Bamboo Boulevard",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "9puJoyDbqA5Y4EdnQdG12ZrBea79gPCz3fuvLbF4JJpn",
  },
  "449-block-hills-drive": {
    name: "449 Block Hills Drive",
    biome: "treehouse",
    frameType: "shuttered-green",
    streetId: "block-hills-drive",
    tokenAddress: "CMwLz5Y9UsV8SqPaQ8yfuhiLkJ44KpimaP6Te3AFNvZ5",
  },
  "449-first-avenue": {
    name: "449 First Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "J4GwLLQFA9NeJjkUpfreQ7WcmyG7gAJKXiE16P3GFVpV",
  },
  "449-lamport-lane": {
    name: "449 Lamport Lane",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "lamport-lane",
    tokenAddress: "BZZCGY4aFHEGZK8DiyWkW8iiLzCXkqvFVHm69o1qGWmE",
  },
  "45-ape-lane": {
    name: "45 Ape Lane",
    biome: "city",
    frameType: "rounded-dark-green",
    streetId: "ape-lane",
    tokenAddress: "8u35agvGySi9S5k7mgrZmonqyPq3kvxP7Q4Rdyr267pG",
  },
  "45-groove-path": {
    name: "45 Groove Path",
    biome: "woods",
    frameType: "glass",
    streetId: "groove-path",
    tokenAddress: "ChgckGgUuHnjicM5vQVwimBsyQZ9DkyK4DrWFCabxjAZ",
  },
  "45-rosa-street": {
    name: "45 Rosa Street",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "rosa-street",
    tokenAddress: "7cei1BVgkniscs9XmFi7RCMvGuzwR2ZUJizCNa3LDBzn",
  },
  "45-supple-circle": {
    name: "45 Supple Circle",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "supple-circle",
    tokenAddress: "5cKr9spDQDHiwJBa8K2qpCFgUfdrdVCg9ZQujPkiA2GA",
  },
  "450-first-avenue": {
    name: "450 First Avenue",
    biome: "arctic",
    frameType: "rounded-pale-green",
    streetId: "first-avenue",
    tokenAddress: "4mj1cPAcVGR5pmZLjgwRGBQUbUHoei8JQ5LCYhyzvfsC",
  },
  "450-grand-avenue": {
    name: "450 Grand Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "grand-avenue",
    tokenAddress: "HTPMSqV5WnfR7vcGP2XmCkfn6jQshmd1u4ee3PMJHosF",
  },
  "450-grime-lane": {
    name: "450 Grime Lane",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "grime-lane",
    tokenAddress: "DcM6gfWTy8QmGaD2JS6HuYizxbnS5xiWU5ovRg8g16Nz",
  },
  "450-lamport-lane": {
    name: "450 Lamport Lane",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "lamport-lane",
    tokenAddress: "9faaFxYwXsovbaddxFt5WdCCgxz4bx1nia2xvFaBHmNT",
  },
  "451-bamboo-boulevard": {
    name: "451 Bamboo Boulevard",
    biome: "city",
    frameType: "occult",
    streetId: "bamboo-boulevard",
    tokenAddress: "9uyeZHNxKaM8zVru4U9r7R9syfyKDtJsnVYESnyBPUQ5",
  },
  "451-dahlia-drive": {
    name: "451 Dahlia Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "VRgQ7maeH4k7MnBcw3TrSeNnjgibas4MknukzxpFrrd",
  },
  "451-first-avenue": {
    name: "451 First Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "6GxwQ7NmChqeuWkHdY8sdcfGTqyY7MTeTNacX6YLAA6V",
  },
  "451-grime-lane": {
    name: "451 Grime Lane",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "xWq1iCqdX6hRfEQHjEJrC8zdSeBCUoMAbFFfxRNQFW9",
  },
  "452-dahlia-drive": {
    name: "452 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "3THuW56zM8ZJQyDd6duuzY3Re6nPFEbpPa2Ys1MCqM5K",
  },
  "452-first-avenue": {
    name: "452 First Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "first-avenue",
    tokenAddress: "4M34LzoSQC2yqk7zjHo23NxaE99dhsw7MGaU7sfYujEQ",
  },
  "452-grime-lane": {
    name: "452 Grime Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "2Apvz2PpJ8PEvCnKkDY98AzJ2U6eohrhTbZQw7Rkhf4W",
  },
  "452-lamport-lane": {
    name: "452 Lamport Lane",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "lamport-lane",
    tokenAddress: "AfmAQU1D8X6aiBjKTMtV6vJzbQhmLs8NtS5fSZQ999xm",
  },
  "453-bamboo-boulevard": {
    name: "453 Bamboo Boulevard",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "APBQHo5E8n3BRfxzB59N9QT21x1MjkMgyrRKEfYEE2P4",
  },
  "453-first-avenue": {
    name: "453 First Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "HUGxffUS9pKTNqxbwqQsgL6AR4DVTjPYWUEk9pE3RX4",
  },
  "453-lamport-lane": {
    name: "453 Lamport Lane",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "lamport-lane",
    tokenAddress: "ENB2DhBUxC5XNAGN6QMw88wS9JdGupz5ZM8ea8jugVx2",
  },
  "454-grime-lane": {
    name: "454 Grime Lane",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "grime-lane",
    tokenAddress: "C6eCuKNbRfFnKUK4ysu865UfTVuzfR2cyBXZTmVCeSvd",
  },
  "454-lamport-lane": {
    name: "454 Lamport Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "6fMXMPansx7x6ZhT3RF1ezf5kJCYZ7HJkGvC4nxP4PjJ",
  },
  "455-first-avenue": {
    name: "455 First Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "first-avenue",
    tokenAddress: "EMWh1jjfZtwpsRPoicUturrVuBPPAHWYAY2gyQx5vDrg",
  },
  "455-lamport-lane": {
    name: "455 Lamport Lane",
    biome: "beach",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "2nwffPJnMnUvSqaVZP9hkXA6rdGrZXaHraDfKf5t8PVS",
  },
  "456-bamboo-boulevard": {
    name: "456 Bamboo Boulevard",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "DFB2xmekfBAzrfQrAa7TdiDBRQRDSeerLneWtA5PMVB4",
  },
  "456-first-avenue": {
    name: "456 First Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "first-avenue",
    tokenAddress: "GxkAN5R5jTQJrmowyvNdbyQS7ow55hDPgVUU4vGK6gbh",
  },
  "456-grime-lane": {
    name: "456 Grime Lane",
    biome: "city",
    frameType: "occult",
    streetId: "grime-lane",
    tokenAddress: "6xqQ6DEdTc9nyaHPv69Ni749pR95eS5kNcWiqkAZCKyZ",
  },
  "456-lamport-lane": {
    name: "456 Lamport Lane",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "EWb5ZqAwddriiTTsA5LiK1Zcm1jVFtWLaop3K5Bscxd9",
  },
  "457-bamboo-boulevard": {
    name: "457 Bamboo Boulevard",
    biome: "arctic",
    frameType: "shuttered-lavender",
    streetId: "bamboo-boulevard",
    tokenAddress: "BrSFG8iHWMuovZ34XFXfRypoytp88hUESmTLoPnmin8y",
  },
  "457-grime-lane": {
    name: "457 Grime Lane",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "grime-lane",
    tokenAddress: "6urSATQTe7UXy7L85FtwKHTyZPiHwvyNEHe8cQzuqeUU",
  },
  "457-lamport-lane": {
    name: "457 Lamport Lane",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "lamport-lane",
    tokenAddress: "AsMk6MptxRV3UFLXz2A5wWMjdz7fNZZqZXuyeNdq9fKd",
  },
  "458-bamboo-boulevard": {
    name: "458 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "DLbhhAendxV2ZHM1ZeYH7GTA2hKjk8eJvuRgSrZcqfgF",
  },
  "458-grime-lane": {
    name: "458 Grime Lane",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "grime-lane",
    tokenAddress: "3bcjYoerCaffat6mJV2tCGLrbfU82sFJSbj3a2RPtsoj",
  },
  "458-lamport-lane": {
    name: "458 Lamport Lane",
    biome: "volcanic",
    frameType: "gold",
    streetId: "lamport-lane",
    tokenAddress: "AJ15tm3ZKhUEqWHHHWQtHS6povhmisT3apv3oByH8Dsi",
  },
  "459-bamboo-boulevard": {
    name: "459 Bamboo Boulevard",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "7JoGUAkjZKZrNx9KiozvFEWzP7gZirrEYfcv4M4DPtRB",
  },
  "459-grime-lane": {
    name: "459 Grime Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "grime-lane",
    tokenAddress: "CRHzy5UjLN4XmFt9t2uar6cfAXdPFUefMq4xbGY8PiVg",
  },
  "459-lamport-lane": {
    name: "459 Lamport Lane",
    biome: "desert",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "6yJ1erkiTAyxiqdXE4BjnayK3nNnkfvNzua1xvwHrFuw",
  },
  "46-ape-lane": {
    name: "46 Ape Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "HW7mHvKKBNA9aWnG9o5sHgCFasz4sbmq7jtGffSyVGxR",
  },
  "46-rosa-street": {
    name: "46 Rosa Street",
    biome: "mountain",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "6XUyYFURhAuTssxeEAetzcXC44wKuLNELxAqHXfpF5YA",
  },
  "46-stewart-avenue": {
    name: "46 Stewart Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "6Yc2evcJduo3Ea8esswqq2mQDMrBiygqK2oMndhWcJtw",
  },
  "46-supple-circle": {
    name: "46 Supple Circle",
    biome: "city",
    frameType: "rounded-green",
    streetId: "supple-circle",
    tokenAddress: "8q7NRKbtzQXPRyEmZ1YBHMVK5Q48N9y5S6G1TTeZpeqq",
  },
  "46-teeps-lane": {
    name: "46 Teeps Lane",
    biome: "arctic",
    frameType: "rounded-green",
    streetId: "teeps-lane",
    tokenAddress: "BajsYrQXspXoavahD2NHQTg6u2V1ukorv6bvXCXMUTSj",
  },
  "460-grime-lane": {
    name: "460 Grime Lane",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "grime-lane",
    tokenAddress: "6BnzWPmAUrfhtbSi8e3JUEEa47CnD3juzS5NpJLvvQzF",
  },
  "460-lamport-lane": {
    name: "460 Lamport Lane",
    biome: "underwater",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "AMgsodWyY2u2qAXm6pT3bM8Wi7kps45p53PvmQsQRNfJ",
  },
  "461-bamboo-boulevard": {
    name: "461 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "HUd6WxStps6fFtAiRmMW7dUML7QnyknzyvperyMr63Z9",
  },
  "461-dahlia-drive": {
    name: "461 Dahlia Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "7FaXyYR8PdJrvq3WsXitZzixyMa9AMzCrmpNq6zzdgLo",
  },
  "461-grime-lane": {
    name: "461 Grime Lane",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "5szy7xyBcqEuLXeqhzLiUKLKeraFxN3uHqE92ryyhU5V",
  },
  "462-bamboo-boulevard": {
    name: "462 Bamboo Boulevard",
    biome: "treehouse",
    frameType: "rounded-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "HPkPWQ9CuVhTX3asy6zh85zfq3f4xPciYRNGY85pi1hn",
  },
  "462-dahlia-drive": {
    name: "462 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "wpMkr9n9KuJQa7naKBM4vJ4HypcZRUviC8j5ToSSh9Y",
  },
  "462-grime-lane": {
    name: "462 Grime Lane",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "GkhNi8nS4ka6MEjUsJ2EPbspbhcnNT5Y28NSTdtrKWGk",
  },
  "462-lamport-lane": {
    name: "462 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "89tS7zMx2ATXRwXhGo2m6Fo3qzG976M62Uxp4fZdT2fw",
  },
  "463-dahlia-drive": {
    name: "463 Dahlia Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "DeQXYJbAUfRU1P1gPueJv3pJvGVmjLBwCesAe5zv1EAT",
  },
  "465-grime-lane": {
    name: "465 Grime Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "grime-lane",
    tokenAddress: "qG2GGr5omq9dh7noVXfXNu3LQjazUbj4YzDrV9EZZuN",
  },
  "465-lamport-lane": {
    name: "465 Lamport Lane",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "5k4zYDwXkrZ62RaxobTv4KdSgWyUbC7wgXfffGbYfWoT",
  },
  "466-bamboo-boulevard": {
    name: "466 Bamboo Boulevard",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "7jyPD6kf3detURa5WwXFpwAcm4uEWt21AfJ7fqPTLJn7",
  },
  "466-dahlia-drive": {
    name: "466 Dahlia Drive",
    biome: "beach",
    frameType: "rounded-lavender",
    streetId: "dahlia-drive",
    tokenAddress: "4BWDAYc3U9NGzyQSACxrarTcfKE3xNzhZqHKWgt6n39q",
  },
  "466-grime-lane": {
    name: "466 Grime Lane",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "grime-lane",
    tokenAddress: "AnbuivdAuAVmLm4tHYHCFg25oHEnLFLSnSQmTjxrYs4x",
  },
  "466-lamport-lane": {
    name: "466 Lamport Lane",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "lamport-lane",
    tokenAddress: "7t9p84RHNRYJYGwJkjBcKRij59N5bp6TnDQhkExrzGSk",
  },
  "467-bamboo-boulevard": {
    name: "467 Bamboo Boulevard",
    biome: "beach",
    frameType: "occult",
    streetId: "bamboo-boulevard",
    tokenAddress: "3iyx3XVeJgeZxDyqWhUmEcxXUYxFkuDyjg34ZPmiE9Ji",
  },
  "467-lamport-lane": {
    name: "467 Lamport Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "8FJE2hwEqR9UujVtmz8AEdrgcwGYY26PG8qnDUU7P1Sc",
  },
  "468-bamboo-boulevard": {
    name: "468 Bamboo Boulevard",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "bamboo-boulevard",
    tokenAddress: "4EJhJ2vLBo3xyM8pKw3B6XMyhFRiU8x8oUvZ4bjE6cne",
  },
  "468-grime-lane": {
    name: "468 Grime Lane",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "grime-lane",
    tokenAddress: "6B1Djc58qeGESZwuT9ke5hBcrA6yrNkA1ccYKgbDEjM2",
  },
  "469-grime-lane": {
    name: "469 Grime Lane",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "grime-lane",
    tokenAddress: "5QqSDJFA5iuoxo8AuxTsibpr2B3CJgJLVZkcyhCF4UoQ",
  },
  "469-lamport-lane": {
    name: "469 Lamport Lane",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "lamport-lane",
    tokenAddress: "HCft2QgWyP9zJmoufeWz41teFhn7MkEwbsa5xjPxe7fM",
  },
  "47-rosa-street": {
    name: "47 Rosa Street",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "BoAcQzHGhWTtMrduZUCafaYY8vDSkWpHzAef5oQ36jxo",
  },
  "47-stewart-avenue": {
    name: "47 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "stewart-avenue",
    tokenAddress: "2p1YVTzuuX3zgNS97LK5BWvrcinW2QQK84qucjahWMgF",
  },
  "47-supple-circle": {
    name: "47 Supple Circle",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "supple-circle",
    tokenAddress: "DLvkUVP6YbGnDcfZ6ghUkihMVN7HiKUtj3juhDjHu3Fr",
  },
  "47-teeps-lane": {
    name: "47 Teeps Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "GvKTb5b5yrwKjrDbnuyF9yfVtut1dqek17EGFZuNVvnU",
  },
  "470-bamboo-boulevard": {
    name: "470 Bamboo Boulevard",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "4jU2ctioQVe2UNURknWQ7vpFXYo92sGPSiHjZxJrcgrz",
  },
  "471-dahlia-drive": {
    name: "471 Dahlia Drive",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "8B1ASQ6WDJ8vB8CmXhdZhjqRRGQhgpWKZk4JUEqNfBXj",
  },
  "471-lamport-lane": {
    name: "471 Lamport Lane",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "lamport-lane",
    tokenAddress: "28CPm7sJvpzyKRfiuZjPLwe55zHpKJuFUSZXtLSn239g",
  },
  "472-dahlia-drive": {
    name: "472 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "65sxRrbzvkbMmEwE4xk85wLVu94gwpEVhnDAUic4G71y",
  },
  "472-lamport-lane": {
    name: "472 Lamport Lane",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "lamport-lane",
    tokenAddress: "4LHoPgWqDxoyRSfYu35uLUnvpPwcJGiJRiJ8qkjQWHgu",
  },
  "473-bamboo-boulevard": {
    name: "473 Bamboo Boulevard",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "CcSjEMTrSBhff6oXCbbNKoHrZHnbrtGDKnuatRTNsXee",
  },
  "474-lamport-lane": {
    name: "474 Lamport Lane",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "lamport-lane",
    tokenAddress: "48k7KYoViai44Yc4TiH8kb26mfU9muGjRUBePKTKXxSk",
  },
  "475-bamboo-boulevard": {
    name: "475 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "G7RcJfj7nBqeuy5q9oEy1utn2fkXpMCdgqabRF43crto",
  },
  "475-lamport-lane": {
    name: "475 Lamport Lane",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "BRQ98pL4vz7GAiSuon3cfp2PH6CcHeHvzf35Z7Chi1xf",
  },
  "477-lamport-lane": {
    name: "477 Lamport Lane",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "lamport-lane",
    tokenAddress: "DFWDjQEL29Xr4sqSgtdKmKytCw7QdXsj3ri4bhwMhY6s",
  },
  "478-bamboo-boulevard": {
    name: "478 Bamboo Boulevard",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "bamboo-boulevard",
    tokenAddress: "8NaWrqRskb7atWpr5aMXJK2vR5nLLTHo6LNmFFKXD3bz",
  },
  "479-bamboo-boulevard": {
    name: "479 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "bamboo-boulevard",
    tokenAddress: "9DRMMfaeEW8XnmwR3e5iorqdPryT6rmrCL9muzxzxQGJ",
  },
  "479-dahlia-drive": {
    name: "479 Dahlia Drive",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "dahlia-drive",
    tokenAddress: "4VUGwapA2BDHZMNpNboquwYASyiTZmycUaKiZ4BpoAuK",
  },
  "479-lamport-lane": {
    name: "479 Lamport Lane",
    biome: "underwater",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "44mM5RPC4vLth2yK1rg9GSu6CKy29voSrPWsUGSJXiKw",
  },
  "48-ape-lane": {
    name: "48 Ape Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "8gZBjPbmYntAVBLvziF8EDCN7bcP6LJbLydhr6dyqQ27",
  },
  "48-stewart-avenue": {
    name: "48 Stewart Avenue",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "8o4YpE3h5pqsJXtrwV3qPo4GZ5KYSAGAyPcsgmqm2fUq",
  },
  "48-supple-circle": {
    name: "48 Supple Circle",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "supple-circle",
    tokenAddress: "BMeYUjkKcxaWzyGxmh3qE1Rfv5pDvP83HGLUqdNFrsFE",
  },
  "481-dahlia-drive": {
    name: "481 Dahlia Drive",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "dahlia-drive",
    tokenAddress: "3KM9ug5STFPcgTgCtWuNV4Sc3U5u3RSt8aJecBDt87YR",
  },
  "481-lamport-lane": {
    name: "481 Lamport Lane",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "CgoFJeW3AAbX6hknTo7QSFJazVJAKA4CVV2CQpVrQUUf",
  },
  "482-dahlia-drive": {
    name: "482 Dahlia Drive",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "3HT64sbuHAZpEXNPEGzw3ZhtdwHkck6GsGQhuPbExyf4",
  },
  "485-bamboo-boulevard": {
    name: "485 Bamboo Boulevard",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "bamboo-boulevard",
    tokenAddress: "LQGUv7ezAGL5a2u7r5fNideMNTy3K6A1vAV2X5xChBC",
  },
  "485-dahlia-drive": {
    name: "485 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "4N2xFtz2DfQ32FucijfBChsrvF7TLDZR8k949B1Cs5am",
  },
  "485-lamport-lane": {
    name: "485 Lamport Lane",
    biome: "field",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "CpJYkoTx5DD5zd9fxCA5fHK5WV33rzpg2DVJ5NtMUJNn",
  },
  "487-dahlia-drive": {
    name: "487 Dahlia Drive",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "E1ZA3J11YuoexUzg8tWRmjyoskZ2D2FWApTWUD5igYvZ",
  },
  "487-lamport-lane": {
    name: "487 Lamport Lane",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "lamport-lane",
    tokenAddress: "9xw4NPdvvBeRx16RgKUnVHV9WKSe9HNnK6vPsFb2Un7c",
  },
  "488-lamport-lane": {
    name: "488 Lamport Lane",
    biome: "woods",
    frameType: "rounded-lavender",
    streetId: "lamport-lane",
    tokenAddress: "6BSyLLpKPfZXzEC4bAfSh2AVjS54iBZH4gs1tMv2jb9h",
  },
  "49-rosa-street": {
    name: "49 Rosa Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "rosa-street",
    tokenAddress: "5N4uNk8Ri5vr8aEvSdmEW8ZY3N1KbSeEnRCA3KnR2das",
  },
  "49-supple-circle": {
    name: "49 Supple Circle",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "37PY9bGvCRHm4KcGWvFYW6y1tZowcq4iqQ8tYqMNJQLS",
  },
  "49-teeps-lane": {
    name: "49 Teeps Lane",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "teeps-lane",
    tokenAddress: "Av2NPCQCWKtVqoNBe5xdvecRM2RfxjeHGvWwDsttngt8",
  },
  "490-dahlia-drive": {
    name: "490 Dahlia Drive",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "7GDeXu9TAB8eRqeBhCFSdDFxxC7EGYJhcsV72CpThVgC",
  },
  "491-lamport-lane": {
    name: "491 Lamport Lane",
    biome: "volcanic",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "217dWFtXHfZneNCFLjusy2pnz75ej1xs8a2VMNm4ymDx",
  },
  "492-dahlia-drive": {
    name: "492 Dahlia Drive",
    biome: "underwater",
    frameType: "occult",
    streetId: "dahlia-drive",
    tokenAddress: "G6e7oWuPF1LXKoxV8DpX6t6zGAJpXuUwZc7oHnNaKEEe",
  },
  "493-lamport-lane": {
    name: "493 Lamport Lane",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "G1NJjAhZpicwCSUpHo9vaDvJSRqjHXBFxXS3c4PZHDwt",
  },
  "495-dahlia-drive": {
    name: "495 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "dahlia-drive",
    tokenAddress: "7Ah421YbtduiMz5gpRr1RSLxkCiqA6ok9YpqCBVYqBii",
  },
  "496-dahlia-drive": {
    name: "496 Dahlia Drive",
    biome: "treehouse",
    frameType: "shuttered-dark-green",
    streetId: "dahlia-drive",
    tokenAddress: "5oGhbVj3YpYSqFfFBAMLNgdkrAiSFkJS8LzEifccBuLB",
  },
  "496-lamport-lane": {
    name: "496 Lamport Lane",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "HWHCLYhf4YzohyZethzhEttrymtBKveoSJ5fPuxbyqXq",
  },
  "497-lamport-lane": {
    name: "497 Lamport Lane",
    biome: "field",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "ESx6SD7iWp974Khq6kbFPPmvQGW74HMfoZazFM5oTKLA",
  },
  "498-dahlia-drive": {
    name: "498 Dahlia Drive",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "CAouqhdMmDxhd7hCV7UG2wnmNyvLcwkvngzpLZbBFHMd",
  },
  "498-lamport-lane": {
    name: "498 Lamport Lane",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "lamport-lane",
    tokenAddress: "HtyNfBXfuiD8W9xf35trrZ7ugofppv4pQW14aauGxqst",
  },
  "499-lamport-lane": {
    name: "499 Lamport Lane",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "lamport-lane",
    tokenAddress: "4UjeJbqKycRZeMFJ4pCMUrajjc7NWX13M7Zf6kitzfEs",
  },
  "50-rosa-street": {
    name: "50 Rosa Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "rosa-street",
    tokenAddress: "22RfyNfKvvPNNb3p4jut34TG3HPiGmoJKbfVTyWCgooP",
  },
  "50-stewart-avenue": {
    name: "50 Stewart Avenue",
    biome: "underwater",
    frameType: "crystal",
    streetId: "stewart-avenue",
    tokenAddress: "B4oM35Q9AerucJpftk5gNhJMyUzM1VGG1VmwUinHb1ci",
  },
  "50-supple-circle": {
    name: "50 Supple Circle",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "supple-circle",
    tokenAddress: "F8Jndu1yaEXBZQn8tZJ6GXWg4KnfWKMo9Eg6u121e1hn",
  },
  "500-lamport-lane": {
    name: "500 Lamport Lane",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "lamport-lane",
    tokenAddress: "4Gej1czSB9cGzjLEuHbRDZLEQkSeHChvNYS3QeYB4Kdi",
  },
  "501-lamport-lane": {
    name: "501 Lamport Lane",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "lamport-lane",
    tokenAddress: "Hf6sxxs3QmHUzqdGsRpWYYPQwshEaFUL543oJ7SsXmMA",
  },
  "503-dahlia-drive": {
    name: "503 Dahlia Drive",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "3DJJyw2iRs7qttEUJLiAWKuc8HWSrAvebcBWWeUZ24Eu",
  },
  "503-lamport-lane": {
    name: "503 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "Ezg8pcY6JMgrfEA1LBLovnPw666B9Krd6ibx9w8aE9aj",
  },
  "504-dahlia-drive": {
    name: "504 Dahlia Drive",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "7BTUnvc5Sp2nLUYQ83JNJskHPRQ5D852P972RT3WeGBT",
  },
  "505-dahlia-drive": {
    name: "505 Dahlia Drive",
    biome: "mountain",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "4PMb5M6htmYhr5cogmaVfFGtVFPW7yeeatT2e3dgg2xq",
  },
  "505-lamport-lane": {
    name: "505 Lamport Lane",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "lamport-lane",
    tokenAddress: "2o7kPxzmEeeebqkfnESdi4VCofaD1mWmdXVsfutLrrmi",
  },
  "508-dahlia-drive": {
    name: "508 Dahlia Drive",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "E68aZHA7FwePArDKxxjNhVfz3vTPgBXuVzaVDaamD7GX",
  },
  "508-lamport-lane": {
    name: "508 Lamport Lane",
    biome: "beach",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "5U9jwUeoVFt1up69JdqQcHgtGFdmyGrHmthboAhkNVbE",
  },
  "509-lamport-lane": {
    name: "509 Lamport Lane",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "lamport-lane",
    tokenAddress: "3CbqRCAdd63WtssDSbuvQBXJ1sfSCCRCxPCq9oY7U9Ws",
  },
  "51-stewart-avenue": {
    name: "51 Stewart Avenue",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "FVv9TMsSuN22pmzZTm8NnNYGcfgE6vhNXV9xa1WezNBP",
  },
  "51-supple-circle": {
    name: "51 Supple Circle",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "GjSJRscrpBFPqkPseTBpJaK4AP5TDFR5HFmMJGarjLmX",
  },
  "513-lamport-lane": {
    name: "513 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "9jimvLg57Z2vU37Qfyw95RVzFcEHBEiv5J4X6us39vYF",
  },
  "514-dahlia-drive": {
    name: "514 Dahlia Drive",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "4h7CyrSr6g5ZZbpkUPmLmxw1QWm8PVJw3LdgNchqSsyg",
  },
  "514-lamport-lane": {
    name: "514 Lamport Lane",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "lamport-lane",
    tokenAddress: "3f7AofR46UFetSyhAG72i2pFKFbw8h6PxWcM6sjrcxhW",
  },
  "515-lamport-lane": {
    name: "515 Lamport Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "lamport-lane",
    tokenAddress: "5jsaPFY24dy1Ct7qBSa5eXbu5kdEg2FtHw3SurpXUN3w",
  },
  "516-dahlia-drive": {
    name: "516 Dahlia Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "DnvprUnvRGo4J9NU2Ne5rUbSaAhFWSbG23grq7WJ5pmP",
  },
  "518-lamport-lane": {
    name: "518 Lamport Lane",
    biome: "field",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "CCQuXqHN9LUmv4KBt9psZskdMnKzzU77q7XUA9sAPPar",
  },
  "519-lamport-lane": {
    name: "519 Lamport Lane",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "lamport-lane",
    tokenAddress: "GnB3dAdZiRk8vsfitcp2inTrev3wbMcf6KWCPmbNZzmH",
  },
  "52-ape-lane": {
    name: "52 Ape Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "BJ9DepuotJjRATGokjD1gLbX553U16rnNU6r8Wz2BwT3",
  },
  "52-king-street": {
    name: "52 King Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "king-street",
    tokenAddress: "B1NXAK3BbQLUsFRhqHE4Npw7HUk5imWCT1fsrmYfN3ux",
  },
  "52-stewart-avenue": {
    name: "52 Stewart Avenue",
    biome: "arctic",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "6vkzh5ijDYjKeZ4J1MMaNAiBP7nBw55hRKhLtAR2rSQw",
  },
  "52-supple-circle": {
    name: "52 Supple Circle",
    biome: "beach",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "36tzR57wCSKU9AacqppwZdvWXXTFBRa8EK2mRBKoiXwc",
  },
  "522-dahlia-drive": {
    name: "522 Dahlia Drive",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "HK2ww1aRJxhwXiiEzTtyrx9EYUDegT4zqtBz3S27bxSh",
  },
  "522-lamport-lane": {
    name: "522 Lamport Lane",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "lamport-lane",
    tokenAddress: "7hQ78fJj1fc2EYhtJWpNhrarBSoiWZNMDV7RJKMRFz8L",
  },
  "523-dahlia-drive": {
    name: "523 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "GXpf97td6F9QNF9HAiUb2LuJnaSz9v8jhDydChfDmqxx",
  },
  "523-lamport-lane": {
    name: "523 Lamport Lane",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "ER1nvYDPygh1yQAM6nSZXiTxN8pyGdD7dx4o85ejw2Rc",
  },
  "524-lamport-lane": {
    name: "524 Lamport Lane",
    biome: "field",
    frameType: "rounded-green",
    streetId: "lamport-lane",
    tokenAddress: "DVN8xKpjNBfgQRvGoa27NaeqLjvF4tRCFnSvV3XANath",
  },
  "526-dahlia-drive": {
    name: "526 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "EwUiKD6mHLWrD3rx2S8Eg45UFH9YM245JzM7BZhNekbz",
  },
  "527-lamport-lane": {
    name: "527 Lamport Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "lamport-lane",
    tokenAddress: "9Aqa55pL8oSr2Bhec2nu4jTzcATDDSX1nTytKuo8rXxt",
  },
  "528-lamport-lane": {
    name: "528 Lamport Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "lamport-lane",
    tokenAddress: "BDKzLcADnrgyweZMCAVSsVs5arnCTd5FoDAXaM2hjUYJ",
  },
  "529-lamport-lane": {
    name: "529 Lamport Lane",
    biome: "mountain",
    frameType: "occult",
    streetId: "lamport-lane",
    tokenAddress: "4Z22SA9A9UGmZjfVAFPZXaTdaTrPmGGeC3YcHt7Y92bg",
  },
  "53-abbey-road": {
    name: "53 Abbey Road",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "abbey-road",
    tokenAddress: "7PakRKoxqb2JsNvGYxGs5P813n3iMGQg26xg4nLR66aK",
  },
  "53-ape-lane": {
    name: "53 Ape Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "BGyFVYEKrKm2HQQUGErWt8XpApM9K2K8jJ2PT8NXdfyy",
  },
  "53-king-street": {
    name: "53 King Street",
    biome: "underwater",
    frameType: "occult",
    streetId: "king-street",
    tokenAddress: "EPAawrc3iaB4uXJSqsmW75VTCJaFzm7KEidXHHdpJUQc",
  },
  "53-rosa-street": {
    name: "53 Rosa Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "82FtCP7CfgzAwUKVtKvk7JPRNwG6yNx4pobSiKZ2cpPW",
  },
  "53-stewart-avenue": {
    name: "53 Stewart Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "DgWyovA3uJw11Jh1rTJ4pBpg4Pzp5Zc9ZN95q5vyADXv",
  },
  "53-supple-circle": {
    name: "53 Supple Circle",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "A1Mnp77BPGSeg3dadAPfpU6kBxKCKGtuHe6hxnqF54rC",
  },
  "530-dahlia-drive": {
    name: "530 Dahlia Drive",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "dahlia-drive",
    tokenAddress: "HduFWGUPB8wrriPi7EiedP4fjvew19HL3oCCq9rtJBTY",
  },
  "531-dahlia-drive": {
    name: "531 Dahlia Drive",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "EAvXhA9he8RBUUGMBMvySKMciZRr5YPttxBpoHiWW2gx",
  },
  "532-dahlia-drive": {
    name: "532 Dahlia Drive",
    biome: "city",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "Ca7D2UZywV9iTTdNwbtkmVueNFZRajmfdVwh467Z4i43",
  },
  "532-jane-jacobs-way": {
    name: "532 Jane Jacobs Way",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "7Gkgeb5unD1GfkWftek7vacvk57vYBjEdbHQMCuR37ig",
  },
  "533-dahlia-drive": {
    name: "533 Dahlia Drive",
    biome: "volcanic",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "EGCa6dUvrLzqr3zs1TRXPRKpdvcCZKBJLjquhSCeGte3",
  },
  "533-jane-jacobs-way": {
    name: "533 Jane Jacobs Way",
    biome: "underwater",
    frameType: "glass",
    streetId: "jane-jacobs-way",
    tokenAddress: "HFA6HmgQeFpHLwL9RUDd7z839hKqwu2NkPH76czc1NLz",
  },
  "534-dahlia-drive": {
    name: "534 Dahlia Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "6X6wmwsb1xhZzkgMNRUDfqpHxrKaVaUogRMdVdSJLc7t",
  },
  "534-jane-jacobs-way": {
    name: "534 Jane Jacobs Way",
    biome: "field",
    frameType: "crystal",
    streetId: "jane-jacobs-way",
    tokenAddress: "7zVTpJA6FRduA9wxxEtmqemAhX4JrewogY1J1QgiwXga",
  },
  "535-jane-jacobs-way": {
    name: "535 Jane Jacobs Way",
    biome: "woods",
    frameType: "glass",
    streetId: "jane-jacobs-way",
    tokenAddress: "2TtLRqQuZFY6ExSd69fmXFkcaDgzTJjiKQbVEm6JxHp6",
  },
  "535-lamport-lane": {
    name: "535 Lamport Lane",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "lamport-lane",
    tokenAddress: "5Mwa5xU3hfUMtLuaJfRJgwSSDJ1PAdiborQvfmCSYFKx",
  },
  "536-jane-jacobs-way": {
    name: "536 Jane Jacobs Way",
    biome: "field",
    frameType: "glass",
    streetId: "jane-jacobs-way",
    tokenAddress: "Cu2B1UbVXewv7W5LS57CiTnfeiRKXFxdEKx8BSNVUsiB",
  },
  "537-jane-jacobs-way": {
    name: "537 Jane Jacobs Way",
    biome: "field",
    frameType: "rounded-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "7nSWm6spVGZMVnRYhaPb9xuwUhRcG85975GiFZSnFYAa",
  },
  "538-jane-jacobs-way": {
    name: "538 Jane Jacobs Way",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "8wHHKhaLW3afQyvaaVuEHWtPbGMNqGXSzy5MTT9rgS32",
  },
  "539-dahlia-drive": {
    name: "539 Dahlia Drive",
    biome: "arctic",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "GpuzayFuHnczDDmoW8RyzAES4X5trt817vs3gpxJFzbf",
  },
  "54-abbey-road": {
    name: "54 Abbey Road",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "abbey-road",
    tokenAddress: "7mfCKEs7Bc97c74YBsaBGrYcGAwY6F7K6toYXgJ8ZG3B",
  },
  "54-ape-lane": {
    name: "54 Ape Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "E7P91Wwpx4darQYxrdNB3fBBBf9vhDGdpAYesQ1fbKD3",
  },
  "54-king-street": {
    name: "54 King Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "king-street",
    tokenAddress: "5XZ6LPVq7KgYdCrxQAidjPgX1Xq4YJn3hLM3Ly7SGLHi",
  },
  "54-rosa-street": {
    name: "54 Rosa Street",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "rosa-street",
    tokenAddress: "G4xVdwkCFZXwXX9bcrJxZaUGBrcMzG85nnW663dNtzn4",
  },
  "54-stewart-avenue": {
    name: "54 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "CsUVAp2ChgiP9KwNHaQphp5qjGYwenRupuG35EEh82jb",
  },
  "540-dahlia-drive": {
    name: "540 Dahlia Drive",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "HC2MQNuzC7vvow4oMx13gHTwgsWbtyjDBNDWDG3fhrQZ",
  },
  "540-jane-jacobs-way": {
    name: "540 Jane Jacobs Way",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "jane-jacobs-way",
    tokenAddress: "5fRycsXQVW9CqwrNqnmPpgeQg3NnRUxNeWFti7aRPug4",
  },
  "541-dahlia-drive": {
    name: "541 Dahlia Drive",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "WSndBudzLFWgJoAxSKVNhhJZjvGjgP5BF4Pe5zMiqBX",
  },
  "541-poplar-street": {
    name: "541 Poplar Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "3MQzLXiGzA8Jya54RznHhWVxEWssHDNMUA2LMHEUN5tG",
  },
  "542-jane-jacobs-way": {
    name: "542 Jane Jacobs Way",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "jane-jacobs-way",
    tokenAddress: "PKBNSLy3jQHTq4bJJcXUfpUbKR71DWxFGGtuCRaY4t8",
  },
  "543-dip-drive": {
    name: "543 Dip Drive",
    biome: "woods",
    frameType: "gold",
    streetId: "dip-drive",
    tokenAddress: "D7Tkh1zKxU1miwXutKmrZeJyBb325qYDsFxLrr3aqJoT",
  },
  "543-jane-jacobs-way": {
    name: "543 Jane Jacobs Way",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "jane-jacobs-way",
    tokenAddress: "629hPSnsMWwg6xVVM1cuQTB64pTwjR4AEMafG4FGaFhu",
  },
  "544-dahlia-drive": {
    name: "544 Dahlia Drive",
    biome: "beach",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "GM4vN1uf6vu2ymyRTaw1vSfzzUJHrERzjoV6Gky48Vju",
  },
  "544-dip-drive": {
    name: "544 Dip Drive",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "EXRg7PGyedxf9Bpwnza62vnHLTWELMmdueMuTiKkhZfH",
  },
  "544-jane-jacobs-way": {
    name: "544 Jane Jacobs Way",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "jane-jacobs-way",
    tokenAddress: "FtWeqVsWCQbLsnhukuRv3GhJ5MFdZcXgQuBcaEH26JiK",
  },
  "544-poplar-street": {
    name: "544 Poplar Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "poplar-street",
    tokenAddress: "8ALTAUCSaPk7UfRDy4VRY1xUe4h8utkQ45kdDUHpjQa2",
  },
  "545-jane-jacobs-way": {
    name: "545 Jane Jacobs Way",
    biome: "city",
    frameType: "rounded-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "8fV7QuUV2ZuNfYQdkBjEYcmn24qNcp2JBsVUqAXcFieF",
  },
  "545-poplar-street": {
    name: "545 Poplar Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "poplar-street",
    tokenAddress: "3Xv7cADppVEYfNtdpxNV75ys4kHjpdKMTyhDW6ASAXD1",
  },
  "546-dahlia-drive": {
    name: "546 Dahlia Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "9f9mL2K1FGBqH6QM4o5n3q1pQiPf3iyQXE99cTEoL3oj",
  },
  "546-jane-jacobs-way": {
    name: "546 Jane Jacobs Way",
    biome: "woods",
    frameType: "crystal",
    streetId: "jane-jacobs-way",
    tokenAddress: "6wHbRXL9rJpNHeqrWyxZ2AgYG6tPZmKYfytrNiLVK9m6",
  },
  "546-poplar-street": {
    name: "546 Poplar Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "poplar-street",
    tokenAddress: "7eTBTycnrnisGTVd6Tqz7AWqpxAzRfYK8XBdk6ntJj2h",
  },
  "547-jane-jacobs-way": {
    name: "547 Jane Jacobs Way",
    biome: "field",
    frameType: "occult",
    streetId: "jane-jacobs-way",
    tokenAddress: "8vm7sUVUpGWwGFTUfZow4XYthE1fFztomQZ4zyD3Z3Pv",
  },
  "547-poplar-street": {
    name: "547 Poplar Street",
    biome: "treehouse",
    frameType: "shuttered-pale-green",
    streetId: "poplar-street",
    tokenAddress: "kpB7nKbfTmDdyXnP2YhNpQZxkkPjSA6wcxHMxB2Kti5",
  },
  "548-jane-jacobs-way": {
    name: "548 Jane Jacobs Way",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "5ubvWpiWQ8tzZ7rkZiVWzZT9qUpDW2zvNzCKsuLT9EqT",
  },
  "549-dahlia-drive": {
    name: "549 Dahlia Drive",
    biome: "city",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "H5Zn213xosovbGMAqjbmMy8JJEiin99kJCmYjwPNq1fw",
  },
  "549-dip-drive": {
    name: "549 Dip Drive",
    biome: "arctic",
    frameType: "shuttered-dark-green",
    streetId: "dip-drive",
    tokenAddress: "HdM1C7ZC4yV2GgJPKzbrjTJFPXKzSbtbkFR6LJQbd3Gn",
  },
  "55-abbey-road": {
    name: "55 Abbey Road",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "abbey-road",
    tokenAddress: "9i2sf8vLUn6ZrwaqXzHDKSKD13NQKfgVHck3t79B8JBL",
  },
  "55-ape-lane": {
    name: "55 Ape Lane",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "ape-lane",
    tokenAddress: "HNzyprxysjdjkd2SA7h3YVMbhKggm6oBvDHr3Ls99BR",
  },
  "55-king-street": {
    name: "55 King Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "king-street",
    tokenAddress: "D5CzLSwz6XhcHx15xS7RkB4VDFwfHTDUoH32DMJthowa",
  },
  "55-rosa-street": {
    name: "55 Rosa Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "rosa-street",
    tokenAddress: "6o7Jsk5caZHSs1Qt6weHt3ZZj6jujGC4foSHr2USju4a",
  },
  "55-supple-circle": {
    name: "55 Supple Circle",
    biome: "arctic",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "DPBVKcUi23pUeKjh2PRZNc42HTepXK8oPWgUfYkS4cgT",
  },
  "55-teeps-lane": {
    name: "55 Teeps Lane",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "teeps-lane",
    tokenAddress: "9pok5QdH9NvUs1bDniZSTURbcvwRsUtxoPiKRLQejMAT",
  },
  "550-dahlia-drive": {
    name: "550 Dahlia Drive",
    biome: "underwater",
    frameType: "rounded-dark-green",
    streetId: "dahlia-drive",
    tokenAddress: "5A3Kc5zUEDSrdcEYyNc8vxx4PFGMYDbQvX1nDuaKJFiu",
  },
  "550-dip-drive": {
    name: "550 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "3eVfCmovQ76KwhmYZMbSs8L9y8LC6pf17wnydYi7PUU1",
  },
  "550-jane-jacobs-way": {
    name: "550 Jane Jacobs Way",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "jane-jacobs-way",
    tokenAddress: "5FV8s76RSSzo3RvGZFaZrgwQaUSUm4FbhyDYnBf652wR",
  },
  "550-poplar-street": {
    name: "550 Poplar Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "39HPLEY9hom7kSCVYPtipv98vyu8NXcg292cV5gZvnQu",
  },
  "551-dip-drive": {
    name: "551 Dip Drive",
    biome: "volcanic",
    frameType: "shuttered-green",
    streetId: "dip-drive",
    tokenAddress: "8yd99jKUXoGfjX1baydJnGbuRkTAJHGVXDvrh85vFSwX",
  },
  "551-jane-jacobs-way": {
    name: "551 Jane Jacobs Way",
    biome: "underwater",
    frameType: "glass",
    streetId: "jane-jacobs-way",
    tokenAddress: "H8vk6PTFtETJuFPENqpt82b7GVAhJE9mXUFzdVqm8Yhn",
  },
  "552-poplar-street": {
    name: "552 Poplar Street",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "VyG2rAffxC4eLJQr1RwCp4NMyurSzWggQYbszPMBh2n",
  },
  "553-dip-drive": {
    name: "553 Dip Drive",
    biome: "woods",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "AkMW1AJ65fiVovtRGrCSYDTWmgdU63SaiRpCDttjLZqc",
  },
  "553-jane-jacobs-way": {
    name: "553 Jane Jacobs Way",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "jane-jacobs-way",
    tokenAddress: "5PmmvALUrCChJBgBv8LDWSgoeh8ZrEzC5MY1skfNZeJz",
  },
  "554-dip-drive": {
    name: "554 Dip Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "2JmBimhuGtHgXFoiMysYAEhbSZPPQG6D3hZM8pDck2ra",
  },
  "554-jane-jacobs-way": {
    name: "554 Jane Jacobs Way",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "BvQnCGn6BFjEiw3v3Vi64t4eYRjGc4TSXHJtsgf8WhfL",
  },
  "554-poplar-street": {
    name: "554 Poplar Street",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "68Nc4PTguGwPJ8gPu1rbbMf9YhKD77xG34VVV4pQe376",
  },
  "555-dip-drive": {
    name: "555 Dip Drive",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "dip-drive",
    tokenAddress: "86x7LqsjLUsty1wH481mxADT3fGXCYvCzF5BV8j36zXJ",
  },
  "555-jane-jacobs-way": {
    name: "555 Jane Jacobs Way",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "923p3PayPPFpP2mjzcoSbPvCKxfgUZHwF3XvxNePgqgt",
  },
  "556-dip-drive": {
    name: "556 Dip Drive",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "dip-drive",
    tokenAddress: "GKv3jTVLfkiKYK82HpNpk5qCAbKyu39ECRnv7kfxzEbU",
  },
  "556-jane-jacobs-way": {
    name: "556 Jane Jacobs Way",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "79HFBfS35KsVEGU81njpskDwa7GGHeUgUnnin8KmW2U6",
  },
  "556-poplar-street": {
    name: "556 Poplar Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "poplar-street",
    tokenAddress: "7hvosyLxNMftfaR35w573wC4FgxjJcVv7hdEPvhkKZAi",
  },
  "557-dip-drive": {
    name: "557 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "BmAncL2hfJccySPJaX2oLaHpTqBU6rsd9GWbQB85igvN",
  },
  "557-poplar-street": {
    name: "557 Poplar Street",
    biome: "arctic",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "9k3LZ5hSE9nusU1CFjDy7JHmeHDHvRJdrNK3DA97teHt",
  },
  "558-dahlia-drive": {
    name: "558 Dahlia Drive",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "7c35bmYTNMa6BtzFYCBc9uo7ztYxv3feSp7VAu9H3cgK",
  },
  "558-jane-jacobs-way": {
    name: "558 Jane Jacobs Way",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "F4rVHqEZVMtbaKL7vYedo4rx65vd3rCWF12YSnAURYMU",
  },
  "559-dahlia-drive": {
    name: "559 Dahlia Drive",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "AfGEKqA27fijaivDhMoKwGzuhqcKzCjjxL7exsUkjoG4",
  },
  "559-jane-jacobs-way": {
    name: "559 Jane Jacobs Way",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "jane-jacobs-way",
    tokenAddress: "CLwggYo9Th9GpPvjV1A86SH5kJ51aeqQMWBor9s5itFw",
  },
  "559-poplar-street": {
    name: "559 Poplar Street",
    biome: "field",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "GqEYPcPZnVqmHq5Ujjkr3GJXEx9vgtc6yY89K5YTSWK1",
  },
  "56-abbey-road": {
    name: "56 Abbey Road",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "abbey-road",
    tokenAddress: "CgpVXuwCoooW4zkDdWQ99FeDhFKZNsdEZ1fx7dCzfkXc",
  },
  "56-ape-lane": {
    name: "56 Ape Lane",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "ape-lane",
    tokenAddress: "7dH8WVj8S96qJ4hXNesvZwivRNV1vpfHQKXEkr9QtpUi",
  },
  "56-king-street": {
    name: "56 King Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "king-street",
    tokenAddress: "8nU8J8GfZMAiCCiQaFLyp4N51gS8R67sf93wENDY5KHK",
  },
  "56-rosa-street": {
    name: "56 Rosa Street",
    biome: "desert",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "CGVg241XCFmoJUuFfiEhdPvMeCC6PxJhoXCBWa97nJqL",
  },
  "56-stewart-avenue": {
    name: "56 Stewart Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "stewart-avenue",
    tokenAddress: "Hxam2EjcDmBP3DbyNnGKYyhjEvdyf9ZTskhCrC3GpHeP",
  },
  "56-supple-circle": {
    name: "56 Supple Circle",
    biome: "mountain",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "A8NKsTNBUEvtcLx6yebPv9PeitptZw4HFvxEexRXnZf",
  },
  "56-teeps-lane": {
    name: "56 Teeps Lane",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "teeps-lane",
    tokenAddress: "HZV2rKkdpdb3ZxE15w3CE5iAi8jW991Fj4y3ApBGPpTu",
  },
  "560-dahlia-drive": {
    name: "560 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "7cf6FDAKESxYysXybLZJCD6vwXuV97ya74JbJXzrbufV",
  },
  "560-dip-drive": {
    name: "560 Dip Drive",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "HNNafPBMsnGBdxVrbmwoarmz5wCAUpLrzXScCb8LvAqW",
  },
  "560-jane-jacobs-way": {
    name: "560 Jane Jacobs Way",
    biome: "field",
    frameType: "glass",
    streetId: "jane-jacobs-way",
    tokenAddress: "DXEiuLUejpEpm3j8WmXp8HJWzBLxKtE4BZooxupPa4Vb",
  },
  "561-dip-drive": {
    name: "561 Dip Drive",
    biome: "field",
    frameType: "occult",
    streetId: "dip-drive",
    tokenAddress: "9DPu6Ds9YHUWdPmVcfKo58kGKbKnjuFpna38XExAVLhq",
  },
  "561-jane-jacobs-way": {
    name: "561 Jane Jacobs Way",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "jane-jacobs-way",
    tokenAddress: "AqYqmemDAHs7LhMetyVCirGY94qBYyCm6PTRt8n4A6UG",
  },
  "561-poplar-street": {
    name: "561 Poplar Street",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "poplar-street",
    tokenAddress: "F9N4an1EUs1uGrZLgNWjVLszigxXVUcMXmrr2k8LFH5o",
  },
  "562-dip-drive": {
    name: "562 Dip Drive",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "dip-drive",
    tokenAddress: "9TUhDMajKPQW2AZDwa4n9gFTUstZH3eprKxxTvXH5p1w",
  },
  "562-jane-jacobs-way": {
    name: "562 Jane Jacobs Way",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "jane-jacobs-way",
    tokenAddress: "9wQwoQWsu9c19Jggng3qBWDJeeLsHS1fpYzJFpitRd2K",
  },
  "562-poplar-street": {
    name: "562 Poplar Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "7HnvdutU91QrXwXqkoxfvvMiUmbiVftQJ5xP9DyCEbJQ",
  },
  "563-dahlia-drive": {
    name: "563 Dahlia Drive",
    biome: "field",
    frameType: "rounded-green",
    streetId: "dahlia-drive",
    tokenAddress: "EuAj9Mq1ZifkcXbom73cXH9VoizBxAUVf3gwemhcKDWp",
  },
  "563-dip-drive": {
    name: "563 Dip Drive",
    biome: "city",
    frameType: "rounded-red",
    streetId: "dip-drive",
    tokenAddress: "6G2trB4xhRWzuBx33qXDzbpNJ99C2GQ2gs7tct4B1hAq",
  },
  "563-jane-jacobs-way": {
    name: "563 Jane Jacobs Way",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "jane-jacobs-way",
    tokenAddress: "5P846LaXsHxicPgbx9zDyyk8eWNsJ4TqcYsDQ6KpD9ZB",
  },
  "564-dip-drive": {
    name: "564 Dip Drive",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "9oXDqMfoBKabd3TRMd9fW498XEBJ7vBzia4nR1Mmfkkt",
  },
  "564-poplar-street": {
    name: "564 Poplar Street",
    biome: "city",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "4WKYrT4of18ftcq4Ckw6zzsVHczi1ReNK6VgkpGoco5P",
  },
  "565-jane-jacobs-way": {
    name: "565 Jane Jacobs Way",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "HV29AfjpK9YXrdUYxEbMy7Z7DiGmzjuU6dPjTzzoKBXf",
  },
  "565-poplar-street": {
    name: "565 Poplar Street",
    biome: "mountain",
    frameType: "rounded-pale-green",
    streetId: "poplar-street",
    tokenAddress: "AqZ1iTXwzzwQE2o1UDKLK8rwwfeXMxZzMYJoKHMdbuWe",
  },
  "566-dip-drive": {
    name: "566 Dip Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "2nyhmWREgsr3GuTGCm9NV9D7SSt5W8MNm4rtTiG2FJFL",
  },
  "566-jane-jacobs-way": {
    name: "566 Jane Jacobs Way",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "jane-jacobs-way",
    tokenAddress: "HuZrft4pQfcvBbTJB5aK3dqqxdY93s1ifHSZbfDAV2M3",
  },
  "566-poplar-street": {
    name: "566 Poplar Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "poplar-street",
    tokenAddress: "4vW4RDv5XNYWsps5JhRQMwzZaX2xgKgWFSEnDiurq95H",
  },
  "567-jane-jacobs-way": {
    name: "567 Jane Jacobs Way",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "jane-jacobs-way",
    tokenAddress: "6LCxsdKHB6ePr99h7YjT4ebdHXfroMDXNK4gZLuFHozM",
  },
  "568-dip-drive": {
    name: "568 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "5nzMgM25KSSBkc7kXKBHhgcFPELMJNktp9u2622QMCgH",
  },
  "568-jane-jacobs-way": {
    name: "568 Jane Jacobs Way",
    biome: "desert",
    frameType: "crystal",
    streetId: "jane-jacobs-way",
    tokenAddress: "5yNiRznSqRLWPyQ9uXeNtBDP4dQo9A4asN1kFr2zMmqD",
  },
  "568-poplar-street": {
    name: "568 Poplar Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "Hte6siyhb2WZVVQi38VvBtKagi72GXkSDsrXnTBGo1Fn",
  },
  "569-dahlia-drive": {
    name: "569 Dahlia Drive",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "96QcupVLkEFS4V6ELXYcy93TZ7XEmpv2Q8yu83QLobyg",
  },
  "569-dip-drive": {
    name: "569 Dip Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "H4xQmqBgdPdMdTrH6D9HWiSjuhGNJez32guNZS1WWHJN",
  },
  "569-poplar-street": {
    name: "569 Poplar Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "Fq5HxsGeGtyx3W4VKGg8fUVGG1ioe8qzNeS3LZA8D33a",
  },
  "57-abbey-road": {
    name: "57 Abbey Road",
    biome: "desert",
    frameType: "crystal",
    streetId: "abbey-road",
    tokenAddress: "GC35h7ybPtQSwnsv71tntxTRFGt8P7W6PRY1eXfdT8Ls",
  },
  "57-ape-lane": {
    name: "57 Ape Lane",
    biome: "arctic",
    frameType: "rounded-pale-green",
    streetId: "ape-lane",
    tokenAddress: "8wnBCyFHSpxd2xZDgzYqx54fvWZnr6zFXiLXExjBuCZG",
  },
  "57-king-street": {
    name: "57 King Street",
    biome: "beach",
    frameType: "occult",
    streetId: "king-street",
    tokenAddress: "2oXN35FuNVbakRJbmdCEedTxzAeUugtF3StpK2KnQCFi",
  },
  "57-rosa-street": {
    name: "57 Rosa Street",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "rosa-street",
    tokenAddress: "9HjQRACYkLojFec6mzmZWgVm5WGowxUButZLf6Nzy1z4",
  },
  "57-supple-circle": {
    name: "57 Supple Circle",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "3ukHp3NKeLJhtkJZY2Sr74cs2JfSLY7qt3JTjQmd78Lh",
  },
  "570-poplar-street": {
    name: "570 Poplar Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "GeGyw56GmfcRSHag4fzuw1G9My6WP3pG7trTGCSpm83M",
  },
  "571-dip-drive": {
    name: "571 Dip Drive",
    biome: "mountain",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "BR5KFk4TrbJTFZEo1VM6TuZoYpcSA5foJvGxCnqXZ1JL",
  },
  "572-dahlia-drive": {
    name: "572 Dahlia Drive",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "FbL6Fc41NfET7zsKGVgtTtpYRg5NHUE5diuyAnpoa4ed",
  },
  "572-dip-drive": {
    name: "572 Dip Drive",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "89RScSd3mksvmqcbBPJdpwRB4Rf1cNAzhRQ39XUxCtkq",
  },
  "572-poplar-street": {
    name: "572 Poplar Street",
    biome: "city",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "5yAtb2gMxzfg2NWE5uyN5RtHS1TfFab6Wwvo9uXETFSg",
  },
  "573-dahlia-drive": {
    name: "573 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "221cd1aKRXYzL8Uz2kDZ7nv65EsvDqhSV9UmhbW9Zeg1",
  },
  "573-dip-drive": {
    name: "573 Dip Drive",
    biome: "woods",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "9x8P2wsHkn11V4LyCt4esYK9KQiiMrNKNUHekWBHnBTU",
  },
  "573-poplar-street": {
    name: "573 Poplar Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "poplar-street",
    tokenAddress: "5jPJPmoWeXhB5kcySj4rnGkX1iu4Bg8YmWdeR5vvePha",
  },
  "574-dip-drive": {
    name: "574 Dip Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "2GtFUA6198ecCkwVtBRAR5mJmHLra1ytWdM5EzDWZkPw",
  },
  "575-poplar-street": {
    name: "575 Poplar Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "poplar-street",
    tokenAddress: "G6wkuS5kBnJUedPv1uNFz5XEGc3je2vjZFLPEtw7XnBa",
  },
  "576-dahlia-drive": {
    name: "576 Dahlia Drive",
    biome: "field",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "38SMJHnYLPsS8ywVPdNcDPtDXMHr1bjUEHQJd8oGgSpd",
  },
  "578-dahlia-drive": {
    name: "578 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "AGyD3VQr8zrrwjCrn9vxM4BaD1cYTHscxZigGYRCi9ZM",
  },
  "578-dip-drive": {
    name: "578 Dip Drive",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "3xKwJAphnUXzGy3HbpmRwEbgjbFCN1pyFo9kQFXYWRKq",
  },
  "578-poplar-street": {
    name: "578 Poplar Street",
    biome: "field",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "8Hs6L4NbTCLqYgACPPBMmsv9Z9Vf7UrWqjkAByaMcs48",
  },
  "579-dip-drive": {
    name: "579 Dip Drive",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "dip-drive",
    tokenAddress: "6DUm4LYnJs1h9kPx9ENPJy2aLqEStUC4usweqDDtCt2E",
  },
  "58-abbey-road": {
    name: "58 Abbey Road",
    biome: "field",
    frameType: "occult",
    streetId: "abbey-road",
    tokenAddress: "4gB35Yhy6HdwrhJ4gdr3QsBhBgS1PZSCNrCEJibTrfbQ",
  },
  "58-ape-lane": {
    name: "58 Ape Lane",
    biome: "city",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "7VoUb6mKjeE36dSZXkTcu2oH5RMWDv8jxFww3uiaiSSY",
  },
  "58-king-street": {
    name: "58 King Street",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "king-street",
    tokenAddress: "6JnnAgmrrbC5mbcT1naBmADouJFQA7EDP1tUGwpEJC3b",
  },
  "58-stewart-avenue": {
    name: "58 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "D4EZ311sNE5FwuBcDR2dQFfvp3yHbvMAGyiu8hQ8NCzC",
  },
  "58-supple-circle": {
    name: "58 Supple Circle",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "supple-circle",
    tokenAddress: "7Ag8WKvN2pbkWyhzATQz3hjhi8t1KguAPBUQZdSq76u2",
  },
  "580-cluster-lane": {
    name: "580 Cluster Lane",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "cluster-lane",
    tokenAddress: "2xjExqEjQq8F7b1F3CJvUCssky83DcisUWt7ChaaS3yh",
  },
  "580-dahlia-drive": {
    name: "580 Dahlia Drive",
    biome: "desert",
    frameType: "gold",
    streetId: "dahlia-drive",
    tokenAddress: "CqkBZRT6avrG1QGAjEaMvK1WXW9hvCjbXBnfJNjBV2WD",
  },
  "580-dip-drive": {
    name: "580 Dip Drive",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "DrM7aR4enxxgTnq13n3chAPeLsjE4XsCDtEvui51CGsT",
  },
  "580-helix-avenue": {
    name: "580 Helix Avenue",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "BQAt2Ys43HqdBRCZ5MAMNFHidiohq38p5MiENo4KETCF",
  },
  "580-poplar-street": {
    name: "580 Poplar Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "3LZQ48TeQxKU91WCr4hbaauhbtx17kRV6iLL73YKrcNE",
  },
  "581-dip-drive": {
    name: "581 Dip Drive",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "dip-drive",
    tokenAddress: "EtJpFWMYoazN66p3L2ViG11QqFk9ZmLSBEAXi1o2Qfeo",
  },
  "582-cluster-lane": {
    name: "582 Cluster Lane",
    biome: "city",
    frameType: "glass",
    streetId: "cluster-lane",
    tokenAddress: "5sWa1kUidnEHczCFSXUcMiazS1HjMZ7GLHGddVDiErxD",
  },
  "582-dip-drive": {
    name: "582 Dip Drive",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "dip-drive",
    tokenAddress: "DMLUT16RYaG2c5bbXBM4PKqjNL9tCL1MeCRo6keGP62p",
  },
  "582-helix-avenue": {
    name: "582 Helix Avenue",
    biome: "underwater",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "B5ncAQY5DUhKg9m8KyZh3wcC589hfdi2PL3JdcMbHTmM",
  },
  "583-dahlia-drive": {
    name: "583 Dahlia Drive",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "dahlia-drive",
    tokenAddress: "GbTbhGJTy8DswFD2P6Ui4viEYcaxGAacrqStY4VPFDhL",
  },
  "584-cluster-lane": {
    name: "584 Cluster Lane",
    biome: "treehouse",
    frameType: "shuttered-lavender",
    streetId: "cluster-lane",
    tokenAddress: "HKZ5ajDdm4u9Lkfa2DwX5MwDNW1e9xByLaAKVXrpwpgh",
  },
  "585-cluster-lane": {
    name: "585 Cluster Lane",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "cluster-lane",
    tokenAddress: "DRQHpGcjiXdNZ1RRLGEEtnxa3R1jTafwVcHrMmP3EVZx",
  },
  "585-helix-avenue": {
    name: "585 Helix Avenue",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "2x4AzGDToAGNXqWrf3anMfq8GRxagxuYM5gDNCFBBeXT",
  },
  "585-poplar-street": {
    name: "585 Poplar Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "poplar-street",
    tokenAddress: "CoSevbSJNFJFFNy6UdzZ53qyt19XW1bq2gjvkduz8Ljb",
  },
  "586-helix-avenue": {
    name: "586 Helix Avenue",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "4ri6gfrpwo5PtSFZ2qFAFroXgKVkSvzK1BJJMpjWtLnK",
  },
  "587-cluster-lane": {
    name: "587 Cluster Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "cluster-lane",
    tokenAddress: "DyP4bHycA4Cy1rqDVQPcDAQrzbMo1XsDvUoVQ8kU1Kfe",
  },
  "587-dahlia-drive": {
    name: "587 Dahlia Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "dahlia-drive",
    tokenAddress: "A9Pdfk8jmgJVSJRz8LSFvJV3VLMb8YMakNzL3AcKC82H",
  },
  "587-helix-avenue": {
    name: "587 Helix Avenue",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "helix-avenue",
    tokenAddress: "CudcimXpMdtenbDEQ7UCdmpBXySTKzdjPXZZU6zRhugn",
  },
  "588-dip-drive": {
    name: "588 Dip Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "6qxCWYabqAJYx89AGQTyuFySzUnA5LEXdRDznasiAZ7P",
  },
  "588-helix-avenue": {
    name: "588 Helix Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "EPF4SHACFUPinPdRnKxUALavMuuPgYL9frpdPHiNMEFo",
  },
  "589-dip-drive": {
    name: "589 Dip Drive",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "dip-drive",
    tokenAddress: "GSsZACw3xQB4W94YHSmtnVxDb8RWFYfrmTnpckqvajoN",
  },
  "589-helix-avenue": {
    name: "589 Helix Avenue",
    biome: "mountain",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "68am4pWwtB8UXXtWGSe6ce5SsscB2SBkSWHULTCR3APs",
  },
  "59-ape-lane": {
    name: "59 Ape Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "5Jc67tbGecLDHZPW8jKqcv8acAUpDdDdkYRKiLxJ43jz",
  },
  "59-king-street": {
    name: "59 King Street",
    biome: "arctic",
    frameType: "rounded-lavender",
    streetId: "king-street",
    tokenAddress: "VddreTrjBSbiKB7h3kwZaiMY9giqpR5cmVHcfZEdrVo",
  },
  "59-rosa-street": {
    name: "59 Rosa Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "rosa-street",
    tokenAddress: "9UeGBancTqhcv5orKV9qbSabWDyNuCSMAtVVbCmcZNM",
  },
  "59-supple-circle": {
    name: "59 Supple Circle",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "ENGb1oNxRnc3LeSQXyXVqjzbnNjkH4ony5t28D7LHwJq",
  },
  "59-teeps-lane": {
    name: "59 Teeps Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "27j6n6PadSJRGWqmNdsfpcEb7eZcDEpcDSsp57mZMxpy",
  },
  "590-cluster-lane": {
    name: "590 Cluster Lane",
    biome: "mountain",
    frameType: "glass",
    streetId: "cluster-lane",
    tokenAddress: "A5rthFZb3rRahEQmiNL4Q4uyxtqyZGJU9b8mu22J6913",
  },
  "591-dip-drive": {
    name: "591 Dip Drive",
    biome: "desert",
    frameType: "rounded-green",
    streetId: "dip-drive",
    tokenAddress: "wc62P8g1D3TgJFt3dFFnAnBUyoe32BDFrqBcEBBJ6EL",
  },
  "591-helix-avenue": {
    name: "591 Helix Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "GLLgyxZkqm8tfwGN7a6yDfgw9bX4ef1FognZ8KNzrosx",
  },
  "592-cluster-lane": {
    name: "592 Cluster Lane",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "cluster-lane",
    tokenAddress: "Fvr7tNmmoRS78CdfGqJcg3U4mHdKcWH7zLssBHYLMW9L",
  },
  "592-dip-drive": {
    name: "592 Dip Drive",
    biome: "field",
    frameType: "rounded-green",
    streetId: "dip-drive",
    tokenAddress: "8a6DhHV4P7stzPxrzrcssKafeyUGkLyZHJ2rxqrw7gBU",
  },
  "592-helix-avenue": {
    name: "592 Helix Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "3AMqteiqfouZikyrBVn448EtZGzu1sbnezuNRq2x43Ay",
  },
  "592-poplar-street": {
    name: "592 Poplar Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "DVdSWa5L93Euys5dTyPsb1nzrExVLXSt7FxV1LXMAayi",
  },
  "593-cluster-lane": {
    name: "593 Cluster Lane",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "cluster-lane",
    tokenAddress: "47gy5iPnk9s4NrPNd5HQxa1yk3b5MqQg2RBv15PzDL5A",
  },
  "593-helix-avenue": {
    name: "593 Helix Avenue",
    biome: "city",
    frameType: "rounded-green",
    streetId: "helix-avenue",
    tokenAddress: "AUm3rDCtiw6d13P3AKivUCM5UfUCQCTJhHoaG8HRFThP",
  },
  "593-poplar-street": {
    name: "593 Poplar Street",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "9WxWXSvhdCXsjTFMHBsEfW8Z7SzfHzBHgizfPqath8T",
  },
  "594-cluster-lane": {
    name: "594 Cluster Lane",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "cluster-lane",
    tokenAddress: "6mC4eznLtTAiw4pduVZHgmhYyhNur1R6qcCnmPDKpZiD",
  },
  "594-dip-drive": {
    name: "594 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "3P4poyfYvjanGADjEs29N12MPTpVXmh3qRpbYbddVyme",
  },
  "594-helix-avenue": {
    name: "594 Helix Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "59FsuVr1JqEEBDci6QkgF5EdBUXHJZKbaSq3aaxkDjXB",
  },
  "595-cluster-lane": {
    name: "595 Cluster Lane",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "cluster-lane",
    tokenAddress: "53qSfd3nvz7i1zQdjFpMBo3B8LfpMoE7ZDi8K9ijG2xi",
  },
  "595-dip-drive": {
    name: "595 Dip Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "4ezHYQqfwPJaQBeZoQUGmH8EJQnPK1cyTndopjLbVRnF",
  },
  "595-poplar-street": {
    name: "595 Poplar Street",
    biome: "volcanic",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "3j4aVLMcSvfQuAnEEEWryHF8qgFSU6Evz2NCh4Kwi2qk",
  },
  "596-dahlia-drive": {
    name: "596 Dahlia Drive",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "DuKGZsQ2WgF3v1MjVBUQRBzzWzkGRqfG9cAoM1tq1LY4",
  },
  "596-dip-drive": {
    name: "596 Dip Drive",
    biome: "volcanic",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "2yZzLqjkMqYP2d1UBiNs1NzSXamMVHNxu5joEE69cPYP",
  },
  "596-helix-avenue": {
    name: "596 Helix Avenue",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "AK3yzjW3Af4gGZ8FGi3V2qdvgYYuXUmRudsCm6u2ksCY",
  },
  "596-poplar-street": {
    name: "596 Poplar Street",
    biome: "field",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "JDNaGC3c1fcEA6QrVyPJooFGpDEx3cV2697FVrGznvSX",
  },
  "597-cluster-lane": {
    name: "597 Cluster Lane",
    biome: "beach",
    frameType: "rounded-pale-green",
    streetId: "cluster-lane",
    tokenAddress: "3cZWa9TFX4ZJreuKFEjBiNGTWRYmKC1UndGDmK4oanLG",
  },
  "597-dahlia-drive": {
    name: "597 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "8zComgvbZiVNewVccW54WU7j6cyJBS3dqaSePZorHRho",
  },
  "597-helix-avenue": {
    name: "597 Helix Avenue",
    biome: "volcanic",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "Gky5U83RLQ6LJ697k1i69tBVHQpVQiFzJQaYtnFBxPwQ",
  },
  "598-cluster-lane": {
    name: "598 Cluster Lane",
    biome: "treehouse",
    frameType: "shuttered-green",
    streetId: "cluster-lane",
    tokenAddress: "8Qh5Hs6B63MF5kkVaA1iboj5rMdG2W6VjoiPoJBxg8Xb",
  },
  "598-helix-avenue": {
    name: "598 Helix Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "4yK8WCkJP4sEXqqQUU33CjZbgKMY75dfzb5GTrJqtc7Y",
  },
  "599-cluster-lane": {
    name: "599 Cluster Lane",
    biome: "desert",
    frameType: "glass",
    streetId: "cluster-lane",
    tokenAddress: "5617urFkooWkHhXmrWCV729kHg4StprfSyeaPAk464h4",
  },
  "599-dip-drive": {
    name: "599 Dip Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "DDHbVoUaGjLR46fdb7LGPqHW2qZRawZVSbjAN6vwcjbQ",
  },
  "599-helix-avenue": {
    name: "599 Helix Avenue",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "9rZVGLP8MBYvxYz2qJLyAAK2tbmLEg6Mj4NFRmFuTNqp",
  },
  "599-poplar-street": {
    name: "599 Poplar Street",
    biome: "city",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "FRbCHasbhynH86Mg5gvshXGgoLRAQosZiaEc6bsn46v2",
  },
  "60-abbey-road": {
    name: "60 Abbey Road",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "abbey-road",
    tokenAddress: "DCKS2QtdNk9A3CN5XnRCT1cLKZ17VLyQMtjnnSYojR7i",
  },
  "60-ape-lane": {
    name: "60 Ape Lane",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "GJBaUnncfSwESvhcNz1r5DtqVDQbmbBKg9DXcRfBeift",
  },
  "60-king-street": {
    name: "60 King Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "king-street",
    tokenAddress: "DYxrTe8GgHXBoeg66eN4TyqNC8ST63VnVE4AuYT8yzGi",
  },
  "60-ligma-lane": {
    name: "60 Ligma Lane",
    biome: "field",
    frameType: "rounded-green",
    streetId: "ligma-lane",
    tokenAddress: "Gg7mEfxkQedDwP3f6X8wY8P9w7txyubKuh3fRJjEjS8a",
  },
  "60-rosa-street": {
    name: "60 Rosa Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "rosa-street",
    tokenAddress: "2G7i9uHPV2m6pMqT7jQX3Ujjz1YGB394mfLEN7rPbXu2",
  },
  "60-supple-circle": {
    name: "60 Supple Circle",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "51TyxHGmtrW9bjv4hbVobsscSTsA2z4oe2YHNaqZha6F",
  },
  "60-wall-street": {
    name: "60 Wall Street",
    biome: "treehouse",
    frameType: "rounded-pale-green",
    streetId: "wall-street",
    tokenAddress: "An8k5KyyEWzc5eAgPZc5zbeUieBUgM8t58ZhV8J85HdU",
  },
  "600-dip-drive": {
    name: "600 Dip Drive",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "9TFe3wsCEVFQYCRAZCMteZFFqwJQzumCuFkhMg4xXmU1",
  },
  "600-poplar-street": {
    name: "600 Poplar Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "3BZcPAdoUP7qrB6zgwUkY4PMsuKgyb537EeooTw8Temz",
  },
  "601-cluster-lane": {
    name: "601 Cluster Lane",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "cluster-lane",
    tokenAddress: "2pR9sJtTZ2pxuJCyCgatP8cx41gUaYbH62FxcsnagDcp",
  },
  "601-dip-drive": {
    name: "601 Dip Drive",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "dip-drive",
    tokenAddress: "3dyXDFFDrJC732nyBybvmnke3VqHKRd7VefVFW2zTZSu",
  },
  "601-helix-avenue": {
    name: "601 Helix Avenue",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "5fcr7aqyiqaCdf7sKNzLAhdpU28BDduw1umx4NHtZ4uL",
  },
  "601-poplar-street": {
    name: "601 Poplar Street",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "poplar-street",
    tokenAddress: "3HXridkMaPiz3H2G77FDk8GWLroGmwuozNVUgaPUVf4D",
  },
  "602-cluster-lane": {
    name: "602 Cluster Lane",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "cluster-lane",
    tokenAddress: "FifUyFNWi3DRTpiLbfzy3G9GMstsAntMxab9LkM1FXT7",
  },
  "602-dip-drive": {
    name: "602 Dip Drive",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "5h49AM5aWo6gxcayg1K6gNu7Numh9DZaf5G3CSfcFPYP",
  },
  "602-helix-avenue": {
    name: "602 Helix Avenue",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "8cmsWP9NeH1Vxwb3XjYrNaD2FXN8oXuXUbtzVqPCNqdD",
  },
  "603-cluster-lane": {
    name: "603 Cluster Lane",
    biome: "woods",
    frameType: "rounded-lavender",
    streetId: "cluster-lane",
    tokenAddress: "3VxMmE8KLoGyt9QwShqBd7s2TJvPwFUEx2DpNDu5DbqR",
  },
  "603-dip-drive": {
    name: "603 Dip Drive",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "dip-drive",
    tokenAddress: "8h7deS2UtWQSiJR9gcxNhpHJo15ajuvEKmadwEYss9Zj",
  },
  "603-helix-avenue": {
    name: "603 Helix Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "BdGvkWcpWNecrYt4hooAKE6j2NAaU6GSK3NEYUFdQB3o",
  },
  "603-poplar-street": {
    name: "603 Poplar Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "7A1BnzeXbs4avVkoQmfjxMWSPnADGJwYU3WoiXFUGEJZ",
  },
  "604-cluster-lane": {
    name: "604 Cluster Lane",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "cluster-lane",
    tokenAddress: "CgN2QVoe5EXoXmvTtN1Gbxf8jtawasHLFACy5sS9tfo9",
  },
  "604-dip-drive": {
    name: "604 Dip Drive",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "dip-drive",
    tokenAddress: "D3VjGaQzbjthpVS2tCRGG8mQaQCBfDf7T16d6psk6sPj",
  },
  "604-helix-avenue": {
    name: "604 Helix Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "4R4ArCTKJrTFgVkKyZxSEicqxGGrEKza7ZgRu6eXWJGr",
  },
  "604-poplar-street": {
    name: "604 Poplar Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "poplar-street",
    tokenAddress: "7Tzv23GXRqGeL6ZbnufknThAtger6pT52vbKKcJ6crCd",
  },
  "605-dip-drive": {
    name: "605 Dip Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "7r1reKgYdBRKTdPspuvqSgEnSK3q3a81EzUT5U78pxXc",
  },
  "605-helix-avenue": {
    name: "605 Helix Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "GtPfBMVqGy18a2eSQQ2JaXvFdDFmQY8GGNmpbxddDKte",
  },
  "605-poplar-street": {
    name: "605 Poplar Street",
    biome: "woods",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "5uTmLvgkygjcookVwNtNqd1gWHju1V9C4GNV2meBa8E3",
  },
  "605-rip-street": {
    name: "605 Rip Street",
    biome: "beach",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "HGduxTjbyrUmoDtBMFfdZKy2LKwLtmnpMqhqQxWf9mqQ",
  },
  "606-dip-drive": {
    name: "606 Dip Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "4LP8jAgoiC4dXRsXTgLYtR6f32U3oxbeE16hjNdNBCZG",
  },
  "607-cluster-lane": {
    name: "607 Cluster Lane",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "cluster-lane",
    tokenAddress: "B4wHQDh3UJ6rwyVb7rhkFhTkXhUpFre6wiMd25cU4oV1",
  },
  "607-dip-drive": {
    name: "607 Dip Drive",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "HNdPE8Hg3oEcqFjyUEFfDkkqy1K1WR7iUwrX6G8dXHMX",
  },
  "607-rip-street": {
    name: "607 Rip Street",
    biome: "city",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "5Ln8SBqGrFiQtMx2RyS5zRSt3cBb7FCBcRdxjPCGu9Th",
  },
  "608-cluster-lane": {
    name: "608 Cluster Lane",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "cluster-lane",
    tokenAddress: "5sjPvTFoAkzBxzbiVMaCxYJ3X7FfEisV9Cuijs4jF5Hu",
  },
  "608-helix-avenue": {
    name: "608 Helix Avenue",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "7BHEVsBMny56hEG8Zcq4G3U5w63PLMV8oqtabTx2srHo",
  },
  "608-poplar-street": {
    name: "608 Poplar Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "poplar-street",
    tokenAddress: "5yS6kwFfkA52GsDYR9KZ6DcHNjUDv1yNy3hfojiWZDRH",
  },
  "608-rip-street": {
    name: "608 Rip Street",
    biome: "treehouse",
    frameType: "shuttered-pale-green",
    streetId: "rip-street",
    tokenAddress: "73VpUSt6VJd8DymRTfJ4BTmCTu9acSNeqJC821TYmphR",
  },
  "609-cluster-lane": {
    name: "609 Cluster Lane",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "cluster-lane",
    tokenAddress: "8YHRvq8gqL8Q7xmvY9AH5jqLtC333d9gaoufLMstWuqN",
  },
  "609-dahlia-drive": {
    name: "609 Dahlia Drive",
    biome: "field",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "F9LFmqnUKtJebY9oz4xh3MVJ9TWQLgSAERP9yW3xeCXh",
  },
  "609-helix-avenue": {
    name: "609 Helix Avenue",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "3bQ51V6Max7oS8LFoALbyooh9or6Fw5VH8JHi9EbhqiB",
  },
  "609-poplar-street": {
    name: "609 Poplar Street",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "poplar-street",
    tokenAddress: "aPgLYfPwkaa8ktQSS2yzTZMh6E2teGz3fVWdSFPQTHH",
  },
  "61-ape-lane": {
    name: "61 Ape Lane",
    biome: "desert",
    frameType: "occult",
    streetId: "ape-lane",
    tokenAddress: "7pMFCyR12TgLXDfQmS7Ht4cKfuTzXcQ94ibDTstRSAvc",
  },
  "61-king-street": {
    name: "61 King Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "king-street",
    tokenAddress: "BG7SeGcMbTfgomEFS8XdniMnf3scQzBhWze8QKvVGFxF",
  },
  "61-stewart-avenue": {
    name: "61 Stewart Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "DgrHDmXm5FN2eNLX9nJqAQioD7XdR9snBpyyM25kv9Mq",
  },
  "61-supple-circle": {
    name: "61 Supple Circle",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "44ATzRw5tqdLWo3PsShbkLUVGZGr4uzVVCBpBdmtrZsU",
  },
  "61-teeps-lane": {
    name: "61 Teeps Lane",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "teeps-lane",
    tokenAddress: "FCJujk5cVZ74eP2ztv3gVYb7Q7Lr6BGouMsCgPBaeYJP",
  },
  "610-cluster-lane": {
    name: "610 Cluster Lane",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "cluster-lane",
    tokenAddress: "Guq2vkqVpEeqDkiwnbXHUPncGrBoRm531SpFspWv8tmR",
  },
  "610-helix-avenue": {
    name: "610 Helix Avenue",
    biome: "treehouse",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "Gugo8N93w3K5Tnp9t2LwKFean9RvMzhdkWQfrwT19ppB",
  },
  "610-rip-street": {
    name: "610 Rip Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "4aG5b3hW2vZbQp8a6G3A2FQLyRmV7tdzAKa652VjReuV",
  },
  "612-dip-drive": {
    name: "612 Dip Drive",
    biome: "field",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "3LoSp35B3Euvq4X8QWpmNLwywYB7BprQnZPViKE6NMPF",
  },
  "612-rip-street": {
    name: "612 Rip Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "F7EJ5ooV8tWPnpSPQ8eiizy4zMVYoXXp6Nxj8DmgAZat",
  },
  "613-cluster-lane": {
    name: "613 Cluster Lane",
    biome: "woods",
    frameType: "glass",
    streetId: "cluster-lane",
    tokenAddress: "A5T1qP2r9XpqHTHVXH26ndqqmSt8y1W56rsYJ1KB1GVj",
  },
  "613-helix-avenue": {
    name: "613 Helix Avenue",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "DqMSGR9N2x1QUm2KhHL3ExJPz9SqefLaNXSpZgz2Ut7r",
  },
  "613-poplar-street": {
    name: "613 Poplar Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "poplar-street",
    tokenAddress: "J7QGBnQ7eVuUCm5m6sQKWpTmZfFG5AD5Bgdrwv8gW2sh",
  },
  "613-rip-street": {
    name: "613 Rip Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "rip-street",
    tokenAddress: "9KjYmcM6GBR1JQgwhDpCi4fcYGcWZcvBcq65MdT4TVjM",
  },
  "614-cluster-lane": {
    name: "614 Cluster Lane",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "cluster-lane",
    tokenAddress: "71C6pnyqU5cSqyuc7t9TLW9FcjeVchybSwcQPoUZA4rx",
  },
  "614-dip-drive": {
    name: "614 Dip Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "dip-drive",
    tokenAddress: "2ycMKpMkxVjpASSNSy4jEZztdUx1zSMDz8qHD4jML17o",
  },
  "614-rip-street": {
    name: "614 Rip Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "HhQQqf6C43jWMCtvUhMcJjLhLfmJriEHQJfB1FBfhGLD",
  },
  "615-dahlia-drive": {
    name: "615 Dahlia Drive",
    biome: "volcanic",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "G8B1m9MqJ85G2cb3f3JF4HhhdBNzVUrvCRSTNyuAzGEQ",
  },
  "615-helix-avenue": {
    name: "615 Helix Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "ATSvp4MXwNR3vJ5hbgs11t3TPhTi2kDesvcr4k5iY1mT",
  },
  "615-poplar-street": {
    name: "615 Poplar Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "poplar-street",
    tokenAddress: "5mXcginUtpHWybfzHLwwSJ1kD5AsshbCUHQJb3oUZGwT",
  },
  "616-cluster-lane": {
    name: "616 Cluster Lane",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "cluster-lane",
    tokenAddress: "Hk3ydryuKZSttdnQ8GLMGStArDfptaLGyu8S56YzdqkN",
  },
  "616-dahlia-drive": {
    name: "616 Dahlia Drive",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "dahlia-drive",
    tokenAddress: "EYRXbxLSg6Susqr2HfrG59qaZqZ7Ey6wcPnF87bLKYM3",
  },
  "616-helix-avenue": {
    name: "616 Helix Avenue",
    biome: "underwater",
    frameType: "occult",
    streetId: "helix-avenue",
    tokenAddress: "FA5E5b4TURQ7uKztcpKGiejebiBdwQJZ5qGK9axbrxK6",
  },
  "616-poplar-street": {
    name: "616 Poplar Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "poplar-street",
    tokenAddress: "5xQczT3uYwn2tKqqZYWAVMEhsvuY2nFLi77aJBco57E9",
  },
  "617-cluster-lane": {
    name: "617 Cluster Lane",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "cluster-lane",
    tokenAddress: "GWVh8wjtrAAW2CTT78w4Bjj8c35DCmimniQDuQgtbnt9",
  },
  "617-dip-drive": {
    name: "617 Dip Drive",
    biome: "arctic",
    frameType: "shuttered-lavender",
    streetId: "dip-drive",
    tokenAddress: "GBAhTa9Hea2yeLgKS1W4UPBdV61UqiAcrv5aDq242A1R",
  },
  "617-poplar-street": {
    name: "617 Poplar Street",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "poplar-street",
    tokenAddress: "4JZhKgMxc5QKuzMPoWc15skaAAr4iYzGnSyTSd8ARBcA",
  },
  "617-rip-street": {
    name: "617 Rip Street",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "rip-street",
    tokenAddress: "DFaP7g2tkdtPuhVD3QGFZDjzkcfRTZmoaBSiNSwfuqaH",
  },
  "618-cluster-lane": {
    name: "618 Cluster Lane",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "cluster-lane",
    tokenAddress: "9t8b8CyzvWkDx3V9uD98FG5rxE9QpUPTXDPrwAHcJc7J",
  },
  "618-dahlia-drive": {
    name: "618 Dahlia Drive",
    biome: "desert",
    frameType: "occult",
    streetId: "dahlia-drive",
    tokenAddress: "LV752PNG8Qz5UqoAvMD9ifa5EVKNHTadHEnAMGJQYo6",
  },
  "618-dip-drive": {
    name: "618 Dip Drive",
    biome: "field",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "fCXvnT27exNRSeN1p4tAgbG83ufhKftvLRNWrprmVp9",
  },
  "618-helix-avenue": {
    name: "618 Helix Avenue",
    biome: "volcanic",
    frameType: "rounded-pale-green",
    streetId: "helix-avenue",
    tokenAddress: "8tG9joz7qfJRiVLQkHVoh8jYQYfZWJyiDfXJRv8iosZV",
  },
  "618-poplar-street": {
    name: "618 Poplar Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "B6otQmqsNF93NbnVP7MmXJSJvtp2cAbzLG5HUcCLs4mx",
  },
  "618-rip-street": {
    name: "618 Rip Street",
    biome: "woods",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "3m2TjRwrLBRG1Sf9bHHNeoC4NcBfmZ48J5zuHRF6Hy8P",
  },
  "619-cluster-lane": {
    name: "619 Cluster Lane",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "cluster-lane",
    tokenAddress: "9de8DxMposuY23PW3spYmU9xwTHKbJnexnR7Ducjiqih",
  },
  "619-dip-drive": {
    name: "619 Dip Drive",
    biome: "underwater",
    frameType: "occult",
    streetId: "dip-drive",
    tokenAddress: "Gkz6pifsnnj9fqm2PzteasHfHY4JsvsnAW21E1tBX21i",
  },
  "619-helix-avenue": {
    name: "619 Helix Avenue",
    biome: "field",
    frameType: "occult",
    streetId: "helix-avenue",
    tokenAddress: "H5T8nUDat6qVuremgk4NX36RroFTPncQcYh4xCGaVRDz",
  },
  "62-abbey-road": {
    name: "62 Abbey Road",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "abbey-road",
    tokenAddress: "D4799W9RLXv1GZL1kTafwihd33QiZD1Uw6WaDtsVZ76A",
  },
  "62-rosa-street": {
    name: "62 Rosa Street",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "rosa-street",
    tokenAddress: "gcCuDqwE8bJpSEH2jWEMzBY3TgPZn6FRMPTa5gn7nSk",
  },
  "62-stewart-avenue": {
    name: "62 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "stewart-avenue",
    tokenAddress: "GiMwo7PhaYgdwkGz1on9PU1yKxjeuMDdu3PDBgTLxxH3",
  },
  "62-supple-circle": {
    name: "62 Supple Circle",
    biome: "beach",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "GSXVHfYbJ5pqP5gwJaTvsZx9JPgUm7bkT1MCx9WaR5i9",
  },
  "62-teeps-lane": {
    name: "62 Teeps Lane",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "2kHCgbSYB7dwnDAxruY4ycbixWKbmU6QhubvXDsdojda",
  },
  "62-wall-street": {
    name: "62 Wall Street",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "wall-street",
    tokenAddress: "2roTbm44jbqX2ZaaBZKBEciGzFU2fXx4eKR7j6yYe4x5",
  },
  "620-dahlia-drive": {
    name: "620 Dahlia Drive",
    biome: "beach",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "BQdSvm4kyzkHnVFvFqWV8mkaHMXkTubMqdgMLJzhhefd",
  },
  "620-dip-drive": {
    name: "620 Dip Drive",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "dip-drive",
    tokenAddress: "BAkjgipq2PwvuQvo7UUUqnh19euqLyEaXoF4pHFVCYgk",
  },
  "620-helix-avenue": {
    name: "620 Helix Avenue",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "FhaBBqe39453JffVbt2BubxH5X1gHKDag3mmTxPStoAc",
  },
  "620-poplar-street": {
    name: "620 Poplar Street",
    biome: "desert",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "92jctyeJLrukzqhfyyS5JBiWsYmkjt641xR34ULgZfW1",
  },
  "620-rip-street": {
    name: "620 Rip Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "rip-street",
    tokenAddress: "Exe7RmeNiU1wAcprZ6Ro98QLru2wbMCGDyzfcsF2nNYE",
  },
  "621-cluster-lane": {
    name: "621 Cluster Lane",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "cluster-lane",
    tokenAddress: "EFM4AEQBJ7GjzZtaH6wugreBk8FrTG4CShmThSR5Tz9v",
  },
  "621-helix-avenue": {
    name: "621 Helix Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "4TR51kinnpdWA8V2QKwLXrgRFrtwbxsBzcPjdjzZztnA",
  },
  "621-rip-street": {
    name: "621 Rip Street",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "rip-street",
    tokenAddress: "EuEFSSeGXWEAGz7cnUwhfsSrfmJhU3GRfNpPDyMaasCa",
  },
  "622-cluster-lane": {
    name: "622 Cluster Lane",
    biome: "volcanic",
    frameType: "shuttered-blue",
    streetId: "cluster-lane",
    tokenAddress: "r5HNcawUSc8Fxv2YZPP4i4SrcfG7mtzw5VPpxsnjhbV",
  },
  "622-dip-drive": {
    name: "622 Dip Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "4thVJFoLYyYU5t2hiJW6M9NgZmwih2gPu8s53fkdTXQ9",
  },
  "622-rip-street": {
    name: "622 Rip Street",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "rip-street",
    tokenAddress: "H3Qj6Wpjchy5kK943VTx1jRfFTemLJuVK7BHAartNKdv",
  },
  "623-dip-drive": {
    name: "623 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "HLppcBpZP1Z2ZqZSbWAVHDq5CNY2X8bXJDJ9Rr7gRZ3c",
  },
  "623-helix-avenue": {
    name: "623 Helix Avenue",
    biome: "underwater",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "79d539bXb56bussrumjjgxrmuJg7oeucvfifpyKqFb1k",
  },
  "624-cluster-lane": {
    name: "624 Cluster Lane",
    biome: "city",
    frameType: "occult",
    streetId: "cluster-lane",
    tokenAddress: "FWFGTc1fXvJqzRucPHDjkHojFB1eQWUdBxTaqw7yRzHE",
  },
  "624-dip-drive": {
    name: "624 Dip Drive",
    biome: "city",
    frameType: "rounded-lavender",
    streetId: "dip-drive",
    tokenAddress: "CJb7wZzZzYapaD7J3HfJtcJgs1jaD8TNJuxDLgnkCTRt",
  },
  "624-poplar-street": {
    name: "624 Poplar Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "poplar-street",
    tokenAddress: "7X23wtZANxt9sg4vPugGYD8er94yN43vNMp5W6dr5PEM",
  },
  "625-cluster-lane": {
    name: "625 Cluster Lane",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "cluster-lane",
    tokenAddress: "6eZ8KByaBnwscuCfNzcbr2jK69VtjU4wirGCjUc38Zyj",
  },
  "625-dip-drive": {
    name: "625 Dip Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "Eu33yxyHWEc4kCDeDHqGc4uZBz6HZ16wF3u7YGS4CRvZ",
  },
  "625-rip-street": {
    name: "625 Rip Street",
    biome: "city",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "H6Ym9MeQ6KtZnVfzaoz8f3dqLEMjVJEA8RPaWBAAPfeL",
  },
  "626-dahlia-drive": {
    name: "626 Dahlia Drive",
    biome: "mountain",
    frameType: "occult",
    streetId: "dahlia-drive",
    tokenAddress: "4CkYAq42gzZGLRysQbWoZgamX6h3pepDaSGvtCkQoiPk",
  },
  "626-dip-drive": {
    name: "626 Dip Drive",
    biome: "treehouse",
    frameType: "occult",
    streetId: "dip-drive",
    tokenAddress: "7d8GeP8DXFBYpVTLfiv48DrWTyMYm77esckULUZ72BKL",
  },
  "626-poplar-street": {
    name: "626 Poplar Street",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "HFLKrQPrByEMus3w25BEEHLsihFrvj5cJas2SzDZ5gBF",
  },
  "627-cluster-lane": {
    name: "627 Cluster Lane",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "cluster-lane",
    tokenAddress: "JATfH1CoECndpjcSXrdV5JLFzUc9mJXJ9atV3a3PZHiC",
  },
  "627-dip-drive": {
    name: "627 Dip Drive",
    biome: "city",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "5ppcjWgmqcWzvSEcUUvtpPjJHoTFSKPHQzrE42gx2CHM",
  },
  "627-helix-avenue": {
    name: "627 Helix Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "CQPWdSnr3odMje3J3LyTTiMizTwQrx3cWCBF8nbdYD2c",
  },
  "627-rip-street": {
    name: "627 Rip Street",
    biome: "volcanic",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "3Xhvfe945A9YY7wKVsvPQUWLsp1HwwRkiKTEQz4SQ5qE",
  },
  "628-helix-avenue": {
    name: "628 Helix Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "3nsj8TDg1DTh1KdLHcvp7xYyESMyKVaADbbsWpT3DszF",
  },
  "628-poplar-street": {
    name: "628 Poplar Street",
    biome: "arctic",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "58ZPa3RJQSeaxihHB3MrLjUTrvkthuhmEhBP64Ro1mMC",
  },
  "629-cluster-lane": {
    name: "629 Cluster Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "cluster-lane",
    tokenAddress: "6jtDP3E1fHXBaaMmz8xJEZzuGGhDfV93CPm1F6eW8fMm",
  },
  "629-dahlia-drive": {
    name: "629 Dahlia Drive",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "dahlia-drive",
    tokenAddress: "2R83qXUGDecocfbt49RqA5JjBsuNFNR3cmhVDU395bJQ",
  },
  "629-dip-drive": {
    name: "629 Dip Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "RUc4FsUepmUKjFUuPnadjRAthyPefq2UN9r65FvSiEa",
  },
  "629-poplar-street": {
    name: "629 Poplar Street",
    biome: "woods",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "C7LjH912njaB81t35yxvVM9dmAVDh2rT797VQJFByYXp",
  },
  "629-rip-street": {
    name: "629 Rip Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "CxS9EAuLTtmMx7Ee2GXTp9bHULLuQGTn3pvzx2tjYKsk",
  },
  "63-abbey-road": {
    name: "63 Abbey Road",
    biome: "beach",
    frameType: "crystal",
    streetId: "abbey-road",
    tokenAddress: "Gq7r185eJA9ydimhShuLf78Eyr7VBYyHNDBpayzj4U8s",
  },
  "63-ape-lane": {
    name: "63 Ape Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "ape-lane",
    tokenAddress: "3xPn5CMthEUspHhCEig2QNumjCHqWCFN8CcYzHCb2rd6",
  },
  "63-king-street": {
    name: "63 King Street",
    biome: "arctic",
    frameType: "shuttered-dark-green",
    streetId: "king-street",
    tokenAddress: "5iVrqunToye8e9y2XDayS7WbWNJiSgpBcMCMSPNgHKoN",
  },
  "63-rosa-street": {
    name: "63 Rosa Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "rosa-street",
    tokenAddress: "BtFQ6A5Z6XPsEan7Tue1nZCFKn1cbkML13otBT85qwdK",
  },
  "63-stewart-avenue": {
    name: "63 Stewart Avenue",
    biome: "city",
    frameType: "rounded-green",
    streetId: "stewart-avenue",
    tokenAddress: "2gEj1rZsQ8iz4gAXspRQ5wM9kgDVyTHipafprxHWqhLm",
  },
  "63-supple-circle": {
    name: "63 Supple Circle",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "supple-circle",
    tokenAddress: "9QJqbNQhMkHGrtBaJE2cZ1MXQwJ4L6utAc4634hAbW4c",
  },
  "63-teeps-lane": {
    name: "63 Teeps Lane",
    biome: "field",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "4tMSTe4sHi6ePJWCJ5JnLmtsn4Mnh6SJqzZYRkcKacmP",
  },
  "63-wall-street": {
    name: "63 Wall Street",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "wall-street",
    tokenAddress: "CzvT6bifiQmpYZqwjrcKhJJK6h9GN26FFqUzXVv22zPR",
  },
  "630-clipping-drive": {
    name: "630 Clipping Drive",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "clipping-drive",
    tokenAddress: "AW7sVKq5pA4skL2pgDzN5d7RmENbv5vodPD9S8E76d5Q",
  },
  "630-helix-avenue": {
    name: "630 Helix Avenue",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "9e2UGD26z79VSS4iAH7Y9UTuTREXDKaw5eMeNpfKEFTi",
  },
  "630-poplar-street": {
    name: "630 Poplar Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "AhVKMiYrvZbzuTVK3dHKVwYvjUWkrvzKF9X9wNamMfCv",
  },
  "630-rip-street": {
    name: "630 Rip Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "rip-street",
    tokenAddress: "Fb8JoyfksztoubYH5ZDh6SHfECyPH3eLjjizjXDzKZm4",
  },
  "631-epoch-avenue": {
    name: "631 Epoch Avenue",
    biome: "treehouse",
    frameType: "shuttered-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "FnXmvdwmt5rwb1VdjkbkzC5ZmH15AkDvj1s2wamKhxWb",
  },
  "631-poplar-street": {
    name: "631 Poplar Street",
    biome: "arctic",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "HNaxyQRqmBnfUhcTtvcFC82nyg7AhkwM9iCWmmcs3MiV",
  },
  "631-rip-street": {
    name: "631 Rip Street",
    biome: "city",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "BZe1aJK2ALf9yLUVqbvjVsZXcee7VtqfWrBiToFwhxvm",
  },
  "632-clipping-drive": {
    name: "632 Clipping Drive",
    biome: "underwater",
    frameType: "rounded-lavender",
    streetId: "clipping-drive",
    tokenAddress: "8rdSmrxb34Jtmnjkeje4qPX4xPtnaHaYieLiNwEHSTts",
  },
  "632-dahlia-drive": {
    name: "632 Dahlia Drive",
    biome: "underwater",
    frameType: "crystal",
    streetId: "dahlia-drive",
    tokenAddress: "3y1A6zRxSK7mQFAwataWvn8KgDKKLzrujd9tmin1DCia",
  },
  "632-dip-drive": {
    name: "632 Dip Drive",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "dip-drive",
    tokenAddress: "CzUsPcbAuSJHvtUFyHBqozvHeUrecCLZ586iN6UgJ4zk",
  },
  "632-epoch-avenue": {
    name: "632 Epoch Avenue",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "epoch-avenue",
    tokenAddress: "DXujLWQ9qdELn9cwowpdSowbDgNSj3mnqTnG4XwBzBbf",
  },
  "632-helix-avenue": {
    name: "632 Helix Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "DEFzM4RZq26Wr6URfjfjWTZGjoZvVgsCrgH47YXXsoZc",
  },
  "632-poplar-street": {
    name: "632 Poplar Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "poplar-street",
    tokenAddress: "Bw84grBWqRYF9BZ8DoyEThCFHDmwA791RyPapusjqAHD",
  },
  "632-rip-street": {
    name: "632 Rip Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "HXpzn1JUAtJVXuNK74h1epRersqgAex7rknGHv4BeEbr",
  },
  "633-dahlia-drive": {
    name: "633 Dahlia Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "6Q1cay5LZmE49mmHWUVEUVbWd4dS4eYC1a3CMqn1o69P",
  },
  "633-dip-drive": {
    name: "633 Dip Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "DwmD1mRwiwPvkSCPoybBSLh1nmaqBpkENh7XZtC1cK2z",
  },
  "633-epoch-avenue": {
    name: "633 Epoch Avenue",
    biome: "woods",
    frameType: "shuttered-lavender",
    streetId: "epoch-avenue",
    tokenAddress: "DVTXSTxTAe1hKeuF88Wm9E8n1s17rzBhnfm4ZkYT5ZLc",
  },
  "634-clipping-drive": {
    name: "634 Clipping Drive",
    biome: "field",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "AzMMFdp8hikrU8qFJ4TdSw2UH9isuYUBFN7DYSwWaWB5",
  },
  "634-dahlia-drive": {
    name: "634 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "dahlia-drive",
    tokenAddress: "2tt8JNd9BHsQaBDwbzusTPc7EASawqFgfM6LhoKSiHPK",
  },
  "634-epoch-avenue": {
    name: "634 Epoch Avenue",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "epoch-avenue",
    tokenAddress: "DztdM2PhCCoRZg65vqxE8vEeVo3LKgnHW2huTyj7ieMj",
  },
  "634-helix-avenue": {
    name: "634 Helix Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "24W1NUkzByf5SDh7ocJyLHhFFtX3fmkuJobR5XVNstcE",
  },
  "634-rip-street": {
    name: "634 Rip Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "GweDw2XbnhUu7kbq5XH3cCYqVtmCjxZCWhKo8G58PDrz",
  },
  "635-helix-avenue": {
    name: "635 Helix Avenue",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "helix-avenue",
    tokenAddress: "CwQSkRwHaNWvawdEyAKu4EAZXsqEm21xU7b8qZqKPTk5",
  },
  "635-rip-street": {
    name: "635 Rip Street",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "rip-street",
    tokenAddress: "Exg1yHYDGF2fTQRoSt9DKnCxd68HmTq79NTQ5GhGKoMH",
  },
  "636-clipping-drive": {
    name: "636 Clipping Drive",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "2fMjSSKhVD4mo9KrSAcem3Ad7e8c2mBbbSymtbb6EEzz",
  },
  "636-dahlia-drive": {
    name: "636 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "C8jAkNcdSoEbUDPU7zYEK7eXuPbqzzLzPPmmpBHoYHRc",
  },
  "636-dip-drive": {
    name: "636 Dip Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "dip-drive",
    tokenAddress: "E7gUrkM2EMuycWEMqqKog9oHqmqTLKNMQmqC2cfb5cED",
  },
  "636-epoch-avenue": {
    name: "636 Epoch Avenue",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "epoch-avenue",
    tokenAddress: "5GsCaat2rhvPhokofnh73dqwH1BAEc9nuy6Pfpo6Vihg",
  },
  "636-helix-avenue": {
    name: "636 Helix Avenue",
    biome: "city",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "Ekt7Z9DXrCBYCAnfWw8uYLsSYQHjHHFJqJo577tidsjE",
  },
  "637-clipping-drive": {
    name: "637 Clipping Drive",
    biome: "field",
    frameType: "rounded-green",
    streetId: "clipping-drive",
    tokenAddress: "EGpucXTcS8qgH7xy1J8tRookkc2ng3424ehPLedSL9b1",
  },
  "637-dahlia-drive": {
    name: "637 Dahlia Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "dahlia-drive",
    tokenAddress: "EHJhz5CveXsnRFKriw6TNp9svS6DbXUzN9YfhhEr4E9M",
  },
  "637-dip-drive": {
    name: "637 Dip Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "dip-drive",
    tokenAddress: "9mS1hNYKKsDg93wXQxoPCRyKRnx4Heqo6SM4pa53YjWx",
  },
  "637-epoch-avenue": {
    name: "637 Epoch Avenue",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "2h6mFUzgPgkYYySJz7RJ8U51F1y11LLnuVrjgaC3RNZJ",
  },
  "637-helix-avenue": {
    name: "637 Helix Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "BfkEbJ1mWHPRmzwupoRtbuF6THMxjcty9s2c16WAm57d",
  },
  "637-rip-street": {
    name: "637 Rip Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "3bShUeXipVxExUgepGQ3GHugcDoL6mowmQdHdjWzvZJs",
  },
  "638-clipping-drive": {
    name: "638 Clipping Drive",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "6ew5CdiGoCg14gATXNRKPKFBTSe27CSKAa6qPVuni3F2",
  },
  "638-dip-drive": {
    name: "638 Dip Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "6sLrno63PVLGisCf5xqEeeRaRGKS9otUxHyjtRanZFVy",
  },
  "638-epoch-avenue": {
    name: "638 Epoch Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "jLJDSH6CFyeYJfYPDLFdnk27txL9Wrqnzgjq6RwHC9S",
  },
  "638-helix-avenue": {
    name: "638 Helix Avenue",
    biome: "woods",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "9Bq456XxA7FjzWSnmxwrVMVVawnHmgjaAXNNaAyZRTA6",
  },
  "638-rip-street": {
    name: "638 Rip Street",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "rip-street",
    tokenAddress: "8C2BmgCt798fiPQhXYvur6AnXJhNE5W5EpZUET8eJPKJ",
  },
  "639-dahlia-drive": {
    name: "639 Dahlia Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "4XnpbBLb7ywK7qZ6qyTj1DVq2N5am8m7caubEMZbvH8v",
  },
  "639-dip-drive": {
    name: "639 Dip Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "dip-drive",
    tokenAddress: "BzqGFzcwkRw8diew1ugAoG8DPQHVoYbhbzYi1mLCMmkd",
  },
  "639-epoch-avenue": {
    name: "639 Epoch Avenue",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "EW6V6nmx8bHUFhDhiMD1iFXsYR7mfDSKVyAgakbENgxG",
  },
  "639-helix-avenue": {
    name: "639 Helix Avenue",
    biome: "woods",
    frameType: "occult",
    streetId: "helix-avenue",
    tokenAddress: "25WLoxAUaK3PFptQQer7k9j1hmxZW1jyUWiGGuYbEoo5",
  },
  "639-poplar-street": {
    name: "639 Poplar Street",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "4K7mWd1idQNoVGCwDkfJ29NySJAAtFiAZEV4X3kVxndr",
  },
  "64-abbey-road": {
    name: "64 Abbey Road",
    biome: "mountain",
    frameType: "crystal",
    streetId: "abbey-road",
    tokenAddress: "8YRgbBm6CLp2jcfiMtoRia5zQRoRtfBV3CLaTjc7kJZ7",
  },
  "64-king-street": {
    name: "64 King Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "9ixnGeXArFnsmKuheMcNSKDj2gLE8aCLbdWYddSkyA1L",
  },
  "64-rosa-street": {
    name: "64 Rosa Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "Doa5FRwogNoVvTBeSGvCho5ceToBm5tPr8Dp7FonSNFL",
  },
  "64-stewart-avenue": {
    name: "64 Stewart Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "FS1vmND4Ltwx3PiTnN2QuWsU4LUUqnXVyWbLkBHoEZtW",
  },
  "64-supple-circle": {
    name: "64 Supple Circle",
    biome: "volcanic",
    frameType: "shuttered-green",
    streetId: "supple-circle",
    tokenAddress: "AQGuzDsSDApYKnsgQweFG49hu16MfDAA9y87CFEPRYXi",
  },
  "64-wall-street": {
    name: "64 Wall Street",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "wall-street",
    tokenAddress: "FmxUBrGZf58iEWyQL1eJqtJxM27nV81jqkDmKaJjgt5H",
  },
  "640-clipping-drive": {
    name: "640 Clipping Drive",
    biome: "woods",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "7d5Hj6LsDjSNkM7emW5d82T3o93THNzmd1i5jScjMspU",
  },
  "640-epoch-avenue": {
    name: "640 Epoch Avenue",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "epoch-avenue",
    tokenAddress: "8D5Jhp7qXgryyXVaZefP99Ji4dCbBH3L66CjHoXTZEL9",
  },
  "640-helix-avenue": {
    name: "640 Helix Avenue",
    biome: "city",
    frameType: "gold",
    streetId: "helix-avenue",
    tokenAddress: "3P8aU7yzf2ARZwLkiRqrTrz4ADAdDh3dQ7koQ7DvbCac",
  },
  "640-poplar-street": {
    name: "640 Poplar Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "EWAnk5roh45xLaJezjimswQWyPKZmPAfV1aGCvkYKqwm",
  },
  "640-rip-street": {
    name: "640 Rip Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "Df3mcKx2S8Y2wpVCqhxgLeFSsBLJvEdpGMYtGHvEv8ij",
  },
  "641-clipping-drive": {
    name: "641 Clipping Drive",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "Cck6zK97CW2KVbNqNXfw6NS2ZPmF1GHsGKm26qJ2mzNW",
  },
  "641-dahlia-drive": {
    name: "641 Dahlia Drive",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "3sza4wwpyB9WvEkonARbqa1AWfGu8hE9YZ611cnfzx8c",
  },
  "641-dip-drive": {
    name: "641 Dip Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "41kyAZLwYmcU6LK6uVhhaDV6FjCFmZZSmX5vsPJvB35z",
  },
  "641-epoch-avenue": {
    name: "641 Epoch Avenue",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "epoch-avenue",
    tokenAddress: "5GUZbVcYPtWPdnApn8vbegJD5RDU7QBARDPpF6MsqHdE",
  },
  "641-helix-avenue": {
    name: "641 Helix Avenue",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "Hr4dz5KEaWszFo7RxEmKL8DSuTvcrfZXCGhWhqKE4KoK",
  },
  "641-poplar-street": {
    name: "641 Poplar Street",
    biome: "beach",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "74QJPKqpF9B3fqZbN1mBj72P15rfH6ZGWUi4v5SErTmF",
  },
  "641-rip-street": {
    name: "641 Rip Street",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "rip-street",
    tokenAddress: "E2dktJCTpmQ5t2pwMTUmLHkoNRkJDKLvQjWrfDhTXDkh",
  },
  "642-dip-drive": {
    name: "642 Dip Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "FbPGoeuM8Gk4wCYvdA1FuDhrjbdjjgJXCMEmm4WbXbez",
  },
  "642-helix-avenue": {
    name: "642 Helix Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "EjDfCJnH7vHJRDMAqD9LdnHiDmk9vR9ENFNNoT8mAAsR",
  },
  "643-clipping-drive": {
    name: "643 Clipping Drive",
    biome: "city",
    frameType: "rounded-green",
    streetId: "clipping-drive",
    tokenAddress: "FpdYvNjW6aX54oFGuqtyBUgZ3ReJXNVHSGTxRQgSEuUb",
  },
  "643-dip-drive": {
    name: "643 Dip Drive",
    biome: "volcanic",
    frameType: "occult",
    streetId: "dip-drive",
    tokenAddress: "3dccEFwtuKXgmfhLzUf2W5vpXsXLT7piQ6xyqQq2jQgQ",
  },
  "643-epoch-avenue": {
    name: "643 Epoch Avenue",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "7ELeVnYNR6Cux6c9wbsEEcwX2xowfDqxJvebKrwBYk6r",
  },
  "643-poplar-street": {
    name: "643 Poplar Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "poplar-street",
    tokenAddress: "9pe2imgNz2ppEwX56ap2if1wacVv35r9kv4EAk6F74GF",
  },
  "644-dip-drive": {
    name: "644 Dip Drive",
    biome: "woods",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "AWowdT6vkjgtjibewp3RdPHxJx1nB3f7cTWVgTetBMFB",
  },
  "644-helix-avenue": {
    name: "644 Helix Avenue",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "BA8beprBXSBhVhGCkxgPhrK7GadcjNy3bWYUBQtMkLoE",
  },
  "644-rip-street": {
    name: "644 Rip Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "4aoYyhBFs1fn2gvQ3H22WckUpUVngkwtpJ4ujWL138uZ",
  },
  "645-epoch-avenue": {
    name: "645 Epoch Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "epoch-avenue",
    tokenAddress: "ATtHv96EfekcW2DFhwBVarXuBbVM7aWSh51Q5PzW58rN",
  },
  "645-poplar-street": {
    name: "645 Poplar Street",
    biome: "arctic",
    frameType: "crystal",
    streetId: "poplar-street",
    tokenAddress: "2yckeJPBgDtgEvhTv2VPMqscfgc4yNEqmbUT1LthrvC4",
  },
  "646-clipping-drive": {
    name: "646 Clipping Drive",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "clipping-drive",
    tokenAddress: "9rZZfgQguHFLSYYRPstPyeb8PqmgVJ9pi7qKk6PzaxgS",
  },
  "646-dip-drive": {
    name: "646 Dip Drive",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "dip-drive",
    tokenAddress: "J3cK2PhrwySNk9xYD5guuz1ghXaYdhcHLVBKtLXpXcLn",
  },
  "646-epoch-avenue": {
    name: "646 Epoch Avenue",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "3gpbsVSoVWTCg6AXVWZUe7tenbJPP9LhAw4WQJWwMQXo",
  },
  "646-helix-avenue": {
    name: "646 Helix Avenue",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "helix-avenue",
    tokenAddress: "7GagTcghNMBX3bMf7ZCwXrnAUcc41zn959bkj8T66pYM",
  },
  "646-poplar-street": {
    name: "646 Poplar Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "poplar-street",
    tokenAddress: "5h6MPy1cfxkrtaPPqoNWP1UUPD7Pk21RmkN41ErbS7sS",
  },
  "647-clipping-drive": {
    name: "647 Clipping Drive",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "6H8ViJyrwjyWyz71s5AXtGZDEpA9rM9Jqsip58CWpqat",
  },
  "647-dip-drive": {
    name: "647 Dip Drive",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "dip-drive",
    tokenAddress: "9sUQo95MY65muWbf9YJvQLFoUmLvZmm9z4sZRTXGKP7j",
  },
  "647-helix-avenue": {
    name: "647 Helix Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "Cf5uebPQQRDTWJMmqpfKzfTG36YWHeNpRH9omJ8ebJfU",
  },
  "647-poplar-street": {
    name: "647 Poplar Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "D34AmSu4ytngFNozDMNrWV5iCK7PGR56zfwbvMC3nShJ",
  },
  "648-dip-drive": {
    name: "648 Dip Drive",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "dip-drive",
    tokenAddress: "5wZHeDRLV2ZXuHGdVRrTJm8fogxXEgQWc3cUxkH7U6nR",
  },
  "648-epoch-avenue": {
    name: "648 Epoch Avenue",
    biome: "woods",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "EStjPe1oGVh6rHso7AAPDJGvRqZyqYMgrAkNyhzC59Zr",
  },
  "648-helix-avenue": {
    name: "648 Helix Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "FkXLduEpVFsMK6Diy2E4Xd11N667Lw4CFDmvzbCihb84",
  },
  "649-clipping-drive": {
    name: "649 Clipping Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "AtJ2vLsM1qU2JokaxETAxaprbvZsJHt62mdz4UZy7fS1",
  },
  "649-dip-drive": {
    name: "649 Dip Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "dip-drive",
    tokenAddress: "4WjrcYXYPnbSSAHJkGB5evcq6DQSwFpcx5B6HbkDDs1j",
  },
  "649-helix-avenue": {
    name: "649 Helix Avenue",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "CDnffAaMKDTGGTTfZWuAV6HQiepYdBHsAeJ9MxWnpnX1",
  },
  "649-rip-street": {
    name: "649 Rip Street",
    biome: "city",
    frameType: "occult",
    streetId: "rip-street",
    tokenAddress: "JBnmsdEuUPzNeDaCB3o1k67KJtGGZTEkGcsY8foqoVbs",
  },
  "65-abbey-road": {
    name: "65 Abbey Road",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "abbey-road",
    tokenAddress: "2TRJoAB4GNdCyrEbi8FmokfxDmiqKrYvEnAyH9r9ZCPr",
  },
  "65-ape-lane": {
    name: "65 Ape Lane",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "ape-lane",
    tokenAddress: "GGzgKawaTathWysbTXgw6HWThanTHrCsSU8AFnFGSApD",
  },
  "65-king-street": {
    name: "65 King Street",
    biome: "treehouse",
    frameType: "rounded-yellow",
    streetId: "king-street",
    tokenAddress: "FhUhvpYRuLR4KYJXosSR9QSHowza4KePB1mZUii415vW",
  },
  "65-rosa-street": {
    name: "65 Rosa Street",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "rosa-street",
    tokenAddress: "969uxQVt2bQuREK1PqTnarWwRhNpQwAfoMwQqAaCKf2o",
  },
  "65-supple-circle": {
    name: "65 Supple Circle",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "5bEUG2Pmbnpv4guSrr5SGsu6Nzp5jfqdYTeZdsdPsbdQ",
  },
  "65-wall-street": {
    name: "65 Wall Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "wall-street",
    tokenAddress: "5Ba6sgQa3ndeQ4kxvDLGcY7JBYzMRbFHhMwf2BPaxJyh",
  },
  "650-clipping-drive": {
    name: "650 Clipping Drive",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "3WofyKxfMpZzSxoHXaZGhTctEKvh1JU4T6f6hKjK69vm",
  },
  "650-dahlia-drive": {
    name: "650 Dahlia Drive",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "dahlia-drive",
    tokenAddress: "2i33xTWLYjBAb5Wpu6s59zvWwPcankrx1QDCC6mUdHMK",
  },
  "650-helix-avenue": {
    name: "650 Helix Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "CNuAT6YKw3k3JNpppBEuFZtuV9m7rgYiU2D7M6yU2jUN",
  },
  "650-ramsey-street": {
    name: "650 Ramsey Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "ramsey-street",
    tokenAddress: "Gqro16bUnRJa6BqEbnNYTKbTeJuEpguqNxHLr5Z5TjT5",
  },
  "651-clipping-drive": {
    name: "651 Clipping Drive",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "bqF5atXiqevs7YrnZEmfDaW851KszSPCbJYfyhxM2if",
  },
  "651-epoch-avenue": {
    name: "651 Epoch Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "epoch-avenue",
    tokenAddress: "FC5a7pQpHnefZZW48RGMQdxPmVjre7nsfx3BAS1udKQD",
  },
  "651-helix-avenue": {
    name: "651 Helix Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "helix-avenue",
    tokenAddress: "CTB8opULnSN5oZZYKXX6dTb6tQrrsjkvoy626h8JaSHS",
  },
  "651-poplar-street": {
    name: "651 Poplar Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "poplar-street",
    tokenAddress: "8hpkUdzDt3nD8HRKCUr4tZQbFJN4qhSHvvzZuhhS2NFB",
  },
  "652-clipping-drive": {
    name: "652 Clipping Drive",
    biome: "mountain",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "uEgrx8LP52xi2FMj9cQv9yuKb9sfLYdiqqLE9HyhGYY",
  },
  "652-dip-drive": {
    name: "652 Dip Drive",
    biome: "field",
    frameType: "glass",
    streetId: "dip-drive",
    tokenAddress: "EzGcN2EjwJRqc19p4HPiFXTnsWp2tGKn1AZnBmt4ALiv",
  },
  "652-epoch-avenue": {
    name: "652 Epoch Avenue",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "28cd1aQsUFNLg5MFc1PDjMaJMEgbma8q933vxpUz3RzS",
  },
  "652-helix-avenue": {
    name: "652 Helix Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "9RWnC5CwqyLdaCjUfjDophaRcRskaC9yoASK1AqJA81t",
  },
  "652-poplar-street": {
    name: "652 Poplar Street",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "poplar-street",
    tokenAddress: "ELMKPggwetB5yMUaE3NZj3BpntfPUHByQvboX946Yjub",
  },
  "652-ramsey-street": {
    name: "652 Ramsey Street",
    biome: "city",
    frameType: "glass",
    streetId: "ramsey-street",
    tokenAddress: "67Jhh1xJyKGstYGo7VCAFMzU9uwwWoMCu6mkcCPoSX2F",
  },
  "653-clipping-drive": {
    name: "653 Clipping Drive",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "GKWbVi3smU4Us8sgjdvtmSXWqRqqhbuQSp1hrC2Fj18s",
  },
  "653-epoch-avenue": {
    name: "653 Epoch Avenue",
    biome: "underwater",
    frameType: "rounded-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "6RMKhYX9J2D2qDdtQvpVGvW3ZEYHRv6yfDkTPWqtn4kE",
  },
  "653-ramsey-street": {
    name: "653 Ramsey Street",
    biome: "mountain",
    frameType: "gold",
    streetId: "ramsey-street",
    tokenAddress: "2eBuqhx6mX73QsKsiySagbQkqrqUuYtn7sjRTJAdhfHY",
  },
  "653-rip-street": {
    name: "653 Rip Street",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "AqH3rQS3m2NSG98tpP94uMPHVR5EgGNZMDQTDcTBPQD5",
  },
  "654-clipping-drive": {
    name: "654 Clipping Drive",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "clipping-drive",
    tokenAddress: "98YGThuMW3MeoNsCTPP3onzJu8LWWc1t7JYCRi77Lv78",
  },
  "654-dahlia-drive": {
    name: "654 Dahlia Drive",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "dahlia-drive",
    tokenAddress: "6FS8HPmw1hqNDF8d3SJyF8JqAg7cH4tZ9aPnMKEYx86c",
  },
  "654-helix-avenue": {
    name: "654 Helix Avenue",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "helix-avenue",
    tokenAddress: "4kfPKSLx9iaau9V7FmATUar6f58xeNaePeW6xFcrfq9H",
  },
  "654-rip-street": {
    name: "654 Rip Street",
    biome: "arctic",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "8wRy7yucaSZCC9YXs3ahEHSvvAjF73WxN3v4pK51oFuD",
  },
  "655-clipping-drive": {
    name: "655 Clipping Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "FV3HQeMn4fTKGEwJWrgKyu2tP1jieX6pv6LXqxLoAWun",
  },
  "655-epoch-avenue": {
    name: "655 Epoch Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "epoch-avenue",
    tokenAddress: "49y1z5arNRzY3RJpgUJttr8oDwiL1NTSMunts4Ni7MNa",
  },
  "655-ramsey-street": {
    name: "655 Ramsey Street",
    biome: "city",
    frameType: "occult",
    streetId: "ramsey-street",
    tokenAddress: "558j8KK9sviUwmMFDDbGWgabJTfAtPqYfUy3oBLxjjP7",
  },
  "656-clipping-drive": {
    name: "656 Clipping Drive",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "HWezyP1KAqafGUF2wrbE3RYJP6mbvQXcE6udutFkpNvU",
  },
  "656-dahlia-drive": {
    name: "656 Dahlia Drive",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "AEWG7TcdEWy696cf1QQpVcSfUwJbv4NroasXQWFbgmi7",
  },
  "656-epoch-avenue": {
    name: "656 Epoch Avenue",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "epoch-avenue",
    tokenAddress: "6wWn5L9S3CCrMp3HmK7nwYSpTZ83m9yKXNNzL87qioj3",
  },
  "656-ramsey-street": {
    name: "656 Ramsey Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "ramsey-street",
    tokenAddress: "6qyUb3NVsSX2W8D4PtnHvsBKWK5o3XQKA5FffgPpTDcT",
  },
  "656-rip-street": {
    name: "656 Rip Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "56SdJ76GmtPRW5wy93DGXtjXpfHBfkLVr2HgRXx1dLsu",
  },
  "657-clipping-drive": {
    name: "657 Clipping Drive",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "clipping-drive",
    tokenAddress: "GcCi8dr97FxG92QWQVfmicCZCxZNDAnmuMXxzHAcL9pY",
  },
  "657-epoch-avenue": {
    name: "657 Epoch Avenue",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "GMKFHH57ESZR8WFVYLYFxWyTGMvQx3Gin4ap9WjnG6xU",
  },
  "657-helix-avenue": {
    name: "657 Helix Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "42fYMDaZfHWaok7mRNHKfcZUaUsmGPVK9fZkEvP9gaPm",
  },
  "657-poplar-street": {
    name: "657 Poplar Street",
    biome: "city",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "F6JZiCoiDtwoFzrRceo4JamEZZovpp3ZZSvc8gW75SeM",
  },
  "658-dahlia-drive": {
    name: "658 Dahlia Drive",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "9WLCqgYrwkXMkUQ9frMbiDNq3FEoTaxMQgBZHGxXjiz6",
  },
  "658-epoch-avenue": {
    name: "658 Epoch Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "Cdz8gobYbvtz7isG1sc7wZwqwgbAuqpAgYHqCbCEzLDk",
  },
  "658-ramsey-street": {
    name: "658 Ramsey Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "ramsey-street",
    tokenAddress: "63ShQaA9CWKvXLUBcZX9UTFPgHz69PEUpDhBPNkJyDkj",
  },
  "658-rip-street": {
    name: "658 Rip Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "2aF8WKhVghSsr5qsyTAXSWzfqLnj2bJXwrg1KhZ2r16M",
  },
  "659-clipping-drive": {
    name: "659 Clipping Drive",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "clipping-drive",
    tokenAddress: "9jQPoWfZwbVsYZgVHE2YS4XPQZGaQ2ZnAovPgrGZfpds",
  },
  "659-dahlia-drive": {
    name: "659 Dahlia Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "dahlia-drive",
    tokenAddress: "6k6DvrFKUcc41u31GTP2C5F74P2PgeN9biWLdM5b4DBd",
  },
  "659-helix-avenue": {
    name: "659 Helix Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "8mUgizbnPg3urySi6hzFumGajGDm8HXGYwN51vhePUaM",
  },
  "659-poplar-street": {
    name: "659 Poplar Street",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "poplar-street",
    tokenAddress: "AcQHbyFmt9cSkbEZBUkNRBUwcsZz8rC5wjps8a9dLcx7",
  },
  "659-ramsey-street": {
    name: "659 Ramsey Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "ramsey-street",
    tokenAddress: "HCJBvRANKBF7gyWPvZB4emnfTKdJmn2Gw6ESC8E4xprt",
  },
  "659-rip-street": {
    name: "659 Rip Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "6A92UweiibsneVhFgGSm1ttKJEBTp3HW1j1q6xjga8SX",
  },
  "66-abbey-road": {
    name: "66 Abbey Road",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "abbey-road",
    tokenAddress: "EN6RQVwpGoKZrZquHG54d6Y2LkBTXDMpzRihWwNTgiQb",
  },
  "66-ape-lane": {
    name: "66 Ape Lane",
    biome: "city",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "DXvzaNZi9etuJkujmVdTwuK1trxjv7rsHyA6Xh4iotuj",
  },
  "66-king-street": {
    name: "66 King Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "Hz9qrWLtHpEABHqCQ4FTZDboWvRJtJR91Uptthf96gBw",
  },
  "66-ligma-lane": {
    name: "66 Ligma Lane",
    biome: "beach",
    frameType: "rounded-pale-green",
    streetId: "ligma-lane",
    tokenAddress: "FeLEwuyePwV1GwinUGckzRwtJyd3xFQyEguwjKRqex4t",
  },
  "66-stewart-avenue": {
    name: "66 Stewart Avenue",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "AEfDoTNsRHksrrhQ3kkktqKUdSeWts6vN5JtqfHd8wxo",
  },
  "66-teeps-lane": {
    name: "66 Teeps Lane",
    biome: "field",
    frameType: "rounded-red",
    streetId: "teeps-lane",
    tokenAddress: "HX9xpYTL1DSgS5zgFfZkcqwf2yzGqwx7CyUzmZn8a67p",
  },
  "660-clipping-drive": {
    name: "660 Clipping Drive",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "8tdAhw3P9zyAa1iTyUZ4BLCCNhQqhoqN93RnxvXnDv4K",
  },
  "660-dahlia-drive": {
    name: "660 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "dahlia-drive",
    tokenAddress: "8zXbNQryypPXekiuiQPHmGwJxcChTwJRMuL7HzdirNaV",
  },
  "660-ramsey-street": {
    name: "660 Ramsey Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "ramsey-street",
    tokenAddress: "GvzUErtww2ZsdNEs7HPpaimQyPkj2zNM4tEPGGZbKoHe",
  },
  "660-rip-street": {
    name: "660 Rip Street",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "Aj5ysP7U5KCNZCXshZWViNz58qo8sDDzPRjvmHKYuB7D",
  },
  "661-dahlia-drive": {
    name: "661 Dahlia Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "Hv2JfFc558bwomCrd1HLMuj5SBoLKYRvuJ12Gv6RSTBX",
  },
  "661-helix-avenue": {
    name: "661 Helix Avenue",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "6koXwTDHM6EnNWmKUrNAUXMNu8TWuepg9eHAs1CrUAxN",
  },
  "661-poplar-street": {
    name: "661 Poplar Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "EDEMZB2CpG7amXMAtieS8yrqpQcRpcrhuJRhePearQe5",
  },
  "661-rip-street": {
    name: "661 Rip Street",
    biome: "city",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "B1VwgvauJvVuzTeqit8Bt4EpMHUAnUPSV1veuY6YSY9T",
  },
  "662-clipping-drive": {
    name: "662 Clipping Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "64Wexmdo7VTLshmTWGbkX6xkFUmTsLN5rbRM7SCvuXry",
  },
  "662-dahlia-drive": {
    name: "662 Dahlia Drive",
    biome: "field",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "A6uvmyexjx6KQmm1djd5P3z2iHALc86VgcxsuwkCV96S",
  },
  "662-rip-street": {
    name: "662 Rip Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "HpjWHdincJWEEVUpuwNq6SVD8xN9QeTfZAoqadLyMWV7",
  },
  "663-clipping-drive": {
    name: "663 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "EBmKfs5unNCdRWyfSqWJHLJEARA3L1kMHdEGZbDpCd7A",
  },
  "663-dahlia-drive": {
    name: "663 Dahlia Drive",
    biome: "underwater",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "837Rt6EwPh9GKoeZaXLvXijtD9uoFHj7k9MbdvJx3ivw",
  },
  "663-helix-avenue": {
    name: "663 Helix Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "8J85G7Kgsa14hMh1CJzUfzmby4w7FdkWkd6S5Axxadif",
  },
  "663-poplar-street": {
    name: "663 Poplar Street",
    biome: "arctic",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "HBYBWTSUhtt4SqSV4KCE5m7Ttn7SwUhURdvNWndsXUgD",
  },
  "663-rip-street": {
    name: "663 Rip Street",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "CTpuvgsEtqtKirDg9zSu3d1xLa7Ba8FfrbBBfembCegn",
  },
  "664-clipping-drive": {
    name: "664 Clipping Drive",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "clipping-drive",
    tokenAddress: "DKKpKiKz7h86N9VRzktTXSNJeeFkcLs2CLbDEWRpaMNX",
  },
  "664-epoch-avenue": {
    name: "664 Epoch Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "ACZUed61mreKrrmFoz4wQ2DwVS9dvLBYPMPtyazH711D",
  },
  "664-helix-avenue": {
    name: "664 Helix Avenue",
    biome: "arctic",
    frameType: "shuttered-pale-green",
    streetId: "helix-avenue",
    tokenAddress: "29pyLwZPS6EWHTPUwJh5Lef7P5sAAgpe2EmJnZvE1yks",
  },
  "664-ramsey-street": {
    name: "664 Ramsey Street",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "ramsey-street",
    tokenAddress: "EDF6GKBdYM5Lrh6E1xRfepydgCmNQNE9Cz4dQD5DRHMy",
  },
  "665-dahlia-drive": {
    name: "665 Dahlia Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "dahlia-drive",
    tokenAddress: "HjQbpXNtpKGSgc8X4vPR42hMy8DKDxcLDf9vfyF3eJTu",
  },
  "665-ramsey-street": {
    name: "665 Ramsey Street",
    biome: "field",
    frameType: "crystal",
    streetId: "ramsey-street",
    tokenAddress: "AbwCKfBZNoYYUMbpKw2SxKHphNMXTkNH6zm8nSnLnWCd",
  },
  "666-dahlia-drive": {
    name: "666 Dahlia Drive",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "dahlia-drive",
    tokenAddress: "Gq9v7Jd474rGER3BdMbnKzdcYeKu2ZMH9dCaTV4tY1Dj",
  },
  "666-helix-avenue": {
    name: "666 Helix Avenue",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "DXXBFEGzRC9PCs1wmrxJNST8ihq8iEgC8TvEdg5sbooQ",
  },
  "666-poplar-street": {
    name: "666 Poplar Street",
    biome: "city",
    frameType: "rounded-green",
    streetId: "poplar-street",
    tokenAddress: "ErNZu4uMNWbrxanQB1cVcPLmGF19aUdCQhL2kAR3HmDP",
  },
  "666-ramsey-street": {
    name: "666 Ramsey Street",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "ramsey-street",
    tokenAddress: "DAjtpMKNaFFgjDNruDKaK7G9g66KRaqrpeynUEFAJinY",
  },
  "667-clipping-drive": {
    name: "667 Clipping Drive",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "23ufUdRyigtyYx5xzJwa6JeWy5T5RDKq3yVjAJ1gZgc3",
  },
  "667-epoch-avenue": {
    name: "667 Epoch Avenue",
    biome: "beach",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "4WQ9Tc3LMN13eNSVMLVBNETTh5CoXTiNg5jNy4S5C5g2",
  },
  "667-poplar-street": {
    name: "667 Poplar Street",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "poplar-street",
    tokenAddress: "FfMin1tJBPvAmLaqDpsMGTWu85q3dYANK1Qy4efQeyGj",
  },
  "667-ramsey-street": {
    name: "667 Ramsey Street",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "ramsey-street",
    tokenAddress: "FTapWUDVXqGbLzPMLMuvvxtXsBtRudzLaQ7s3HBJAiG8",
  },
  "667-rip-street": {
    name: "667 Rip Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "AcR9oVd9Hzc6Cpcq6NvxMPHG1yXjSeBuPg4Wb6Ta1TFd",
  },
  "668-clipping-drive": {
    name: "668 Clipping Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "6N8azTW5wN1vVmR1WmtHrp94V89W5Gmyooz6J8yUhMV2",
  },
  "668-dahlia-drive": {
    name: "668 Dahlia Drive",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "dahlia-drive",
    tokenAddress: "JMryFRfHqa9vn3crFNdQQcDKPCBVswQVT4uhRabWwye",
  },
  "668-epoch-avenue": {
    name: "668 Epoch Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "E2fVXUY5aG3CcUd58Me3rCF87g286YjWaA2ayiZNnCkH",
  },
  "668-poplar-street": {
    name: "668 Poplar Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "poplar-street",
    tokenAddress: "A25hscJgEjr5bxrgKrP9RcPtgnspCCC3FqXjTPBfpf1R",
  },
  "668-rip-street": {
    name: "668 Rip Street",
    biome: "beach",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "AzdRq2DbkvpwuD3hpj1Xsh4qK7b2x5Ku3mKjsbGUxp4Y",
  },
  "669-clipping-drive": {
    name: "669 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "2A8DE2CFtMTktn5sSvQAJi4cZqT4v8ZmPqFzody7G6Fe",
  },
  "669-dahlia-drive": {
    name: "669 Dahlia Drive",
    biome: "mountain",
    frameType: "shuttered-dark-green",
    streetId: "dahlia-drive",
    tokenAddress: "6yHgqKvm7jq8MPx3GJdDdRDxfCkDvegePYVVnTBoXo53",
  },
  "669-helix-avenue": {
    name: "669 Helix Avenue",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "91W1Rhzz3yCsvj38xDLJjbx7Wxwxq5MVPppQ4H8kTSsU",
  },
  "669-ramsey-street": {
    name: "669 Ramsey Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "ramsey-street",
    tokenAddress: "7tZiTfy5CzJ6HDBQkzZgwMfv7sCQeoBgULFkhkTNT7XQ",
  },
  "669-rip-street": {
    name: "669 Rip Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "rip-street",
    tokenAddress: "3prZz8eVqaxor8YVZLTkMjVw8sjws4SmERQp1drGApA2",
  },
  "67-abbey-road": {
    name: "67 Abbey Road",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "abbey-road",
    tokenAddress: "8WMqBYXyHHtqnazpyBHN2ZVoeAYgWEcZV7P2GzSCzAPz",
  },
  "67-ape-lane": {
    name: "67 Ape Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "5v9ejU8xtksDokeB8UoNvssKCSpWjgagbiPXMnC4k4Nt",
  },
  "67-king-street": {
    name: "67 King Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "king-street",
    tokenAddress: "25kT966jwsrXF5jguSsdSkLGL41Ti7MnfVndF5y767vN",
  },
  "67-rosa-street": {
    name: "67 Rosa Street",
    biome: "volcanic",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "4pEXxzmVuyGyJFNVHt2VZyjDm2KAHC1PxEKQFJ2qcgfx",
  },
  "67-supple-circle": {
    name: "67 Supple Circle",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "supple-circle",
    tokenAddress: "2QCRsn7jcU1AuUNFtNkSDeUrsduaLpfykmp4mpKzLNrX",
  },
  "67-wall-street": {
    name: "67 Wall Street",
    biome: "field",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "FMA6WmhnNzysx1y3bjEVBG7LPmH7JyRN29szKwwqLJcy",
  },
  "670-epoch-avenue": {
    name: "670 Epoch Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "epoch-avenue",
    tokenAddress: "B1nuJeM8TaJHkSgwa5n1xL9bT6CwArUMzjXQqvGb51Cm",
  },
  "670-helix-avenue": {
    name: "670 Helix Avenue",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "3dWxcZSP1kZcKHijdcTvbwioqDwsMw8K1VCvadLbGV3P",
  },
  "670-poplar-street": {
    name: "670 Poplar Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "poplar-street",
    tokenAddress: "8gnihjAXzfcWeDhKpVukJksw6VpWUSuB9etorb4KPztP",
  },
  "670-ramsey-street": {
    name: "670 Ramsey Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "ramsey-street",
    tokenAddress: "5xVES2pMVt1so2KcD4rT5mxUy1WSQdUb2E2JQZMQKk4N",
  },
  "670-rip-street": {
    name: "670 Rip Street",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "J5aotUQkM4ASYsdSqBUMQatrUwqbwCiECQivTBgBjcMw",
  },
  "671-epoch-avenue": {
    name: "671 Epoch Avenue",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "Cbq4XaxNy2HMG6B1kRepP6LdcxDW1XD3vGYbk4GWeZ6a",
  },
  "671-poplar-street": {
    name: "671 Poplar Street",
    biome: "field",
    frameType: "glass",
    streetId: "poplar-street",
    tokenAddress: "5wCKZNSbMTjmU6kyCtzfpeeSGfQK9Wyi9Bnmos5RVVPK",
  },
  "671-ramsey-street": {
    name: "671 Ramsey Street",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "ramsey-street",
    tokenAddress: "4JGTfpkD6RrvY2qnyaLWMCU5EsfcdQCUty5YtAk5meZQ",
  },
  "671-rip-street": {
    name: "671 Rip Street",
    biome: "desert",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "BnpFGcePkP9TusWLXsipxhSTzaQkjvWJDc25cEfoTPqc",
  },
  "672-helix-avenue": {
    name: "672 Helix Avenue",
    biome: "beach",
    frameType: "shuttered-green",
    streetId: "helix-avenue",
    tokenAddress: "9Fmb5Lb5gTuuC5vcpj3cqXHDxkRth9KseKF7zM6tp7U5",
  },
  "672-rip-street": {
    name: "672 Rip Street",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "rip-street",
    tokenAddress: "D93oz6ao97ZZxA5Tx6tM41XnzHaFRNMX3mwfh34LM3fo",
  },
  "673-clipping-drive": {
    name: "673 Clipping Drive",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "clipping-drive",
    tokenAddress: "7o6MAMm8Bwqz5n2iLEg85Sa9CXMrLuaMBtbWdT1YDFS5",
  },
  "673-epoch-avenue": {
    name: "673 Epoch Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "Ght8EqhqbpEiY8Wges4gHmRBegi7WoFt9MBfnNNbo2WN",
  },
  "674-epoch-avenue": {
    name: "674 Epoch Avenue",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "epoch-avenue",
    tokenAddress: "7bHBnc2XCHYCK2zrG5Jm4CrMgfuGGoodDVe2bXY1Zjtx",
  },
  "674-helix-avenue": {
    name: "674 Helix Avenue",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "helix-avenue",
    tokenAddress: "JAnrM6zqWQqWMeMtmGUzbgHJRgWaDZzedCTV1tS6hZZW",
  },
  "674-rip-street": {
    name: "674 Rip Street",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "rip-street",
    tokenAddress: "6J71koRNyt7b3i6ZoVmvR8d9sqaiLX4AjcXPu4ovMu8M",
  },
  "675-clipping-drive": {
    name: "675 Clipping Drive",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "FkkzFXXxeDmGw3fNPutjKkeuhRRyML2dYyqR11BgjqCQ",
  },
  "675-epoch-avenue": {
    name: "675 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "3rqCU1iTRxQP7L56Ar7pKnxTYcKtGVCTLHGZhV97e1Gm",
  },
  "675-helix-avenue": {
    name: "675 Helix Avenue",
    biome: "field",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "AExhsvugrFsKydeBJCLqAGSkcjxR8aW1AdKWgYVnaVKR",
  },
  "675-poplar-street": {
    name: "675 Poplar Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "poplar-street",
    tokenAddress: "9umXBrzpXhLvypUwS7fZzYbaVMBwnRsXFEyZFJz6y24H",
  },
  "675-ramsey-street": {
    name: "675 Ramsey Street",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "ramsey-street",
    tokenAddress: "9V9SEwqB92a2QKu3FGSf4MEdFabRvcDGBTATVLZgyWbA",
  },
  "676-clipping-drive": {
    name: "676 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "clipping-drive",
    tokenAddress: "5fQrgEY5HFE6EJkj1XrY4eKfsHcJYBh252tnqSpMWYzP",
  },
  "676-epoch-avenue": {
    name: "676 Epoch Avenue",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "EmsEeNDyat9KDfKD1nroGybbS6VJBSVgJGECoQnJHdeM",
  },
  "677-clipping-drive": {
    name: "677 Clipping Drive",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "HWuMjJWSUqDXjTPe1tqVsJJZLmoecYWZBy5NgLsKCMHQ",
  },
  "677-helix-avenue": {
    name: "677 Helix Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "Bu2KiMD8TtKZiQzXNU5tkp32Z36t7Ru1MAzipcrktRc4",
  },
  "677-ramsey-street": {
    name: "677 Ramsey Street",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "ramsey-street",
    tokenAddress: "5115ApSbFsPWMXnmPMc6XL4WKaFkkwyH6uXZcqSv9mub",
  },
  "678-clipping-drive": {
    name: "678 Clipping Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "6u7J7pyGpVVuZeJe1VT52XDBpKJsL7eSpvzjYsDQCMi",
  },
  "678-epoch-avenue": {
    name: "678 Epoch Avenue",
    biome: "city",
    frameType: "rounded-red",
    streetId: "epoch-avenue",
    tokenAddress: "FNqpdPUeqwQyBPfgjkCkUaztqE39S7FD8Ky9Y4Ew7uYa",
  },
  "678-ramsey-street": {
    name: "678 Ramsey Street",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "ramsey-street",
    tokenAddress: "24D6CvSaPyCuFbv96UydgfEMD2sUM9Kn7pDn5u6wc1jA",
  },
  "678-rip-street": {
    name: "678 Rip Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "JBYQUkzEyboNPSeUqLJT1taUFLV6CTcvjq1nw8ygBnKt",
  },
  "679-epoch-avenue": {
    name: "679 Epoch Avenue",
    biome: "treehouse",
    frameType: "shuttered-lavender",
    streetId: "epoch-avenue",
    tokenAddress: "5r8GrMZVPpinYaa3p7NLwBzzHoyQ11N35YAZTKB7zx2z",
  },
  "679-helix-avenue": {
    name: "679 Helix Avenue",
    biome: "city",
    frameType: "rounded-green",
    streetId: "helix-avenue",
    tokenAddress: "Dhu99MUG6dLQdMVwPfKNutsFFyaDaV3uKtYAtjcCy63j",
  },
  "679-ramsey-street": {
    name: "679 Ramsey Street",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "ramsey-street",
    tokenAddress: "41ruFvzpqB4BAwcLtQ9QZedAExQRArBmGXNWTQ23ZUCE",
  },
  "68-ape-lane": {
    name: "68 Ape Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "7R911JG23yX7K9ZQhcqZgxHXwcpUznWNYsCkKy41gPww",
  },
  "68-king-street": {
    name: "68 King Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "king-street",
    tokenAddress: "BDcA7K5cY2wnastJ1ThmEAhimBhBx1Ehy25jGSy5GbR4",
  },
  "68-rosa-street": {
    name: "68 Rosa Street",
    biome: "arctic",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "DwSuvZKTExMGTyDcZRAUbPRfpWsWLvXGJTdZsxb3JiYM",
  },
  "68-supple-circle": {
    name: "68 Supple Circle",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "supple-circle",
    tokenAddress: "oDzv8Zfw1o2MKkpPAwy4rLsMCNcNT7NeKwtyBCwDNbF",
  },
  "68-teeps-lane": {
    name: "68 Teeps Lane",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "teeps-lane",
    tokenAddress: "BtkE37Zg2pHA3QWUB1Lqijmguj6LHLayN9NCPPcK4aEu",
  },
  "68-wall-street": {
    name: "68 Wall Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "wall-street",
    tokenAddress: "8BuMWooigri4cw81snXqfoL99LoyAp2nQiUCe51JZPqu",
  },
  "680-clipping-drive": {
    name: "680 Clipping Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "3YhR5E4CZqyWY51LgTBnAegFYYuMCKTwwmnGWRFkZ6o4",
  },
  "680-epoch-avenue": {
    name: "680 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "E2ikgvaNRwdceSSiCu5xc2d3DsYXtppxqan67yn3zy6R",
  },
  "680-helix-avenue": {
    name: "680 Helix Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "FN4qekT1TSTn5r1LoioMGWFY2yiz243zQtdZCPjx8Vzw",
  },
  "680-poplar-street": {
    name: "680 Poplar Street",
    biome: "underwater",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "JesCdNXtwabm6PjzV6tjWDQn1xXvBobUcehyjLqh9S2",
  },
  "680-ramsey-street": {
    name: "680 Ramsey Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "ramsey-street",
    tokenAddress: "CzkciCZsHi72iTq4ffX6MSWdLvFx2sPWsTDRd4c4Zypi",
  },
  "680-rip-street": {
    name: "680 Rip Street",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "rip-street",
    tokenAddress: "5MS6hkik2YnEY8nW7qvsMMqx3r9g5BnHqUkL4wZ7BjNn",
  },
  "681-clipping-drive": {
    name: "681 Clipping Drive",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "clipping-drive",
    tokenAddress: "FhDNi4s1S4jrCckJFpsMLDVLDYetdfA8DdKh8g7P9Xba",
  },
  "681-helix-avenue": {
    name: "681 Helix Avenue",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "helix-avenue",
    tokenAddress: "3rzHftEHxhLJob23hg4y1mEQqNV5uU8f98XCuwijNFxB",
  },
  "681-poplar-street": {
    name: "681 Poplar Street",
    biome: "field",
    frameType: "occult",
    streetId: "poplar-street",
    tokenAddress: "BpiBh3Cnr363oSeqB3WceSGcPC1vhTZA4qZaCqm6wrRV",
  },
  "681-ramsey-street": {
    name: "681 Ramsey Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "ramsey-street",
    tokenAddress: "2jG1zwjXWWMbdNhDj1QKSCNXDL82UuBs9zfhmE2Lwbb2",
  },
  "681-rip-street": {
    name: "681 Rip Street",
    biome: "field",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "6tGpJsqTXA6x1PPXtVUPHoZVC6Y9NGQdccFVxVr3XEba",
  },
  "682-clipping-drive": {
    name: "682 Clipping Drive",
    biome: "mountain",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "DcYtAmwypBc9QNRWKfdo8o7179X7wwcz4avc9MVraya7",
  },
  "682-epoch-avenue": {
    name: "682 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "epoch-avenue",
    tokenAddress: "5L3PpaA8YsWJMwrs5x8M3GNBAfQBwscEZzBt7vXps9as",
  },
  "682-poplar-street": {
    name: "682 Poplar Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "poplar-street",
    tokenAddress: "CfzyNQWAu5Ey9Mvq9wA6ubiz4hsgrjKgcKfEUAHqrpHZ",
  },
  "683-clipping-drive": {
    name: "683 Clipping Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "5zWAraWfvoJr77XPEMfhejNBsnmx52dN64mQ3uXgy3CL",
  },
  "683-ramsey-street": {
    name: "683 Ramsey Street",
    biome: "beach",
    frameType: "shuttered-pale-green",
    streetId: "ramsey-street",
    tokenAddress: "666b1DwaqiWRq4b8eeHmsvYgiVcUoSC8LbekoW6bQLDk",
  },
  "683-rip-street": {
    name: "683 Rip Street",
    biome: "woods",
    frameType: "shuttered-dark-green",
    streetId: "rip-street",
    tokenAddress: "cZYmf4phjz36dXCxfv5M9SQc32ZU8CoNnf9LE6H3XHr",
  },
  "684-epoch-avenue": {
    name: "684 Epoch Avenue",
    biome: "beach",
    frameType: "glass",
    streetId: "epoch-avenue",
    tokenAddress: "ANg3Jsi7oLqPjj1HHLKKznhPwLnb2hmCmC6RAJ1YXXMy",
  },
  "684-ramsey-street": {
    name: "684 Ramsey Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "ramsey-street",
    tokenAddress: "8hcrtdHj5DVs5BRBmQnimj3n2gdEaorKC5ADM4rg4Qvn",
  },
  "685-clipping-drive": {
    name: "685 Clipping Drive",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "clipping-drive",
    tokenAddress: "9SjswbDaFQrXBJFfTB9LxsyEkUwFfAfmr4dqVSnBosWn",
  },
  "685-epoch-avenue": {
    name: "685 Epoch Avenue",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "epoch-avenue",
    tokenAddress: "DQ8jB8rpMfdYt8Yr2AcQphzQ1yRSKehpCfb2F25oVvZU",
  },
  "685-helix-avenue": {
    name: "685 Helix Avenue",
    biome: "arctic",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "13Y6bJCztk6pygycC5gQodBZZL4VKuMsr3BPKgSUpwzJ",
  },
  "685-rip-street": {
    name: "685 Rip Street",
    biome: "volcanic",
    frameType: "rounded-red",
    streetId: "rip-street",
    tokenAddress: "4oh4vbp31dgsZ1wvnM9s3Bo8gSaTZrrKw7SF7dsMAStC",
  },
  "686-poplar-street": {
    name: "686 Poplar Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "poplar-street",
    tokenAddress: "FVxCk7TVMR2PEcAkBghvHQrJYQkA2cq4iYBdAjY2H4SF",
  },
  "686-ramsey-street": {
    name: "686 Ramsey Street",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "ramsey-street",
    tokenAddress: "CZ74rCxkob7DBBFHdUPjSu2DLajMnRnQGxkMxHS5niky",
  },
  "686-rip-street": {
    name: "686 Rip Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "rip-street",
    tokenAddress: "HkkKC6WqYNTMjbfzbck5cT37VJFsXcpwGBMvPnvRxcHX",
  },
  "687-clipping-drive": {
    name: "687 Clipping Drive",
    biome: "underwater",
    frameType: "rounded-lavender",
    streetId: "clipping-drive",
    tokenAddress: "3FSwmLNHBBL56aTkZCPCuTLzujSBAK9rCkTnsXrXHeG6",
  },
  "687-epoch-avenue": {
    name: "687 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "C6iBXf5k3PZxYqiwbX23GBpmsg1RRfdEijpXHuSCqPhm",
  },
  "687-ramsey-street": {
    name: "687 Ramsey Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ramsey-street",
    tokenAddress: "EhkyBme2HehF3Rha9n2yiEL4QYg8o9Hw8Wk8YUrvHEgw",
  },
  "687-rip-street": {
    name: "687 Rip Street",
    biome: "volcanic",
    frameType: "rounded-blue",
    streetId: "rip-street",
    tokenAddress: "3Z4MTnQaMGaEHdugSUFAC5tmVjx11xSLH9C2LPbCM5cL",
  },
  "688-epoch-avenue": {
    name: "688 Epoch Avenue",
    biome: "underwater",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "6AYFhyJCrnBHbCkwFBXLqz27VJkdRmb6Vwn4cX5jaikZ",
  },
  "688-poplar-street": {
    name: "688 Poplar Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "poplar-street",
    tokenAddress: "Cm3hEfrVcV2onYtaBmAHjv4AT6SDwP9aMgEoXvArvfwU",
  },
  "688-ramsey-street": {
    name: "688 Ramsey Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "ramsey-street",
    tokenAddress: "Ffvn23eCgXZW6NZd85vrD1zBTAbR3UG7MfYfTFbKG8kv",
  },
  "688-rip-street": {
    name: "688 Rip Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "39bGUahcb8uGfrUTdiRUzNn4JADBBdFBLBwvbtupn1tG",
  },
  "689-clipping-drive": {
    name: "689 Clipping Drive",
    biome: "underwater",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "6Auebos4dbBy1HZxgd5CtxB6W1ro4NJLpnxHJ5ypQARc",
  },
  "689-poplar-street": {
    name: "689 Poplar Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "poplar-street",
    tokenAddress: "861i2BWQiXnxdE9iRRAnNmNiubCBnJTTbpaNw2CoKfUb",
  },
  "689-ramsey-street": {
    name: "689 Ramsey Street",
    biome: "arctic",
    frameType: "shuttered-red",
    streetId: "ramsey-street",
    tokenAddress: "3o1iytTt95XyoZPf2bFNYVDTCdx3HKJcV4omhBhB81Hy",
  },
  "69-abbey-road": {
    name: "69 Abbey Road",
    biome: "field",
    frameType: "rounded-red",
    streetId: "abbey-road",
    tokenAddress: "9uC68Ww8UPhX1eGyzSMF5tECTYLXP29BEs8QbAtZdyKp",
  },
  "69-ligma-lane": {
    name: "69 Ligma Lane",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "ligma-lane",
    tokenAddress: "8CjrcgQftL28xg5RiwiM9a5r612eU59gR7XhS8NJHaAr",
  },
  "69-supple-circle": {
    name: "69 Supple Circle",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "supple-circle",
    tokenAddress: "ETKdM8FwDjMZsbQRMZfFo2xoiKY4D8rZ2JVM3GjEkHP8",
  },
  "69-teeps-lane": {
    name: "69 Teeps Lane",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "6wqPH535CVSs6epECJAnBPJwYYdLay9KapYprbS35hYz",
  },
  "690-clipping-drive": {
    name: "690 Clipping Drive",
    biome: "city",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "DPzyZxgFy6zahCJu4XVuUE7EqfNKf4g9LHunQJQfCZaQ",
  },
  "690-epoch-avenue": {
    name: "690 Epoch Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "3Tnma7rVhfH3REsK81czJCb7FESy4tvFvGMPixTYBD5j",
  },
  "691-clipping-drive": {
    name: "691 Clipping Drive",
    biome: "arctic",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "HLLatZCzzsN2grtgtjhoiXjqgTS1RMWeJgm3eYvWDP9f",
  },
  "691-epoch-avenue": {
    name: "691 Epoch Avenue",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "CwwToG8YXt3Ws2ZaWX6S524Xr1ib4GhfJaW5TjCoWVgC",
  },
  "691-helix-avenue": {
    name: "691 Helix Avenue",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "BgNs1kdwYdiPv1U22mZmXvBggu5yFKYt96nhXc8inLvx",
  },
  "691-rip-street": {
    name: "691 Rip Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "rip-street",
    tokenAddress: "GnF5ZepNRFsgia2kg8U4rnN8jNoj57k8TEtVchkwyckq",
  },
  "692-epoch-avenue": {
    name: "692 Epoch Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "4b6gHeiediAVojnnjF3Wp7zHzfYhwJ6SvpcTHXNBDy7q",
  },
  "692-helix-avenue": {
    name: "692 Helix Avenue",
    biome: "underwater",
    frameType: "rounded-lavender",
    streetId: "helix-avenue",
    tokenAddress: "4KBfEfPepByc9SufukRweD52tJpyfJMzckEuaCfxEab8",
  },
  "693-clipping-drive": {
    name: "693 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "7F6TTbfVsdhQqnScDphShypwcW7nehMzHBbKm6SYdtSf",
  },
  "693-epoch-avenue": {
    name: "693 Epoch Avenue",
    biome: "woods",
    frameType: "glass",
    streetId: "epoch-avenue",
    tokenAddress: "C8PoELsAdwTDa9WsjZMaVGPSroqCyqxFDD7HWqFuy85S",
  },
  "693-helix-avenue": {
    name: "693 Helix Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "7yPE8oHvtMbLtDtUY3b3b8k2BDP7QEDFdkKPBjavCJA9",
  },
  "694-clipping-drive": {
    name: "694 Clipping Drive",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "clipping-drive",
    tokenAddress: "8bU2Cki7QYeh4AcS57JwYd9MSTaKJNozUJANMepzfq4M",
  },
  "694-helix-avenue": {
    name: "694 Helix Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "2fWCvgAbMJ9wVR4UJZSJWg77v8ypvxyzLdX15YQ1oqjE",
  },
  "694-rip-street": {
    name: "694 Rip Street",
    biome: "city",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "ELXEapbcM9a3NokJf3L9TbR31mynFrmvSj6NHhX21RNh",
  },
  "695-epoch-avenue": {
    name: "695 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "epoch-avenue",
    tokenAddress: "EzDLWFQKSDup93tBiKy17cmbFwVTXZQ8oN3PTinpi8cy",
  },
  "695-helix-avenue": {
    name: "695 Helix Avenue",
    biome: "volcanic",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "Am9zXTXsmLZaA937bqgTRAq6VkdT4gYuLwkay7bjz42W",
  },
  "695-ramsey-street": {
    name: "695 Ramsey Street",
    biome: "treehouse",
    frameType: "rounded-dark-green",
    streetId: "ramsey-street",
    tokenAddress: "G5Y6mF1uEyMj3joASLqV9QFREppf5c29RpVzeK89KDpK",
  },
  "695-rip-street": {
    name: "695 Rip Street",
    biome: "field",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "FSuaECHRrAzHxmy7R19hoqdvMSR4pLmZgi89uiQNuVy7",
  },
  "696-clipping-drive": {
    name: "696 Clipping Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "MHGFbQ4xVVjbTgfdwnWEdxkiKqGTiN84hmHnZdf2Xrp",
  },
  "696-epoch-avenue": {
    name: "696 Epoch Avenue",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "HbZaqcZLyh83DtLX66MpMi6KBJAppGtfybdwu2KBkrme",
  },
  "697-epoch-avenue": {
    name: "697 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "epoch-avenue",
    tokenAddress: "5HKStVr7C5eBouXHQpnopEk1pXRrZGoLbRvSQNeCGG2w",
  },
  "697-helix-avenue": {
    name: "697 Helix Avenue",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "4xdzbUXCHFQPcz54LcPEhTMZ71SQY9a9YRuj19MT2zDm",
  },
  "697-rip-street": {
    name: "697 Rip Street",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "rip-street",
    tokenAddress: "6bThPVrfmP5QiGCcdJCQj63L1NX933bu7YQj1KJpoDDe",
  },
  "698-epoch-avenue": {
    name: "698 Epoch Avenue",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "epoch-avenue",
    tokenAddress: "GAbd3gDTx1qx6W8eTe1xH3K2QmRMGMzYK4FwXGkzxHDz",
  },
  "698-rip-street": {
    name: "698 Rip Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "EfrK7f9kEgLegtRKBJX6cYcNNtWVMepSvUrPajU2NdvN",
  },
  "699-epoch-avenue": {
    name: "699 Epoch Avenue",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "jv1eLtpmmX5phJMdN9VHae9hkiTwGksL12ui9s5fzFU",
  },
  "699-helix-avenue": {
    name: "699 Helix Avenue",
    biome: "arctic",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "DzD3rX5zy4VZBSGq38PR85JCR2QsEvM9k26CnmqqehDT",
  },
  "699-rip-street": {
    name: "699 Rip Street",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "rip-street",
    tokenAddress: "BALkiyxesS9go3DyA9wTtyeH6iJKAEh5wTCp6KfnEwWx",
  },
  "70-abbey-road": {
    name: "70 Abbey Road",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "abbey-road",
    tokenAddress: "8Dm2f85D9uC3YuStpFAfxSe3uTi6tXmztggJUpcCE6bW",
  },
  "70-ape-lane": {
    name: "70 Ape Lane",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "ape-lane",
    tokenAddress: "2jXKGyJshPvCnVDFwU1HdkZYv1gBqxnr2m54fNkxQeKb",
  },
  "70-king-street": {
    name: "70 King Street",
    biome: "field",
    frameType: "glass",
    streetId: "king-street",
    tokenAddress: "A35jg3fXhBxTDUTE2YX1JEf9fYTFubVr5QS1nCSpB39A",
  },
  "70-rosa-street": {
    name: "70 Rosa Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "DY79dcBszRaRP1wRdmzh8TTf2fd5G2nh5JrDSRGKJL8A",
  },
  "70-supple-circle": {
    name: "70 Supple Circle",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "supple-circle",
    tokenAddress: "88XoKBroiShT38dTRYsACtvAvDSPwQUTJZipWm7n3EBt",
  },
  "70-teeps-lane": {
    name: "70 Teeps Lane",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "teeps-lane",
    tokenAddress: "8TTLKBYnj65b6bF8LGWgiU4KpqhAJY8nif8G6GqtdsH9",
  },
  "70-wall-street": {
    name: "70 Wall Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "2RWThA3Ct1GUZazXEYKCtisJdGqXfLM4Mh92QtnDdVsJ",
  },
  "700-clipping-drive": {
    name: "700 Clipping Drive",
    biome: "field",
    frameType: "rounded-red",
    streetId: "clipping-drive",
    tokenAddress: "BRoVY4dWiTQuf51CSMRBJeusbp7tWdJche3wsAAoAbZy",
  },
  "700-epoch-avenue": {
    name: "700 Epoch Avenue",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "epoch-avenue",
    tokenAddress: "AnMdrRfamYFYP7kMjUfSyfSrBFGVF4bEnddA11rzTJMj",
  },
  "700-helix-avenue": {
    name: "700 Helix Avenue",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "Di8LdbrSZHoKYfYTYGw6RryspBuMzqHBvYg232YvoTPU",
  },
  "700-rip-street": {
    name: "700 Rip Street",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "rip-street",
    tokenAddress: "FEEvVx4vdVeVVEjcfNBU9cdbUdkYcAKmiscRVoPissUJ",
  },
  "701-clipping-drive": {
    name: "701 Clipping Drive",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "Yos4ppzRYZTHVZSCpmKKGLBtpvgPw4hANeeCpt2yzhp",
  },
  "701-epoch-avenue": {
    name: "701 Epoch Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "epoch-avenue",
    tokenAddress: "HWKS4zq9qU9DVv59qSjP5ju9xYShAzJLqCVjEw8TdLKy",
  },
  "701-helix-avenue": {
    name: "701 Helix Avenue",
    biome: "arctic",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "ATDxejzRPzH78v67X822h1GsFxUtYsSjzECkZho8JFNB",
  },
  "701-rip-street": {
    name: "701 Rip Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "rip-street",
    tokenAddress: "2Z9U6MRGp7x4MBVxo4BLdHUD8XW4xUpBkUvuCPCEQMXF",
  },
  "702-epoch-avenue": {
    name: "702 Epoch Avenue",
    biome: "field",
    frameType: "rounded-green",
    streetId: "epoch-avenue",
    tokenAddress: "Y9GSWwVeYED9WVgPTk9qi6Nrcu1Hhtph8p5H8VPWoTc",
  },
  "702-rip-street": {
    name: "702 Rip Street",
    biome: "desert",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "6fcXmBAVPmjWTLBm2xv7RT89xfrymZdWdGenoCH7RquM",
  },
  "703-clipping-drive": {
    name: "703 Clipping Drive",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "9jjNhhdCxN4KxRkzyRBpBtuEVZHXBy7bWxnreGcAtXFY",
  },
  "703-helix-avenue": {
    name: "703 Helix Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "Bc5HACkQpfT4Exsc2MdveXyYkdE35hiDwPKfv66Ucy2b",
  },
  "703-rip-street": {
    name: "703 Rip Street",
    biome: "field",
    frameType: "glass",
    streetId: "rip-street",
    tokenAddress: "9oYao8WttUrthyHkohBUco7RbEPzKsEerdrrhvFBQX4N",
  },
  "704-clipping-drive": {
    name: "704 Clipping Drive",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "2tpnwPisnXw4yFzf7isVtAQykVGJrWVQ4BnVwcFHHygv",
  },
  "704-epoch-avenue": {
    name: "704 Epoch Avenue",
    biome: "woods",
    frameType: "rounded-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "HUbrq5V9WTQtAngUhsUcKrou6wS62KKZZRJX7gGm1rXf",
  },
  "704-helix-avenue": {
    name: "704 Helix Avenue",
    biome: "desert",
    frameType: "rounded-pale-green",
    streetId: "helix-avenue",
    tokenAddress: "JAreWgF4JmZNooWKDp7W5ygQBAFRZHy5pgkU4kx6hUmJ",
  },
  "705-helix-avenue": {
    name: "705 Helix Avenue",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "AMvGd4KLt4ihzxbaNY8rswqNNLgG75oHp32tRwyxs8EZ",
  },
  "706-epoch-avenue": {
    name: "706 Epoch Avenue",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "BFAJ8gedRwbWEo2i56QKHH2ViVVmw6hAon13wjwM4NyF",
  },
  "706-helix-avenue": {
    name: "706 Helix Avenue",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "helix-avenue",
    tokenAddress: "Cpurf6qA5MZMCRxqdWxPeCcq8s5PVKMity5dGLJGoviG",
  },
  "707-clipping-drive": {
    name: "707 Clipping Drive",
    biome: "field",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "8LNT69RcQLwb6r249WY1srmiNBSt422Luz7Nn2PFwvLN",
  },
  "707-epoch-avenue": {
    name: "707 Epoch Avenue",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "J9k2SpH8PRXzrQ7qh3ixeJNnWMmR7e48xcWXkF8vCUQs",
  },
  "707-rip-street": {
    name: "707 Rip Street",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "rip-street",
    tokenAddress: "J2gRKyg1zLvdRzmSyXs63xFLgT94WffqR7Yk6xs7x6Xk",
  },
  "708-clipping-drive": {
    name: "708 Clipping Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "4F46QGPrvQfKbxyu8jgBxcfJmUamqS6xt2HHSQuPY69P",
  },
  "708-epoch-avenue": {
    name: "708 Epoch Avenue",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "epoch-avenue",
    tokenAddress: "3gUqLGjkbCD3L2PNvTdEkwWJ8XUBoF9ccMnqtEaQ5XvS",
  },
  "708-rip-street": {
    name: "708 Rip Street",
    biome: "arctic",
    frameType: "rounded-dark-green",
    streetId: "rip-street",
    tokenAddress: "E39RtBxPzJPZhYjjYifMA1EvifzYsLu2LvPxRqFADYyC",
  },
  "709-clipping-drive": {
    name: "709 Clipping Drive",
    biome: "desert",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "8RUGSdBug7GyCbHd5JDLn1dekHW7c3RoiisfFLmQvXv4",
  },
  "709-epoch-avenue": {
    name: "709 Epoch Avenue",
    biome: "treehouse",
    frameType: "rounded-green",
    streetId: "epoch-avenue",
    tokenAddress: "DZHChB3DZ7wL2PRNvcmxKcoW1EiETTPAGx6pMwatLHEK",
  },
  "709-helix-avenue": {
    name: "709 Helix Avenue",
    biome: "city",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "97ptu7Xt6WajxqbLWJWyMcQarbNvmPW5KKZGMZ4HyWoy",
  },
  "709-rip-street": {
    name: "709 Rip Street",
    biome: "field",
    frameType: "crystal",
    streetId: "rip-street",
    tokenAddress: "7DseuGYXPEk2xfvu5xEPrJEd2jtsTyCygbJire3YaPGM",
  },
  "71-abbey-road": {
    name: "71 Abbey Road",
    biome: "underwater",
    frameType: "occult",
    streetId: "abbey-road",
    tokenAddress: "ES9uerWnaK4NKNNSYcXHhRaqibgzeb6nprugZqTeDnBX",
  },
  "71-ape-lane": {
    name: "71 Ape Lane",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "ape-lane",
    tokenAddress: "CceWAw2JVqAw7H14bbkRNt5hgR2fAcBnxp4cPwu4YByu",
  },
  "71-king-street": {
    name: "71 King Street",
    biome: "woods",
    frameType: "glass",
    streetId: "king-street",
    tokenAddress: "FMsy9RiXVJSExARWPPF8e2T72YynRxBPDx1FW9CksDum",
  },
  "71-stewart-avenue": {
    name: "71 Stewart Avenue",
    biome: "field",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "4KKanH266zRMzdUSVVChRwkqhT2bR15w2hgrpg7QMTV8",
  },
  "71-supple-circle": {
    name: "71 Supple Circle",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "CFNVP3V9hnaJubb6gyDGkKHgkkiDyWt6zZnsJ8vwxSLE",
  },
  "71-wall-street": {
    name: "71 Wall Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "wall-street",
    tokenAddress: "3NUq61KttFZeLhSKzqM1s9wV1SnNw4mTb87H4P3myMAg",
  },
  "710-clipping-drive": {
    name: "710 Clipping Drive",
    biome: "woods",
    frameType: "rounded-dark-green",
    streetId: "clipping-drive",
    tokenAddress: "pS6sy1dP1xPh6qbK3tVFFFM9MhgjojXqeBuboRgD4co",
  },
  "710-helix-avenue": {
    name: "710 Helix Avenue",
    biome: "field",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "Ckqc4FGdpTfPcsSJpShkL5jCeQd91r7vXB3H2dQ2tpyu",
  },
  "710-rip-street": {
    name: "710 Rip Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "rip-street",
    tokenAddress: "4mJdJP1mmHxSF7v5zcxAsXVJ1CiQncQ36iCJ7h8gGJZo",
  },
  "711-clipping-drive": {
    name: "711 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "2XN9Fikf4CGjLDtRiqGAp7BiTGyRGghzonjK6zdPWTqQ",
  },
  "711-helix-avenue": {
    name: "711 Helix Avenue",
    biome: "city",
    frameType: "occult",
    streetId: "helix-avenue",
    tokenAddress: "7wH6LFaX4dWTVhtAHKvAs9v1eTDbeh8p6PKz8vFA3ugD",
  },
  "712-clipping-drive": {
    name: "712 Clipping Drive",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "clipping-drive",
    tokenAddress: "6hjHh4h2vUyg11rbMrzg4EZ9AwbW8S6mmMft7g1iGZip",
  },
  "712-helix-avenue": {
    name: "712 Helix Avenue",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "helix-avenue",
    tokenAddress: "HPFpSwzzkqupQWqQZDKNpDANa6jZej5XssdLV7KJDtQE",
  },
  "713-helix-avenue": {
    name: "713 Helix Avenue",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "helix-avenue",
    tokenAddress: "GX7Eh2MktqaYPCzc71FnWdSAfCsELw9YThQDC6t7ZXpi",
  },
  "714-clipping-drive": {
    name: "714 Clipping Drive",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "clipping-drive",
    tokenAddress: "3AciGtXp1cmfPnjwd83NJaY8A9yuFbSqAJg1LdUg8UPF",
  },
  "714-helix-avenue": {
    name: "714 Helix Avenue",
    biome: "desert",
    frameType: "glass",
    streetId: "helix-avenue",
    tokenAddress: "2CY53efoodCT5HnSzYZyQn7ahmGveURBBgtExqmVZ8uB",
  },
  "715-clipping-drive": {
    name: "715 Clipping Drive",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "clipping-drive",
    tokenAddress: "5anjCTBc2iAgENwLEMd4huy5uKXxi8skm93bRNaAJxGW",
  },
  "715-helix-avenue": {
    name: "715 Helix Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "A19nZ1PgUswETxHzBMM38BYBibD9Rbp76jm8VPSKPXLL",
  },
  "716-helix-avenue": {
    name: "716 Helix Avenue",
    biome: "arctic",
    frameType: "rounded-dark-green",
    streetId: "helix-avenue",
    tokenAddress: "3S3iNKaT2mYp5PeaKWFJM8X8nwgbKRHkQehNF19RJM9V",
  },
  "717-clipping-drive": {
    name: "717 Clipping Drive",
    biome: "field",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "mLruiDXohjXDhuQB8iAkHBpBTtcSiBjmjTUGiexhrkn",
  },
  "717-helix-avenue": {
    name: "717 Helix Avenue",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "helix-avenue",
    tokenAddress: "Aw9zSHngy7g1rCcKXKvUwydq4X4boymU7UPF3mEmxabJ",
  },
  "718-helix-avenue": {
    name: "718 Helix Avenue",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "HzBxF9r8XpAH13hLNW7TczQfZ3cgLb2wqhbxCK5SwoiU",
  },
  "719-helix-avenue": {
    name: "719 Helix Avenue",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "helix-avenue",
    tokenAddress: "5CznB5xPiCUNUUG9BLCNrLZka5t2ZeL2TJbeVa9jxoyR",
  },
  "72-abbey-road": {
    name: "72 Abbey Road",
    biome: "arctic",
    frameType: "shuttered-blue",
    streetId: "abbey-road",
    tokenAddress: "GThuu8Tny7czw1NRXm9tQ6D5VzMhHiDbDeKevNwKSEWV",
  },
  "72-ape-lane": {
    name: "72 Ape Lane",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "ape-lane",
    tokenAddress: "GevhGbJSyrSnKziJ7KYVd2xW4N4hejmvmPciPZkVaDhp",
  },
  "72-dojo-drive": {
    name: "72 Dojo Drive",
    biome: "beach",
    frameType: "shuttered",
    streetId: "dojo-drive",
    tokenAddress: "EE6793rtbk5juwM3ji8awFDetQoYGssoAUhbqjus91qf",
  },
  "72-king-street": {
    name: "72 King Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "king-street",
    tokenAddress: "2ew8kTc1HS8NPLxd3CcggmeN9AkRH3NgAtyDaczoU4G7",
  },
  "72-stewart-avenue": {
    name: "72 Stewart Avenue",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "Grr3dB7JFeGMZf4wSqqbBq4ScWD6Jk87JjpnPJzm8wtu",
  },
  "72-supple-circle": {
    name: "72 Supple Circle",
    biome: "beach",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "CT3KNVNxb9ZQFJBjRX6eigbyyuzUAR1bi34yj63xHkon",
  },
  "72-teeps-lane": {
    name: "72 Teeps Lane",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "teeps-lane",
    tokenAddress: "Go3fuLKJKfeq6Fg3qwbQNM46b54Tdofd8e7fH5x8H9ti",
  },
  "72-wall-street": {
    name: "72 Wall Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "wall-street",
    tokenAddress: "A4CVtBKiqrK9cjrxrXQHBLtS1iJaxCGQN6gfwMHLgGQA",
  },
  "720-clipping-drive": {
    name: "720 Clipping Drive",
    biome: "city",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "3SLGBE1kSBZtS93XNbEDLTVkAqiV6w44SqNuv63STxX7",
  },
  "721-helix-avenue": {
    name: "721 Helix Avenue",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "ReitXTMXM3ih98gWMRBTtYEMzQtCS86eo2ydRifHp2p",
  },
  "722-helix-avenue": {
    name: "722 Helix Avenue",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "helix-avenue",
    tokenAddress: "41HUq3AUNmyWGBYWCjung4nt3Lf6JdAgFDdvq1AQbAju",
  },
  "724-clipping-drive": {
    name: "724 Clipping Drive",
    biome: "field",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "2JB62CXpJLZW8TiGYeiR29TTqF3gdCSPHkM4ZJLziacJ",
  },
  "725-clipping-drive": {
    name: "725 Clipping Drive",
    biome: "volcanic",
    frameType: "rounded-yellow",
    streetId: "clipping-drive",
    tokenAddress: "2qDAHDH1VGLd8bqTxDtN4dQqNHkhLBLnZLfoFVQ5o1Gn",
  },
  "726-clipping-drive": {
    name: "726 Clipping Drive",
    biome: "treehouse",
    frameType: "rounded-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "AzsSRXyj6kwnmwhqvjhoiKtCNnMkH5kuWtqcJP1LdcsR",
  },
  "726-helix-avenue": {
    name: "726 Helix Avenue",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "helix-avenue",
    tokenAddress: "DFqwvevXY2qYDwK6qa3zP2McntoF3ebb1wv3U2sCtt82",
  },
  "727-clipping-drive": {
    name: "727 Clipping Drive",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "6BknbrhFE3Tf7gy8vtB4HoG6ApCKcxtJz8iM95gaiAFV",
  },
  "727-helix-avenue": {
    name: "727 Helix Avenue",
    biome: "field",
    frameType: "gold",
    streetId: "helix-avenue",
    tokenAddress: "BeUqxwYogpgrwSRFvE2cULwmXsyDe9oaDrX1qAdQrWrT",
  },
  "728-helix-avenue": {
    name: "728 Helix Avenue",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "helix-avenue",
    tokenAddress: "Gc1JJhp979vhFYAGuzB7Hyave99Fe1Ugza1A4entrvAx",
  },
  "729-clipping-drive": {
    name: "729 Clipping Drive",
    biome: "beach",
    frameType: "rounded-blue",
    streetId: "clipping-drive",
    tokenAddress: "3tLTqUmj68YRZr1dSYXNvvrKRZQZums2kGvtsMUkU9HP",
  },
  "729-helix-avenue": {
    name: "729 Helix Avenue",
    biome: "desert",
    frameType: "crystal",
    streetId: "helix-avenue",
    tokenAddress: "F8KKx7yZTf3tmqpC4rpEcy19ynYYR32WVikJXhiRyMjL",
  },
  "73-abbey-road": {
    name: "73 Abbey Road",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "abbey-road",
    tokenAddress: "Cf5hbGTvdxXqNQvaULwKMqVkeg8pwQVJiMrZ78gijrzN",
  },
  "73-ape-lane": {
    name: "73 Ape Lane",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "34GTKDRFm7S4HgrftnaD7GKmouPEn4eYhZCSUixU3who",
  },
  "73-king-street": {
    name: "73 King Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "king-street",
    tokenAddress: "BQmyvVNqT8jbjk4Nn4HoPfYDHb2RTe6VpPyUhwSjC4bG",
  },
  "73-stewart-avenue": {
    name: "73 Stewart Avenue",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "stewart-avenue",
    tokenAddress: "6wtFQjzoNxJZAequ2KTXWf1QDCpCTmnxh1xzmcdcgFn8",
  },
  "73-supple-circle": {
    name: "73 Supple Circle",
    biome: "beach",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "6eNXFcjU9BhpwCaUsV76PA9MmY5VqQgVamSi25YiYB7m",
  },
  "73-teeps-lane": {
    name: "73 Teeps Lane",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "teeps-lane",
    tokenAddress: "uBU7fxS4arQ3wZPuv7RyE223LTmchJGxfVKGTJ5KSq5",
  },
  "73-wall-street": {
    name: "73 Wall Street",
    biome: "field",
    frameType: "crystal",
    streetId: "wall-street",
    tokenAddress: "FaYtrQJCCZbn9sXFPeU5o9cU9G58G31DMBiBWqzgUkNP",
  },
  "730-clipping-drive": {
    name: "730 Clipping Drive",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "clipping-drive",
    tokenAddress: "5LV2UPcbNMmfiZoDEG5pTSVoo9BFKEGjiEQWnQGB8tnB",
  },
  "731-clipping-drive": {
    name: "731 Clipping Drive",
    biome: "arctic",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "GGpfkdj2qvQr5RbEndj16n4z7mhiVjZSgYDa6ZTpmPRZ",
  },
  "732-clipping-drive": {
    name: "732 Clipping Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "E9g9Men47XE8Y3iJBpYr5JSDhKAZvjihBVFmWJgAd5QV",
  },
  "732-rodeo-drive": {
    name: "732 Rodeo Drive",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "rodeo-drive",
    tokenAddress: "Ew2avCjcqtgdixr2A6MCtnsGeg8xdAKEm6KsBhhm7LRn",
  },
  "734-clipping-drive": {
    name: "734 Clipping Drive",
    biome: "city",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "BbhSFLvn4QBiyHQt7vWjMvbWUrB2QhWmKQpxui5hSyKK",
  },
  "735-clipping-drive": {
    name: "735 Clipping Drive",
    biome: "arctic",
    frameType: "crystal",
    streetId: "clipping-drive",
    tokenAddress: "8XrDCC5VmRdaRcyGmBporvepxPwzkCeGkWGaCN6zLPTS",
  },
  "736-clipping-drive": {
    name: "736 Clipping Drive",
    biome: "desert",
    frameType: "occult",
    streetId: "clipping-drive",
    tokenAddress: "7jdzZRiUJt9n5DoEVQkHs8e8dKrtowBmW99kjuAf9nHR",
  },
  "736-rodeo-drive": {
    name: "736 Rodeo Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "rodeo-drive",
    tokenAddress: "AqHVKXFSis7Eam4Jeu21JQ1n8m3KpBLDG4oxGiDEirXf",
  },
  "737-clipping-drive": {
    name: "737 Clipping Drive",
    biome: "woods",
    frameType: "glass",
    streetId: "clipping-drive",
    tokenAddress: "BdUuAsfDTz9AjKASw1bqC3rvZw7wBy6YwUFGuFjyqt8K",
  },
  "737-rodeo-drive": {
    name: "737 Rodeo Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "9XwBhdWisR7Sw1nNMMHTemuUz2ESSSecGHYa96M1AmEV",
  },
  "738-rodeo-drive": {
    name: "738 Rodeo Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "6SudLigV2CVPSfNr8WK5owYDEExLtgHQXUhz4rJYeDzV",
  },
  "74-abbey-road": {
    name: "74 Abbey Road",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "abbey-road",
    tokenAddress: "28dWVg74pWLodguRee7j6kPzGKBt4nKosZ1FASNrgJeF",
  },
  "74-king-street": {
    name: "74 King Street",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "king-street",
    tokenAddress: "EU5ag8YuZGQ9yeeqeywqoYx31tos5ya73PLtj8DWwtwC",
  },
  "74-supple-circle": {
    name: "74 Supple Circle",
    biome: "treehouse",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "FcfWhBftjaRMMvMxr7T4pVuJS9hBbHe6q2p8tqXVFq3a",
  },
  "74-wall-street": {
    name: "74 Wall Street",
    biome: "treehouse",
    frameType: "crystal",
    streetId: "wall-street",
    tokenAddress: "2NMtGtFgn2qqsmhBRsXtrbAytT3fz5sT8Ynvg7zwpMyU",
  },
  "741-rodeo-drive": {
    name: "741 Rodeo Drive",
    biome: "city",
    frameType: "rounded-pale-green",
    streetId: "rodeo-drive",
    tokenAddress: "3eDPbdkjAVRNATn348b61wT1nUyFBxKRQiBxPTHqqK7Y",
  },
  "743-rodeo-drive": {
    name: "743 Rodeo Drive",
    biome: "volcanic",
    frameType: "rounded-red",
    streetId: "rodeo-drive",
    tokenAddress: "Fg2XafwaDCjn1joVGvSznE1ZmVPk3SJyi6oKZsyFUuCT",
  },
  "744-rodeo-drive": {
    name: "744 Rodeo Drive",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "rodeo-drive",
    tokenAddress: "3Hvk7B23krLQ5htSj9SMVEik27aDKk6TnsaELwM5z2c7",
  },
  "747-rodeo-drive": {
    name: "747 Rodeo Drive",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "rodeo-drive",
    tokenAddress: "A7YM1TmxjupQQzoGyZLVn8EsRp5WPKZGwZFagvxmuzf3",
  },
  "748-rodeo-drive": {
    name: "748 Rodeo Drive",
    biome: "city",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "ErV57BX31j33aeMrDAM8hftBswq9vjPTHttqFZd5pgaY",
  },
  "75-abbey-road": {
    name: "75 Abbey Road",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "abbey-road",
    tokenAddress: "CwUzXjjFpycQ9ZCEn1yjs82yuDyuNbdwEBTqp2S9c9to",
  },
  "75-ape-lane": {
    name: "75 Ape Lane",
    biome: "beach",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "6vPgb4kjeoScDuazSFZk7UrHwfES5aXLR14Tj1wW6qwf",
  },
  "75-king-street": {
    name: "75 King Street",
    biome: "underwater",
    frameType: "occult",
    streetId: "king-street",
    tokenAddress: "5eaZNVcSyfzMbiVHbi6whT1CFtrUPjJFwUHb7DEXw6HD",
  },
  "75-rosa-street": {
    name: "75 Rosa Street",
    biome: "desert",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "9cCWLGoFMvLbTcRPNp2NMpojxdyVyYxXUoBp23JByFCS",
  },
  "75-stewart-avenue": {
    name: "75 Stewart Avenue",
    biome: "city",
    frameType: "occult",
    streetId: "stewart-avenue",
    tokenAddress: "7S4VGfd63NxaHbEfBQBQbekxwMqxnDKe4kuN3FZ8mum7",
  },
  "75-supple-circle": {
    name: "75 Supple Circle",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "supple-circle",
    tokenAddress: "4S2oWrogjzzrPtgcijkkMBErU6tSQxWk1qLkbwAYFdkr",
  },
  "75-wall-street": {
    name: "75 Wall Street",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "wall-street",
    tokenAddress: "9VhiHZVTfgrap5zkTHCSwbk8JxQa4WUD2uk2sx5az2uP",
  },
  "750-rodeo-drive": {
    name: "750 Rodeo Drive",
    biome: "beach",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "6xYVBfT82g7edtZWg1pKqih3CSKjju248SouoPLFGFf1",
  },
  "753-rodeo-drive": {
    name: "753 Rodeo Drive",
    biome: "city",
    frameType: "rounded-green",
    streetId: "rodeo-drive",
    tokenAddress: "3gW2sohjyDutfsYHLhrUWRxQjpaokRaNrJ9uG1aJ8DZj",
  },
  "754-rodeo-drive": {
    name: "754 Rodeo Drive",
    biome: "woods",
    frameType: "occult",
    streetId: "rodeo-drive",
    tokenAddress: "Ca4V7Tx7UniXM8hJte5S4tP64RS2dMhz2CeTWfzhfY5E",
  },
  "755-rodeo-drive": {
    name: "755 Rodeo Drive",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "rodeo-drive",
    tokenAddress: "AWtbwnucXQ5wBUxsaSrtB4gbdpfF8uNF1Q9BmeSrE3C9",
  },
  "756-rodeo-drive": {
    name: "756 Rodeo Drive",
    biome: "treehouse",
    frameType: "shuttered-red",
    streetId: "rodeo-drive",
    tokenAddress: "6JdwHT4JxJcWvoUQZeii4TguHk8BAF3qydq8GPKwpaag",
  },
  "758-rodeo-drive": {
    name: "758 Rodeo Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "rodeo-drive",
    tokenAddress: "4zK1N4Aj32PRccoLNmq2yvmVtzJsbTKBpGcLivenYxi3",
  },
  "759-rodeo-drive": {
    name: "759 Rodeo Drive",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "rodeo-drive",
    tokenAddress: "EW1C1zdxVbC1VoeCMvp2GQnAiqLcBEhf7MaWBH7Fvvxu",
  },
  "76-abbey-road": {
    name: "76 Abbey Road",
    biome: "city",
    frameType: "crystal",
    streetId: "abbey-road",
    tokenAddress: "5F5dszP5SQshzGutUDoYGLamm2L9q58wdhAQahrSQWqw",
  },
  "76-ape-lane": {
    name: "76 Ape Lane",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "ape-lane",
    tokenAddress: "9sbK8QrYGTCAsfhJKPeF5iJqw9MfdzDoQC56GFj1KCTS",
  },
  "76-king-street": {
    name: "76 King Street",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "king-street",
    tokenAddress: "G2Hp8dR2NbwLSBPbrvQ8Rya6ausqpgQUYzQFDGvaJJJA",
  },
  "76-ligma-lane": {
    name: "76 Ligma Lane",
    biome: "city",
    frameType: "occult",
    streetId: "ligma-lane",
    tokenAddress: "HJC5LgoGoKXecdcgxX6sp82ENfkWwPCn6W6Q4Zf8veo6",
  },
  "76-rosa-street": {
    name: "76 Rosa Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "rosa-street",
    tokenAddress: "9Ycz2yNwFwjt8yrwdoEdPqSnKMKS9VT1FLXPt6nezVJ9",
  },
  "76-supple-circle": {
    name: "76 Supple Circle",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "6GGqZ8ivHhKGPAFLY5p9kd4ZWzWZ9SDRFMjRwioWXmuK",
  },
  "760-rodeo-drive": {
    name: "760 Rodeo Drive",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "rodeo-drive",
    tokenAddress: "64oupWnodpTFuWpEzff9zmvGVxZ9fecYJ5dbQqCeJbK9",
  },
  "764-rodeo-drive": {
    name: "764 Rodeo Drive",
    biome: "mountain",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "8KYrBJso14aDs4evSL2v7BuB8rVtoGBSdF84Azqbsxrc",
  },
  "765-rodeo-drive": {
    name: "765 Rodeo Drive",
    biome: "beach",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "2BXb6m6edtY67m3X6bk4e2Kzz7Vo8hyaSsUXsyBg4QuK",
  },
  "767-rodeo-drive": {
    name: "767 Rodeo Drive",
    biome: "mountain",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "AVieBGgSuJ1KdjabJ3v9rsBSoRmrbAoa5sAMDjkTaHC1",
  },
  "768-rodeo-drive": {
    name: "768 Rodeo Drive",
    biome: "desert",
    frameType: "occult",
    streetId: "rodeo-drive",
    tokenAddress: "Hrtu2aSsWu9F7BdbEQgX61zwyfqFBGjuLAVAedqhqZSE",
  },
  "77-abbey-road": {
    name: "77 Abbey Road",
    biome: "woods",
    frameType: "glass",
    streetId: "abbey-road",
    tokenAddress: "4Mbn8HuujssCgBxRVqJeDxB63pV3sfc32phU6TK7NH69",
  },
  "77-ape-lane": {
    name: "77 Ape Lane",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "DJcvhHaDVf2gCXw7QurGsuxp4TKQ9YprRPogHV1j4hrc",
  },
  "77-king-street": {
    name: "77 King Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "TkNV6Hdp2tRPfgVSQvCSux3BgSkwqH6Hish39YfxGjs",
  },
  "77-rosa-street": {
    name: "77 Rosa Street",
    biome: "volcanic",
    frameType: "rounded-green",
    streetId: "rosa-street",
    tokenAddress: "6TfsBViwEiUSt7ctdfQBqhrKaRtZQmCJBfngb3eWR9Sf",
  },
  "77-stewart-avenue": {
    name: "77 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "DxXuhTWAwMsGirMYXETPcRi6aXMRL38EeDvJMACsi94v",
  },
  "77-supple-circle": {
    name: "77 Supple Circle",
    biome: "underwater",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "GjCXUK7zWqDWMoRL2BkKdvwFbMm4LaGvqdH7GjV43FnF",
  },
  "77-wall-street": {
    name: "77 Wall Street",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "wall-street",
    tokenAddress: "7Bcrm3KNTkksTv52XYg1B6XeKt3G5rNMR2SRwWmECWQ8",
  },
  "771-rodeo-drive": {
    name: "771 Rodeo Drive",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "rodeo-drive",
    tokenAddress: "DM8rByJ18qSg5cNv5ejAM14QsHbvbkWk6kkM5yBqTAD3",
  },
  "774-rodeo-drive": {
    name: "774 Rodeo Drive",
    biome: "desert",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "9AcKXZsV6RzHZ7yYnSTWw6Zee38dXV9RxDF8Xt3TYW9L",
  },
  "775-rodeo-drive": {
    name: "775 Rodeo Drive",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "rodeo-drive",
    tokenAddress: "54Q9EMHcHN2PoCyqP3ag8JQMiy5B1eVMcYZQF7Gj3Jzq",
  },
  "777-rodeo-drive": {
    name: "777 Rodeo Drive",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "rodeo-drive",
    tokenAddress: "2DfsABdCParZqoR87qkpfP5jF2DTyiCeMMJwrgp92fUJ",
  },
  "779-rodeo-drive": {
    name: "779 Rodeo Drive",
    biome: "underwater",
    frameType: "rounded-red",
    streetId: "rodeo-drive",
    tokenAddress: "27dDZxZPiEpvxeJ3zvjzLUaPvVSFVyfEF1JB2iiBZT5q",
  },
  "78-abbey-road": {
    name: "78 Abbey Road",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "abbey-road",
    tokenAddress: "9TMjXjdaYptSfwJVCiYjokLDRcVUVYi2SyBb6SHGu2xN",
  },
  "78-ape-lane": {
    name: "78 Ape Lane",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "EBMgjpdKQKuVd2gBxQWdQ9UsHctYtrksYhqBUsCqrnzm",
  },
  "78-king-street": {
    name: "78 King Street",
    biome: "city",
    frameType: "gold",
    streetId: "king-street",
    tokenAddress: "H7nzZq872h4FSR5xM9bfBcuKXWfWsztgUP23PcQe8it4",
  },
  "78-rosa-street": {
    name: "78 Rosa Street",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "rosa-street",
    tokenAddress: "HL5GV1B7AvmBdmYsyZJHfRD4q5xPXJR89Sns6Y2YmDzL",
  },
  "78-stewart-avenue": {
    name: "78 Stewart Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "am9nfHkkTaNyXXYfyjTdSbABToR1cuLQt7mZdbX6BUy",
  },
  "78-supple-circle": {
    name: "78 Supple Circle",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "supple-circle",
    tokenAddress: "5hYer733JdDn1sx2v456i3GGpa8v9JEwZ7WbRQVuaCN8",
  },
  "78-teeps-lane": {
    name: "78 Teeps Lane",
    biome: "desert",
    frameType: "occult",
    streetId: "teeps-lane",
    tokenAddress: "FuAihgkoVdgVKvFsnhst35XjnRf23CmS9StfAS9J1JAn",
  },
  "78-wall-street": {
    name: "78 Wall Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "FQvc6TsKTTEqazze4y28YMPHj4mpaYXPZsBoMdmeTEFP",
  },
  "781-rodeo-drive": {
    name: "781 Rodeo Drive",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "rodeo-drive",
    tokenAddress: "BT3nqrFp6ypGabMBwF5NQpjwLUowwYqRz5HyG3vG4ZeX",
  },
  "784-rodeo-drive": {
    name: "784 Rodeo Drive",
    biome: "volcanic",
    frameType: "shuttered-yellow",
    streetId: "rodeo-drive",
    tokenAddress: "9EC88eaRX5Tn4TBjJAdzPNx5sZoyrVbCC5BFY4j4vQj4",
  },
  "786-rodeo-drive": {
    name: "786 Rodeo Drive",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "rodeo-drive",
    tokenAddress: "2dhUubPtaYKFEgATRDnxxyDZinn7vMLKRvViQzhgCzN2",
  },
  "787-rodeo-drive": {
    name: "787 Rodeo Drive",
    biome: "field",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "2N7G39uNA4UZwVTGD53hHPXjHnhouR9SoJ7y5dRHw2p8",
  },
  "789-battle-street": {
    name: "789 Battle Street",
    biome: "desert",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "ELuG9emw8FfaBmxHR6WcCmLDuxFMN4YLY4ogDQn6TEV4",
  },
  "79-abbey-road": {
    name: "79 Abbey Road",
    biome: "desert",
    frameType: "rounded-lavender",
    streetId: "abbey-road",
    tokenAddress: "2bKgHkb2uGKKwbe76Vq5Y5pfUsCwVSfi5Cx765AbEivN",
  },
  "79-ape-lane": {
    name: "79 Ape Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "EznB38gG1HAqHu3yQZdXMdxAbRRxzQCRqcgFkhSTT4rQ",
  },
  "79-king-street": {
    name: "79 King Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "GCueQSySQZ18LhAYGNnHgSPPPw7tKAwr67pQrR3xKZgE",
  },
  "79-rosa-street": {
    name: "79 Rosa Street",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "rosa-street",
    tokenAddress: "6N7HFXfVkBDTVxmkpEPGtUUbpdAXv8tZ5G3F7pDMnFMd",
  },
  "79-supple-circle": {
    name: "79 Supple Circle",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "supple-circle",
    tokenAddress: "EgS3WWVkyZfSJd5mHYEbdwBA7KvDwQY1CZ4LszvTdwCV",
  },
  "79-wall-street": {
    name: "79 Wall Street",
    biome: "city",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "5mAdsfCPbUvaUcTkVufxHmBmYbjrwpy5ktLiG5ChWxCL",
  },
  "790-battle-street": {
    name: "790 Battle Street",
    biome: "desert",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "rptyZVJnw9iCw2uhqi2iXHqVRfix1mD8NeawzNJ9bSC",
  },
  "790-rodeo-drive": {
    name: "790 Rodeo Drive",
    biome: "arctic",
    frameType: "shuttered-green",
    streetId: "rodeo-drive",
    tokenAddress: "rZi3KYV3ScEZVAc4RXsF1UZKqGgqC9dvPe1XzsgvfDj",
  },
  "791-battle-street": {
    name: "791 Battle Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "DqETa3JgyHsBfrxocb3rCneY2feTRcGTLNvfxXoyZhjX",
  },
  "793-battle-street": {
    name: "793 Battle Street",
    biome: "underwater",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "2Ynyza5Sz9c66ysKP9g5bSjLfbzoFsGA4jAgRKwXzi7d",
  },
  "793-rodeo-drive": {
    name: "793 Rodeo Drive",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "rodeo-drive",
    tokenAddress: "5RpQfTZXwPZ84gq2DisCejHs8b1xJknx6i8twuiH8uhu",
  },
  "795-battle-street": {
    name: "795 Battle Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "8TPCu52rnYzvW62dCt1bJZWCqyCPH3cUD3gihFYmsF8C",
  },
  "795-rodeo-drive": {
    name: "795 Rodeo Drive",
    biome: "mountain",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "HwbigJbv1nBLd9F9KbQhsb4wXLbwkFMULYHT7PyveCQx",
  },
  "796-battle-street": {
    name: "796 Battle Street",
    biome: "mountain",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "3NBdHQcffD4mP3hpHx6ddhsjMJsRQqJy3iGHZjPaiJet",
  },
  "796-rodeo-drive": {
    name: "796 Rodeo Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "AF17Y9mVd1YMD8Chc4eo3RKDasdBoZAJvRPQGvox4fi",
  },
  "797-battle-street": {
    name: "797 Battle Street",
    biome: "field",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "FU2MS2Yrvf2F9kfvV3eTFym89rindii2AsomjQ1S2hEa",
  },
  "797-rodeo-drive": {
    name: "797 Rodeo Drive",
    biome: "field",
    frameType: "occult",
    streetId: "rodeo-drive",
    tokenAddress: "HKrrWj25tyjhvdxXs4Ak9WSZjPf9MBAy8bfFwdQUuzeJ",
  },
  "798-battle-street": {
    name: "798 Battle Street",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "HYWfBZM3huK4wYCe5w1gJVtcH3dkdVNFAmC86MvtNVoy",
  },
  "799-battle-street": {
    name: "799 Battle Street",
    biome: "beach",
    frameType: "rounded-dark-green",
    streetId: "battle-street",
    tokenAddress: "HtCnNPgzG35UPbhMNfPeiLHKJYuL5eJyzfMsGsr9BVzs",
  },
  "8-teeps-lane": {
    name: "8 Teeps Lane",
    biome: "field",
    frameType: "glass",
    streetId: "teeps-lane",
    tokenAddress: "7bkzf6bWZJcpbKJuT5skV5eAPaKfwp9uv5rmVmbi3CuE",
  },
  "80-abbey-road": {
    name: "80 Abbey Road",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "abbey-road",
    tokenAddress: "318cGu6r8x6AHryc97w2SzXEHHiwtMYHFjMk4MksLV7Y",
  },
  "80-king-street": {
    name: "80 King Street",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "5R5nv2UW5DBo12kUnQbmtDuNphuJVf1qy45Md3zsiA38",
  },
  "80-rosa-street": {
    name: "80 Rosa Street",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "CaAktBZrmcSUV9Uynu8ZGTXHrHSsJpcCDhfe5o2QnVtW",
  },
  "80-supple-circle": {
    name: "80 Supple Circle",
    biome: "field",
    frameType: "occult",
    streetId: "supple-circle",
    tokenAddress: "6VoKyhC9Em37ykiYeyyjdUJaedVtSNmBYTXV7q9m8JSD",
  },
  "80-wall-street": {
    name: "80 Wall Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "wall-street",
    tokenAddress: "FXeKWPr3a1V71HGgW6BoTR9c7xZzmH2cBeeZrbbqkjpb",
  },
  "800-rodeo-drive": {
    name: "800 Rodeo Drive",
    biome: "beach",
    frameType: "shuttered-pale-green",
    streetId: "rodeo-drive",
    tokenAddress: "D6JZcXukMQYXJWeHvVtfoL4FYucThEyHRySw9beuA9BF",
  },
  "801-rodeo-drive": {
    name: "801 Rodeo Drive",
    biome: "woods",
    frameType: "rounded-pale-green",
    streetId: "rodeo-drive",
    tokenAddress: "AmLW1rARWT2nuznPEfyvimH3YM9uJLkTJds2vSxUKpLy",
  },
  "802-battle-street": {
    name: "802 Battle Street",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "battle-street",
    tokenAddress: "8csNXfErf7qYkQkDMa38sG5vrFvDjcKs9r6PbCa3F3Qe",
  },
  "803-battle-street": {
    name: "803 Battle Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "CGao7RWh96t7QmDfgJXNHnX9Te8r6BRQCY3z2kuU1PHe",
  },
  "804-battle-street": {
    name: "804 Battle Street",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "D2Gaw6yhiFynBJTAPTYVgsCwKE4yQVXDNrPQ9tjc6Kpq",
  },
  "805-battle-street": {
    name: "805 Battle Street",
    biome: "mountain",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "39zYdiCrqAN3vCTovugT5aj8igDdP5ZyvjrwwDAGKwFb",
  },
  "805-rodeo-drive": {
    name: "805 Rodeo Drive",
    biome: "underwater",
    frameType: "rounded-pale-green",
    streetId: "rodeo-drive",
    tokenAddress: "21tupzyhiExtMHjPGkY49txsSsCniJnYjtLzXNbWih4c",
  },
  "806-battle-street": {
    name: "806 Battle Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "CRsunGNwhQo2UpkDJ1i4zKHGdYmy19Ui3prMiJJTEKxP",
  },
  "807-broadway": {
    name: "807 Broadway",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "broadway",
    tokenAddress: "KbqMKHCaorwH871uJx1Ahu3g8ueURaVqtQpv7hiocP1",
  },
  "807-rodeo-drive": {
    name: "807 Rodeo Drive",
    biome: "field",
    frameType: "glass",
    streetId: "rodeo-drive",
    tokenAddress: "7bqUrpFVa9szJpRoNDiGLV9A4WPj9Wkh7Zm7M1neawCM",
  },
  "808-battle-street": {
    name: "808 Battle Street",
    biome: "desert",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "CTsGcp8v8JfxBH7hz449eTDtktPy3LESh2vZdUQiaCFg",
  },
  "808-rodeo-drive": {
    name: "808 Rodeo Drive",
    biome: "field",
    frameType: "rounded-green",
    streetId: "rodeo-drive",
    tokenAddress: "7c2BeajsebNuumj12EyJfXCqHRUyg1kwFQGtqtkWHdnp",
  },
  "809-battle-street": {
    name: "809 Battle Street",
    biome: "desert",
    frameType: "rounded-blue",
    streetId: "battle-street",
    tokenAddress: "8tphZcQwTHf3p43HSnSaPqfLjQJNHYivrYXdaSqHZr7V",
  },
  "809-broadway": {
    name: "809 Broadway",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "broadway",
    tokenAddress: "CFRWZsJx23tYQHWKy9wzefptCeMEqSxajwtkZo1wiK9P",
  },
  "809-rodeo-drive": {
    name: "809 Rodeo Drive",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "rodeo-drive",
    tokenAddress: "9CxZHUmFJYV9p2h6sViPSSspVL2WpKVcb6zacg9cgKRE",
  },
  "81-ape-lane": {
    name: "81 Ape Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "2KRZoPmt1SHkuPVdqp9LiS5g1qr2vbZxFKpcf2iBYbLh",
  },
  "81-king-street": {
    name: "81 King Street",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "DVwQkpBw3x7QfxVf7yUm1N4NyakEGGD7nwzxbX3pzVPN",
  },
  "81-rosa-street": {
    name: "81 Rosa Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "rosa-street",
    tokenAddress: "7VzQNzscZSZiDJut4ERDg8uKXXCJmDgk8uiDdkHp2a6V",
  },
  "81-supple-circle": {
    name: "81 Supple Circle",
    biome: "woods",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "Ft2daocHncpsouncaQPyFsuRGepswBVYPRWJLTfn1Kdh",
  },
  "81-teeps-lane": {
    name: "81 Teeps Lane",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "HvGwRifNPNdsyGsSinr1MKASCSFdx8RbpiG5UMQ2b8fK",
  },
  "81-wall-street": {
    name: "81 Wall Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "wall-street",
    tokenAddress: "7eXTnhjUweSSfQM7Cz79p5aJKYgs62MSUNGP8wYVgd9k",
  },
  "810-battle-street": {
    name: "810 Battle Street",
    biome: "city",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "7FnYUv19hSTAK7bVG94iaPrfS55SN5f5Yq8iU9fnfvwN",
  },
  "810-broadway": {
    name: "810 Broadway",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "broadway",
    tokenAddress: "5DjfKks7QTGh7pyFJ8ncPvvTQVyAHXLbkhTQtTjMoCZt",
  },
  "810-rodeo-drive": {
    name: "810 Rodeo Drive",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "rodeo-drive",
    tokenAddress: "GJPVaSdhfLiaTcuCY126ySHpuRpDA1zpABsDDWe6Wfxc",
  },
  "811-battle-street": {
    name: "811 Battle Street",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "DAQK5bqEEqkuEDdcdAa6e22t3T4rxhp9X2z1gYXUio3x",
  },
  "811-rodeo-drive": {
    name: "811 Rodeo Drive",
    biome: "city",
    frameType: "rounded-red",
    streetId: "rodeo-drive",
    tokenAddress: "8pNZduqkRYw5Le6Yb1mYi2herqXY3qtByQZsVjmHHoWo",
  },
  "812-broadway": {
    name: "812 Broadway",
    biome: "underwater",
    frameType: "rounded-yellow",
    streetId: "broadway",
    tokenAddress: "EqnNJ4CWTivwPynwMSpfHUXgcFJGsgj4iKCswLqa7f7Q",
  },
  "812-rodeo-drive": {
    name: "812 Rodeo Drive",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "rodeo-drive",
    tokenAddress: "DbENokETGyZ5M7sqG5JyZ9mzyzdzuGWTSjHbhKic8taN",
  },
  "813-battle-street": {
    name: "813 Battle Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "8cpcAGAcepureGhf8y79PPKkG5JU4v6aBF2Z2zFLN7KB",
  },
  "813-rodeo-drive": {
    name: "813 Rodeo Drive",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "rodeo-drive",
    tokenAddress: "27hZqSVgC3KxhdGRiAvK7mnzEcoZTbgtSeFxNpezKmp6",
  },
  "814-broadway": {
    name: "814 Broadway",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "broadway",
    tokenAddress: "5CZkdJBEc4oU2pfRfoy1VJcKN3qzFn93ufSPm4s4TWD6",
  },
  "814-rodeo-drive": {
    name: "814 Rodeo Drive",
    biome: "desert",
    frameType: "crystal",
    streetId: "rodeo-drive",
    tokenAddress: "2WmPbu6RacK1gEXrmCw3592ZdEU2HxsLwBGkqVX2RTMi",
  },
  "816-broadway": {
    name: "816 Broadway",
    biome: "city",
    frameType: "shuttered-dark-green",
    streetId: "broadway",
    tokenAddress: "GBa26pHamoSfvYwZLAtW2XbT9E6BrLmbe4VqrUoXm94M",
  },
  "817-battle-street": {
    name: "817 Battle Street",
    biome: "volcanic",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "B17w7PAQhA8GtSbrcAUxdzMzy5ct1MbenijVBZ62Pkc5",
  },
  "817-broadway": {
    name: "817 Broadway",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "broadway",
    tokenAddress: "G7Vyoqkptg2uPXqoiD2rvMi5KvjyhJAeL1onZaGKjAfG",
  },
  "818-battle-street": {
    name: "818 Battle Street",
    biome: "city",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "CAn9TFEoL1x38TVYPYiKZe3ycr3LiTJWPS7Kyow412fa",
  },
  "819-battle-street": {
    name: "819 Battle Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "7pcgwyMPFt9Z6jBAtoZRu7u7CYB9NdJLPhx5mTuAMuJM",
  },
  "819-rodeo-drive": {
    name: "819 Rodeo Drive",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "rodeo-drive",
    tokenAddress: "35sZDxcpECJVoATJCWxLLpnLzZjuBWuA4JX9LS1XU3LE",
  },
  "82-abbey-road": {
    name: "82 Abbey Road",
    biome: "treehouse",
    frameType: "rounded-blue",
    streetId: "abbey-road",
    tokenAddress: "AQXTb729SjwMCnKdpduX926QtDYi7F6r16VkHckq6kXJ",
  },
  "82-ape-lane": {
    name: "82 Ape Lane",
    biome: "field",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "BMyRtJkVerb7yPCnKud5ta8wFTAe4qNvemiuSYhuvUtL",
  },
  "82-king-street": {
    name: "82 King Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "king-street",
    tokenAddress: "4d8kASEDBGLqG4QqRNSc9aPqXZPuETCPS2nkQ7TGLXvz",
  },
  "82-supple-circle": {
    name: "82 Supple Circle",
    biome: "treehouse",
    frameType: "shuttered-lavender",
    streetId: "supple-circle",
    tokenAddress: "y2mC9gGrYw2qbmnKLthgUYbUe6nDYKoRyWFLiyx8zLB",
  },
  "82-wall-street": {
    name: "82 Wall Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "wall-street",
    tokenAddress: "EVCS4sLLVmRbfeeTcKc4FLg41oLXSPsDPvyoQ4U3zq8g",
  },
  "820-battle-street": {
    name: "820 Battle Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "Fsnzyqs5bXfu67GS418NzjfQZzYcF61A54cn9tjAw1QP",
  },
  "820-broadway": {
    name: "820 Broadway",
    biome: "woods",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "HWsXrEGpvrBf7tEPkphLiAaXHy574uGyZfZ21JJc74X2",
  },
  "821-battle-street": {
    name: "821 Battle Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "CvXNgtwpfaeZDaJY5Rd5nxkJHQPjzZZQT9e6HX17TVWM",
  },
  "822-battle-street": {
    name: "822 Battle Street",
    biome: "field",
    frameType: "occult",
    streetId: "battle-street",
    tokenAddress: "G5S2RbcPbhjLFs9CEq2DNqiRcDjFzowhp2znTUYUW33Y",
  },
  "822-broadway": {
    name: "822 Broadway",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "E3oCrzA3T9k3NV8C1wUBTRbAoEZSgUPiUWQVQQjvEDYy",
  },
  "823-battle-street": {
    name: "823 Battle Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "Ckq2wFqMgd7aSvbdgyFkv45coMPguQvdz4ibNaenzo59",
  },
  "824-battle-street": {
    name: "824 Battle Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "FNzTCd49LGjnyX6n9BBxV8dqGBKxLtr6PpcT1r1CvJnA",
  },
  "825-battle-street": {
    name: "825 Battle Street",
    biome: "city",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "51Jw53d3bEPx1mdiBsr9WhtX72k3i4wnwfoBVpzqAH8i",
  },
  "826-battle-street": {
    name: "826 Battle Street",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "battle-street",
    tokenAddress: "CRe5chuWqBTK7ydX3UphDVWAoUjZh5fBWpJTyxAf5uq8",
  },
  "826-broadway": {
    name: "826 Broadway",
    biome: "woods",
    frameType: "rounded-blue",
    streetId: "broadway",
    tokenAddress: "9A3rUKP5ywcHZE4e6eAZJHCD1gq8eM7JwvFBMMS4N5xi",
  },
  "827-battle-street": {
    name: "827 Battle Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "3rS6XGQqjrs6jFHM9nLinK11MaY9urMFEPnsPWph4cgB",
  },
  "827-broadway": {
    name: "827 Broadway",
    biome: "underwater",
    frameType: "shuttered-blue",
    streetId: "broadway",
    tokenAddress: "BYFCu2Bbe59vN91Vjash19bnPkFJnrc7Qixa2WDeUuhB",
  },
  "828-battle-street": {
    name: "828 Battle Street",
    biome: "underwater",
    frameType: "rounded-blue",
    streetId: "battle-street",
    tokenAddress: "JUaEhGcqJLVNT7vsotf4oTsCvwWsN396Pk63AnAAHNU",
  },
  "828-broadway": {
    name: "828 Broadway",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "broadway",
    tokenAddress: "2LZLWyUxxwFBP42q6zxgzYq9snayPogHwVVKGVGMLkeD",
  },
  "829-battle-street": {
    name: "829 Battle Street",
    biome: "desert",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "4EHYYoGnK9GdGAW8yezh19ZqVQq5F1M5QnjsuWRExeLp",
  },
  "829-broadway": {
    name: "829 Broadway",
    biome: "city",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "25kYBdrQQvj4yh6Td1JTVQsnU8ZdCRT1iWu5cBxexygx",
  },
  "83-abbey-road": {
    name: "83 Abbey Road",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "abbey-road",
    tokenAddress: "GNG8KpFF1nA6eTNjtdqLzP68mq6jJDhwz3mM4DZAb53k",
  },
  "83-ape-lane": {
    name: "83 Ape Lane",
    biome: "arctic",
    frameType: "rounded-yellow",
    streetId: "ape-lane",
    tokenAddress: "DButQAQ6c1XDDF1uWwmUSpZ6ZWhFuGJaeR2s5fLeCVj9",
  },
  "83-king-street": {
    name: "83 King Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "king-street",
    tokenAddress: "957EzR6zffBzrzvFkaDa1qPd6DkbsuhiWi3DLFkkxxBN",
  },
  "83-rosa-street": {
    name: "83 Rosa Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "rosa-street",
    tokenAddress: "14HhmATXqpiXLuRz48hbQqFpGeekhyc5fwBZfxM4aUm5",
  },
  "83-supple-circle": {
    name: "83 Supple Circle",
    biome: "field",
    frameType: "rounded-green",
    streetId: "supple-circle",
    tokenAddress: "72iXNfbPQNqgcmYHFXGQEqnqBCjMczRzXwrHKcV2sRBB",
  },
  "83-teeps-lane": {
    name: "83 Teeps Lane",
    biome: "arctic",
    frameType: "crystal",
    streetId: "teeps-lane",
    tokenAddress: "AtpxzTgKALuhhaHLCemW8eB2FpFocQUDbhBcFHxBFyFf",
  },
  "83-wall-street": {
    name: "83 Wall Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "wall-street",
    tokenAddress: "EoSQeFJTCxGvR87JVgfYmwsmN5NQYtkKCtdhBPnrtL5P",
  },
  "830-battle-street": {
    name: "830 Battle Street",
    biome: "woods",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "BA9cfHGmESEykCg3UDk4Xzi5ofg9oNtsuBnHbRsTGYep",
  },
  "830-broadway": {
    name: "830 Broadway",
    biome: "volcanic",
    frameType: "occult",
    streetId: "broadway",
    tokenAddress: "FbmA4VNZayJi7o7nZ1B1cRQVjXPG9spFc8RMVSV1JDYR",
  },
  "831-broadway": {
    name: "831 Broadway",
    biome: "treehouse",
    frameType: "shuttered-yellow",
    streetId: "broadway",
    tokenAddress: "391x7V3EKDzFknxtrLu33x2J47vATbzFRJTUbT5qYfDS",
  },
  "832-battle-street": {
    name: "832 Battle Street",
    biome: "beach",
    frameType: "rounded-dark-green",
    streetId: "battle-street",
    tokenAddress: "CWDfigHUttA2ciAp9BMwYhdkZDVdbUvkc85XiM6Mk5RP",
  },
  "832-broadway": {
    name: "832 Broadway",
    biome: "underwater",
    frameType: "glass",
    streetId: "broadway",
    tokenAddress: "8Jucv2Dp5H92zPZbCQpMgkhT5Ae5ttNKz5yvtsoHcFp7",
  },
  "833-battle-street": {
    name: "833 Battle Street",
    biome: "city",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "71rZvBs1HZyfRxJ6t9QQHrac9wXAPnH9rSXbksTCoQYN",
  },
  "834-broadway": {
    name: "834 Broadway",
    biome: "mountain",
    frameType: "rounded-blue",
    streetId: "broadway",
    tokenAddress: "8bYiQewfwNqJJKgn31DLrAYLj1hjUHJKafFCamxtkCZi",
  },
  "835-broadway": {
    name: "835 Broadway",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "3rjHdD2V3v3nmDLhpBs7MTbdgHtgAKQADV9VFTnW9tsD",
  },
  "836-broadway": {
    name: "836 Broadway",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "BkVKg5gySGybBP59w4aV3dZwU25xewt7KMGgBWX8gmSB",
  },
  "838-battle-street": {
    name: "838 Battle Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "76xu7krEFVcnCwUV2mVVDB5aT61AtzxtYxUJxwWmwY8g",
  },
  "839-battle-street": {
    name: "839 Battle Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "3nxsM1bBfNSS6qaTXB7QRrB7PpogWhRpknNt2TEsXz2E",
  },
  "84-ape-lane": {
    name: "84 Ape Lane",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "ape-lane",
    tokenAddress: "5MmvRaoePZUZdPsQzb4uYwCHKSfbEhY9uKmdAQ7i1Ur2",
  },
  "84-king-street": {
    name: "84 King Street",
    biome: "beach",
    frameType: "crystal",
    streetId: "king-street",
    tokenAddress: "3NiwiZc23ALzXDrubPtpwagzxsSjSCtnKLTiMqDSzQrf",
  },
  "84-rosa-street": {
    name: "84 Rosa Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "9VNM9r3xxvypMm4EGKCAKcX7SgwK4RmeHWfHUSxBAsc8",
  },
  "84-stewart-avenue": {
    name: "84 Stewart Avenue",
    biome: "field",
    frameType: "occult",
    streetId: "stewart-avenue",
    tokenAddress: "6AsLUDsj4Wt6CRsk62E6Hj9V4yv1LTt7aqGAESBhg8yx",
  },
  "84-supple-circle": {
    name: "84 Supple Circle",
    biome: "mountain",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "6DsbXCuhptoBBB6CiGJKg1WzGCMh3NsfcCZ2AKRX4pnh",
  },
  "84-teeps-lane": {
    name: "84 Teeps Lane",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "teeps-lane",
    tokenAddress: "4gcjBYJabTNqpQohUpx2b5Vy6UJRPCWDq7wNMZk3rERK",
  },
  "840-battle-street": {
    name: "840 Battle Street",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "5Dsf68XiBQGzF3Pn4b7a5DNnipJncwZAtTTpx2kTJgx3",
  },
  "840-broadway": {
    name: "840 Broadway",
    biome: "mountain",
    frameType: "rounded-pale-green",
    streetId: "broadway",
    tokenAddress: "3A8o76YftwWXbGQp12LLAAEV7oZGUcrBDx8VnUYsviw6",
  },
  "841-battle-street": {
    name: "841 Battle Street",
    biome: "underwater",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "A2wcF8fBjwpqoVGSZK5EMUCtkSpvymCiuCA1axJP6wz8",
  },
  "842-battle-street": {
    name: "842 Battle Street",
    biome: "treehouse",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "14nJhFwrsEHo1sEiEBUjbgX3stE832vNHAAk7msmf6HJ",
  },
  "843-broadway": {
    name: "843 Broadway",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "broadway",
    tokenAddress: "3GSXAT1n4gtGg94aNEA73G1nqn6ZYeb7c1xjtgZFmS8x",
  },
  "844-battle-street": {
    name: "844 Battle Street",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "battle-street",
    tokenAddress: "FXg2N1A2QsNtxMxqvJu4zjBzsNzCNeWRqse2Mvtn8BSt",
  },
  "844-broadway": {
    name: "844 Broadway",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "broadway",
    tokenAddress: "HM8vc1tCrFTGiqN41ZLyL6B3KRfwwmYH7t5QwibYVeyg",
  },
  "845-battle-street": {
    name: "845 Battle Street",
    biome: "desert",
    frameType: "rounded-dark-green",
    streetId: "battle-street",
    tokenAddress: "A9gEjidrCwvNL5hhpgur6DjFw97KhxtpDS97CxiCJ3uN",
  },
  "845-broadway": {
    name: "845 Broadway",
    biome: "treehouse",
    frameType: "occult",
    streetId: "broadway",
    tokenAddress: "HzhnaPjK2JHrGaFS8xU7MbxeTCmAA18WrYXA2q7k5D1X",
  },
  "846-battle-street": {
    name: "846 Battle Street",
    biome: "woods",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "DAKDjcYZ7Y4PsTJebmfHbrnZmZE8SZG244gXeL9fFLuq",
  },
  "846-broadway": {
    name: "846 Broadway",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "CS77byninKjdeDauEqM6NQX87AT4zrYoFQxCLrNH1ku4",
  },
  "847-battle-street": {
    name: "847 Battle Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "5ujDapPjx4s16zExuLZrkxhoW5oiMW1XhvNzZU7xnqZ2",
  },
  "847-broadway": {
    name: "847 Broadway",
    biome: "woods",
    frameType: "glass",
    streetId: "broadway",
    tokenAddress: "54YBaZ8Cs26JDZM9utCEjirp6YU1MuCgp9QvL5odSwZi",
  },
  "849-battle-street": {
    name: "849 Battle Street",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "battle-street",
    tokenAddress: "4VTmK9yQm4JRiCYUXeWQkaXwfcMkobxYmXpq11NStF2m",
  },
  "85-ape-lane": {
    name: "85 Ape Lane",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "ape-lane",
    tokenAddress: "844nsKzXcMNg4c92XHUtZ74uc7yCMga1J5z9d1TRYZeV",
  },
  "85-king-street": {
    name: "85 King Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "king-street",
    tokenAddress: "8xuU571DD7S9fRZbUc9uipzeYudKC9pNUCEGcpY5KRrZ",
  },
  "85-rosa-street": {
    name: "85 Rosa Street",
    biome: "treehouse",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "9aFPa6K4tXqJj6bsbFETqaMMEH6vNeB7if4KcKMfwNux",
  },
  "85-stewart-avenue": {
    name: "85 Stewart Avenue",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "G2bMnciCTqoixirmK3wck8eoTfag45BfmUe3oh3dghC1",
  },
  "850-battle-street": {
    name: "850 Battle Street",
    biome: "field",
    frameType: "rounded-dark-green",
    streetId: "battle-street",
    tokenAddress: "6jXnwWAaPqrd8x1s2NHkreMcR7zeU7apEDKwL1GW4G7k",
  },
  "850-broadway": {
    name: "850 Broadway",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "PNJDpgJPDGkmtz9cYKLstABEzxiD1GcaowSk7ru9s5i",
  },
  "851-battle-street": {
    name: "851 Battle Street",
    biome: "woods",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "AAq4PMf5gPq3aKdnEa7PXtxy75HYZRRSCHzX3tNZfeJ5",
  },
  "851-broadway": {
    name: "851 Broadway",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "F11EdV8B4TtzHUSdDiPBFkEffMiqUr4kPCsRZdNBMX8N",
  },
  "852-battle-street": {
    name: "852 Battle Street",
    biome: "beach",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "EetYs7B4bBxb53yLBHHy2zy91ZicBMQ7xatRPXRq73kR",
  },
  "852-broadway": {
    name: "852 Broadway",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "broadway",
    tokenAddress: "DeRDfZQBXhpBTf5pb8NiRzCN9BNcYqfaYQQMScGc99M5",
  },
  "853-battle-street": {
    name: "853 Battle Street",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "BNCckK3A7xyQ84FF9ps5KjfkSTQ9vmM2sDx7uvFvpi81",
  },
  "854-battle-street": {
    name: "854 Battle Street",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "battle-street",
    tokenAddress: "5ommAFL91tvFF6vJ97mLzP2rpLaWPyyhJMv3X1ZdjJNd",
  },
  "854-broadway": {
    name: "854 Broadway",
    biome: "beach",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "6mPbdsuiWhJUSq8FCFgs6eJVN2LyRsjcmhzV2jUvN6w4",
  },
  "856-broadway": {
    name: "856 Broadway",
    biome: "field",
    frameType: "rounded-red",
    streetId: "broadway",
    tokenAddress: "B6mPJxs7W7Xms1kS5CGwADVFsfDZNDMP4B5gacKjVeex",
  },
  "857-battle-street": {
    name: "857 Battle Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "GkuY8ud3XL76w3t4YQsb1YjM9gLRRpnSKY33JSPJVRCE",
  },
  "858-battle-street": {
    name: "858 Battle Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "DN1unxeH1CVU2VdhGBkzhdZYcqTGiqpDVfAbbM3xXnr9",
  },
  "858-broadway": {
    name: "858 Broadway",
    biome: "city",
    frameType: "rounded-blue",
    streetId: "broadway",
    tokenAddress: "99nwLcUTrU1F3tgbWPvp6pWMz1wj2r9iFJsUefaZ6k1i",
  },
  "86-abbey-road": {
    name: "86 Abbey Road",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "abbey-road",
    tokenAddress: "J6UBjxdMNf74kEvCe5T58zdYPv8ZfAwajAG3xi6Kk4d3",
  },
  "86-rosa-street": {
    name: "86 Rosa Street",
    biome: "mountain",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "91J6tbGKqQDif4ouzHrMCen2Ns3SzttCmDbhu6FXSf5B",
  },
  "86-stewart-avenue": {
    name: "86 Stewart Avenue",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "stewart-avenue",
    tokenAddress: "4poJKvZzjqYpVodSdVvzekfZt696FK5DJGPN5ymeUE97",
  },
  "86-supple-circle": {
    name: "86 Supple Circle",
    biome: "treehouse",
    frameType: "glass",
    streetId: "supple-circle",
    tokenAddress: "3iPdR9UPpAxbEDmYTn4Xdvx5TanCMPYay9W3gQKXSKd8",
  },
  "86-wall-street": {
    name: "86 Wall Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "wall-street",
    tokenAddress: "41wJ4x9DnHpJWDU9k3JTHf4WuFvW4c4oajvHD5CHZS5L",
  },
  "860-battle-street": {
    name: "860 Battle Street",
    biome: "field",
    frameType: "shuttered-dark-green",
    streetId: "battle-street",
    tokenAddress: "3speWuuCbbg3q45xQqgaqZX9wG3eBtp9Gj4dPfVQkYna",
  },
  "860-broadway": {
    name: "860 Broadway",
    biome: "city",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "GnDXRmDeteyyEGB9tzGiUYhCovp5aAz54MyJqKiQGnjg",
  },
  "861-battle-street": {
    name: "861 Battle Street",
    biome: "underwater",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "GeK3oiybM2PpEP1gUJHasD4t7mSHaJZ8cMR2si4QTi5",
  },
  "862-battle-street": {
    name: "862 Battle Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "MVKaKqMt5NUFYGwG4uNRp2ayv5E2UWDrgxfGWfjsvVD",
  },
  "863-broadway": {
    name: "863 Broadway",
    biome: "underwater",
    frameType: "glass",
    streetId: "broadway",
    tokenAddress: "5LeKesW1DE57Pe1EXpgcccVyxf8xKj5tCaf33NqdeKgr",
  },
  "864-battle-street": {
    name: "864 Battle Street",
    biome: "arctic",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "HX4e7xmR7vRvVH3w3m6sCgxKJTFCdLtZxrPrxwcCZcf6",
  },
  "865-battle-street": {
    name: "865 Battle Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "8uTHnZyxBtvWttRGLMofBAw1yphM2w8Ud8AyqXXF3X9k",
  },
  "866-broadway": {
    name: "866 Broadway",
    biome: "desert",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "37YNuKbggKJ6JeKWKd4mgXTF4LtPBkhhrkdRESWMBXyU",
  },
  "867-battle-street": {
    name: "867 Battle Street",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "9hb2FD1gK2NSjnBZt92ctLFwnJnEPkKGBdDZGfEQB5X",
  },
  "867-broadway": {
    name: "867 Broadway",
    biome: "field",
    frameType: "rounded-red",
    streetId: "broadway",
    tokenAddress: "nyTteHHfCHkeU2j5wXn5qebQ6wmdQYC6BS1rXTSxCD3",
  },
  "868-battle-street": {
    name: "868 Battle Street",
    biome: "desert",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "3ETDkt8uQWSydi2vRDCF8BbKVMVwKMsEcthT7c9GrAJ5",
  },
  "868-broadway": {
    name: "868 Broadway",
    biome: "city",
    frameType: "occult",
    streetId: "broadway",
    tokenAddress: "3E6zxNeRoC4DELFi424dCdHatcJrhJgwizzrZDdBmX9a",
  },
  "869-battle-street": {
    name: "869 Battle Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "battle-street",
    tokenAddress: "9ZQg6ybMGTTS2r6FNRyJ22UzhiyzBN3MLV8opnCajkAn",
  },
  "87-abbey-road": {
    name: "87 Abbey Road",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "abbey-road",
    tokenAddress: "13ZpjcVyxLbtMEE7Xakbx2scjEyCpQ43vzh2XfspyLjy",
  },
  "87-ape-lane": {
    name: "87 Ape Lane",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "DzMbZ8rPnXrcaxJbQVuPEAbhBdR6oWcUXUXj14zbPU7U",
  },
  "87-king-street": {
    name: "87 King Street",
    biome: "desert",
    frameType: "shuttered-blue",
    streetId: "king-street",
    tokenAddress: "6e7QjvNMCLNseaVgeGpiHaBrAiCRN7eKzGEWKaRvUEvT",
  },
  "87-rosa-street": {
    name: "87 Rosa Street",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "rosa-street",
    tokenAddress: "EShrb6SpRrbxaNw5h59fnMKti5NUiXoMs9Wd7qiAFxfj",
  },
  "87-supple-circle": {
    name: "87 Supple Circle",
    biome: "mountain",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "CMEFTg31LCktJRmiBmDs6tPcqnbZ2RPSuBcY8EKFHk6H",
  },
  "87-teeps-lane": {
    name: "87 Teeps Lane",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "teeps-lane",
    tokenAddress: "GqCCNH5CDtVDQyBSALd9vCwSLTzFo4zVadj1aR2uofFp",
  },
  "87-wall-street": {
    name: "87 Wall Street",
    biome: "mountain",
    frameType: "rounded-lavender",
    streetId: "wall-street",
    tokenAddress: "Bg3p9XHRtLEggG6qz1Cp4FWkM5gr5tmpfSVgajYyRXoA",
  },
  "870-battle-street": {
    name: "870 Battle Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "8njaWqyfS1EtAcS2GcAKExraeQXD3nX5BaZndFFNugkD",
  },
  "870-broadway": {
    name: "870 Broadway",
    biome: "beach",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "57fmeBMZMv5iLMGSPxMu6jsFV9MVBnwnygp8ZSbwB56d",
  },
  "872-battle-street": {
    name: "872 Battle Street",
    biome: "desert",
    frameType: "occult",
    streetId: "battle-street",
    tokenAddress: "5RHA9QjXGX7LHkz3wwgLmonqQ3CaxfiSjzoxHiRvXbue",
  },
  "872-broadway": {
    name: "872 Broadway",
    biome: "beach",
    frameType: "rounded-yellow",
    streetId: "broadway",
    tokenAddress: "FoTPWXo9zjit2ncp4H43QFXLxqedtEpxk74Lp7JEeciC",
  },
  "873-battle-street": {
    name: "873 Battle Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "Ae2YGr82stpF6vgDGGJGpbtHAudwwVALbMTZaonE5YAd",
  },
  "873-broadway": {
    name: "873 Broadway",
    biome: "city",
    frameType: "shuttered-pale-green",
    streetId: "broadway",
    tokenAddress: "E9Gto3jtSgnUB26iZUsJZ6GMbRkvvwYSBkLptbzuECos",
  },
  "874-battle-street": {
    name: "874 Battle Street",
    biome: "field",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "CHYUPrVig8XhEdyZwHBTj9AQyg2kMMSSi22e95nCYBsA",
  },
  "875-battle-street": {
    name: "875 Battle Street",
    biome: "woods",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "ABhanpJDtXq18fm7or87bvrELpzgKejLnSag12Lds6xD",
  },
  "876-battle-street": {
    name: "876 Battle Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "NR5Pdirby2j6x42Gf4WWfAwg58dJP8HhAbRFp8m5s2Q",
  },
  "876-broadway": {
    name: "876 Broadway",
    biome: "desert",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "EyztQ6HwFG2cedJSgwkkyE41hRaCbnXX5wRbawTuNaeh",
  },
  "877-battle-street": {
    name: "877 Battle Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "battle-street",
    tokenAddress: "F9N5Z27869TKwxZR1mpVP9ueqY3F1W2rdfT8QXc6gAVw",
  },
  "877-broadway": {
    name: "877 Broadway",
    biome: "field",
    frameType: "rounded-lavender",
    streetId: "broadway",
    tokenAddress: "2x54adbJ6uakVghiXaU637P7F3AjjaV1oumvTrkNXdBw",
  },
  "878-broadway": {
    name: "878 Broadway",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "2wtSMa9oBRd28oPdQcDTZvGbsUPdEQQsehDvszKd46U5",
  },
  "879-battle-street": {
    name: "879 Battle Street",
    biome: "field",
    frameType: "rounded-yellow",
    streetId: "battle-street",
    tokenAddress: "XRJZi6unuhw66SfJEseWDqGmivdDHG5mfa5jXVt51xM",
  },
  "88-abbey-road": {
    name: "88 Abbey Road",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "abbey-road",
    tokenAddress: "BWgwRMTgW8qxeAzt1yUSuDUGBJpxmWwMQVYhBoSFiECo",
  },
  "88-king-street": {
    name: "88 King Street",
    biome: "mountain",
    frameType: "rounded-red",
    streetId: "king-street",
    tokenAddress: "5Liw9rzX9nLYELEXWpUpU5qdiBXCRfX8rhCqBMWYooy6",
  },
  "88-stewart-avenue": {
    name: "88 Stewart Avenue",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "stewart-avenue",
    tokenAddress: "BQc2NmqU6sfN9S5UL1cGGr9ttXHkMBCzEUDTV5SRXdXi",
  },
  "88-teeps-lane": {
    name: "88 Teeps Lane",
    biome: "beach",
    frameType: "rounded-green",
    streetId: "teeps-lane",
    tokenAddress: "6YWKjx5J7pHzN4gYeExbfvKT3Ju18sQEnH8SVKzcxWMQ",
  },
  "88-wall-street": {
    name: "88 Wall Street",
    biome: "woods",
    frameType: "rounded-green",
    streetId: "wall-street",
    tokenAddress: "5VRJjNHXwEusPPRNNcc2HDPwmVScxeND7ccoBBLCCEWa",
  },
  "881-battle-street": {
    name: "881 Battle Street",
    biome: "woods",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "FFb2MittChLohqQCP2djeRw5VKQL2dRYyKR8pFVMBq3S",
  },
  "881-broadway": {
    name: "881 Broadway",
    biome: "underwater",
    frameType: "crystal",
    streetId: "broadway",
    tokenAddress: "2wg1ZHMjy9NrvnDV5YSf7ZBj8ZLApCaEJbGEqqmarqaq",
  },
  "882-broadway": {
    name: "882 Broadway",
    biome: "mountain",
    frameType: "glass",
    streetId: "broadway",
    tokenAddress: "DPseHEhDHPyGtBtFa4nNm3crNhZ6Z7RSbdMSyiVXNep9",
  },
  "883-battle-street": {
    name: "883 Battle Street",
    biome: "mountain",
    frameType: "shuttered-pale-green",
    streetId: "battle-street",
    tokenAddress: "8eRD8sobvjrBtWccGeAHCTN21SwemwFNbAUuewkYRwom",
  },
  "884-battle-street": {
    name: "884 Battle Street",
    biome: "mountain",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "FWhQX69Lpfe91Jnqe1fSPFRNC7axQRfu59EhZ6WAzbXK",
  },
  "885-battle-street": {
    name: "885 Battle Street",
    biome: "mountain",
    frameType: "occult",
    streetId: "battle-street",
    tokenAddress: "CsNrbae2vrJfDe4SE3LzQdiZRe4K7ykU5KCNSKYhuSPD",
  },
  "886-battle-street": {
    name: "886 Battle Street",
    biome: "city",
    frameType: "rounded-yellow",
    streetId: "battle-street",
    tokenAddress: "4A1YfeSbMaH4Zc3BEYseskCXgKmxLM6kLZ3KZ4DBct6Z",
  },
  "886-broadway": {
    name: "886 Broadway",
    biome: "mountain",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "6AKFqrDAEQNePPvc4rBRK7ubN57ZJhHJidmHhJNLVmEb",
  },
  "887-battle-street": {
    name: "887 Battle Street",
    biome: "woods",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "7FDNSvZa137xN6MqtTCLskhXSDANwjfwURG2Sn4wnHxa",
  },
  "887-broadway": {
    name: "887 Broadway",
    biome: "desert",
    frameType: "shuttered-pale-green",
    streetId: "broadway",
    tokenAddress: "BBm2aNBnYbL2Ns4eYS9sbyaQrjxvpCcxvDHT9AMmz1gs",
  },
  "888-battle-street": {
    name: "888 Battle Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "PhQVPxNWsWthrdJJTKzbR4gndLfYzseyMrJdVsc4pqB",
  },
  "888-broadway": {
    name: "888 Broadway",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "broadway",
    tokenAddress: "C9xqjkfUyu1XmoS3EkgEbwPLZ6TGpBB3wHc8BBzvxcS1",
  },
  "889-battle-street": {
    name: "889 Battle Street",
    biome: "mountain",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "23nKpgrUXswGho1SuGWkzUL4WwUxKBWP6Y88SHS5xyVG",
  },
  "889-broadway": {
    name: "889 Broadway",
    biome: "field",
    frameType: "shuttered-lavender",
    streetId: "broadway",
    tokenAddress: "2Zj3PNFKa2MZk2SPbLvKzUcLv5o4CFfiutVaTo9qbRRs",
  },
  "89-abbey-road": {
    name: "89 Abbey Road",
    biome: "desert",
    frameType: "rounded-red",
    streetId: "abbey-road",
    tokenAddress: "3kd7B9bMaLPQgCuC5Mr9puqU4yy6yDeB13YQLyYqU68z",
  },
  "89-ape-lane": {
    name: "89 Ape Lane",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "ape-lane",
    tokenAddress: "B9GdJa8VwcijEFRzKQooYksRjfdiD4QAfEJ5uuM53R9K",
  },
  "89-king-street": {
    name: "89 King Street",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "king-street",
    tokenAddress: "2jWPJxi944UJ1C1EFt4sPKhREhRFDopdzqb4unNbyoc5",
  },
  "89-rosa-street": {
    name: "89 Rosa Street",
    biome: "desert",
    frameType: "rounded-yellow",
    streetId: "rosa-street",
    tokenAddress: "9jFeMzueenRZdhEFnr7Ud8Hbn8f337uWNW4kYvMhiVWp",
  },
  "89-supple-circle": {
    name: "89 Supple Circle",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "3kxHaAYo8fnUshngRoRPPvLfuUSN8EaqXAaBeX14hFuL",
  },
  "89-teeps-lane": {
    name: "89 Teeps Lane",
    biome: "underwater",
    frameType: "shuttered-pale-green",
    streetId: "teeps-lane",
    tokenAddress: "JB9nhUQLEmSbdZFnWAhKoWptYasz9du4BgAQALyJjb6U",
  },
  "89-wall-street": {
    name: "89 Wall Street",
    biome: "beach",
    frameType: "shuttered-blue",
    streetId: "wall-street",
    tokenAddress: "63FX3kbM5UohbeVpNxsYm7DKMQJp6jtcm1wyGFjnp26S",
  },
  "890-battle-street": {
    name: "890 Battle Street",
    biome: "woods",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "AxPXMFV3VMNy1aCEAh1wWChePKXZhmzYs3gUKjjc4Cob",
  },
  "891-battle-street": {
    name: "891 Battle Street",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "6oTNGhBG84KEMBPKDgpQMUDjFoeHgY6iFq89XvbAUbwb",
  },
  "892-battle-street": {
    name: "892 Battle Street",
    biome: "woods",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "EbnKx1rg5QxiFsSoUybkjjm1K4s6w2YdnGLrfDM7ndQm",
  },
  "893-battle-street": {
    name: "893 Battle Street",
    biome: "arctic",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "ADgGYEn1pKnqZKTEM23p8sWgXh95LWe2SSh5gT48b9Wz",
  },
  "894-battle-street": {
    name: "894 Battle Street",
    biome: "underwater",
    frameType: "shuttered-dark-green",
    streetId: "battle-street",
    tokenAddress: "JCiEBCndYzEjxR4FUbYjvwBi7ZuQWE5Fjvd3M39ubm1J",
  },
  "895-battle-street": {
    name: "895 Battle Street",
    biome: "city",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "WguPJHHnpbw73aZ6To9vayP18hEqdddpYBhNue7n2vN",
  },
  "896-battle-street": {
    name: "896 Battle Street",
    biome: "beach",
    frameType: "occult",
    streetId: "battle-street",
    tokenAddress: "FZHn4JPzaUivDHUvjufAYQRWsPsBBM4k5khZZJBK9kfP",
  },
  "897-battle-street": {
    name: "897 Battle Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "battle-street",
    tokenAddress: "2JYvT6dmkhgWgj3KApAuKg2QVwM4fvtvPJCNibC5yhZ9",
  },
  "898-battle-street": {
    name: "898 Battle Street",
    biome: "city",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "CmN26rYd7i5BVawj1L6eN1aYnvNArQj3HWxqsjjb8AS8",
  },
  "90-abbey-road": {
    name: "90 Abbey Road",
    biome: "field",
    frameType: "glass",
    streetId: "abbey-road",
    tokenAddress: "5PtSn535gCC8t3gLiVhcy2NxMRPPR2Hi9GKCPSpRPF1Q",
  },
  "90-rosa-street": {
    name: "90 Rosa Street",
    biome: "beach",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "H2HEAREofuTCEPbdoe8WDB8CdUi8jfUiE3PM4xVMwWDB",
  },
  "90-stewart-avenue": {
    name: "90 Stewart Avenue",
    biome: "woods",
    frameType: "occult",
    streetId: "stewart-avenue",
    tokenAddress: "5iLhJTs9bff4ZQs7y6TFhsD2RDd4iz4wVD3AtZmrjF9E",
  },
  "90-teeps-lane": {
    name: "90 Teeps Lane",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "teeps-lane",
    tokenAddress: "9QvwvGGM2vBmb7WQ2u4ySYi2wfvGjL6ch2Y1qZLkKH9m",
  },
  "90-wall-street": {
    name: "90 Wall Street",
    biome: "beach",
    frameType: "occult",
    streetId: "wall-street",
    tokenAddress: "FzSY1zzgMUi7UGj1rjR8Ne2NKafwy95mBfoVGbTZZnyC",
  },
  "900-battle-street": {
    name: "900 Battle Street",
    biome: "field",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "ApxYDb76Tux14dFX1gwaJkHH6mAeJ8XziRTy7wPjZnms",
  },
  "901-battle-street": {
    name: "901 Battle Street",
    biome: "field",
    frameType: "rounded-green",
    streetId: "battle-street",
    tokenAddress: "GFoojif4aySMuwS3fypvMRjQs31BgowDQEyCEy5zY23u",
  },
  "902-battle-street": {
    name: "902 Battle Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "Bmdv9NCHvbTQkN2iF5pcKumUsnGseEJ3GsCfY8hUSe5a",
  },
  "904-battle-street": {
    name: "904 Battle Street",
    biome: "city",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "7XZhfSyBmBTpoMFMrggoMGoFooQ4yyjfqxfDCKedTMSs",
  },
  "905-battle-street": {
    name: "905 Battle Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "5jw8Kb3EXEr7JViUsE49QxoXjxBGyK5o4JKW8okN5RWq",
  },
  "906-battle-street": {
    name: "906 Battle Street",
    biome: "field",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "FShSHsMwAMZCXmL7Wdo89RxrwXSWCwZsoSCUEtYT7spf",
  },
  "907-battle-street": {
    name: "907 Battle Street",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "BnCSRR5aDuKvqJv5xw6EFwL8aUUQrzn5exiVXEhmtBpD",
  },
  "908-battle-street": {
    name: "908 Battle Street",
    biome: "beach",
    frameType: "shuttered-red",
    streetId: "battle-street",
    tokenAddress: "97X4QK68GP6Pp35FEg8GPt9Y8zxWJ1VZLBMUickfZbpE",
  },
  "91-abbey-road": {
    name: "91 Abbey Road",
    biome: "treehouse",
    frameType: "occult",
    streetId: "abbey-road",
    tokenAddress: "38waR5nc9MqZrDtKq6SrjjUMkeYFoXXHPhKTHENa1oqd",
  },
  "91-rosa-street": {
    name: "91 Rosa Street",
    biome: "field",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "EpAXurwRGWsCJxxh5sRtUEZt9ZYeZypjX3BeTvzvaYDS",
  },
  "91-stewart-avenue": {
    name: "91 Stewart Avenue",
    biome: "underwater",
    frameType: "shuttered-red",
    streetId: "stewart-avenue",
    tokenAddress: "BDdsohGzMWjstmkZnGDzMtSzmV7i3ssyDo1DLjpoMNpE",
  },
  "91-supple-circle": {
    name: "91 Supple Circle",
    biome: "treehouse",
    frameType: "rounded-red",
    streetId: "supple-circle",
    tokenAddress: "Fwhj5RoWvbjyxm5JnFdPQiYsyEUQRiqU3pczGEUSX2HD",
  },
  "91-vibe-drive": {
    name: "91 Vibe Drive",
    biome: "city",
    frameType: "shuttered-gmoot",
    streetId: "vibe-drive",
    tokenAddress: "B9Msgcup3NPCGBXqm8n3pgbyE4F27i86KEa58HQ56LER",
  },
  "91-wall-street": {
    name: "91 Wall Street",
    biome: "field",
    frameType: "rounded-pale-green",
    streetId: "wall-street",
    tokenAddress: "4cd7ks7S8xPKTvgA9JCJ7qoxgQRLvHBcQwtBxbqxY4Tf",
  },
  "910-battle-street": {
    name: "910 Battle Street",
    biome: "desert",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "WQJJ4XfhvSN2UsLWujigJggy7Dq5MnHsXj1XctwmvF2",
  },
  "911-battle-street": {
    name: "911 Battle Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "battle-street",
    tokenAddress: "HVcJfEYXtkWEnuD8f9DJGdbk6CyFQiwyjKpXSuf7UjKp",
  },
  "912-battle-street": {
    name: "912 Battle Street",
    biome: "beach",
    frameType: "shuttered-dark-green",
    streetId: "battle-street",
    tokenAddress: "7yo4EX8qo3hsJEhacpZJD5MPUoYcAu2KrRx9ySmw7Yg1",
  },
  "913-battle-street": {
    name: "913 Battle Street",
    biome: "volcanic",
    frameType: "rounded-red",
    streetId: "battle-street",
    tokenAddress: "5ga79C5RGCSgEVCvPgf28nZDAS59fkcgEvvxH5sowc3g",
  },
  "915-battle-street": {
    name: "915 Battle Street",
    biome: "woods",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "7a9sMkNHpTjBzxmgqM2DGqUSUeSJmfv7eSye6U85DFjU",
  },
  "916-battle-street": {
    name: "916 Battle Street",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "F6ZnKLwyCU8YQRgZUwu5nXcDs7qimjnSch6nZgy4HNkH",
  },
  "917-battle-street": {
    name: "917 Battle Street",
    biome: "mountain",
    frameType: "shuttered-lavender",
    streetId: "battle-street",
    tokenAddress: "5qgX1g7qVaYUgbKzuyCRx5tKXPjc4HpcCrNSDdaeuSEv",
  },
  "918-battle-street": {
    name: "918 Battle Street",
    biome: "woods",
    frameType: "crystal",
    streetId: "battle-street",
    tokenAddress: "2NWbe5cvikWrCg4a8VupbyodehpJsLQHpErkJ1XYNFVK",
  },
  "92-ape-lane": {
    name: "92 Ape Lane",
    biome: "field",
    frameType: "crystal",
    streetId: "ape-lane",
    tokenAddress: "GLz4tWdcSnFhhptNHvXxE5ZN7u5qxecL6fBEimejdzja",
  },
  "92-rosa-street": {
    name: "92 Rosa Street",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "rosa-street",
    tokenAddress: "qHuWyk7yuJKiFJPSQ86CdZeW2Fs7reqpRRZqovTqrh9",
  },
  "92-supple-circle": {
    name: "92 Supple Circle",
    biome: "desert",
    frameType: "shuttered-dark-green",
    streetId: "supple-circle",
    tokenAddress: "DRhC9bKthfShcipB21MEb3dDmyAfVWbYdRh4cDzyD99H",
  },
  "920-battle-street": {
    name: "920 Battle Street",
    biome: "field",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "SeQSQzsdvff1F3RsjgVoH2Q91DBu1CGSxPM3WpKZWom",
  },
  "923-battle-street": {
    name: "923 Battle Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "5oebWzXix3eyU6qkLXQxQVmMad5Vct6emKH9BPy5Sqo",
  },
  "925-battle-street": {
    name: "925 Battle Street",
    biome: "desert",
    frameType: "shuttered-green",
    streetId: "battle-street",
    tokenAddress: "861XHZ4jraSZvwRRNBXdnNcziRvWcLa7eZkR5oPkywRh",
  },
  "926-battle-street": {
    name: "926 Battle Street",
    biome: "city",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "Hp5kt75jFrFEAPzahbCPthykHdtzYS9wbPFGdddreeC5",
  },
  "927-battle-street": {
    name: "927 Battle Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "9mNpbsa3r3HRDeZC5aMBug9XiZ8CrGfNGPEr96RYw8Ge",
  },
  "929-battle-street": {
    name: "929 Battle Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "battle-street",
    tokenAddress: "Ed3nyFrA5eun7zNrsYYXAdt4AyGYkmCTB73vQDhoFRVv",
  },
  "93-ape-lane": {
    name: "93 Ape Lane",
    biome: "city",
    frameType: "shuttered-green",
    streetId: "ape-lane",
    tokenAddress: "6iytWEkqUeJi1fMaZBn5ut4XnSG1HYwVMMjhWPDtR4w3",
  },
  "93-rosa-street": {
    name: "93 Rosa Street",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "rosa-street",
    tokenAddress: "61khHTVUmeUscGwvW97ACAxm9HRT51gShc1XL3HxjR3v",
  },
  "93-supple-circle": {
    name: "93 Supple Circle",
    biome: "beach",
    frameType: "occult",
    streetId: "supple-circle",
    tokenAddress: "HJT9kSm9385FY81da1AP5CTkr3nrEMiiRLNC8nx47Ton",
  },
  "933-battle-street": {
    name: "933 Battle Street",
    biome: "underwater",
    frameType: "glass",
    streetId: "battle-street",
    tokenAddress: "5dP9TEQ5fW1ngofjC1aW5zVxCbjRUCVGNCoBdhXWfWU1",
  },
  "94-ape-lane": {
    name: "94 Ape Lane",
    biome: "mountain",
    frameType: "rounded-green",
    streetId: "ape-lane",
    tokenAddress: "43trYa9VKVTgY88UDgAxHdc4b3HaMkX5kPickrEqy7Pt",
  },
  "94-rosa-street": {
    name: "94 Rosa Street",
    biome: "mountain",
    frameType: "shuttered-blue",
    streetId: "rosa-street",
    tokenAddress: "AZVkAi6tx9kMc4b9jTh4vhL7g49p298qVB1Cao8Fm2Np",
  },
  "94-stewart-avenue": {
    name: "94 Stewart Avenue",
    biome: "woods",
    frameType: "shuttered-blue",
    streetId: "stewart-avenue",
    tokenAddress: "6ApoJ79U9pbXA419kZpHQJArPrB32NUuWvzmJazMKFxZ",
  },
  "94-supple-circle": {
    name: "94 Supple Circle",
    biome: "beach",
    frameType: "shuttered-lavender",
    streetId: "supple-circle",
    tokenAddress: "5kkMTcQ55usF6SHdK7H1976F9Tm3LfE1a1xDmcKiZpeB",
  },
  "95-ape-lane": {
    name: "95 Ape Lane",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "6okRJojt6uwC7UtDYWtcwoLJzQ6GnqFkppp1jn6tD9ib",
  },
  "95-rosa-street": {
    name: "95 Rosa Street",
    biome: "underwater",
    frameType: "shuttered-green",
    streetId: "rosa-street",
    tokenAddress: "8w34QHvXTAHNxXj6NLeg62zs9ULW7Hw5bRo1GvHqA63j",
  },
  "95-supple-circle": {
    name: "95 Supple Circle",
    biome: "mountain",
    frameType: "rounded-yellow",
    streetId: "supple-circle",
    tokenAddress: "2VvGhYWDLm1qG3qoEm9xCXWMQpGv68VtfK2WSJrWy8LH",
  },
  "95-teeps-lane": {
    name: "95 Teeps Lane",
    biome: "city",
    frameType: "shuttered-blue",
    streetId: "teeps-lane",
    tokenAddress: "GCLkKrnvTBuMdAhDyLAsiBirbafpsWATgXf3aefscC4A",
  },
  "95-wall-street": {
    name: "95 Wall Street",
    biome: "woods",
    frameType: "shuttered-red",
    streetId: "wall-street",
    tokenAddress: "DD56ZNGGw9YTT9NrmrdXgSu4CjE4h2YrJK6BvBJAiVxf",
  },
  "96-ape-lane": {
    name: "96 Ape Lane",
    biome: "underwater",
    frameType: "shuttered-yellow",
    streetId: "ape-lane",
    tokenAddress: "2o9HnXr2h64pHR5pcWgML9tWCU3A8V7xLjPgmyHdrCSW",
  },
  "96-rosa-street": {
    name: "96 Rosa Street",
    biome: "beach",
    frameType: "glass",
    streetId: "rosa-street",
    tokenAddress: "3sJ4qrxVviuLbgVpWCjVGEH1hcw7ju4aq5KNfBV12Gf2",
  },
  "96-stewart-avenue": {
    name: "96 Stewart Avenue",
    biome: "underwater",
    frameType: "glass",
    streetId: "stewart-avenue",
    tokenAddress: "38A4bhNYEXxnPF1wHesVM7PUGjtRNcbiWxu4TduyMuRb",
  },
  "96-supple-circle": {
    name: "96 Supple Circle",
    biome: "underwater",
    frameType: "crystal",
    streetId: "supple-circle",
    tokenAddress: "6W5Ge1RiSy9ipTVoibH9TWdoATaubGnvVWuFaau1DqNv",
  },
  "96-wall-street": {
    name: "96 Wall Street",
    biome: "beach",
    frameType: "rounded-red",
    streetId: "wall-street",
    tokenAddress: "DYou6Pf25eHqnFYYVMyikrQjxAh8ug7tW6835CkorZR7",
  },
  "97-rosa-street": {
    name: "97 Rosa Street",
    biome: "field",
    frameType: "gold",
    streetId: "rosa-street",
    tokenAddress: "397tLwKiqBjZtLoyrUGVwns9kb7ub7RaW2MEVxF7bdQz",
  },
  "97-supple-circle": {
    name: "97 Supple Circle",
    biome: "field",
    frameType: "shuttered-pale-green",
    streetId: "supple-circle",
    tokenAddress: "qJ7nNw8JS7M6H12E1vFSsLMM1EFb1tZ9ifuVbftVydN",
  },
  "97-teeps-lane": {
    name: "97 Teeps Lane",
    biome: "field",
    frameType: "occult",
    streetId: "teeps-lane",
    tokenAddress: "AfMWSSUH9qdXnrBwdQLyC8V67xcC1qNRBzrB9UvfHgVE",
  },
  "97-wall-street": {
    name: "97 Wall Street",
    biome: "field",
    frameType: "shuttered-yellow",
    streetId: "wall-street",
    tokenAddress: "FCAekcruKUhwFBM8QYTWwt6whxTaG1PFMNroPg51gNHC",
  },
  "98-ape-lane": {
    name: "98 Ape Lane",
    biome: "city",
    frameType: "rounded-red",
    streetId: "ape-lane",
    tokenAddress: "EMuCgZRYLq38ad362MFaPTmPDbxC9XMWgKxPuRKScsWB",
  },
  "98-rosa-street": {
    name: "98 Rosa Street",
    biome: "arctic",
    frameType: "crystal",
    streetId: "rosa-street",
    tokenAddress: "5D3v54zPRwrhpNai2HJTvBtvN1E3ZsLVG34D8WFc74Wk",
  },
  "98-supple-circle": {
    name: "98 Supple Circle",
    biome: "field",
    frameType: "rounded-red",
    streetId: "supple-circle",
    tokenAddress: "F2xHibCWFfquFM4y5phz8tB1o2voMnyMtwhYoLuEXfSG",
  },
  "98-teeps-lane": {
    name: "98 Teeps Lane",
    biome: "field",
    frameType: "rounded-blue",
    streetId: "teeps-lane",
    tokenAddress: "Apqkw1D49T5dY6z7PN8LggoZMvyMKStkz9k8cV8WAdUc",
  },
  "99-ape-lane": {
    name: "99 Ape Lane",
    biome: "underwater",
    frameType: "glass",
    streetId: "ape-lane",
    tokenAddress: "3aXpqG9PGf9sdSQzq8tK1CqBcmBoZDdZRqAmhR2MZNiR",
  },
  "99-stewart-avenue": {
    name: "99 Stewart Avenue",
    biome: "volcanic",
    frameType: "occult",
    streetId: "stewart-avenue",
    tokenAddress: "ebZNgv1HerjfiCHcTTp1waDNKNWeuQoBnv6wyPN6haE",
  },
  "99-supple-circle": {
    name: "99 Supple Circle",
    biome: "desert",
    frameType: "shuttered-yellow",
    streetId: "supple-circle",
    tokenAddress: "DHFV8rvYbB2eyEGcDJeAr1FXKM8RAvkFbfos7oAtd1xJ",
  },
  "99-wall-street": {
    name: "99 Wall Street",
    biome: "volcanic",
    frameType: "glass",
    streetId: "wall-street",
    tokenAddress: "35ey2mZGp12wqKBhAPvJZ4ztmTE2iUp8kzSq8BAERKBd",
  },
};
