import { web3 } from "@project-serum/anchor";

export enum MarketplaceWallets {
  SOLANART = "3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK",
  DIGITAL_EYES = "F4ghBzHFNgJxV4wEQDchU5i7n4XWWMBSaq7CuswGiVsr",
  MAGIC_EDEN = "GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp",
  ALPHA_ART = "4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw",
  EXCHANGE_ART = "BjaNzGdwRcFYeQGfuLYsc1BbaNRG1yxyWs1hZuGRT8J2",
  // FTX is weird we need to use their api to see tokens listed there
  // FTX = "73tF8uN3BwVzUzwETv59WNAafuEBct2zTgYbYXLggQiU",
}

export function isMarketplaceWallet(walletAddress: web3.PublicKey) {
  return MARKETPLACE_WALLETS.includes(walletAddress.toString());
}

export const MARKETPLACE_WALLETS: string[] = [
  MarketplaceWallets.SOLANART,
  MarketplaceWallets.DIGITAL_EYES,
  MarketplaceWallets.MAGIC_EDEN,
  MarketplaceWallets.ALPHA_ART,
  MarketplaceWallets.EXCHANGE_ART,
];

export const MARKETPLACE_NAME_MAP: { [key in MarketplaceWallets]: string } = {
  "3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK": "Solanart",
  F4ghBzHFNgJxV4wEQDchU5i7n4XWWMBSaq7CuswGiVsr: "Digital Eyes",
  GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp: "Magic Eden",
  "4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw": "Alpha Art",
  BjaNzGdwRcFYeQGfuLYsc1BbaNRG1yxyWs1hZuGRT8J2: "Exchange.Art",
};

export const MARKETPLACE_LINK_BUILDER_MAP: {
  [key in MarketplaceWallets]: (tokenAddress: string) => string;
} = {
  "3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK": (tokenAddress: string) => {
    return `https://solanart.io/search/?token=${tokenAddress}`;
  },
  F4ghBzHFNgJxV4wEQDchU5i7n4XWWMBSaq7CuswGiVsr: (tokenAddress: string) => {
    return `https://digitaleyes.market/item/${tokenAddress}`;
  },
  GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp: (tokenAddress: string) => {
    return `https://www.magiceden.io/item-details/${tokenAddress}`;
  },
  "4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw": (tokenAddress: string) => {
    return `https://alpha.art/t/${tokenAddress}`;
  },
  BjaNzGdwRcFYeQGfuLYsc1BbaNRG1yxyWs1hZuGRT8J2: (tokenAddress: string) => {
    return `https://exchange.art/single/${tokenAddress}`;
  },
};
