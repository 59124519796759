import arcticIcon from "../../public/images/icon_biome_arctic.svg";
import beachIcon from "../../public/images/icon_biome_beach.svg";
import cityIcon from "../../public/images/icon_biome_city.svg";
import desertIcon from "../../public/images/icon_biome_desert.svg";
import fieldIcon from "../../public/images/icon_biome_field.svg";
import mountainIcon from "../../public/images/icon_biome_mountain.svg";
import treehouseIcon from "../../public/images/icon_biome_treehouse.svg";
import underwaterIcon from "../../public/images/icon_biome_underwater.svg";
import volcanicIcon from "../../public/images/icon_biome_volcanic.svg";
import woodsIcon from "../../public/images/icon_biome_woods.svg";

export const BIOME_ICON_MAP: { [key: string]: string } = {
  arctic: arcticIcon,
  beach: beachIcon,
  city: cityIcon,
  desert: desertIcon,
  field: fieldIcon,
  mountain: mountainIcon,
  treehouse: treehouseIcon,
  underwater: underwaterIcon,
  volcanic: volcanicIcon,
  woods: woodsIcon,
};
